import { createAsyncReducer } from '~/lib/redux';
import { success } from '~/lib/redux/async';

import { InvestSectionDto } from '~/entities/anketa';

import {
  anketaGetInvestSectionStartAction,
  anketaGetInvestSectionSuccessAction,
  anketaGetInvestSectionErrorAction,
  anketaSaveInvestSectionSuccessAction,
  anketaUpdateInvestSectionSuccessAction,
} from '../actions';

export const STATE_NAME = 'investSection';

const [reducer, defaultState] = createAsyncReducer(
  anketaGetInvestSectionStartAction,
  anketaGetInvestSectionSuccessAction,
  anketaGetInvestSectionErrorAction,
);

const successReducer = (_: unknown, payload: InvestSectionDto): AnketaInvestSectionState =>
  success<InvestSectionDto>(payload);

reducer.on(anketaSaveInvestSectionSuccessAction, successReducer);
reducer.on(anketaUpdateInvestSectionSuccessAction, successReducer);

export type AnketaInvestSectionState = typeof defaultState;

export default reducer;
