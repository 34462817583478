import { AppError } from "~/entities/app";

import { KeycloakResponseFailModel } from "~/entities/keycloak";

export class KeycloakResponseError extends AppError {
  public readonly type: string = "KeycloakResponseError";

  public data: KeycloakResponseFailModel;

  public static is = (value: unknown): value is KeycloakResponseError =>
    !!value && value instanceof KeycloakResponseError;

  public constructor(data: KeycloakResponseFailModel, error?: Error) {
    super(error);

    this.data = data;
  }
}
