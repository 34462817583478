import { mimeType } from "~/constants/enums";

export type CustomFile = {
  file: File;
  hasError: boolean;
  errorText: string;
};

export enum SendTo {
  AM = "AM",
  CLIENTS = "CLIENTS",
  MIDDLE_OFFICE = "MIDDLE_OFFICE",
}

export type FeedbackRequestDto = {
  subject: string; // Тема письма
  body: string; // Содержание письма
  documents?: File[]; // список файлов
  clientEmail: string;
  clientFullName: string;
  clientAgreement: string;
  sendTo?: SendTo;
};

export const mimeTypes = [
  mimeType.png,
  mimeType.jpeg,
  mimeType.gif,
  mimeType.pdf,
  mimeType.doc,
  mimeType.xlsx,
  mimeType.excel,
  mimeType.msword,
];

export const messageMaxLength = 4095;
export const megabyte = 1048576;
export const maxFilesCount = 5;
export const subjectMaxLength = 255;
