import { AffordToLoseBOEnum, InvestmentObjectiveBOEnum, InvestPeriodBOEnum, RatioBOEnum } from "~/constants/enums";

export const investmentObjectiveArrayBO = [
  {
    label: "OPEN_ACCOUNT.investSection.selectors.investmentObjective.1",
    val: InvestmentObjectiveBOEnum.INFLATION_PROTECTION,
  },
  {
    label: "OPEN_ACCOUNT.investSection.selectors.investmentObjective.2",
    val: InvestmentObjectiveBOEnum.FUTURE_PENSION_SURPLUS,
  },
  {
    label: "OPEN_ACCOUNT.investSection.selectors.investmentObjective.3",
    val: InvestmentObjectiveBOEnum.PURPOSE_SAVING,
  },
  { label: "OPEN_ACCOUNT.investSection.selectors.investmentObjective.5", val: InvestmentObjectiveBOEnum.EXTRA_INCOME },
  { label: "OPEN_ACCOUNT.investSection.selectors.investmentObjective.6", val: InvestmentObjectiveBOEnum.BASIS_INCOME },
];
export const investmentObjectiveArray = [
  { label: "OPEN_ACCOUNT.investSection.selectors.investmentObjective.1", val: 0 },
  { label: "OPEN_ACCOUNT.investSection.selectors.investmentObjective.2", val: 1 },
  { label: "OPEN_ACCOUNT.investSection.selectors.investmentObjective.3", val: 2 },
  { label: "OPEN_ACCOUNT.investSection.selectors.investmentObjective.4", val: 3 },
  { label: "OPEN_ACCOUNT.investSection.selectors.investmentObjective.5", val: 4 },
  { label: "OPEN_ACCOUNT.investSection.selectors.investmentObjective.6", val: 5 },
  { label: "OPEN_ACCOUNT.investSection.selectors.investmentObjective.7", val: 6 },
];
export const savingsParts = [
  { label: "OPEN_ACCOUNT.investSection.selectors.savingsPart.1", val: 0 },
  { label: "OPEN_ACCOUNT.investSection.selectors.savingsPart.2", val: 1 },
  { label: "OPEN_ACCOUNT.investSection.selectors.savingsPart.3", val: 2 },
  { label: "OPEN_ACCOUNT.investSection.selectors.savingsPart.4", val: 3 },
];
export const investPeriod = [
  { label: "OPEN_ACCOUNT.investSection.selectors.investPeriod.1", val: 0 },
  { label: "OPEN_ACCOUNT.investSection.selectors.investPeriod.2", val: 1 },
  { label: "OPEN_ACCOUNT.investSection.selectors.investPeriod.3", val: 2 },
];
export const investPeriodBO = [
  { label: "OPEN_ACCOUNT.investSection.selectors.investPeriod.1", val: InvestPeriodBOEnum.LESS_THAN_5_YEARS },
  { label: "OPEN_ACCOUNT.investSection.selectors.investPeriod.2", val: InvestPeriodBOEnum.FROM_5_TO_10_YEARS },
  { label: "OPEN_ACCOUNT.investSection.selectors.investPeriod.3", val: InvestPeriodBOEnum.MORE_THAN_10_YEARS },
];
export const ratioArrayBO = [
  {
    label: "OPEN_ACCOUNT.investSection.selectors.ratio.1",
    val: RatioBOEnum.INCOME_MUCH_GREATER_EXPENSES,
  },
  {
    label: "OPEN_ACCOUNT.investSection.selectors.ratio.2",
    val: RatioBOEnum.INCOME_GREATER_EXPENSES,
  },
  { label: "OPEN_ACCOUNT.investSection.selectors.ratio.3", val: RatioBOEnum.INCOME_EQUAL_EXPENSES },
  { label: "OPEN_ACCOUNT.investSection.selectors.ratio.4", val: RatioBOEnum.EXPENSES_GREATER_INCOME },
];
export const ratioArray = [
  { label: "OPEN_ACCOUNT.investSection.selectors.ratio.1", val: 0 },
  { label: "OPEN_ACCOUNT.investSection.selectors.ratio.2", val: 1 },
  { label: "OPEN_ACCOUNT.investSection.selectors.ratio.3", val: 2 },
  { label: "OPEN_ACCOUNT.investSection.selectors.ratio.4", val: 3 },
];
export const affordToLose = [
  { label: "OPEN_ACCOUNT.investSection.selectors.affordToLose.1", val: 0 },
  { label: "OPEN_ACCOUNT.investSection.selectors.affordToLose.2", val: 1 },
  { label: "OPEN_ACCOUNT.investSection.selectors.affordToLose.3", val: 2 },
];
export const affordToLoseBO = [
  {
    label: "OPEN_ACCOUNT.investSection.selectors.affordToLose.1",
    val: AffordToLoseBOEnum.SMALL_SHORT_TERM_INVEST_LOSS,
  },
  {
    label: "OPEN_ACCOUNT.investSection.selectors.affordToLose.2",
    val: AffordToLoseBOEnum.MODERATE_MID_TERM_INVEST_LOSS,
  },
  { label: "OPEN_ACCOUNT.investSection.selectors.affordToLose.3", val: AffordToLoseBOEnum.SIGNIFICANT_INVEST_LOSS },
];
export const chooseArray = [
  {
    label: "GENERAL.yes",
    value: true,
  },
  {
    label: "GENERAL.no",
    value: false,
  },
];
