import React, { ReactNode, useCallback } from "react";
import { Outlet } from "react-router-dom";
import { Box, Container, Grid } from "@mui/material";

import { links } from "~/constants/enums";
import { Footer } from "~/features/Footer/Footer";
import { RegistrationProgressBar } from "~/features/UnauthAndBlocked";
import { useTranslation } from "~/i18n";
import { useRouterHooks } from "~/lib/router";
import { WithNotificationModal } from "~/widgets/WithNotificationModal";
import ViewportHeader from "../BaseLayout/components/ViewportHeader";
import { useStyles } from "./styles";

export interface UnauthorizedLayoutProps {
  children?: ReactNode;
}

const UnauthorizedLayout = ({ children }: UnauthorizedLayoutProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { location } = useRouterHooks();

  const headerTitle = useCallback(() => {
    if (location.pathname?.includes(links.login)) {
      return <h1 className={classes.pageTitle}>{t("LOGIN.welcome")}</h1>;
    }

    if (location.pathname?.includes(links.forgotPassword)) {
      return <h1 className={classes.pageTitle}>{t("FORGOT_PASSWORD.title")}</h1>;
    }

    if (location.pathname?.includes(links.registration)) {
      return <h1 className={classes.pageTitle}>{t("REGISTRATION.title")}</h1>;
    }
    if (location.pathname?.includes(links.unblocking)) {
      return <h1 className={classes.pageTitle}>{t("UNBLOCKING_USER.title")}</h1>;
    }
  }, [location.pathname]);

  return (
    <WithNotificationModal>
      <Grid container direction="column" alignItems="stretch" wrap="nowrap" className={classes.root}>
        <Box className={classes.containerWrapper}>
          <ViewportHeader unAuthorized={true} />
          <Grid item className={classes.container}>
            <Box>{headerTitle()}</Box>
            {location.pathname === links.registration && (
              <Box style={{ width: "100%" }}>
                <RegistrationProgressBar />
              </Box>
            )}
            <Container maxWidth={false} className={classes.container2}>
              {children || <Outlet />}
            </Container>
          </Grid>
          <Grid className={classes.footerWrapper}>
            <Footer />
          </Grid>
        </Box>
      </Grid>
    </WithNotificationModal>
  );
};

export default UnauthorizedLayout;
