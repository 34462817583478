import React, { ReactElement } from "react";
import { Box } from "@mui/material";
import { nanoid } from "nanoid";
import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";
import { INotificationButtonProps } from "~/shared/types";
import { BaseButton } from "~/shared/ui-kit/BaseComponents";

const useStyles = makeStyles()(() => ({
  wrapperBtn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "0 40px",
    marginTop: "32px",

    [themeV5.breakpoints.down(768)]: {
      flexDirection: "column-reverse",
      gap: "16px 0",
      width: "100%",
      marginTop: "16px",
    },
  },
  blackButton: {
    minWidth: "135px",
    [themeV5.breakpoints.down(768)]: {
      width: "100%",
    },
  },
}));

interface TModalButtonGroupProps {
  docBtn?: ReactElement;
  buttons: INotificationButtonProps[];
}

// внутри строки в языковых файлах можно оставлять ссылки на почту и телефон
// если нужно прокинуть элемент, то прокидываем его одним из элементов массива

export const ModalButtonGroup = ({ docBtn, buttons }: TModalButtonGroupProps): ReactElement => {
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapperBtn}>
      {docBtn}
      {buttons.map((el) => (
        <BaseButton
          className={el.className || classes.blackButton}
          onClick={el.buttonHandler}
          typeStyle={el.typeStyle}
          key={nanoid()}
        >
          <span>{el.buttonText}</span>
        </BaseButton>
      ))}
    </Box>
  );
};
