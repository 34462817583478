import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { routes } from "~/constants/enums";
import Loader from "~/features/Loader";
import { NotAvailable } from "./NotAvailable";

export const TechnicalWorks = () => {
  return (
    <Routes>
      <Route
        path={routes.notAvailable}
        element={
          <Suspense fallback={<Loader />}>
            <NotAvailable />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to={routes.notAvailable} replace />} />
    </Routes>
  );
};
