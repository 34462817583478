import React, { memo, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Box } from "@mui/material";
import cn from "classnames";
import { range } from "lodash";
import { makeStyles } from "tss-react/mui";

import SectionError from "~/features/SectionError";
import { appApiUrl } from "~/lib/request";
import token from "~/lib/token";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

interface IProps {
  url?: string;
  base64?: string;
  onLoad: () => void;
  onError: (error: Error) => void;
  className?: string;
  isLoaded?: boolean;
  setPageHeight?: (height: number) => void;
}

const PdfDocument = ({ url, base64, onLoad, onError, className, isLoaded, setPageHeight }: IProps): ReactElement => {
  const [count, setCount] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (setPageHeight && isLoaded && ref?.current?.offsetHeight && ref?.current?.offsetHeight > 0) {
      setPageHeight(ref.current?.offsetHeight);
    }
  }, [loaded, count, isLoaded, setPageHeight]);

  const handleDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }): void => setCount(numPages),
    [setCount]
  );
  const handleDocumentLoadError = useCallback((err: Error): void => onError(err), [onError]);
  const handlePageRenderError = useCallback((): void => setLoaded((value) => value + 1), [setLoaded]);
  const handlePageRenderSuccess = useCallback((): void => setLoaded((value) => value + 1), [setLoaded]);

  const documentFile = useMemo(() => {
    if (url) {
      const authorizationBearerTokenFromStorage = token.getAuthorizationBearerToken();

      if (authorizationBearerTokenFromStorage) {
        return {
          url: appApiUrl(url),
          httpHeaders: {
            Authorization: `Bearer ${authorizationBearerTokenFromStorage}`,
          },
        };
      }
      return url;
    } else if (base64) {
      return base64;
    }
  }, [url, base64]);

  useEffect(() => {
    if (count > 0 && count === loaded) {
      onLoad();
    }
  }, [count, loaded, onLoad]);

  const canvasPageStyles = makeStyles()({
    root: {
      "& canvas": {
        maxWidth: "970px",
        width: "100% !important",
        height: "auto !important",
        filter: "drop-shadow(0px 4px 8px rgba(31, 45, 63, 0.03)) drop-shadow(0px 8px 32px rgba(31, 45, 63, 0.1))",
      },
    },
  });

  const options = useMemo(
    () => ({
      isEvalSupported: false,
    }),
    []
  );

  const { classes: canvasPage } = canvasPageStyles();

  return (
    <Document
      file={documentFile}
      onLoadSuccess={handleDocumentLoadSuccess}
      onLoadError={handleDocumentLoadError}
      loading=""
      options={options}
      error={
        <Box mt={6} mb={6}>
          <SectionError />
        </Box>
      }
    >
      {count > 0 &&
        range(count).map((page) => (
          <Page
            inputRef={ref}
            className={cn(canvasPage.root, className)}
            key={page}
            pageNumber={page + 1}
            width={970}
            loading=""
            renderAnnotationLayer={false}
            renderTextLayer={false}
            onRenderError={handlePageRenderError}
            onRenderSuccess={handlePageRenderSuccess}
          />
        ))}
    </Document>
  );
};

export default memo(PdfDocument);
