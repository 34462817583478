import PropTypes from 'prop-types';

export interface AnyTargetEvent {
  target: {
    name?: string;
    value: string;
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const formikPropTypes = () => ({
  submitForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitCount: PropTypes.number.isRequired,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const formikFieldComponentPropTypes = () => ({
  field: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired as typeof PropTypes.any,
  form: PropTypes.shape(formikPropTypes()).isRequired as typeof PropTypes.any,
});

export const preventDefaultCallback = (event: { preventDefault: () => unknown }): void => {
  event.preventDefault();
};
