import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useEconomySectionStyles = makeStyles()(() => ({
  economySection: {
    display: "grid",
    gap: "40px",
  },
  switchIndividual: {
    background: colorTable.lightGrey,
    padding: "12px 24px",
    width: "100% !important",
    justifyContent: "space-between !important",
    borderRadius: "4px",
  },
  mbFormBlockIndividual: {
    marginBottom: "40px",
  },
  errorRadio: {
    color: colorTable.red,
  },
  ptFormBlock: {
    paddingTop: "40px",
  },
}));
