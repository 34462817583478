import { Store } from "effector";
import { Rule } from "effector-forms";
import moment from "moment";

import { megabyte, mimeTypes } from "~/entities/feedback";
import { isDateLessThanToday, isMinDateValid } from "~/lib/date";

export const filesRules = {
  required: (): Rule<string | null> => ({
    name: "required",
    validator: (file) => Boolean(file),
    errorText: "VALIDATION.required-field",
  }),
  maxFileSize: (mb: number): Rule<File> => ({
    name: "file size",
    validator: (file) => file.size >= megabyte * mb,
    errorText: "OPEN_ACCOUNT.errors.file-too-big",
  }),
  fileType: (): Rule<File> => ({
    name: "file type",
    validator: (file) => mimeTypes.some((type) => file.type === type),
    errorText: "OPEN_ACCOUNT.errors.invalid-format",
  }),
};

export const newDateRules = {
  required: (booleanSource?: Store<boolean>): Rule<Date | null> => ({
    name: "required",
    source: booleanSource,
    validator: (date, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate ? Boolean(date) : true;
      return Boolean(date);
    },
    errorText: "VALIDATION.required-field",
  }),
  lessToday: (
    booleanSource?: Store<boolean>,
    errorText = "OPEN_ACCOUNT.errors.date_less_today"
  ): Rule<Date | null | string> => ({
    name: "lessToday",
    source: booleanSource,
    validator: (date, _, needToValidate) => {
      if (date) {
        if (needToValidate !== null) {
          return needToValidate ? isDateLessThanToday(date) : true;
        }
        return isDateLessThanToday(date);
      }
      return true;
    },
    errorText,
  }),
  minDate: (booleanSource?: Store<boolean>): Rule<Date | null | string> => ({
    name: "minDate",
    source: booleanSource,
    validator: (date, _, needToValidate) => {
      if (date) {
        const isValid = isMinDateValid(date);
        if (needToValidate !== null) return needToValidate ? isValid : true;
        return isValid;
      }
      return true;
    },
    errorText: "OPEN_ACCOUNT.errors.licence_min_date",
  }),
  requiredDate: (booleanSource?: Store<boolean>): Rule<Date | null | string> => ({
    name: "requiredDate",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate ? Boolean(val) : true;
      else return Boolean(val);
    },
    errorText: "VALIDATION.required-field",
  }),
  validDate: (booleanSource?: Store<boolean>, customError = "VALIDATION.invalid_date"): Rule<Date | null | string> => ({
    name: "validDate",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (!val && needToValidate) return false;

      if (val && needToValidate) {
        return moment(val).isValid();
      } else return true;
    },
    errorText: customError,
  }),
};

export const dateRules = {
  required: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "required",
    source: booleanSource,
    validator: (date, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate ? Boolean(date) : true;
      return Boolean(date);
    },
    errorText: "VALIDATION.required-field",
  }),
  lessToday: (booleanSource?: Store<boolean>, errorText = "OPEN_ACCOUNT.errors.date_less_today"): Rule<string> => ({
    name: "lessToday",
    source: booleanSource,
    validator: (date, _, needToValidate) => {
      if (date) {
        if (needToValidate !== null) return needToValidate ? isDateLessThanToday(date) : true;
        return isDateLessThanToday(date);
      }
      return true;
    },
    errorText,
  }),
  minDate: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "minDate",
    source: booleanSource,
    validator: (date, _, needToValidate) => {
      if (date) {
        if (needToValidate !== null) return needToValidate ? isMinDateValid(date) : true;
        return isMinDateValid(date);
      }
      return true;
    },
    errorText: "OPEN_ACCOUNT.errors.licence_min_date",
  }),
};
