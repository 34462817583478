import React, { ReactNode, useMemo, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import cn from "classnames";

import { BaseTooltip } from "~/features/BaseComponents";
import Icon from "~/shared/Icon";
import { IconSize } from "~/shared/Icon/Icon";
import { useStyles } from "./styles";

export interface BaseAccordionProps {
  title: string | ReactNode;
  subTitle?: string | object;
  tooltipText?: string | object;
  children: ReactNode;
  defaultExpanded?: boolean;
  expanded?: boolean;
  disabled?: boolean;
  style?: object;
  flexDirectionContent?: string;
  disableExpandButton?: boolean;
  chevronSize?: IconSize;
  className?: string;
  childrenClassName?: string;
}

export const BaseAccordion = ({
  title = "",
  subTitle = "",
  tooltipText = "",
  children = <></>,
  defaultExpanded = true,
  disabled = false,
  style = {},
  expanded = true,
  flexDirectionContent = "column",
  disableExpandButton,
  chevronSize = 14,
  className,
  childrenClassName,
}: BaseAccordionProps) => {
  const { classes } = useStyles();
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded);
  const accordionTitle = useMemo(() => {
    if (tooltipText) {
      return (
        <BaseTooltip text={tooltipText}>
          <Typography className={classes.title}>{title}</Typography>
        </BaseTooltip>
      );
    }
    if (typeof title === "string") {
      return <Typography className={classes.title}>{title}</Typography>;
    }
    return title;
  }, [tooltipText, title]);

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      disabled={disabled}
      className={cn(classes.accordionWrapper, className)}
      style={style}
      expanded={disableExpandButton ? true : isExpanded}
      onChange={(_, expanded) => setIsExpanded(expanded)}
    >
      <AccordionSummary
        expandIcon={
          !disableExpandButton ? <Icon name="chevronAccordion" size={chevronSize} className={classes.icon} /> : null
        }
      >
        {accordionTitle}
      </AccordionSummary>
      <AccordionDetails
        //@ts-ignore
        style={{ flexDirection: `${flexDirectionContent}` }}
      >
        {subTitle && <Typography className={classes.subTitle}>{subTitle}</Typography>}
        <Box className={cn(classes.innerWrap, childrenClassName)}>{children}</Box>
      </AccordionDetails>
    </Accordion>
  );
};
