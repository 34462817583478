import React, { Fragment, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDebounce } from "react-use";
import { Box } from "@mui/material";

import { PrintForm, PrintForms } from "~/entities/common";
import Loader from "~/features/Loader";
import InView from "~/shared/ui/InView";
import PdfDocument from "~/shared/ui/PdfDocument";
import VisibilityControl from "~/shared/ui/VisibilityControl";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import AcceptFormAction from "./components/AcceptFormAction";
import Actions from "./components/Actions";
import Content from "./components/Content";
import Dialog from "./components/Dialog";
import Header from "./components/Header";

export interface PdfConfirmationsModalProps {
  files: PrintForms | PrintForm[];
  otpForm?: ReactNode;
  watchText?: string;
  onClose: () => void;
  onSave: (files: PrintForms) => void;
  children?: never;
  open?: boolean;
  acceptText?: () => string;
  disableActions?: boolean;
  filesLoad?: boolean;
  isAsset?: boolean;
  readonly?: boolean;
  headerText?: string;
  pending?: boolean;
  isOne?: boolean;
  action?: () => void;
  dopText?: any;
  onAccept?: () => void;
}

const PdfConfirmationsModal = ({
  files,
  otpForm,
  onClose,
  onSave,
  watchText,
  open = true,
  acceptText,
  disableActions,
  isAsset,
  readonly,
  headerText,
  pending,
  isOne,
  action,
  dopText = "",
  onAccept,
}: PdfConfirmationsModalProps) => {
  const ref = useRef<{ scroll: () => void } | null>(null);
  const [loaded, setLoaded] = useState(0);
  const [viewed, setViewed] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isLoadedDebounce, setIsLoadedDebounce] = useState(false);
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);

  const currentDocument: PrintForm | undefined = useMemo(
    () => files[currentDocumentIndex] || undefined,
    [files, currentDocumentIndex]
  );

  const isLoaded = loaded === files.length;

  const handleContentScroll = useCallback(
    (value: number): void => {
      if (isLoadedDebounce) {
        setProgress(value);
      }
    },
    [setProgress, isLoadedDebounce]
  );

  const handleOnLoad = useCallback((): void => {
    setLoaded((value) => (value + 1 <= files.length ? value + 1 : files.length));
  }, [setLoaded, files.length]);

  const handleOnError = useCallback((): void => {
    setLoaded(files.length);
  }, [setLoaded, files.length]);

  const handleOnAccept = useCallback((): void => {
    setAccepted(true);
    if (onAccept) {
      onAccept();
    }
  }, [setAccepted]);

  const handleOnEnter = useCallback((index): void => {
    setCurrentDocumentIndex(index);
  }, []);

  useEffect(() => {
    if (!viewed && progress > (isAsset ? 93 : 95)) {
      setViewed(true);
    }
  }, [progress, viewed, setViewed]);

  useEffect(() => {
    if (loaded > 0 && ref && ref.current) {
      ref.current.scroll();
    }
  }, [loaded]);

  useDebounce(
    () => {
      setIsLoadedDebounce(isLoaded);
    },
    100,
    [setIsLoadedDebounce, isLoaded]
  );

  const handleOnSave = useCallback((): void => {
    onSave(files);
  }, [onSave, files]);

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <Header
        ready={isLoadedDebounce}
        index={currentDocumentIndex}
        limit={files.length}
        progress={progress}
        title={currentDocument ? currentDocument.filename : ""}
        onSave={handleOnSave}
        isOne={isOne}
      />
      {headerText && (
        <Actions>
          <AcceptFormAction readonly={readonly} blocked={!viewed} onAccept={handleOnAccept} watchText={headerText} />
        </Actions>
      )}
      <Content ref={ref} onScroll={handleContentScroll}>
        {(!isLoaded || pending) && <Loader my={8} height100={false} />}
        <VisibilityControl hidden={!isLoaded}>
          {files.map((file, index) => (
            <Fragment key={file.url || file.base64}>
              <InView value={index} onEnter={handleOnEnter}>
                <PdfDocument url={file.url} base64={file.base64} onLoad={handleOnLoad} onError={handleOnError} />
              </InView>
            </Fragment>
          ))}
        </VisibilityControl>
      </Content>
      {!disableActions && (
        <Box style={{ borderTop: `2px solid ${borderColors.lightGrey_60}` }}>
          {viewed && dopText && <Box>{dopText}</Box>}
          <Actions>
            {!accepted && (
              <AcceptFormAction
                readonly={readonly}
                blocked={!viewed}
                onAccept={handleOnAccept}
                watchText={watchText ? watchText : ""}
                acceptText={acceptText}
              />
            )}
            {accepted && !action && otpForm}
            {accepted && action && action()}
          </Actions>
        </Box>
      )}
    </Dialog>
  );
};

export default PdfConfirmationsModal;
