type TTarif = {
  id: string;
  feeTransaction: number;
  tariffName: string;
};

type TTariffPlan = {
  id: string;
  tariffs: TTarif[];
  tariffPlanName: string;
};

type TBrokerAccountDetail = {
  id?: string;
  planingBalanceValue?: number;
  planingBalanceCurrency?: string;
  monthlimitBalanceCurrency?: string;
  monthlimitBalanceValue?: number;
  blockedSumCurrency?: string;
  blockedSumValue?: number;
  availableBalanceCurrency?: string;
  availableBalanceValue?: number;
  actualBalanceCurrency?: string;
  actualBalanceValue?: number;
  beneficiaryId?: string;
  currencyId?: string;
};

type TBrokerAccountDetailOverrided = TBrokerAccountDetail & {
  currencyCode?: string;
  ccurrencySymbol?: string;
  currencyName?: string;
};

type TBrokerAccount = {
  id: string;
  brokerAccountDetails: [TBrokerAccountDetail]; // всегда одно значение в массиве, если массив пуст - валюты нет
  beneficiary: string;
  brokerAccount: string;
  brokerBank: string | null;
  brokerAccountCountry: string;
  brokerAccountBankCity: string;
  brokerLocation: string;
  brokerAccountSwift?: string;
  paymentDetails?: string;
  brokerName: string;
  brokerTin: string;
  correspAccount: string;
  correspBank: string;
  correspSwift: string;
  number: string;
  group?: {
    id: string;
    bodrId: string;
    name: string;
    currencyCode: string;
  };
};

type TBrokerAccountOverrided = Omit<TBrokerAccount, "brokerAccountDetails"> & {
  brokerAccountDetails: TBrokerAccountDetailOverrided[];
  group?: {
    id: string;
    bodrId: string;
    name: string;
    currencyCode: string;
  };
};

export enum AgreementTypeEnum {
  GENERAL_BO = "GENERAL_BO",
  GENERAL_DU = "GENERAL_DU",
  EDO = "EDO",
}

export enum AgreementStatusEnum {
  ACTIVE = "ACTIVE",
  PROCESSING = "PROCESSING",
  CANCELLED = "CANCELLED",
  DENIED = "DENIED",
  CLOSED = "CLOSED",
}

export enum AvailableStatusEnum {
  REQUEST_IS_OLD = "REQUEST_IS_OLD",
  CLIENT_DATA_EXPIRED = "CLIENT_DATA_EXPIRED",
  REQUEST_IS_OFFLINE = "REQUEST_IS_OFFLINE",
  REQUEST_ALREADY_SENT = "REQUEST_ALREADY_SENT",
  AGREEMENT_ALREADY_EXISTS = "AGREEMENT_ALREADY_EXISTS",
  NEED_OPEN_BROKER_ACCOUNT = "NEED_OPEN_BROKER_ACCOUNT",
  REJECTED = "REJECTED",
  ACTIVATED = "ACTIVATED",
  BLOCKED = "BLOCKED",
  PENDING = "PENDING",
  SENT = "SENT",
}

export type THoldBlockedOperation = {
  id: string;
  holdBlockedOperations: string;
};

export type TAccountAgreement = {
  id: string;
  tariffPlans: TTariffPlan[];
  brokerAccounts: TBrokerAccount[];
  clientId: string;
  number: string;
  holdBlockedOperations: THoldBlockedOperation[];
  type: AgreementTypeEnum;
  status: AgreementStatusEnum;
  openDate: string;
};

export type TAccountAgreementOverrided = Omit<TAccountAgreement, "brokerAccounts"> & {
  brokerAccounts: TBrokerAccountOverrided[];
};
