import { RefObject, useEffect } from "react";

export const useOtpSmsSubstitution = (ref: RefObject<HTMLInputElement>) => {
  const ac = new AbortController();

  useEffect(() => {
    if ("OTPCredential" in window) {
      navigator.credentials
        .get({
          // @ts-ignore
          otp: { transport: ["sms"] },
          signal: ac.signal,
        })
        .then((otp) => {
          // Получаем код
          if (!!ref.current && !!otp) {
            // @ts-ignore
            ref.current.value = otp.code;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  return null;
};
