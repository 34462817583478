import { createDomain, forward, sample } from "effector";
import { createForm } from "effector-forms";
import { createGate } from "effector-react";

import { links, processActionsEnum, VALID_FILE_TYPES } from "~/constants/enums";
import { megabyte } from "~/entities/feedback";
import { ProcessStateRequest } from "~/entities/keycloak";
import { authApi } from "~/entities/keycloak/api";
import { processResultEnum } from "~/entities/productBuy/types";
import { replenishmentBrokerageAccountApi } from "~/entities/replenishment-brokerage-account";
import {
  TPaymentProofState,
  TTaskCompleteParams,
  UPLOAD_REQUEST_DOCUMENTS,
} from "~/entities/replenishment-brokerage-account/types";
import { showErrorMessage } from "~/lib/errorHandlers";
import { getInvalidFileMessage } from "~/lib/file";
import { notificationModel } from "~/modules/notification-model";
import { TListResponse } from "~/modules/professional-status-model/upload-documents-model";
import { brokerServiceApi } from "~/pages/OpenAccount/services/api";
import { GetInvalidFileMessageParams, TProcessError } from "~/shared/types";
import { TFormDoc } from "./types";

const domain = createDomain("PaymnetProofDocument");
const gate = createGate({ domain });

const startProcessFx = domain.createEffect<string, string, TProcessError>(
  replenishmentBrokerageAccountApi.startProcess
);
const getStateProcessFx = domain.createEffect<ProcessStateRequest | undefined, TPaymentProofState, TProcessError>(
  authApi.getProcessState
);
const taskCompleteFx = domain.createEffect<TTaskCompleteParams, [], TProcessError>(
  replenishmentBrokerageAccountApi.taskComplete
);
const uploadDocumentsFx = domain.createEffect(replenishmentBrokerageAccountApi.uploadDocuments);
const filesListFx = domain.createEffect<void, TListResponse[]>({
  handler: brokerServiceApi.downloadingFilesList,
});
const downloadDocFx = domain.createEffect({ handler: brokerServiceApi.downloadingFiles });
const validateDocumentFx = domain.createEffect<GetInvalidFileMessageParams, null, string>(getInvalidFileMessage);

const startProcess = domain.createEvent<string>();
const uploadDoc = domain.createEvent<File>();
const deleteDoc = domain.createEvent<TFormDoc>();
const downloadDoc = domain.createEvent<TFormDoc>();
const complete = domain.createEvent();
const setShowSuccessMessage = domain.createEvent<boolean>();
const setShowErrorMessage = domain.createEvent<boolean>();
const setReplenishmentId = domain.createEvent<string>();
const setLoadingAfterUpload = domain.createEvent<boolean>();

const $replenishmentId = domain.createStore<string | null>(null);
$replenishmentId.on(setReplenishmentId, (_, data) => data);
$replenishmentId.reset(gate.close);

const $businessKey = domain.createStore("");
$businessKey.on(startProcessFx.doneData, (_, data) => data);
$businessKey.reset(gate.close);

const $processInfo = domain.createStore<TPaymentProofState | null>(null);
$processInfo.reset(gate.close);
$processInfo.on(getStateProcessFx.doneData, (_, data) => data);

const $taskId = domain.createStore<string | null>(null);
$taskId.reset(gate.close);
$taskId.on($processInfo, (_, data) => data?.activeTasks?.[0].id);

const $taskDefinitionKey = domain.createStore<string | null>(null);
$taskDefinitionKey.reset(gate.close);
$taskDefinitionKey.on($processInfo, (_, data) => data?.activeTasks?.[0].taskDefinitionKey);

const $uploadDocumentErrorMessage = domain.createStore<string | null>(null);
$uploadDocumentErrorMessage.reset([gate.close, validateDocumentFx.done]);
$uploadDocumentErrorMessage.on(validateDocumentFx.failData, (_, data) => data);

const $showSuccessMessage = domain.createStore<boolean>(false);
$showSuccessMessage.on(setShowSuccessMessage, (_, data) => data);
$showSuccessMessage.reset(gate.close);

const $showErrorMessage = domain.createStore<boolean>(false);
$showErrorMessage.on(setShowErrorMessage, (_, data) => data);
$showErrorMessage.reset(gate.close);

const $currentFilePendingDelete = domain.createStore<TFormDoc | null>(null);
$currentFilePendingDelete.reset(gate.close);
$currentFilePendingDelete.on(deleteDoc, (_, doc) => {
  return { bcsfsIds: doc?.bcsfsIds, type: doc?.type };
});

const $isLoadingAfterUpload = domain.createStore(false);
$isLoadingAfterUpload.reset(gate.close);
$isLoadingAfterUpload.on(setLoadingAfterUpload, (_, payload) => payload);

const documentsForm = createForm({
  domain: domain,
  fields: {
    documents: {
      init: [] as TFormDoc[],
    },
  },
  validateOn: ["submit"],
});

forward({
  from: startProcess,
  to: startProcessFx,
});

sample({
  source: startProcessFx.doneData,
  fn: (businessKey) => ({
    processBusinessKey: businessKey,
  }),
  target: getStateProcessFx,
});

sample({
  clock: taskCompleteFx.doneData,
  source: $businessKey,
  filter: (businessKey) => !!businessKey,
  fn: (businessKey) => ({
    processBusinessKey: businessKey,
  }),
  target: getStateProcessFx,
});

sample({
  source: $taskId,
  clock: complete,
  filter: (taskId) => !!taskId,
  fn: (taskId) => {
    return {
      taskId: taskId ?? "",
      variables: {
        extraDocuments: null,
      },
    };
  },
  target: taskCompleteFx,
});

sample({
  source: uploadDoc,
  clock: uploadDoc,
  fn: (file) => {
    return {
      file: file,
      fileTypes: VALID_FILE_TYPES,
      fileSize: {
        bytes: megabyte * 10,
        megabytes: 10,
      },
    };
  },
  target: validateDocumentFx,
});

sample({
  source: uploadDoc,
  clock: validateDocumentFx.done,
  fn: (file) => {
    return {
      file,
      type: UPLOAD_REQUEST_DOCUMENTS,
      taskId: $taskId.getState(),
    };
  },
  target: uploadDocumentsFx,
});

sample({
  clock: uploadDocumentsFx.done,
  fn: () => true,
  target: setLoadingAfterUpload,
});

sample({
  clock: getStateProcessFx.finally,
  source: $isLoadingAfterUpload,
  fn(isLoadingAfterUpload, data) {
    if (data.status === "fail") return false;

    if (isLoadingAfterUpload && data?.result?.activeTasks?.length) return !isLoadingAfterUpload;

    return isLoadingAfterUpload;
  },
  target: setLoadingAfterUpload,
});

sample({
  source: $businessKey,
  clock: uploadDocumentsFx.done,
  fn: (businessKey) => {
    return {
      processBusinessKey: businessKey,
    };
  },
  target: getStateProcessFx,
});

sample({
  source: $taskId,
  clock: deleteDoc,
  filter: (taskId, file) => !!taskId && !!file.bcsfsIds,
  fn: (taskId, file): TTaskCompleteParams => {
    return {
      taskId: taskId ?? "",
      variables: {
        extraDocuments: processActionsEnum.REMOVE,
        removeBcsfsId: file.bcsfsIds,
        removeDocumentType: UPLOAD_REQUEST_DOCUMENTS,
      },
    };
  },
  target: taskCompleteFx,
});

sample({
  source: downloadDoc,
  filter: (document) => !!document?.bcsfsIds && !!document?.name,
  fn: (document) => {
    return { fileId: document.bcsfsIds ?? "", name: document.name ?? "" };
  },
  target: downloadDocFx,
});

forward({
  from: $processInfo,
  to: filesListFx,
});

sample({
  source: $processInfo,
  clock: filesListFx.doneData,
  fn: (processInfo, data) => {
    const documentIds = processInfo?.inputData?.documents;
    const mapping: TFormDoc[] = [];
    documentIds?.forEach((item) => {
      data.forEach((doc) => {
        if (doc.id === item.bcsfsId) {
          mapping.push({
            name: doc?.name,
            type: doc?.type,
            bcsfsIds: item.bcsfsId,
          });
        }
      });
    });

    return {
      documents: mapping,
    };
  },
  target: documentsForm.setForm,
});

getStateProcessFx.doneData.watch((data) => {
  switch (data.processResult) {
    case processResultEnum.PAYMENT_PROOF_DOCUMENT_SENT:
      setShowSuccessMessage(true);
      break;
    case processResultEnum.ERROR_SENDING_BODR_REQUEST:
      setShowErrorMessage(true);
      break;
    default:
      break;
  }
});

gate.close.watch(() => {
  documentsForm.reset();
});

startProcessFx.fail.watch((data) => {
  showErrorMessage(data.error, notificationModel.error);
  notificationModel.pushTo(links.operationsAssets);
});

getStateProcessFx.fail.watch((data) => {
  showErrorMessage(data.error, notificationModel.error);
  notificationModel.pushTo(links.operationsAssets);
});

taskCompleteFx.fail.watch((data) => {
  showErrorMessage(data.error, notificationModel.error);
  notificationModel.pushTo(links.operationsAssets);
});

export const paymentProofDocumentModel = {
  gate,
  startProcess,
  uploadDoc,
  documentsForm,
  $statePending: getStateProcessFx.pending,
  $completePending: taskCompleteFx.pending,
  $uploadPending: uploadDocumentsFx.pending,
  $currentFilePendingDelete: $currentFilePendingDelete,
  deleteDoc,
  downloadDoc,
  $uploadDocumentErrorMessage,
  $taskId,
  setShowSuccessMessage,
  complete,
  $showSuccessMessage,
  setReplenishmentId,
  $replenishmentId,
  $showErrorMessage,
  $isLoadingAfterUpload,
  setShowErrorMessage,
};
