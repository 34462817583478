import { createAsyncReducer } from '~/lib/redux';
import { initial } from '~/lib/redux/async';

import {
  getProductCommissionErrorAction,
  getProductCommissionStartAction,
  getProductCommissionSuccessAction,
  productClearAction,
} from '../actions';

export const STATE_NAME = 'commission';

const [reducer, defaultState] = createAsyncReducer(
  getProductCommissionStartAction,
  getProductCommissionSuccessAction,
  getProductCommissionErrorAction,
);

reducer.on(productClearAction, () => initial());

export type CommissionState = typeof defaultState;

export default reducer;
