interface ExpectedAssetsOption {
  from: number | null;
  to: number | null;
}

export const ExpectedAssetsOptions: ExpectedAssetsOption[] = [
  {
    from: null,
    to: 5000,
  },
  {
    from: 5000,
    to: 20000,
  },
  {
    from: 20000,
    to: 50000,
  },
  {
    from: 50000,
    to: 200000,
  },
  {
    from: 200000,
    to: 500000,
  },
  {
    from: 500000,
    to: 1000000,
  },
  {
    from: 1000000,
    to: null,
  },
];
