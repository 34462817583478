import { ReactElement, useEffect } from "react";

interface TimerProps {
  time: number;
  setTime: (newTime: number) => void;
  startTime: number;
  children: (props: { expired: number }) => ReactElement;
}

export const Timer = (props: TimerProps) => {
  const { time, setTime, children, startTime } = props;

  useEffect(() => {
    return () => setTime(startTime);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => setTime(time - 1), 1000);
    return () => clearTimeout(timeout);
  }, [time]);

  return children({ expired: time });
};
