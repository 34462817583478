import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  activityItem: {
    padding: "24px",
    background: colorTable.lightGrey_10,
    borderRadius: "4px",
    height: "72px",
    marginBottom: "16px",
    "& .MuiFormControlLabel-labelPlacementStart": {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  activityItemInner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  checkbox: {
    width: "24px",
    height: "24px",
    padding: 0,
  },
  checkboxMobile: {
    width: "16px",
    height: "16px",
    padding: 0,
  },
  label: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: colorTable.black,
  },
  labelMobile: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 400,
    color: colorTable.black,
  },
  subLabel: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: colorTable.anthraciteGray,
  },
  subLabelMobile: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: colorTable.anthraciteGray,
    marginTop: "15px",
  },
});
