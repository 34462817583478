import { makeStyles } from "tss-react/mui";

import theme from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  textMobile: {
    fontSize: "32px",
    textAlign: "center",
    lineHeight: "48px",
    color: colorTable.misteryBlue,
  },
  text: {
    color: colorTable.textSecondary,
    marginBottom: "40px",
    "& a": {
      textDecoration: "none",
      color: colorTable.charcoalBlack,
      fontWeight: 600,
    },
  },
  header: {
    marginBottom: "16px",
    marginTop: "16px",
    fontWeight: 600,
    fontSize: "24px",
    color: colorTable.blackDefault,
    lineHeight: "28px",
    [theme.breakpoints.down(768)]: {
      fontSize: "20px",
    },
  },
  headerMobile: {
    textAlign: "center",
    lineHeight: "52px",
    marginBottom: "32px",
    marginTop: "16px",
    fontSize: "48px",
    color: colorTable.blackDefault,
  },
  alreadyExistsBox: {
    [theme.breakpoints.down(768)]: {
      marginTop: "64px",
      marginBottom: "64px",
    },
  },
  btn: {
    width: "280px",
    "&:first-child": {
      marginBottom: "16px",
    },
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
});
