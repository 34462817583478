import React, { ReactElement, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { hot } from "react-hot-loader/root";
import { Provider } from "react-redux";
import { useGate } from "effector-react";
import { initializeApp } from "firebase/app";
import { getRemoteConfig } from "firebase/remote-config";

import App from "~/app/App";
import { AppThemeProvider } from "~/app/AppThemeProvider";
import { firebaseConfig } from "~/configs/firebase";
import store from "~/configs/store";
import ErrorFallback from "~/features/ErrorFallback";
import { technicalWorksModel } from "~/modules/app/technical-works-model";
import { firebaseModel } from "~/modules/firebase";
import { loginModel } from "~/modules/keycloak/login-model";

const strict = (content: ReactElement) =>
  process.env.NODE_ENV === "development" && process.env.REACT_APP_DISABLE_STRICT_MODE_IN_DEV === "false" ? (
    <React.StrictMode>{content}</React.StrictMode>
  ) : (
    content
  );

export const Root = () => {
  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const remoteConfig = getRemoteConfig(app);
    firebaseModel.getOtpSecret(remoteConfig);
    loginModel.checkTokens();
  }, []);

  useGate(technicalWorksModel.technicalWorksGate);

  return strict(
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY as string}>
      <AppThemeProvider>
        <Provider store={store}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <App />
          </ErrorBoundary>
        </Provider>
      </AppThemeProvider>
    </GoogleReCaptchaProvider>
  );
};

export default process.env.REACT_APP_HOT_LOADER_ENABLED === "true" && module.hot ? hot(Root) : Root;
