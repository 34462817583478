import React from "react";
import { LinearProgress, linearProgressClasses, styled } from "@mui/material";

import colorTable from "~/shared/ui-kit/constants/colors";

type TLinearProgressProps = {
  value: number;
  variant?: "determinate" | "indeterminate" | "buffer" | "query";
};

export const LinearProgressBar = ({ value, variant = "determinate" }: TLinearProgressProps) => {
  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 2,
    borderRadius: 4,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: colorTable?.greyContour,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 4,
      backgroundColor: colorTable?.green_50,
    },
  }));

  return <BorderLinearProgress variant={variant} value={value} />;
};
