import { cast, Yup } from "~/lib/validation";
import { CrsInfoDto, schema as crsInfoDtoSchema } from "./CrsInfoDto";
import { IoPepInfoDto, schema as ioPepInfoDtoSchema } from "./IoPepInfoDto";

type CommonSectionPassportFields = "passNum" | "passportAuthority" | "passportDate" | "passportExpireDate";
type CommonSectionInternalPassportFields =
  | "internalNumber"
  | "internalDate"
  | "internalAuthority"
  | "internalDepartmentCode";

type CommonSectionPassportFilled = Record<CommonSectionPassportFields, string> &
  Record<CommonSectionInternalPassportFields, string | null>;

type CommonSectionInternalPassportFilled = Record<CommonSectionInternalPassportFields, string> &
  Record<CommonSectionPassportFields, string | null>;

export type CommonSectionDtoType = (CommonSectionPassportFilled | CommonSectionInternalPassportFilled) & {
  // step 1
  lastName: string;
  firstName: string;
  middleName: string;
  nationalityCode: string;
  dateBirth: string;
  placeBirthCity: string;
  placeBirthCountryCode: string;
  mobilePhone: string;
  otherPhone: string;
  emailAddress: string;
  prefFormOfContactMail: boolean;
  prefFormOfContactOnline: boolean;
  // step 2
  coIncidence: boolean;
  coIncidenceActualAndPermanent: boolean;
  passSeries: string;
  internalSeries: string;
  //
  mailAddress: string;
  mailAddressApartment: number;
  mailAddressCity: string;
  mailAddressCountryCode: string;
  mailAddressHouse: string;
  mailAddressHousing: string;
  mailAddressIndex: number;
  mailAddressRegion: string;
  mailAddressStreet: string;
  //
  permanentAddress: string;
  permanentAddressApartment: number;
  permanentAddressCity: string;
  permanentAddressCountryCode: string;
  permanentAddressHouse: string;
  permanentAddressHousing: string;
  permanentAddressIndex: number;
  permanentAddressRegion: string;
  permanentAddressStreet: string;
  //
  actualResidenceAddress: string;
  actualResidenceAddressApartment: number;
  actualResidenceAddressCity: string;
  actualResidenceAddressCountryCode: string;
  actualResidenceAddressHouse: string;
  actualResidenceAddressHousing: string;
  actualResidenceAddressIndex: number;
  actualResidenceAddressRegion: string;
  actualResidenceAddressStreet: string;
  // step 3
  ioPep: boolean;
  ioPepInfo: IoPepInfoDto[];
  crsInfo: CrsInfoDto[];
};

export type CommonSectionDto = Nullable<CommonSectionDtoType>;
export type CommonSectionSaveRequest = { step: 1 | 2 | 3; data: CommonSectionDto };

const passportValidationFields = Yup.string().when(
  "passNum",
  (passNum: string | null, fieldSchema: Yup.StringSchema) => (passNum ? fieldSchema.required() : fieldSchema.nullable())
);

const internalPassportValidationFields = Yup.string().when(
  "internalNumber",
  (internalNumber: string | null, fieldSchema: Yup.StringSchema) =>
    internalNumber ? fieldSchema.required() : fieldSchema.nullable()
);

export const schema = Yup.object<CommonSectionDto>({
  // step 1
  dateBirth: Yup.string().nullable(),
  emailAddress: Yup.string().nullable(),
  firstName: Yup.string().nullable(),
  lastName: Yup.string().nullable(),
  middleName: Yup.string().nullable(),
  mobilePhone: Yup.string().nullable(),
  nationalityCode: Yup.string().nullable(),
  otherPhone: Yup.string().nullable(),
  placeBirthCity: Yup.string().nullable(),
  placeBirthCountryCode: Yup.string().nullable(),
  prefFormOfContactMail: Yup.boolean().nullable(),
  prefFormOfContactOnline: Yup.boolean().nullable(),
  // step 2
  coIncidence: Yup.boolean().nullable(),
  coIncidenceActualAndPermanent: Yup.boolean().nullable(),
  //
  passSeries: Yup.string().nullable(),
  passNum: Yup.string().when("internalNumber", (internalNumber: string | null, fieldSchema: Yup.StringSchema) =>
    internalNumber ? fieldSchema.nullable() : fieldSchema.required()
  ),
  passportAuthority: Yup.string().nullable(),
  passportDate: passportValidationFields,
  passportExpireDate: passportValidationFields,
  //
  internalSeries: Yup.string().nullable(),
  internalNumber: Yup.string().nullable(),
  internalAuthority: Yup.string().nullable(),
  internalDate: internalPassportValidationFields,
  internalDepartmentCode: internalPassportValidationFields,
  //
  mailAddress: Yup.string().nullable(),
  mailAddressApartment: Yup.number().nullable(),
  mailAddressCity: Yup.string().nullable(),
  mailAddressCountryCode: Yup.string().nullable(),
  mailAddressHouse: Yup.string().nullable(),
  mailAddressHousing: Yup.string().nullable(),
  mailAddressIndex: Yup.number().nullable(),
  mailAddressRegion: Yup.string().nullable(),
  mailAddressStreet: Yup.string().nullable(),
  //
  permanentAddress: Yup.string().nullable(),
  permanentAddressApartment: Yup.number().nullable(),
  permanentAddressCity: Yup.string().nullable(),
  permanentAddressCountryCode: Yup.string().nullable(),
  permanentAddressHouse: Yup.string().nullable(),
  permanentAddressHousing: Yup.string().nullable(),
  permanentAddressIndex: Yup.number().nullable(),
  permanentAddressRegion: Yup.string().nullable(),
  permanentAddressStreet: Yup.string().nullable(),
  //
  actualResidenceAddress: Yup.string().nullable(),
  actualResidenceAddressApartment: Yup.number().nullable(),
  actualResidenceAddressCity: Yup.string().nullable(),
  actualResidenceAddressCountryCode: Yup.string().nullable(),
  actualResidenceAddressHouse: Yup.string().nullable(),
  actualResidenceAddressHousing: Yup.string().nullable(),
  actualResidenceAddressIndex: Yup.number().nullable(),
  actualResidenceAddressRegion: Yup.string().nullable(),
  actualResidenceAddressStreet: Yup.string().nullable(),
  // step 3
  crsInfo: Yup.array<CrsInfoDto>().of<CrsInfoDto>(crsInfoDtoSchema).nullable(),
  ioPep: Yup.boolean().nullable(),
  ioPepInfo: Yup.array<IoPepInfoDto>().of<IoPepInfoDto>(ioPepInfoDtoSchema).nullable(),
}).label("CommonSectionDto");

export const castCommonSectionDto = cast<CommonSectionDto>(schema);

export const commonSectionNullable: CommonSectionDto = {
  // step 1
  dateBirth: null,
  emailAddress: null,
  firstName: null,
  lastName: null,
  middleName: null,
  mobilePhone: null,
  nationalityCode: null,
  otherPhone: null,
  placeBirthCity: null,
  placeBirthCountryCode: null,
  prefFormOfContactMail: null,
  prefFormOfContactOnline: null,
  // step 2
  coIncidence: null,
  coIncidenceActualAndPermanent: null,
  internalAuthority: null,
  internalDate: null,
  internalDepartmentCode: null,
  internalSeries: null,
  internalNumber: null,
  //
  mailAddress: null,
  mailAddressApartment: null,
  mailAddressCity: null,
  mailAddressCountryCode: null,
  mailAddressHouse: null,
  mailAddressHousing: null,
  mailAddressIndex: null,
  mailAddressRegion: null,
  mailAddressStreet: null,
  passportAuthority: null,
  passportDate: null,
  passportExpireDate: null,
  passSeries: null,
  passNum: null,
  //
  permanentAddress: null,
  permanentAddressApartment: null,
  permanentAddressCity: null,
  permanentAddressCountryCode: null,
  permanentAddressHouse: null,
  permanentAddressHousing: null,
  permanentAddressIndex: null,
  permanentAddressRegion: null,
  permanentAddressStreet: null,
  //
  actualResidenceAddress: null,
  actualResidenceAddressApartment: null,
  actualResidenceAddressCity: null,
  actualResidenceAddressCountryCode: null,
  actualResidenceAddressHouse: null,
  actualResidenceAddressHousing: null,
  actualResidenceAddressIndex: null,
  actualResidenceAddressRegion: null,
  actualResidenceAddressStreet: null,
  // step 3
  ioPep: null,
  ioPepInfo: null,
  crsInfo: null,
};
