import React from "react";
import { Box } from "@mui/material";

import { useTranslation } from "~/i18n";
import BaseModal from "~/shared/ui/BaseModal/BaseModal";
import { BaseButton, BaseTypography } from "~/shared/ui-kit/BaseComponents";
import { useStyles } from "./styles";

interface IproductTabListFilterMobile {
  open: boolean;
  onClose: () => void;
  acceptFilters: () => void;
  resetFilters: () => void;
  children: React.ReactNode;
  isDisabledReset?: boolean;
  isDisabledAccept?: boolean;
}

export const BaseMobileFilters = ({
  open,
  onClose,
  acceptFilters,
  resetFilters,
  children,
  isDisabledReset,
  isDisabledAccept,
}: IproductTabListFilterMobile) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <BaseModal open={open} onClose={onClose} maxWidthContent="100%">
      <Box>
        <Box className={classes.head}>
          <BaseTypography tag="p" fontType="title_2_semiBold">
            {t("INVEST_IDEAS.filters")}
          </BaseTypography>
        </Box>
        <Box>{children}</Box>
        <BaseButton onClick={acceptFilters} className={classes.btnApply} typeStyle="black" disabled={isDisabledAccept}>
          {t("GENERAL.apply")}
        </BaseButton>
        <BaseButton typeStyle="white" onClick={resetFilters} className={classes.btnReset} disabled={isDisabledReset}>
          {t("GENERAL.reset")}
        </BaseButton>
      </Box>
    </BaseModal>
  );
};
