import { makeStyles } from "tss-react/mui";

import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export default makeStyles()(() => ({
  radioField: {
    display: "flex",
    alignItems: "start",
    gap: "0 16px",
    cursor: "pointer",
    width: "fit-content",

    "&.disabled": {
      cursor: "not-allowed",
    },
  },
  label: {
    color: colorTable.charcoalBlack,
    cursor: "inherit",

    "&.disabled": {
      color: colorTable.moonGrey,
    },
  },
  radio: {
    minWidth: "24px",
    minHeight: "24px",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    background: colorTable.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${borderColors.greyContour}`,

    "&.checked": {
      background: colorTable.charcoalBlack,
    },
  },
  circle: {
    minWidth: "8px",
    minHeight: "8px",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    background: colorTable.white,
  },
  input: {
    position: "absolute",
    zIndex: "-1000",
    opacity: 0,
  },
}));
