if (process.env.REACT_APP_APPDYNAMICS_APP_KEY) {
  (function (config): void {
    config.appKey = process.env.REACT_APP_APPDYNAMICS_APP_KEY;
    config.adrumExtUrlHttp = process.env.REACT_APP_APPDYNAMICS_ADRUM_EXT_URL_HTTP;
    config.adrumExtUrlHttps = process.env.REACT_APP_APPDYNAMICS_ADRUM_EXT_URL_HTTPS;
    config.beaconUrlHttp = process.env.REACT_APP_APPDYNAMICS_BEACON_URL_HTTP;
    config.beaconUrlHttps = process.env.REACT_APP_APPDYNAMICS_BEACON_URL_HTTPS;
    config.xd = {
      enable: false,
    };
    config.spa = {
      spa2: true,
    };

    const script = document.createElement("script");
    script.async = true;
    // скрипт, вызывающий переполнение callstack при обновлении axios, требуется отслеживать обновление AppDynamics
    script.src = "//cdn.appdynamics.com/adrum/adrum-latest.js";

    document.getElementsByTagName("head")[0].appendChild(script);
  })(window["adrum-config"] || (window["adrum-config"] = {}));
}

export const setAppDynamicsUserData = (value: any | undefined): void => {
  (function (config, value): void {
    (function (info): void {
      const data = value
        ? (): AdrumConfigPageViewType => ({
            userData: {
              "D-Login": value.login,
              // isTemporaryPassword: value.isTemporaryPassword,
            },
          })
        : undefined;
      info.Ajax = data;
      info.PageView = data;
      info.VPageView = data;
    })(config.userEventInfo || (config.userEventInfo = {}));
  })(window["adrum-config"] || (window["adrum-config"] = {}), value);
};
