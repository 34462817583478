import { AxiosError } from "axios";
import { createDomain, forward, sample } from "effector";
import { createGate } from "effector-react";

import { appApi } from "~/entities/app";

const technicalWorksDomain = createDomain("technical works domain");
const gate = createGate({ domain: technicalWorksDomain });

const checkTechnicalWorksFx = technicalWorksDomain.createEffect<void, any, AxiosError>({
  handler: appApi.checkTechnicalWorks,
});

const setTechWorkError = technicalWorksDomain.createEvent<{
  enabled?: boolean;
  startDate?: string;
  endDate?: string;
}>();

const $isTechnicalWorks = technicalWorksDomain.createStore(false);
$isTechnicalWorks.on(setTechWorkError, (_, data) => data?.enabled);

const $message = technicalWorksDomain.createStore<{
  title: string;
  startDate: undefined | string;
  endDate: undefined | string;
}>({ title: "ERRORS.technical_work", startDate: "", endDate: "" });

$message.on(setTechWorkError, (_, data) => {
  return { title: "ERRORS.technical_work", startDate: data?.startDate, endDate: data?.endDate };
});

forward({ from: gate.open, to: checkTechnicalWorksFx });

sample({
  source: checkTechnicalWorksFx.doneData,
  filter: (data) => {
    return data;
  },
  target: setTechWorkError,
});

export const technicalWorksModel = {
  technicalWorksGate: gate,
  $isTechnicalWorks,
  $message,
  setTechWorkError,
};
