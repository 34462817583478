export const getStorage = (storageName: "localStorage" | "sessionStorage"): Storage | null => {
  if (typeof window === "undefined") {
    return null;
  }

  let storage: Storage | null = null;
  try {
    if (storageName === "localStorage" && window.localStorage && typeof window.localStorage.setItem === "function") {
      storage = window.localStorage;
    } else if (
      storageName === "sessionStorage" &&
      window.sessionStorage &&
      typeof window.sessionStorage.setItem === "function"
    ) {
      storage = window.sessionStorage;
    }
  } catch {}

  if (storage) {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    const key = `test_${storageName}_${array[0]}`;

    try {
      storage.setItem(key, key);
      if (key === storage.getItem(key)) {
        storage.removeItem(key);
        return storage;
      }
    } catch {}
  }

  return null;
};
