import { IconNameType } from "~/shared/Icon/Icon";

export const CURRENCY_CODES: TCurrencyCode[] = [
  {
    code: "840",
    name: "USD",
    symbol: "$",
    icon: "currrencyUsd",
    minimalIcon: "minimalDollar",
    assetsIcon: "assetsDollar",
  },
  { code: "156", name: "CNY", symbol: "¥", icon: "currrencyCny", minimalIcon: "minimalCny", assetsIcon: "assetsCNY" },
  { code: "643", name: "RUB", symbol: "₽", icon: "currrencyRub", minimalIcon: "minimalRub", assetsIcon: "assetsRub" },
  { code: "978", name: "EUR", symbol: "€", icon: "currrencyEur", minimalIcon: "minimalEur", assetsIcon: "assetsEuro" },
  { code: "784", name: "AED", symbol: "Dh", icon: "", minimalIcon: "minimalDh", assetsIcon: "assetsDirham" },
];

export type TCurrencyCode = {
  code: string;
  name: string;
  symbol: string;
  icon: string;
  minimalIcon: string;
  assetsIcon: IconNameType;
};

export enum CurrencyCodeEnum {
  USD = "840",
  CNY = "156",
  RUB = "643",
  EUR = "978",
  AED = "784",
}
