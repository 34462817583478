export enum processResultEnum {
  SUCCESSFUL_REGISTRATION = "SUCCESSFUL_REGISTRATION",
  USER_ALREADY_EXISTS = "USER_ALREADY_EXISTS",
  SUCCESSFUL_RESET_PASSWORD = "SUCCESSFUL_RESET_PASSWORD",
  INVALID_ANSWER = "INVALID_ANSWER",
  CANNOT_SEND_SMS = "CANNOT_SEND_SMS",
  MAXIMUM_NUMBER_OF_INPUT_ATTEMPTS = "MAXIMUM_NUMBER_OF_INPUT_ATTEMPTS",
  USER_NOT_SET_QUESTION_ANSWER = "USER_NOT_SET_QUESTION_ANSWER",
  USER_ACCOUNT_DISABLED = "USER_ACCOUNT_DISABLED",
  SUCCESSFUL_UNLOCK_ACCOUNT = "SUCCESSFUL_UNLOCK_ACCOUNT",
  INVALID_OTP_FIRST_BLOCK = "INVALID_OTP_FIRST_BLOCK",
  INVALID_OTP_SECOND_BLOCK = "INVALID_OTP_SECOND_BLOCK",
  USERNAME_PERMANENTLY_BLOCKED = "USERNAME_PERMANENTLY_BLOCKED",
  TIME_EXPIRED = "TIME_EXPIRED",
  SECURITY_QUESTION_TIME_EXPIRED = "SECURITY_QUESTION_TIME_EXPIRED",
  WAITING_BODR_DESICION = "WAITING_BODR_DESICION",
  CANNOT_SEND_OTP_CODE = "CANNOT_SEND_OTP_CODE",
  FORBIDDEN = "FORBIDDEN",
  DONE = "DONE",
  SUCCESS = "SUCCESS",
  DATA_MISMATCH = "DATA_MISMATCH",
  DATA_OF_BIRTH_MISMATCH = "DATA_OF_BIRTH_MISMATCH",
  COUNTRY_OF_RESIDENCE_MISMATCH = "COUNTRY_OF_RESIDENCE_MISMATCH",
  COUNTRY_OF_CITIZENSHIP_MISMATCH = "COUNTRY_OF_CITIZENSHIP_MISMATCH",
  PRINT_FORM_ERROR = "PRINT_FORM_ERROR",
  ERROR_SENDING_BODR_REQUEST = "ERROR_SENDING_BODR_REQUEST",
  ERROR_STATUS = "ERROR_STATUS",
  GO_TO_SUPPORT = "GO_TO_SUPPORT",
  USER_PERMANENTLY_BLOCKED = "USER_PERMANENTLY_BLOCKED",
  PAYMENT_PROOF_DOCUMENT_SENT = "PAYMENT_PROOF_DOCUMENT_SENT",
  ERROR_SEND_TO_BODR = "ERROR_SEND_TO_BODR",
  SAVE_ERROR = "SAVE_ERROR",
  OTP_SECRET_DOES_NOT_MATCH = "OTP_SECRET_DOES_NOT_MATCH",
  VALIDATION_ERROR = "VALIDATION_ERROR",
  CANNOT_CHECK_OTP_COUNTER = "CANNOT_CHECK_OTP_COUNTER",
  SERVER_ERROR = "SERVER_ERROR",
  UNSUPPORTED_ERROR = "UNSUPPORTED_ERROR",
  PENDING = "PENDING",
  ERROR_SENDING_ACCOUNT_REQUEST = "ERROR_SENDING_ACCOUNT_REQUEST",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  ERROR_SENDING_TRADER_REQUEST = "ERROR_SENDING_TRADER_REQUEST",
  RISK_PROFILE_MISMATCH = "RISK_PROFILE_MISMATCH",
}

export enum taskDefinitionKeyEnum {
  OTP_CONFIRMATION = "OTP_CONFIRMATION",
  ENTER_USERNAME = "ENTER_USERNAME",
  ENTER_NEW_SECURITY_QUESTION = "ENTER_NEW_SECURITY_QUESTION",
  ENTER_QA_TASK = "ENTER_QA_TASK",
}

export const OTP_DONT_MATCH = "entered and granted otp don't match";
export const TEMPORARY_BLOCKED_USER_AFTER_SET_QUESTION =
  "The answer to the security question is incorrect. The account is blocked";
export const REPEATING_PASSWORD = "The password should not be the same as the previous 5 passwords.";
export const SERVER_ERROR = "SERVER_ERROR";
export const DONE = "DONE";

export const USER_AUTH_BLOCK = "Validation sms blocked";
export const USER_RESET_PASSWORD_BLOCKED = "The answer to the security question is incorrect. The account is blocked.";
export const INVALID_OTP_FIRST_BLOCK = "INVALID_OTP_FIRST_BLOCK";
