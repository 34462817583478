import React, { useState } from "react";
import { ClickAwayListener, Typography, useMediaQuery } from "@mui/material";

import { useTranslation } from "~/i18n";
import { BaseButton, BaseTypography } from "~/shared/ui-kit/BaseComponents";
import colorTable from "~/shared/ui-kit/constants/colors";
import Icon from "~/shared/ui-kit/constants/icons/Icon";
import { BaseMobileFilters } from "./BaseMobileFilters";
import { useStyles } from "./styles";

interface IBaseFiltersProps {
  children: React.ReactNode;
  isDisabledReset?: boolean;
  isDisabledAccept?: boolean;
  counter?: number;
  acceptFilter: () => void;
  resetFilter: () => void;
  closeFilters: () => void;
}

export const BaseFilters = ({
  children,
  acceptFilter,
  resetFilter,
  closeFilters,
  isDisabledReset,
  isDisabledAccept,
  counter,
}: IBaseFiltersProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isActiveFilterModal, setIsActiveFilterModal] = useState<boolean>(false);

  const handleOpenFilters = () => {
    setIsActiveFilterModal(true);
  };
  const handleCloseFilters = () => {
    setIsActiveFilterModal(false);
    closeFilters();
  };
  const handleAcceptFilters = () => {
    setIsActiveFilterModal(false);
    acceptFilter();
  };
  const handleResetFilters = () => {
    setIsActiveFilterModal(false);
    resetFilter();
  };

  return (
    <div className={classes.investHead}>
      <BaseButton className={classes.filterButton} onClick={handleOpenFilters}>
        <Icon
          name="filter"
          color={isActiveFilterModal && !isMobile ? colorTable.nobleOchre : colorTable.anthraciteGray}
        />
        {t("INVEST_IDEAS.filters")}
        {!!counter && <Typography className={classes.filterCounter}>{counter}</Typography>}
      </BaseButton>
      {isMobile && (
        <BaseMobileFilters
          open={isActiveFilterModal}
          onClose={handleCloseFilters}
          acceptFilters={handleAcceptFilters}
          resetFilters={handleResetFilters}
          isDisabledAccept={isDisabledAccept}
          isDisabledReset={isDisabledReset}
        >
          {children}
        </BaseMobileFilters>
      )}
      {isActiveFilterModal && !isMobile && (
        <ClickAwayListener onClickAway={handleCloseFilters}>
          <div className={classes.filtersBox}>
            <div className={classes.filterHead}>
              <BaseTypography tag="p" fontType="headline_semiBold">
                {t("INVEST_IDEAS.filters")}
              </BaseTypography>
              <BaseButton typeStyle="inline" onClick={handleCloseFilters}>
                <Icon
                  name="close"
                  size={16}
                  withBackground={{
                    backgroundColor: colorTable.white_20,
                    padding: "4px",
                    borderRadius: "50%",
                    height: "24px",
                    width: "24px",
                  }}
                />
              </BaseButton>
            </div>
            <div>{children}</div>
            <div className={classes.btnWrap}>
              <BaseButton typeStyle="black" onClick={handleAcceptFilters} disabled={isDisabledAccept}>
                {t("GENERAL.apply")}
              </BaseButton>
              <BaseButton typeStyle="white" disabled={isDisabledReset} onClick={handleResetFilters}>
                {t("GENERAL.reset")}
              </BaseButton>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};
