import { createAsyncReducer } from "~/lib/redux";
import { clientCardErrorAction, clientCardStartAction, clientCardSuccessAction } from "../actions";

export const STATE_NAME = "current";

const [reducer, defaultState] = createAsyncReducer(
  clientCardStartAction,
  clientCardSuccessAction,
  clientCardErrorAction
);

export type ClientCardCurrentState = typeof defaultState;

export default reducer;
