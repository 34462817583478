import React from "react";
import { Box } from "@mui/material";

import { BaseTypography } from "~/shared/ui-kit/BaseComponents";
import { DocumentIcon } from "~/shared/ui-kit/constants/icons";
import { useStyles } from "./styles";

export interface DocumentCardProps {
  onClickDocument: (docId: DocumentCardProps["docId"], fileName: DocumentCardProps["fileName"]) => void;
  docId: string | number;
  fileName: string;
}

export default (props: DocumentCardProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.card} onClick={() => props.onClickDocument(props.docId, props.fileName)}>
      <BaseTypography tag="span" fontType="text">
        {props.fileName}
      </BaseTypography>
      <Box className={classes.wrapperIcon}>
        <DocumentIcon className={classes.icon} />
      </Box>
    </Box>
  );
};
