import { createReducer } from '~/lib/redux';

import { appErrorAction, appErrorClearAction } from '../actions';

export const STATE_NAME = 'error';

export interface AppErrorState {
  error?: Error;
  title?: string;
  message?: string;
}

const defaultState: AppErrorState = {
  error: undefined,
};

const reducer = createReducer({}, defaultState);

reducer.on(
  appErrorAction,
  (state, { error, title, message }): AppErrorState => ({
    ...state,
    error,
    title,
    message,
  }),
);

reducer.on(
  appErrorClearAction,
  (state): AppErrorState => ({
    ...state,
    error: undefined,
    title: undefined,
    message: undefined,
  }),
);

export default reducer;
