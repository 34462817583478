import { createAsyncReducer } from "~/lib/redux";
import { initial } from "~/lib/redux/async";
import {
  accountClearAction,
  accountPrintFormsClearAction,
  accountPrintFormsErrorAction,
  accountPrintFormsStartAction,
  accountPrintFormsSuccessAction,
} from "../actions";

export const STATE_NAME = "printForms";

const [reducer, defaultState] = createAsyncReducer(
  accountPrintFormsStartAction,
  accountPrintFormsSuccessAction,
  accountPrintFormsErrorAction,
  accountPrintFormsClearAction
);

reducer.on(accountClearAction, () => initial());

export type AccountPrintFormsState = typeof defaultState;

export { defaultState };
export default reducer;
