import { createAction, createAsyncActions } from '~/lib/redux';

import {
  BOBrokerReportRequestDTO,
  BrokerReportRequestDTO,
  FundsWithdrawalRequestDTO,
  FundsWithdrawalResponseDTO,
} from '~/entities/requests';

export const [
  requestsPostBrokerReportRequestAction,
  requestsPostBrokerReportStartAction,
  requestsPostBrokerReportSuccessAction,
  requestsPostBrokerReportErrorAction,
  requestsPostBrokerReportClearAction,
] = createAsyncActions<BOBrokerReportRequestDTO, BrokerReportRequestDTO>('REQUESTS_POST_BROKER_REPORT');

export const [
  requestsPostFundsWithdrawalRequestAction,
  requestsPostFundsWithdrawalStartAction,
  requestsPostFundsWithdrawalSuccessAction,
  requestsPostFundsWithdrawalErrorAction,
  requestsPostFundsWithdrawalClearAction,
] = createAsyncActions<FundsWithdrawalResponseDTO, FundsWithdrawalRequestDTO>('REQUESTS_POST_FUNDS_WITHDRAWAL');

export interface PrintFiles {
  requestId: string;
  printFiles: { name: string; data: string } | null;
}

export const [
  requestsPrintFormsRequestAction,
  requestsPrintFormsStartAction,
  requestsPrintFormsSuccessAction,
  requestsPrintFormsErrorAction,
  requestsPrintFormsClearAction,
] = createAsyncActions<PrintFiles, string>('REQUESTS_PRINT_FORMS');

export const requestsSmsSignSignRequestAction = createAction<{ requestId: string }>('REQUESTS_SMS_SIGN_SIGN');
export const requestsSmsSignResendRequestAction = createAction<{ requestId: string }>('REQUESTS_SMS_SIGN_RESEND');
export const requestsSmsSignCheckRequestAction = createAction<{ requestId: string; otp: string }>(
  'REQUESTS_SMS_SIGN_CHECK',
);

export const requestsSavePrintFormsAction = createAction<{ data: string; filename: string; isBase64: boolean }[]>(
  'REQUEST_SAVE_PRINT_FORMS',
);
