import { createDomain } from "effector";
import { createGate } from "effector-react";

const domain = createDomain("not-auth-blocked");
const gate = createGate({ domain });

const setOpenPage = domain.createEvent<boolean>();

const $isOpenPage = domain.createStore(false);
$isOpenPage.on(setOpenPage, (_, payload) => payload);
$isOpenPage.reset(gate.close);

export const modelNotAuthBlocked = {
  gate,
  $isOpenPage,
  setOpenPage,
};
