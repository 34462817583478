import { makeStyles } from "tss-react/mui";

import theme from "~/configs/theme";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  title: {
    display: "block",
    fontSize: "24px",
    lineHeight: "28px",
    color: colorTable.charcoalBlack,
    [theme.breakpoints.down(768)]: {
      fontSize: "20px",
    },
  },
  subTitle: {
    display: "block",
    fontSize: "16px",
    lineHeight: "24px",
    color: colorTable.lightGrey_90,
    paddingTop: "8px",
  },
  accordionWrapper: {
    padding: "0 0 41px 0",
    borderBottom: `1px solid ${borderColors.lightGrey_50}`,
    marginBottom: "40px",
    boxShadow: "none",
    borderRadius: "0px",
    "&:before": {
      display: "none !important",
    },
    "& .MuiAccordionSummary-root": {
      padding: 0,
      minHeight: "unset",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      flexDirection: "column",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0",
      flexDirection: "column",
    },
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      padding: "0px",
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: "0px",
    },
    "& .MuiAccordionDetails-root": {
      padding: "0px",
    },
    "& .MuiIconButton-edgeEnd": {
      marginRight: "0",
    },
  },
  icon: {
    transform: "rotate(180deg)",
    padding: "0px",
  },
  innerWrap: {
    paddingTop: "24px",
  },
});
