import { AxiosError } from "axios";

import { AppError } from "./AppError";

export class AppResponseError extends AppError<AxiosError> {
  public readonly type: string = "AppResponseError";
  public readonly error: AxiosError;
  public parent?: Error;

  public static is = (value: unknown): value is AppResponseError => !!value && value instanceof AppResponseError;

  public constructor(error: AxiosError, message?: string) {
    super(error, message);

    this.error = error;
  }
}
