import { getStorage } from "./getStorage";

let storage: Storage | undefined | null;

const ensureStorageIsInitialized = (): Storage | null => {
  if (storage === undefined) {
    storage = getStorage("localStorage");
  }
  return storage;
};

const s = (): Storage | null => ensureStorageIsInitialized();

export const isSupported = (): boolean => !!s();

export const get = (key: string): string | null => {
  const p = s();
  return p ? p.getItem(key) : null;
};

export const set = (key: string, value: string): void => {
  const p = s();
  if (p) p.setItem(key, value);
};

export const remove = (key: string): void => {
  const p = s();
  if (p) p.removeItem(key);
};
