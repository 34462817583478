import { get } from "~/lib/request";

export type ItemDocument = {
  id: string;
  isArchive: boolean;
  processType: string;
  bcsfsBucket: string;
  bcsfsUuid: string;
  endDate?: string;
  externalId: string;
  publicationDate: string;
  startDate: string;
  type: string;
  enName?: string;
  startDateForExisted?: string;
};

export type DocumentListResponse = {
  content: ItemDocument[];
};

export const getDocumentsListUKF = async (): Promise<DocumentListResponse> => {
  const res = await get("@auth/document/business/documents/client", { params: { size: 1000 } });
  return res.data;
};

export const getDocumentUKF = async (id: string) => {
  const res = await get("@auth/document/business/documents/file/base64", { params: { id } });
  return res.data;
};
