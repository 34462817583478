import React, { ReactHTML, useImperativeHandle, useRef } from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import omit from "lodash/omit";

import { BaseTypography, BaseTypographyProps } from "~/shared/ui-kit/BaseComponents";
import useStyles from "./styles";

export interface BaseRadioProps<T extends keyof ReactHTML> extends React.ComponentProps<"input"> {
  typographyProps?: Pick<BaseTypographyProps<T>, "className" | "fontType">;
  children?: BaseTypographyProps<T>["children"];
  radioRef?: React.MutableRefObject<HTMLInputElement | null>;
  radioFieldRef?: React.MutableRefObject<HTMLDivElement | null>;
}

export default function <P extends keyof ReactHTML>(props: BaseRadioProps<P>) {
  const radioProps = omit(props, ["typographyProps", "children", "radioFieldRef", "radioRef", "className"]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  useImperativeHandle(props.radioRef, () => inputRef.current as HTMLInputElement);

  const handlerClickField = () => {
    inputRef.current && inputRef.current.click();
  };

  const tableStateClasses = {
    disabled: props.disabled,
    checked: props.checked,
  };

  const { classes } = useStyles();
  return (
    <div
      onClick={handlerClickField}
      className={cn(classes.radioField, tableStateClasses, props.className)}
      ref={props.radioFieldRef}
    >
      <Box className={cn(classes.radio, tableStateClasses)}>
        <Box className={classes.circle} />
        <input {...radioProps} type="checkbox" ref={inputRef} className={classes.input} checked={props.checked} />
      </Box>
      {props.children && (
        <BaseTypography
          {...props.typographyProps}
          tag="label"
          fontType={props.typographyProps?.fontType || "subhead"}
          className={cn(classes.label, tableStateClasses, props.typographyProps?.className)}
        >
          {props.children}
        </BaseTypography>
      )}
    </div>
  );
}
