export enum DealsNumberEnum {
  FROM_0_TO_200 = "FROM_0_TO_200",
  FROM_200_TO_1000 = "FROM_200_TO_1000",
  MORE_THAN_1000 = "MORE_THAN_1000",
}
export enum SourceOfWealthEnum {
  ANNUAL_SALARY = "ANNUAL_SALARY",
  TOTAL_NET = "TOTAL_NET",
  RENTIER = "RENTIER",
  BENEFITS_FIN_SUPPORT = "BENEFITS_FIN_SUPPORT",
  PENSIONS = "PENSIONS",
}
export enum ProfessionalActivityEnum {
  EMPLOYEE = "EMPLOYEE",
  SELF_EMPLOYED = "SELF_EMPLOYED",
  UNEMPLOYED = "UNEMPLOYED",
  STUDENT = "STUDENT",
  PENSIONER = "PENSIONER",
}
