import React from "react";
import { ErrorOutline } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";

interface SectionErrorProps {
  message?: string;
  children?: never;
}

const SectionError = ({ message = "Something went wrong!" }: SectionErrorProps) => {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <ErrorOutline fontSize="large" color="action" />
      </Grid>
      <Grid item>
        <Box mt={2}>
          <Typography paragraph>{message}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SectionError;
