import {
  KeycloakResponseModel,
  KeycloakResponseModelCode,
  KeycloakResponseModelState,
} from '../models/KeycloakResponseModel';

export const getKeycloakResponseSuccessModelOtpContent = (data?: KeycloakResponseModel | null): string => {
  return data &&
    data.state === KeycloakResponseModelState.Success &&
    data.code === KeycloakResponseModelCode.Otp &&
    data.content
    ? data.content
    : '';
};
