import { toast } from "react-toastify";
import { AppLocale } from "~/entities/app";

import { createReducer } from "~/lib/redux";
import { appSetLocaleAction } from "../actions";

export const STATE_NAME = "locale";

export type AppLocaleState = AppLocale;

export const defaultState: AppLocaleState = "en";

const reducer = createReducer<AppLocaleState>({}, defaultState);

reducer.on(appSetLocaleAction, (_, payload) => {
  toast.dismiss();
  return payload;
});

export default reducer;
