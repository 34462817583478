import { AxiosError } from "axios";
import { combine, createDomain, forward, sample } from "effector";
import { createGate } from "effector-react";
import { createReEffect } from "effector-reeffect";
import { delay } from "patronum";

import { productBuyApi, productsApi } from "~/entities/productBuy";
import { AgreementTypeEnum } from "~/entities/productBuy/models/ClientCard";
import { t } from "~/i18n";
import { getCurrency } from "~/lib/money";
import { clientCardModel } from "~/modules/client-card-model";
import { notificationModel } from "~/modules/notification-model";

const getForwardsFx = createReEffect<void, any, AxiosError>({
  handler: productsApi.getForwards,
});
const getNotesFx = createReEffect<void, any, AxiosError>({
  handler: productsApi.getNotes,
});
const getOthersFx = createReEffect<void, any, AxiosError>({
  handler: productsApi.getOthers,
});
const getPreIposFx = createReEffect<void, any, AxiosError>({
  handler: productsApi.getPreIpos,
});
const getCurrenciesFx = createReEffect<void, any, AxiosError>({
  handler: productsApi.getAccounts,
});
const getDateFx = createReEffect<void, any, AxiosError>({
  handler: productsApi.getDate,
});
const getUnderlyingAssetsFx = createReEffect<any, any, AxiosError>({
  handler: productsApi.getUnderlyingAssets,
});

const getTwaFx = createReEffect<any, any, Error>({
  handler: productBuyApi.getTwa,
});

const generateTwaFx = createReEffect<any, any, Error>({
  handler: productBuyApi.generateTwa,
});

const productsDomain = createDomain("offer list");
const gate = createGate({ domain: productsDomain });

const getProducts = productsDomain.createEvent();
const getTwa = productsDomain.createEvent();
const setCurrentOfferId = productsDomain.createEvent();
const closeProduct = productsDomain.createEvent();
const setIsTwaOpen = productsDomain.createEvent<boolean>();

forward({ from: gate.close, to: [getForwardsFx.cancel, getNotesFx.cancel, getOthersFx.cancel, getPreIposFx.cancel] });

const $forwards = productsDomain.createStore<any>([]);
$forwards.reset(gate.close);

const $notes = productsDomain.createStore<any>([]);
$notes.reset(gate.close);

const $preIpos = productsDomain.createStore<any>([]);
$preIpos.reset(gate.close);

const $others = productsDomain.createStore<any>([]);
$others.reset(gate.close);

const $currencies = productsDomain.createStore<any>([]);
$currencies.reset(gate.close);

const $date = productsDomain.createStore<any>({});
$date.reset(gate.close);

const $twa = productsDomain.createStore<any>(null);
$twa.reset(closeProduct);
$twa.on(getTwaFx.doneData, (_, data) => (data?.file ? data : null));

const $isTwaOpen = productsDomain.createStore(false);
$isTwaOpen.reset(closeProduct);
$isTwaOpen.on(setIsTwaOpen, (_, data) => data);

const $currentOfferId = productsDomain.createStore<any>(null);
$currentOfferId.reset(closeProduct);
$currentOfferId.on(setCurrentOfferId, (_, offerId) => offerId);

const updateUnderlyingAssets = productsDomain.createEvent();
const $underlyingAssets = productsDomain
  .createStore<any>([])
  .on(updateUnderlyingAssets, (prev: any, data: any) => [...prev, ...data]);

const $preparedUnderlying = productsDomain.createStore([]).on(getUnderlyingAssetsFx.doneData, (_, data) => data);

//по clientId запрашиваем все форвардные контракты
forward({ from: getProducts, to: getForwardsFx });

//кладем форвардные контракты в state
sample({
  source: getForwardsFx.doneData,
  fn: (data): any => {
    return [...data.content];
  },
  target: $forwards,
});

sample({
  source: $forwards,
  fn: (forwards) => forwards.map((item: any) => item?.underlyingAssets).flat(),
  target: updateUnderlyingAssets,
});

//по clientId запрашиваем все ноты
forward({ from: getProducts, to: getNotesFx });

//кладем ноты в state
sample({
  source: getNotesFx.doneData,
  fn: (data): any => data.content,
  target: $notes,
});

//по clientId запрашиваем все pre-ipo
forward({ from: getProducts, to: getPreIposFx });

//кладем pre-ipos в state
sample({
  source: getPreIposFx.doneData,
  fn: (data): any => {
    return [...data.content];
  },
  target: $preIpos,
});

sample({
  source: $preIpos,
  fn: (preIpos) => preIpos.map((item: any) => item?.underlyingAssets).flat(),
  target: updateUnderlyingAssets,
});

forward({
  from: $underlyingAssets,
  to: getUnderlyingAssetsFx,
});

const $modPreIpos = combine($preIpos, $preparedUnderlying, (ipos, assets) => {
  return ipos.map((item: any) => ({
    ...item,
    actives: item.underlyingAssets
      .map((asset: any) => assets.find((res: any) => res.isin === asset))
      .filter((filteredItem: any) => !!filteredItem),
  }));
});

const $modForwards = combine($forwards, $preparedUnderlying, (forwards, assets) => {
  return forwards.map((item: any) => ({
    ...item,
    actives: item.underlyingAssets
      .map((asset: any) => assets.find((res: any) => res.isin === asset))
      .filter((filteredItem: any) => !!filteredItem),
  }));
});

//по clientId запрашиваем все ценные бумаги
forward({ from: getProducts, to: getOthersFx });

//кладем ценные бумаги в state
sample({
  source: getOthersFx.doneData,
  fn: (data): any => data.content,
  target: $others,
});

//по clientId запрашиваем все ценные бумаги
forward({ from: getProducts, to: getCurrenciesFx });

//кладем ценные бумаги в state
sample({
  source: getCurrenciesFx.doneData,
  fn: (data): any => data.content,
  target: $currencies,
});

const $accountAgreements = combine(
  $currencies,
  clientCardModel.$agreements,
  $modForwards,
  $notes,
  $others,
  $modPreIpos,
  (currencies, agreements, forwards, notes, others, preIpos) => {
    const availableAgreements = agreements?.filter((item) => item?.type != AgreementTypeEnum.GENERAL_DU);
    return availableAgreements.map((item) => ({
      id: item.id,
      number: item.number,
      status: item.status,
      openDate: item.openDate,
      brokerAccounts: currencies
        .filter((currencyItem: any) => currencyItem.agreementNumber === item.number)
        .map((filteredItem: any) => ({
          ...filteredItem,
          currencyInfo: getCurrency(filteredItem.locationGroup.currencyCode),
        })),
      forwards: forwards
        .filter((forwardItem: any) => forwardItem.agreementNumber === item.number)
        .map((filteredItem: any) => ({
          ...filteredItem,
          currencyInfo: getCurrency(filteredItem.currencyCode),
        })),
      notes: notes
        .filter((notesItem: any) => notesItem.agreementNumber === item.number)
        .map((filteredItem: any) => ({
          ...filteredItem,
          currencyInfo: getCurrency(filteredItem.currencyCode),
        })),
      others: others
        .filter((othersItem: any) => othersItem.agreementNumber === item.number)
        .map((filteredItem: any) => ({
          ...filteredItem,
          currencyInfo: getCurrency(filteredItem.currencyCode),
        })),
      preIpos: preIpos
        .filter((preIposItem: any) => preIposItem.agreementNumber === item.number)
        .map((filteredItem: any) => ({
          ...filteredItem,
          currencyInfo: getCurrency(filteredItem.currencyCode),
        })),
    }));
  }
);

forward({ from: getProducts, to: getDateFx });

sample({
  source: getDateFx.doneData,
  fn: (data): any => data.content[0],
  target: $date,
});

sample({
  source: $currentOfferId,
  clock: getTwa,
  fn: (offerId) => ({
    id: offerId,
  }),
  target: generateTwaFx,
});

sample({
  source: $currentOfferId,
  clock: generateTwaFx.doneData,
  fn: (offerId) => ({
    id: offerId,
  }),
  target: getTwaFx,
});

sample({
  source: $twa,
  clock: delay({ source: getTwaFx.doneData, timeout: 3000 }),
  filter: (twa) => !twa?.file,
  target: getTwaFx.prepend(() => ({ id: $currentOfferId.getState() })),
});

getTwaFx.fail.watch(() => {
  if ($isTwaOpen.getState()) {
    notificationModel.error(t("ERRORS.error_load_doc"));
    setIsTwaOpen(false);
  }
});

generateTwaFx.fail.watch(() => {
  if ($isTwaOpen.getState()) {
    notificationModel.error(t("ERRORS.error_load_doc"));
    setIsTwaOpen(false);
  }
});

export const productListModel = {
  gate,
  $forwards,
  $notes,
  $preIpos,
  $others,
  $currencies,
  $date,
  $twa,
  $isTwaOpen,
  $currentOfferId,
  getProducts,
  $accountAgreements,
  getTwa,
  setCurrentOfferId,
  setIsTwaOpen,
  closeProduct,
  $loading:
    getCurrenciesFx.pending ||
    getDateFx.pending ||
    getForwardsFx.pending ||
    getPreIposFx.pending ||
    getOthersFx.pending ||
    getUnderlyingAssetsFx.pending,
};
