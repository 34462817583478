import React from "react";
import { Box, Button, Link } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import { links } from "~/constants/enums";
import { useTranslation } from "~/i18n";
import { useRouterHooks } from "~/lib/router";
import { loginModel } from "~/modules/keycloak/login-model";
import { LoginStepsTypes } from "~/modules/keycloak/login-model/types";
import Icon from "~/shared/Icon";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    marginTop: "70px",
    [theme.breakpoints.down(768)]: {
      marginTop: "64px",
      marginBottom: "44px",
    },
  },
  buttonWrapper: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  buttonWrapperMobile: {
    marginTop: "20px",
    marginBottom: "50px",
  },
  title: {
    fontSize: "24px",
    color: colorTable.blackDefault,
    lineHeight: "28px",
    fontWeight: 600,
  },
  titleMobile: {
    fontSize: "48px",
    color: colorTable.blackDefault,
    lineHeight: "48px",
    fontWeight: 600,
  },
  button: {
    width: "150px",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100‰",
  },
  link: {
    color: colorTable.blue_20,
    textDecoration: "none",
    fontSize: "16px",
    marginLeft: "4px",
  },
  linkMobile: {
    color: colorTable.blue_20,
    textDecoration: "none",
    fontSize: "32px",
    marginLeft: "8px",
  },
  icon: {
    marginBottom: "16px",
  },
  buttonMobile: {
    height: "12vw",
    width: "100%",
    borderRadius: "4px",
    background: colorTable.darkBlue,
    "& > span": {
      fontSize: "28px",
      color: colorTable.white,
    },
    "&.MuiButton-contained": {
      boxShadow: "none",
    },
    "&:hover": {
      background: colorTable.blue_10,
    },
  },
  buttonDesktop: {
    height: "48px",
    width: "261px",
    border: `1px solid ${borderColors.lightGrey_50}`,
    background: colorTable.darkBlue,
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
    "&:hover": {
      background: colorTable.blue_10,
    },
    "&.MuiButton-contained": {
      boxShadow: "none",
    },
    "& > span": {
      color: colorTable.white,
      fontSize: "14px",
    },
  },
  text: {
    marginBottom: "40px",
    fontSize: "16px",
    maxWidth: "467px",
    color: colorTable.lightGrey_90,
  },
  textMobile: {
    marginBottom: "80px",
    fontSize: "32px",
    color: colorTable.lightGrey_90,
  },
}));

type BlockedPropsTypes = {
  header?: string;
  subtitle?: string;
  subtitleSecond?: string;
};

const NotSecurityQuestion = ({ header, subtitle, subtitleSecond }: BlockedPropsTypes) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { navigate } = useRouterHooks();

  const { setStep } = loginModel;

  const backToLogin = () => {
    setStep(LoginStepsTypes.LOGIN);
    navigate(links.login);
    window.location.reload();
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.icon}>
        <Icon name="NotSecurityQuestionIcon" size={70} />
      </Box>
      <Box mb="16px" className={classes.title}>
        {header}
      </Box>
      <Box className={classes.text}>
        {subtitle}
        <Link className={classes.link}>{subtitleSecond}</Link>
      </Box>
      <Box className={classes.buttonWrapper}>
        <Button className={classes.buttonDesktop} onClick={backToLogin} variant="contained" color="primary">
          {t("BLOCKED.button")}
        </Button>
      </Box>
    </Box>
  );
};

export default NotSecurityQuestion;
