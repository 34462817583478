import { makeStyles } from "tss-react/mui";

import theme from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()(() => ({
  wrapper: {
    borderRadius: "8px",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.04)",
    background: colorTable.white,
    padding: "32px 40px",
    margin: "10px 0",
    [theme.breakpoints.down(768)]: {
      maxHeight: "500px",
      padding: "15px",
    },
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "24px",
    alignContent: "center",
    alignItems: "center",
  },
  content: {
    color: colorTable.textSecondary,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  imageWrapper: {
    marginRight: "24px",
  },
  title: {
    color: colorTable.charcoalBlack,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
  },
  button: {
    height: "24px",
    padding: "0px 8px",
    color: colorTable.charcoalBlack,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    overflow: "hidden",
    marginTop: "40px",
    backgroundColor: colorTable.lightGrey,
  },
}));
