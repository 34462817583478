import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export default makeStyles()(() => ({
  wrapper: {
    background: colorTable.lightGrey_10,
    padding: "24px",
    display: "flex",
    alignItems: "center",
    gap: "0 16px",
    justifyContent: "space-between",
    flexWrap: "wrap",

    [themeV5.breakpoints.down(768)]: {
      alignItems: "start",
      padding: "12px",
    },
  },
}));
