import { createAsyncReducer } from "~/lib/redux";
import { getOfferListErrorAction, getOfferListStartAction, getOfferListSuccessAction } from "../actions";

export const STATE_NAME = "list";

const [reducer, defaultState] = createAsyncReducer(
  getOfferListStartAction,
  getOfferListSuccessAction,
  getOfferListErrorAction
);

export type OfferListState = typeof defaultState;

export default reducer;
