import { SelectChangeEvent } from "@mui/material";
import { Event } from "effector";

import { notDigitPattern } from "~/constants/regexp";
import { CountryCode } from "~/entities/keycloak";

export const setCountry = (
  e: SelectChangeEvent<string>,
  countryCodes: CountryCode[],
  phoneNumber: string,
  onChangeInput: (value: string) => string,
  setCountryCode: Event<string>,
  codeIso: string
) => {
  const countryCodeCurrent = countryCodes.find((code) => code.codeIso === codeIso) ?? null;
  const countryCodeFuture = countryCodes.find((code) => code.codeIso === e.target.value) ?? null;

  if (countryCodeCurrent && countryCodeFuture) {
    const newPhoneValue = countryCodeFuture.phoneCode + phoneNumber.slice(countryCodeCurrent.phoneCode.length);

    onChangeInput(newPhoneValue);
  }

  setCountryCode(e.target.value);
};

export function getCountryByPhonePrefix(
  phoneNumber: string,
  countryCodes: CountryCode[],
  codeIso: string,
  setCountryCode: Event<string>
) {
  const phoneNumberWithoutNonDigits = phoneNumber.replace(notDigitPattern, "");

  let foundCountry = null;

  let allPossibleCountry: CountryCode[] = [];

  for (let length = 1; length <= 4; length++) {
    const possiblePrefix = "+" + phoneNumberWithoutNonDigits.substring(0, length);

    const possibleCountry = countryCodes.filter((country) => country.phoneCode === possiblePrefix);

    if (!!possibleCountry.length) {
      allPossibleCountry = [...allPossibleCountry, ...possibleCountry];
      foundCountry = possibleCountry[0].codeIso;
    }
  }

  const isCurrentCountryInPossibleCountry = !!allPossibleCountry.find((code) => code.codeIso === codeIso);

  if (allPossibleCountry.length > 1 && isCurrentCountryInPossibleCountry) {
    let firstCountyCode = allPossibleCountry[0].phoneCode;
    const countryWithOnePhoneCode = allPossibleCountry.filter((code) => code.phoneCode === firstCountyCode);

    if (countryWithOnePhoneCode.length === allPossibleCountry.length) {
      return;
    }
  }

  if (foundCountry) {
    setCountryCode(foundCountry);
  }
}
