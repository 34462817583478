import { createDomain, createEffect, forward } from "effector";
import { createGate } from "effector-react";
import { AxiosError } from "axios";

import { brokerServiceApi } from "~/pages/OpenAccount/services/api";

const documentsDomain = createDomain("documents domain");
const gate = createGate({ domain: documentsDomain });

const getDocumentFx = createEffect<any, any, AxiosError>({
  handler: brokerServiceApi.getDocumentById,
});

const downloadDocFx = createEffect<any, any, AxiosError>({
  handler: brokerServiceApi.getDocumentById,
});

const setIdDoc = documentsDomain.createEvent<number>();
const deleteDocId = documentsDomain.createEvent();

const $doc = documentsDomain.createStore<any>(null);
$doc.on(getDocumentFx.doneData, (_, data) => data);
$doc.on(deleteDocId, () => null);
$doc.reset(gate.close);

const $downloadDoc = documentsDomain.createStore<any>(null);
$downloadDoc.on(downloadDocFx.doneData, (_, data) => data);
$downloadDoc.reset(gate.close);

forward({ from: setIdDoc, to: getDocumentFx });

export const documentsModel = {
  $doc,
  gate,
  setIdDoc,
  deleteDocId,
  $getDocumentsPending: getDocumentFx.pending,
  $downloadDocPending: downloadDocFx.pending,
  $downloadDoc,
  getDocumentFx,
};
