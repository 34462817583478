import { makeStyles } from "tss-react/mui";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  checkboxWrapper: {
    width: "100%",
    margin: 0,
    "& .MuiCheckbox-root": {
      width: "24px",
      height: "24px",
      color: colorTable.lightGrey_100,
      padding: 0,
      marginRight: "16px",
    },
    "& .Mui-checked": {
      color: colorTable.blackBlue,
    },
  },
  checkboxLabel: {
    color: colorTable.black,
    textAlign: "left",
    fontSize: "14px",
    lineHeight: "20px",
  },
  redLabel: {
    color: colorTable.red,
  },
});
