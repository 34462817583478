import { ProfileExistenceResponse } from './ProfileExistenceResponse';

export enum AnketaSectionName {
  commonSection = 'commonSection',
  economySection = 'economySection',
  investSection = 'investSection',
  bankSection = 'bankSection',
  docsSection = 'docsSection',
}

export interface AnketaSection {
  path: string;
  prevSection?: AnketaSectionName;
  nextSection?: AnketaSectionName;
  isAllowed: (pe: ProfileExistenceResponse) => boolean;
}

export type AnketaSections = {
  [key in AnketaSectionName]: AnketaSection;
};

export const firstAvailableSection = AnketaSectionName.commonSection;

export const anketaSections: AnketaSections = {
  commonSection: {
    path: 'state-1',
    isAllowed: (): boolean => true,
    nextSection: AnketaSectionName.economySection,
  },
  economySection: {
    path: 'state-2',
    isAllowed: (pe: ProfileExistenceResponse): boolean => pe.economSectionCreated,
    prevSection: AnketaSectionName.commonSection,
    nextSection: AnketaSectionName.investSection,
  },
  investSection: {
    path: 'state-3',
    isAllowed: (pe: ProfileExistenceResponse): boolean => pe.investSectionCreated,
    prevSection: AnketaSectionName.economySection,
    nextSection: AnketaSectionName.bankSection,
  },
  bankSection: {
    path: 'state-4',
    isAllowed: (pe: ProfileExistenceResponse): boolean => pe.bankSectionCreated,
    prevSection: AnketaSectionName.investSection,
    nextSection: AnketaSectionName.docsSection,
  },
  docsSection: {
    path: 'state-5',
    isAllowed: (pe: ProfileExistenceResponse): boolean => pe.bankSectionCreated,
    prevSection: AnketaSectionName.bankSection,
  },
};
