import React from "react";
import { Box, FormControlLabel, Typography } from "@mui/material";
import cn from "classnames";

import CustomSwitchMin from "~/features/CustomSwitchMin/CustomSwitchMin";
import { useStyles } from "./styles";

export interface BaseSwitchLabelProps {
  title: string;
  checked: boolean;
  onChange?: any | (() => any);
  value?: string;
  styleSwitch?: object;
  styleWrapper?: object;
  styleTitle?: object;
  mt?: string;
  mb?: string;
  className?: string;
  disabled?: boolean;
  classNameSwitch?: string;
}

export const BaseSwitchLabel = ({
  title = "",
  checked = false,
  onChange = () => {},
  value = "",
  styleSwitch = {},
  styleWrapper = {},
  styleTitle,
  mt = "8px",
  mb = "24px",
  className,
  disabled,
  classNameSwitch,
}: BaseSwitchLabelProps) => {
  const { classes } = useStyles();

  return (
    <Box mt={mt} mb={mb} style={styleWrapper} className={cn(classes.switchWrapper, className)}>
      <Typography className={classes.title} style={styleTitle}>
        {title}
      </Typography>
      <FormControlLabel
        style={styleSwitch}
        control={<CustomSwitchMin checked={checked} onChange={onChange} value={value} />}
        label=""
        disabled={disabled}
        className={cn(classes.switch, classNameSwitch)}
      />
    </Box>
  );
};
