import { Yup } from "~/lib/validation";

export type PrintForm = {
  filename: string;
  url?: string;
  base64?: string;
};

export const schema = Yup.object<PrintForm>({
  filename: Yup.string().required(),
  url: Yup.string(),
  base64: Yup.string(),
}).label("PrintForm");

export type TEdmDocument = PrintForm & { type: string };
