import React from "react";
import { Grid } from "@mui/material";
import { useGate, useStore } from "effector-react";
import { makeStyles } from "tss-react/mui";

import { OtpEnterForm } from "~/features/UnauthAndBlocked";
import { useTranslation } from "~/i18n";
import { UnblockingUserSteps } from "~/modules/keycloak/unblocking-user-model/steps-enum";
import { unblockingModel } from "~/modules/keycloak/unblocking-user-model/unblocking-user-model";
import Blocked from "~/pages/UnauthAndBlocked/Blocked";
import { TemporaryBlockedAccount } from "~/pages/UnauthAndBlocked/BlockedAccount";
import NotSecurityQuestion from "~/pages/UnauthAndBlocked/NotSecurityQuestion";
import RegistrationSuccess from "~/pages/UnauthAndBlocked/Registration/screens/RegistationScreen/components/RegistrationSuccess";
import EnterAnswerForm from "~/pages/UnauthAndBlocked/UnBlockingUser/screens/UnBlockingScreen/components/EnterAnswerForm";
import EnterPhoneNumber from "~/pages/UnauthAndBlocked/UnBlockingUser/screens/UnBlockingScreen/components/EnterPhoneNumber";
import colorTable from "~/shared/ui-kit/constants/colors";

const useStyles = makeStyles()({
  root: {
    width: 480,
    color: colorTable.blackDefault,
    textAlign: "center",
    position: "relative",
  },

  mobileContainer: {
    width: "100%",
  },
});

const UnBlockingUser = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const {
    $currentStep,
    otpForm,
    $enterOtpCodeFxPending,
    $checkStatusProcessAfterEnterOtpCodeFxPending,
    submitOtp,
    $otpError,
    getNewOtp,
    onBack,
    gate,
  } = unblockingModel;

  const currentStep = useStore($currentStep);
  const enterOtpCodeFxPending = useStore($enterOtpCodeFxPending);
  const checkStatusProcessAfterEnterOtpCodeFxPending = useStore($checkStatusProcessAfterEnterOtpCodeFxPending);
  const otpError = useStore($otpError);
  useGate(gate);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item className={classes.root}>
          {currentStep === UnblockingUserSteps.EnterUserPhone && <EnterPhoneNumber />}
          {currentStep === UnblockingUserSteps.EnterOtpCode && (
            <OtpEnterForm
              enterCallback={submitOtp}
              isPending={enterOtpCodeFxPending || checkStatusProcessAfterEnterOtpCodeFxPending}
              otpError={otpError}
              getNewOtpCallback={getNewOtp}
              onBackCallback={onBack}
              form={otpForm}
            />
          )}
          {currentStep === UnblockingUserSteps.OtpEnterError && (
            <Blocked header={t("BLOCKED.cannot-send-sms.0")} subtitle={t("BLOCKED.cannot-send-sms.1")} />
          )}
          {currentStep === UnblockingUserSteps.UserAccountDisabled && (
            <Blocked header={t("BLOCKED.text.0")} subtitle={t("BLOCKED.text.1")} subtitleSecond={t("BLOCKED.text.2")} />
          )}
          {currentStep === UnblockingUserSteps.UserAccountTemporaryBlocked && <TemporaryBlockedAccount />}
          {currentStep === UnblockingUserSteps.EnterSecurityAnswer && <EnterAnswerForm />}
          {currentStep === UnblockingUserSteps.UserNotQuestion && (
            <NotSecurityQuestion
              header={t("FORGOT_PASSWORD.not-security-question.header")}
              subtitle={t("FORGOT_PASSWORD.not-security-question.title")}
              subtitleSecond={t("FORGOT_PASSWORD.not-security-question.subtitleSecond")}
            />
          )}
          {currentStep === UnblockingUserSteps.SuccessfulUnlockAccount && (
            <RegistrationSuccess header={t("UNBLOCKING_USER.header")} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default UnBlockingUser;
