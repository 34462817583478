import React, { ReactNode, useCallback, useMemo } from "react";
import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/material";
import cn from "classnames";

import { links, OpenAccountProcesses } from "~/constants/enums";
import { BaseBackButton } from "~/features/BaseComponents";
import { LinearProgressBar } from "~/features/OpenAccount";
import { useTranslation } from "~/i18n";
import { useRouterHooks } from "~/lib/router";
import { getValueByCondition } from "~/lib/utils";
import OAStepProgressBar from "~/pages/OpenAccount/components/OAStepProgressBar/OAStepProgressBar";
import OAStepProgressBarExtraDoc from "~/pages/OpenAccount/components/OAStepProgressBar/OAStepProgressBarExtraDoc";
import OAStepProgressBarSow from "~/pages/OpenAccount/components/OAStepProgressBar/OAStepProgressBarSow";
import { brokerService } from "~/pages/OpenAccount/services/services";
import { BaseButton, BaseTypography } from "~/shared/ui-kit/BaseComponents";
import useStyles from "./styles";

interface ControlsProps {
  isDocuments?: boolean;
  isBO?: boolean;
  stepPaths: string[];
  onBackButton?: () => void;
  backButtonText?: string;
  onNextStep?: () => void;
  onPrevStep?: () => void;
  children: ReactNode;
  pending?: boolean;
  disableNextButton?: boolean;
  isFirst?: boolean;
  disablePrevButton?: boolean;
  contentWithButtonsStyle?: object;
  withoutProcessButtons?: boolean;
  classNameSpacer?: string;
  stepForGoBack?: number;
  isRDU?: boolean;
}

export default (props: ControlsProps) => {
  const { onBackButton, backButtonText, isRDU } = props;
  const { t, i18n } = useTranslation();
  const { navigate, location } = useRouterHooks();
  const { goBackTo } = brokerService;
  const { classes } = useStyles();
  const currentStep = props.stepPaths.findIndex((stepPath) => stepPath === location.pathname) + 1;
  const isTabletOrMobile = useMediaQuery("(max-width: 1024px)");

  const headerText = useMemo(() => {
    if (props.isBO) {
      switch (currentStep) {
        case 1:
          return {
            title: t("EDM.personal_details.title"),
            subtitle: "",
          };
        case 2:
          return {
            title: t("OPEN_ACCOUNT.stepper.financialProfile"),
            subtitle: t("OPEN_ACCOUNT.stepper.financialProfileSubtitle"),
          };
        case 3:
          return {
            title: t("OPEN_ACCOUNT.stepper.assessmentTest"),
            subtitle: "",
          };
        case 4:
          return {
            title: t("W8.action.title"),
            subtitle: "",
          };
        case 5:
          return {
            title: getValueByCondition(
              [
                {
                  condition: location.pathname === OpenAccountProcesses.SOW_DOCUMENT_SECTION,
                  value: t("OPEN_ACCOUNT.stepper.confirmationDocuments"),
                },
                {
                  condition: location.pathname === OpenAccountProcesses.UPLOAD_OFFLINE_DOCUMENTS,
                  value: t("OPEN_ACCOUNT.identityDocuments"),
                },
              ],
              t("OPEN_ACCOUNT.stepper.documentsSign")
            ),
            subtitle: getValueByCondition(
              [
                {
                  condition: location.pathname === OpenAccountProcesses.SOW_DOCUMENT_SECTION,
                  value: t("OPEN_ACCOUNT.attachConfirmationSubtitle"),
                },
                {
                  condition: location.pathname === OpenAccountProcesses.UPLOAD_OFFLINE_DOCUMENTS,
                  value: t("OPEN_ACCOUNT.attachConfirmationIdentitySubtitle"),
                },
              ],
              t("GET_PROFESSIONAL_STATUS.review_documents_section.description")
            ),
          };
        case 6:
          return {
            title: t("OPEN_ACCOUNT.stepper.documentsSign"),
            subtitle: t("GET_PROFESSIONAL_STATUS.review_documents_section.description"),
          };
        default:
          return {
            title: t("OPEN_ASSET_MANAGEMENT.title"),
            subtitle: "",
          };
      }
    } else {
      switch (currentStep) {
        case 2:
          return {
            title: t("BANK_SECTION.AMStepLabel"),
            subtitle: t("BANK_SECTION.AMStepSubLabel"),
          };
        case 3:
          return {
            title: t("OPEN_ACCOUNT.stepper.documentsSign"),
            subtitle: t("GET_PROFESSIONAL_STATUS.review_documents_section.description"),
          };
        case 4:
          if (isRDU) {
            return {
              title: t("OPEN_ACCOUNT.stepper.documentsSign"),
              subtitle: t("GET_PROFESSIONAL_STATUS.review_documents_section.description"),
            };
          } else {
            return {
              title: t("OPEN_ASSET_MANAGEMENT.individual_title"),
              subtitle: t("OPEN_ASSET_MANAGEMENT.individual_request"),
            };
          }
        default:
          return {
            title: t("OPEN_ASSET_MANAGEMENT.individual_title"),
            subtitle: t("OPEN_ASSET_MANAGEMENT.individual_request"),
          };
      }
    }
  }, [i18n.language, props.isDocuments, props.isBO, props.stepPaths, location]);

  const OAStepStep = useMemo(() => {
    switch (location.pathname) {
      case OpenAccountProcesses.SOW_DOCUMENT_SECTION:
        return <OAStepProgressBarSow page={currentStep} />;
      case OpenAccountProcesses.UPLOAD_OFFLINE_DOCUMENTS:
        return <OAStepProgressBarExtraDoc page={currentStep} />;
      case OpenAccountProcesses.DOCUMENTS_SIGN_SECTION:
        return <OAStepProgressBarSow page={currentStep} />;
      default:
        return <OAStepProgressBar page={currentStep} />;
    }
  }, [i18n.language, props.stepPaths, location]);

  const StepperRender = () => {
    if (!!props?.isBO) {
      return OAStepStep;
    } else {
      return null;
    }
  };

  const isAssetManagement = Boolean(localStorage.getItem("strategyForAM"));

  const progressPercentage = useMemo(() => {
    return currentStep * 16.6;
  }, [props.stepPaths, location.pathname]);

  const onPrevStep = useCallback(() => {
    if (props.stepForGoBack) {
      goBackTo(props.stepForGoBack);
    }
  }, [props.stepForGoBack]);

  const handleBackButton = () => {
    if (onBackButton) {
      onBackButton();
      return;
    }
    navigate(links.operationsAssets);
  };

  return (
    <Box>
      <BaseBackButton onBack={handleBackButton} resetMargin className={classes.backButton}>
        {backButtonText || t("OPERATIONS.portfolio.portfolio")}
      </BaseBackButton>
      <Box className={classes.titleBox}>
        <BaseTypography
          tag="h1"
          fontType="title_1"
          className={cn(classes.title, { [classes.titleMargin]: headerText.subtitle })}
        >
          {headerText.title}
        </BaseTypography>
        {headerText.subtitle && (
          <BaseTypography tag="p" fontType="text" className={classes.subtitle}>
            {headerText.subtitle}
          </BaseTypography>
        )}
      </Box>
      {isTabletOrMobile && !isAssetManagement ? (
        <Box className={classes.linearProgress}>
          <LinearProgressBar variant="determinate" value={progressPercentage} />
        </Box>
      ) : (
        <StepperRender />
      )}
      <Box className={cn(classes.spacer, props.classNameSpacer)} />
      <Box style={props.contentWithButtonsStyle}>
        <Box>{props.children}</Box>
        {!props.withoutProcessButtons && (
          <Box className={classes.wrapperBtn}>
            <BaseButton
              type="button"
              onClick={props.onPrevStep ? props.onPrevStep : onPrevStep}
              typeStyle="white"
              disabled={props.disablePrevButton}
              className={classes.button}
            >
              {t("GENERAL.back")}
            </BaseButton>
            <BaseButton
              onClick={props.onNextStep}
              typeStyle="black"
              disabled={props.pending || props.disableNextButton}
              className={classes.button}
            >
              {getValueByCondition(
                [
                  {
                    condition: Boolean(props.isDocuments),
                    value: t("GENERAL.continue"),
                  },
                ],
                t("OPEN_ACCOUNT.next")
              )}
            </BaseButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};
