export type CommissionRequest = {
  account?: string;
  agreementNumber?: string;
  investmentSum?: number;
  offerId?: string;
};

export type ConvertCurrencyRequest = {
  price?: number;
  investmentSum?: number;
  currency?: string;
  currencySettlement?: string;
  clientId?: string;
};

export type ConvertCurrencyResponse = {
  totalAmount: number;
};
