import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import { t } from "~/i18n";
import { useStyles } from "./styles";

export interface BaseDescriptionCardProps {
  description: string;
  imageOrIcon: React.ReactNode;
  title: string;
  buttonVisible?: boolean;
  buttonText?: string;
  buttonEvent?: () => void;
  buttonStyle?: object;
  style?: object;
  maxLength?: number;
}

export const BaseDescriptionCard = ({
  description,
  imageOrIcon,
  title,
  buttonVisible = false,
  buttonText,
  buttonStyle = {},
  style = {},
  maxLength = 200,
  buttonEvent,
}: BaseDescriptionCardProps) => {
  const { classes } = useStyles();
  const [text, setText] = useState(description);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (buttonVisible && description.length > maxLength) {
      cutText();
    }
  }, []);

  const cutText = () => {
    setText(`${description.slice(0, maxLength)}...`);
  };

  const handleClick = () => {
    if (buttonEvent) return buttonEvent();

    if (isExpanded) {
      cutText();
    } else {
      setText(description);
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <Box className={classes.wrapper} style={style}>
      <Box className={classes.header}>
        <Box className={classes.imageWrapper}>{imageOrIcon}</Box>
        <Typography className={classes.title}>{title}</Typography>
      </Box>
      <Box className={classes.content}>{text}</Box>
      {buttonVisible && (
        <Button className={classes.button} style={buttonStyle} onClick={handleClick}>
          {buttonText || t(isExpanded ? "PRODUCT-BUY.showcase.minimize" : "OPERATIONS.titles.read_all")}
        </Button>
      )}
    </Box>
  );
};
