import { locationGroupEnum } from "~/constants/enums";
import {
  CASH_USD_LOCATION_ID,
  CLIENT_MONEY_TRADING_ACCOUNT_EUR,
  CLIENT_MONEY_TRADING_ACCOUNT_USD,
  DMA_US_MARKET_LOCATION_ID,
} from "~/constants/money";
import { currentFilterArgs, filteredProductArgs } from "./types";

export const SELECT_ALL_NAME = "default";

export const INITIAL_FILTER = {
  agreement: "",
  account: SELECT_ALL_NAME,
  currency: SELECT_ALL_NAME,
} as currentFilterArgs;

export const cashGroups = [CASH_USD_LOCATION_ID, DMA_US_MARKET_LOCATION_ID];

export const cashLocationGroups = [locationGroupEnum.CLIENT_CASH_USD, locationGroupEnum.DMA_US_MARKET];

export const INITIAL_FILTERED_PRODUCTS = {
  availableMoney: [],
  availableMoneyPlate: [],
  forwards: [],
  preIpos: [],
  others: [],
  notes: [],
} as filteredProductArgs;

export const tradingGroups = [CLIENT_MONEY_TRADING_ACCOUNT_EUR, CLIENT_MONEY_TRADING_ACCOUNT_USD];

export enum applicationsTypeEnum {
  NEW = "NEW",
  CREATED = "CREATED",
  ACTIVE = "ACTIVE",
  INTIALIZING = "INTIALIZING",
  SENT = "SENT",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
}
