import { createDomain, forward, sample } from "effector";
import { createForm } from "effector-forms";
import { createGate } from "effector-react";
import { createReEffect } from "effector-reeffect";
import { combineEvents } from "patronum";

import { processStatus } from "~/constants/enums";
import { notDigitPattern } from "~/constants/regexp";
import {
  CHANGE_PASSWORD_ENUM,
  CountryCode,
  ProcessBusinessRequest,
  ProcessBusinessResponse,
  ProcessStateRequest,
} from "~/entities/keycloak";
import { authApi } from "~/entities/keycloak/api";
import {
  INVALID_OTP_FIRST_BLOCK,
  OTP_DONT_MATCH,
  processResultEnum,
  TEMPORARY_BLOCKED_USER_AFTER_SET_QUESTION,
  USER_RESET_PASSWORD_BLOCKED,
} from "~/entities/productBuy/types";
import { t } from "~/i18n";
import { autofillOtpCode } from "~/lib/autofillOtpCode";
import { showOtpSecretError } from "~/lib/errorHandlers";
import { maxLengthPassword, minLengthPassword, rules } from "~/lib/validation/rules";
import { firebaseModel } from "~/modules/firebase";
import { loginModel } from "~/modules/keycloak/login-model";
import { UnblockingUserSteps } from "~/modules/keycloak/unblocking-user-model/steps-enum";
import { notificationModel } from "~/modules/notification-model";
import { TGetBusinessKeyError } from "~/shared/types";

const unblockingUserFx = createReEffect<ProcessBusinessRequest, ProcessBusinessResponse, TGetBusinessKeyError>({
  handler: authApi.unblockingUser,
});

const checkStatusProcessAfterUnblockingUserFx = createReEffect<any, any, any>({
  handler: authApi.getProcessState,
});

const enterOtpCodeFx = createReEffect<any, any, any>({
  handler: authApi.completeTask,
});

const checkStatusProcessAfterEnterOtpCodeFx = createReEffect<any, any, any>({
  handler: authApi.getProcessState,
});

const enterNewPasswordAndSecurityAnswerFx = createReEffect<any, any, any>({
  handler: authApi.completeTask,
});

const checkStatusProcessAfterEnterNewPasswordFx = createReEffect<any, any, any>({
  handler: authApi.getProcessState,
});

const getCountryCodeFx = createReEffect<any, CountryCode[], any>({ handler: authApi.getCountryCode });

const unblockingUserDomain = createDomain("unblockingUser-model");

const userUnblockingGate = createGate({ domain: unblockingUserDomain });

forward({ from: userUnblockingGate.open, to: getCountryCodeFx });

const setCountryCode = unblockingUserDomain.createEvent<string>();
const onBack = unblockingUserDomain.createEvent();
const setStep = unblockingUserDomain.createEvent<UnblockingUserSteps>();
const isOtpError = unblockingUserDomain.createEvent<boolean>();
const unBlockUser = unblockingUserDomain.createEvent();
const businessKey = unblockingUserDomain.createEvent<{ processBusinessKey: string }>();
const submitOtp = unblockingUserDomain.createEvent();
const getNewOtp = unblockingUserDomain.createEvent();
const toSecurityQuestion = unblockingUserDomain.createEvent();
const setErrorNotRegisterNumber = unblockingUserDomain.createEvent<boolean>();
const setAnswerAndPassword = unblockingUserDomain.createEvent();
const setInvalidAnswer = unblockingUserDomain.createEvent<boolean>();

const $invalidAnswer = unblockingUserDomain.createStore<boolean>(false);
$invalidAnswer.on(setInvalidAnswer, (_, value) => value);

const $notRegisterNumber = unblockingUserDomain.createStore<boolean>(false);
$notRegisterNumber.reset(onBack);
$notRegisterNumber.on(setErrorNotRegisterNumber, (_, value) => value);

const $question = unblockingUserDomain.createStore<string>("");
$question.reset(onBack);
$question.on(toSecurityQuestion, (_, securityQuestion) => securityQuestion);

const $processInfoOtp = unblockingUserDomain.createStore<any>(null);
$processInfoOtp.on(
  [
    checkStatusProcessAfterUnblockingUserFx.doneData,
    checkStatusProcessAfterEnterOtpCodeFx.doneData,
    checkStatusProcessAfterEnterNewPasswordFx.doneData,
  ],
  (_, data) => data
);

const $otpError = unblockingUserDomain.createStore<boolean>(false);
$otpError.reset(onBack);
$otpError.on(isOtpError, (_, status) => status);

const $currentStep = unblockingUserDomain.createStore<UnblockingUserSteps>(UnblockingUserSteps.EnterUserPhone);
$currentStep.on(setStep, (_, step) => step);

const $countryCodes = unblockingUserDomain.createStore<CountryCode[]>([]);
$countryCodes.on(getCountryCodeFx.doneData, (_, data) => data);

const $codeIso = unblockingUserDomain.createStore<string>("");
$codeIso.on(setCountryCode, (_, code) => code);

const setRecaptchaValue = unblockingUserDomain.createEvent<string>();

const $recaptchaValue = unblockingUserDomain.createStore<string>("service is unavailable");
$recaptchaValue.on(setRecaptchaValue, (_, data) => data);

const $retryTaskId = unblockingUserDomain.createStore<string | null>(null);
const setRetryTaskId = unblockingUserDomain.createEvent<string>();
$retryTaskId.on(setRetryTaskId, (_, data) => data);
$retryTaskId.reset(userUnblockingGate.close);

sample({
  clock: onBack,
  target: setStep.prepend(() => UnblockingUserSteps.EnterUserPhone),
});

const formPhoneNumber = createForm({
  fields: {
    phoneNumber: {
      init: "",
      rules: [rules.phoneNumberIsField($countryCodes, $codeIso), rules.required()],
    },
  },
  domain: unblockingUserDomain,
  validateOn: ["submit"],
});

forward({ from: submitOtp, to: formPhoneNumber.reset });

const formOtp = createForm({
  fields: {
    otp: {
      init: "",
      rules: (_value: string, form) =>
        form.isOtpRequired
          ? [
              {
                name: "otp is empty",
                validator: (value: string) => value.trim() !== "",
              },
              {
                name: "otp length",
                validator: (value: string) => value.trim().length === 6,
              },
            ]
          : [],
    },
  },
  domain: unblockingUserDomain,
  validateOn: ["submit"],
});

$otpError.reset(formOtp.fields.otp.onChange);
forward({ from: setAnswerAndPassword, to: formPhoneNumber.reset });

const formUserAnswer = createForm({
  fields: {
    answer: {
      init: "",
      rules: [
        {
          name: "answer is empty",
          validator: (value: string) => value.trim() !== "",
          errorText: "VALIDATION.required-field",
        },
        {
          name: "answer is empty",
          validator: (value: string) => value?.trim().length <= 255,
          errorText: "REGISTRATION.error.0",
        },
      ],
    },
    newPassword: {
      init: "",
      rules: [
        rules.password(),
        {
          name: "password short",
          validator: (password: string) => password.trim().length >= minLengthPassword,
          errorText: "VALIDATION.short-password",
        },
        {
          name: "password long",
          validator: (password: string) => password.trim().length < maxLengthPassword,
          errorText: "VALIDATION.long-password",
        },
      ],
    },
    confirmPassword: {
      init: "",
      rules: [
        {
          name: "confirm password is empty",
          validator: (confirmPassword: string) => confirmPassword?.trim() !== "",
        },
      ],
    },
  },
  domain: unblockingUserDomain,
  validateOn: ["submit", "change"],
});

sample({
  source: $countryCodes,
  clock: getCountryCodeFx.doneData,
  fn: (country) => {
    const countryPreview = country.find((item) => item.codeIso === "643");
    return countryPreview?.codeIso ?? "";
  },
  target: $codeIso,
});

sample({
  source: [$countryCodes, $codeIso] as const,
  clock: getCountryCodeFx.doneData,
  fn: ([country, codeIso]) => {
    const countryPreview = country.find((item) => item.codeIso === "643");
    const chosenCountry = country.find((item) => item.codeIso === codeIso);

    return chosenCountry?.phoneCode || (countryPreview?.phoneCode ?? "");
  },
  target: formPhoneNumber.fields.phoneNumber.onChange,
});

const unBlockingUser = combineEvents({
  events: [unBlockUser, formPhoneNumber.formValidated],
});

sample({
  source: [formPhoneNumber.$values, $countryCodes, $codeIso, $recaptchaValue, firebaseModel.$otpSecret] as const,
  clock: unBlockingUser,
  fn: ([values, countryCodes, codeIso, recaptcha, otpSecret]) => {
    const countryCode = countryCodes.find((codePhone) => codePhone.codeIso === codeIso);
    const phoneNumber = "+" + values.phoneNumber.replace(notDigitPattern, "");

    return {
      username: {
        login: phoneNumber,
        region: countryCode?.charCode2 ?? "",
      },
      recaptcha,
      otpSecret,
    };
  },
  target: unblockingUserFx,
});

sample({
  source: unblockingUserFx.doneData,
  clock: unblockingUserFx.doneData,
  fn: (data) => ({ processBusinessKey: data.businessKey }),
  target: businessKey,
});

sample({
  source: unblockingUserFx.doneData,
  clock: unblockingUserFx.doneData,
  fn: (data): ProcessStateRequest => ({ processBusinessKey: data.businessKey }),
  target: checkStatusProcessAfterUnblockingUserFx,
});

checkStatusProcessAfterUnblockingUserFx.doneData.watch((data) => {
  if (data.activeTasks[0]?.taskDefinitionKey === "ENTER_USERNAME") {
    setErrorNotRegisterNumber(true);
  }
  if (data.processResult === USER_RESET_PASSWORD_BLOCKED) {
    setStep(UnblockingUserSteps.UserAccountTemporaryBlocked);
  }
  if (data.processResult === processResultEnum.USER_ACCOUNT_DISABLED) {
    setStep(UnblockingUserSteps.UserAccountDisabled);
  }
  if (data.processResult === processResultEnum.OTP_SECRET_DOES_NOT_MATCH) {
    return firebaseModel.otpSecretDoesNotMatch();
  }
  if (
    data.processResult === processResultEnum.CANNOT_SEND_SMS ||
    data.processResult === processResultEnum.MAXIMUM_NUMBER_OF_INPUT_ATTEMPTS
  ) {
    setStep(UnblockingUserSteps.OtpEnterError);
  }
  if (data.activeTasks[0]?.taskDefinitionKey === "OTP_CONFIRMATION") {
    setStep(UnblockingUserSteps.EnterOtpCode);
    autofillOtpCode(data?.resultData?.debugData, () => formOtp.setForm({ otp: data.resultData.debugData }));
  }
});

sample({
  source: [$processInfoOtp, formOtp.fields.otp.$value],
  clock: submitOtp,
  fn: ([processInfo, otp]) => ({
    taskId: processInfo?.activeTasks[0]?.id ?? "",
    variables: {
      enteredCode: otp,
    },
  }),
  target: enterOtpCodeFx,
});

sample({
  source: $processInfoOtp,
  clock: getNewOtp,
  fn: (processInfo) => {
    return {
      taskId: processInfo?.activeTasks[0]?.id ?? "",
      variables: {
        enteredCode: null,
      },
    };
  },
  target: enterOtpCodeFx,
});

sample({
  source: businessKey,
  clock: enterOtpCodeFx.doneData,
  fn: (data): ProcessStateRequest => ({ processBusinessKey: data.processBusinessKey }),
  target: checkStatusProcessAfterEnterOtpCodeFx,
});

checkStatusProcessAfterEnterOtpCodeFx.doneData.watch((data) => {
  if (data.resultData.error_message === OTP_DONT_MATCH) {
    isOtpError(true);
  }

  if (
    data.processResult === processResultEnum.CANNOT_SEND_SMS ||
    data.processResult === processResultEnum.MAXIMUM_NUMBER_OF_INPUT_ATTEMPTS
  ) {
    setStep(UnblockingUserSteps.OtpEnterError);
  }
  if (data.activeTasks[0]?.taskDefinitionKey === "ENTER_USERNAME") {
    setErrorNotRegisterNumber(true);
    loginModel.showOtpForm(false);
    setStep(UnblockingUserSteps.EnterUserPhone);
  }
  if (data.processResult === INVALID_OTP_FIRST_BLOCK) {
    setStep(UnblockingUserSteps.UserAccountTemporaryBlocked);
  }
  if (data.processResult === processResultEnum.USER_NOT_SET_QUESTION_ANSWER) {
    setStep(UnblockingUserSteps.UserNotQuestion);
  }
  if (data.resultData.question) {
    setStep(UnblockingUserSteps.EnterSecurityAnswer);
    toSecurityQuestion(data.resultData.question);
  }
});

const completeEnterNewPasswordAndSecurityAnswer = combineEvents({
  events: [formUserAnswer.formValidated, setAnswerAndPassword],
});

sample({
  source: [
    $retryTaskId,
    $processInfoOtp,
    formUserAnswer.fields.answer.$value,
    formUserAnswer.fields.newPassword.$value,
  ],
  clock: completeEnterNewPasswordAndSecurityAnswer,
  fn: ([retryTaskId, processInfo, answer, password]) => {
    return {
      taskId: (retryTaskId || processInfo?.activeTasks[0]?.id) ?? "",
      variables: {
        password,
        answer: answer.trim(),
      },
    };
  },
  target: enterNewPasswordAndSecurityAnswerFx,
});

sample({
  source: businessKey,
  clock: enterNewPasswordAndSecurityAnswerFx.doneData,
  fn: (data): ProcessStateRequest => ({ processBusinessKey: data.processBusinessKey }),
  target: checkStatusProcessAfterEnterNewPasswordFx,
});

sample({
  clock: checkStatusProcessAfterEnterNewPasswordFx.doneData,
  filter: (data) => data.processResult === processResultEnum.SUCCESSFUL_UNLOCK_ACCOUNT,
  target: setStep.prepend(() => UnblockingUserSteps.SuccessfulUnlockAccount),
});

sample({
  source: businessKey,
  clock: checkStatusProcessAfterEnterNewPasswordFx.doneData,
  filter: (_, data) => !data.activeTasks.length && data.state !== processStatus.DONE,
  fn: (data) => ({ processBusinessKey: data.processBusinessKey }),
  target: checkStatusProcessAfterEnterNewPasswordFx,
});

sample({
  clock: checkStatusProcessAfterEnterNewPasswordFx.doneData,
  filter: (data) => data.activeTasks[0]?.id,
  fn: (data) => data.activeTasks[0]?.id,
  target: setRetryTaskId,
});

checkStatusProcessAfterEnterNewPasswordFx.doneData.watch((data) => {
  if (data.processResult === CHANGE_PASSWORD_ENUM.PASSWORD_MATCHES_5_PREVIOUS) {
    notificationModel.error(t("VALIDATION.same-password"));
    return;
  }

  if (data.processResult === CHANGE_PASSWORD_ENUM.INVALID_PASSWORD) {
    notificationModel.error(t("VALIDATION.invalid_password"));
    return;
  }

  if (
    data.processResult === processResultEnum.CANNOT_SEND_SMS ||
    data.processResult === processResultEnum.MAXIMUM_NUMBER_OF_INPUT_ATTEMPTS
  ) {
    setStep(UnblockingUserSteps.OtpEnterError);
  }
  if (data.processResult === processResultEnum.USER_NOT_SET_QUESTION_ANSWER) {
    setStep(UnblockingUserSteps.UserNotQuestion);
  }
  if (data.processResult === processResultEnum.USER_ACCOUNT_DISABLED) {
    setStep(UnblockingUserSteps.UserAccountDisabled);
  }
  if (
    data.processResult === TEMPORARY_BLOCKED_USER_AFTER_SET_QUESTION ||
    data.processResult === USER_RESET_PASSWORD_BLOCKED
  ) {
    setStep(UnblockingUserSteps.UserAccountTemporaryBlocked);
  }
  if (data.processResult === processResultEnum.SUCCESSFUL_UNLOCK_ACCOUNT) {
    formUserAnswer.reset();
    formOtp.reset();
    formPhoneNumber.reset();
  }
});

sample({
  clock: checkStatusProcessAfterEnterNewPasswordFx.doneData,
  filter: (data) => data.processResult === "INVALID_ANSWER",
  target: setInvalidAnswer.prepend(() => true),
});

sample({
  source: checkStatusProcessAfterEnterNewPasswordFx.doneData,
  filter: (data) => data.processResult === "INVALID_ANSWER",
  fn: (data) => {
    return data;
  },
  target: $processInfoOtp,
});

checkStatusProcessAfterEnterNewPasswordFx.doneData.watch((data) => {
  if (process.env.PUBLIC_URL !== "/online/" && data?.resultData?.debugData) {
    formOtp.setForm({ otp: data.resultData.debugData });
  }
});

unblockingUserFx.fail.watch((data) => {
  showOtpSecretError(data);
});

export const unblockingModel = {
  $countryCodes,
  $codeIso,
  setCountryCode,
  gate: userUnblockingGate,
  form: formPhoneNumber,
  otpForm: formOtp,
  formUserAnswer,
  onBack,
  $currentStep,
  $invalidAnswer,
  $notRegisterNumber,
  setStep,
  $question,
  $otpError,
  isOtpError,
  submitOtp,
  getNewOtp,
  unBlockUser,
  setAnswerAndPassword,
  $enterOtpCodeFxPending: enterOtpCodeFx.pending,
  $checkStatusProcessAfterEnterOtpCodeFxPending: checkStatusProcessAfterEnterOtpCodeFx.pending,
  $enterNewPasswordAndSecurityAnswerFxPending: enterNewPasswordAndSecurityAnswerFx.pending,
  $checkStatusProcessAfterEnterNewPasswordFxPending: checkStatusProcessAfterEnterNewPasswordFx.pending,
  $unblockingUserFxPending: unblockingUserFx.pending,
  $checkStatusProcessAfterUnblockingUserFxPending: checkStatusProcessAfterUnblockingUserFx.pending,
  setRecaptchaValue,
};
