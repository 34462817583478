import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import cn from "classnames";

import checkMark from "~/assets/svg/icons/check-mark.svg";
import checkMarkSuccess from "~/assets/svg/icons/check-mark-success.svg";
import { useTranslation } from "~/i18n";
import { useStyles } from "./styles";

interface IChangeOrResetPasswordFormErrorsProps {
  variant: "login" | "profile";
  on: boolean;
  submitted: boolean;
  short: boolean;
  weak: boolean;
  long: boolean;
}

export const ChangeOrResetPasswordFormErrors = ({
  variant,
  on,
  submitted,
  short,
  weak,
  long,
}: IChangeOrResetPasswordFormErrorsProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles({ variant });
  const isTabletOrMobile = useMediaQuery("(max-width: 768px)");

  const isSuccess = (value: boolean | undefined): boolean => on && value === false;
  const isError = (value: boolean | undefined): boolean => on && submitted && value === true;

  return (
    <ul className={classes.root}>
      <Box className={classes.itemBox}>
        <img src={isSuccess(short) ? checkMarkSuccess : checkMark} width={16} height={16} />
        <li
          className={cn(
            { [classes.error]: isError(short), [classes.success]: isSuccess(short) },
            isTabletOrMobile && classes.labelMobile
          )}
        >
          {t("VALIDATION.short-password")}
        </li>
      </Box>
      <Box className={classes.itemBox}>
        <img src={isSuccess(weak) ? checkMarkSuccess : checkMark} width={16} height={16} />
        <li
          className={cn(
            { [classes.error]: isError(weak), [classes.success]: isSuccess(weak) },
            isTabletOrMobile && classes.labelMobile
          )}
        >
          {t("VALIDATION.weak-password")}
        </li>
      </Box>
      <Box className={classes.itemBox}>
        <img src={isSuccess(long) ? checkMarkSuccess : checkMark} width={16} height={16} />
        <li
          className={cn(
            { [classes.error]: isError(long), [classes.success]: isSuccess(long) },
            isTabletOrMobile && classes.labelMobile
          )}
        >
          {t("VALIDATION.long-password")}
        </li>
      </Box>
    </ul>
  );
};
