import { ReactNode } from 'react';

export type AppMessageOptionsKey = string | number;

export type AppMessageVariant = 'success' | 'error' | 'info' | 'warning';

export interface AppMessageOptionsModel {
  key?: AppMessageOptionsKey;
  onClose?: (event: object, reason: string) => void;
  onExited?: () => void;
  anchorOrigin?: {
    horizontal: 'left' | 'center' | 'right';
    vertical: 'top' | 'bottom';
  };
  variant?: AppMessageVariant;
}

export interface AppMessageModel {
  message: ReactNode;
  options?: AppMessageOptionsModel;
}
