/* eslint-disable @typescript-eslint/camelcase */
import jwtDecode from "jwt-decode";
import {
  castKeycloakDecodedTokenModel,
  castKeycloakTokenModel,
  KeycloakDecodedTokenModel,
  KeycloakTokenModel,
} from "~/entities/keycloak";

import { TOKENS_STORAGE_KEY } from "~/constants/money";
import * as storage from "~/lib/storage";

const get = (): KeycloakTokenModel | null => {
  const token = storage.get(TOKENS_STORAGE_KEY);
  if (token) {
    try {
      const tokenParsed = castKeycloakTokenModel(JSON.parse(token));
      if (tokenParsed) {
        return tokenParsed;
      }
    } catch {} /* eslint-disable-line no-empty */
  }

  return null;
};

const getAuthorizationBearerToken = (): string => {
  const token = get();
  return token && token.access_token ? token.access_token : "";
};

const set = ({ access_token, refresh_token }: KeycloakTokenModel): void => {
  storage.set(TOKENS_STORAGE_KEY, JSON.stringify({ access_token, refresh_token }));
};

const clear = (): void => {
  storage.remove(TOKENS_STORAGE_KEY);
};

const decode = (t: KeycloakTokenModel | null | undefined, throwError = false): KeycloakDecodedTokenModel | null => {
  if (t) {
    try {
      const model = jwtDecode(t.access_token);
      const token = castKeycloakDecodedTokenModel(model, throwError);
      if (token) {
        return token;
      }
    } catch (error) {
      if (throwError && error) {
        throw error;
      }
    }
  }

  return null;
};

const verify = (t: KeycloakTokenModel | null | undefined): boolean => {
  if (t) {
    // TODO
    return true;
  }

  return false;
};

const onStorage = (callback: () => void): boolean => {
  try {
    window.addEventListener(
      "storage",
      (event: StorageEvent): void => {
        if (event) {
          const { key } = event;
          if (key === TOKENS_STORAGE_KEY) {
            callback();
          }
        }
      },
      false
    );
    return true;
  } catch {} // eslint-disable-line no-empty
  return false;
};

export default { get, set, clear, decode, verify, getAuthorizationBearerToken, onStorage };
