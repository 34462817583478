import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import { Event } from "effector";

import { accountActivity } from "~/pages/OpenAccount/services/EconomySectionServices/acсountActivity/accountActivity";
import { BaseTypography } from "~/shared/ui-kit/BaseComponents";
import useStyles from "./styles";

interface RadioItemEconomySectionProps {
  label: string;
  isChecked?: boolean;
  children?: ReactNode;
  radioSetter?: Event<boolean>;
  isFirstElem?: boolean;
  accountActivityService?: {
    setRadio: Event<Event<boolean>>;
  };
  title?: string;
  onClick?: () => void;
}

export default ({
  label,
  radioSetter,
  isChecked,
  children,
  accountActivityService,
  onClick,
}: RadioItemEconomySectionProps) => {
  const { setRadio } = accountActivityService ? accountActivityService : accountActivity;
  const { classes } = useStyles();

  const handlerClick = () => {
    radioSetter && setRadio(radioSetter);
    onClick?.();
  };

  return (
    <Box>
      <Box className={cn(classes.radioItem)} onClick={handlerClick}>
        <BaseTypography tag="p" fontType="text">
          {label}
        </BaseTypography>
        <Box className={cn(classes.radio, isChecked && "checked")}>
          <Box className={classes.circle} />
          <input className={classes.input} checked={isChecked} />
        </Box>
      </Box>
      {isChecked && children && <Box className={classes.content}>{children}</Box>}
    </Box>
  );
};
