import { AccountRequest } from "~/entities/account";
import { PrintForms } from "~/entities/common";
import { createAction, createAsyncActions } from "~/lib/redux";

export const [
  accountPrintFormsRequestAction,
  accountPrintFormsStartAction,
  accountPrintFormsSuccessAction,
  accountPrintFormsErrorAction,
  accountPrintFormsClearAction,
] = createAsyncActions<PrintForms>("ACCOUNT_PRINT_FORMS");

export const [
  accountRequestRequestAction,
  accountRequestStartAction,
  accountRequestSuccessAction,
  accountRequestErrorAction,
] = createAsyncActions<AccountRequest>("ACCOUNT_REQUEST");

export const accountSmsSignSignRequestAction = createAction("ACCOUNT_SMS_SIGN_SIGN");
export const accountSmsSignResendRequestAction = createAction("ACCOUNT_SMS_SIGN_RESEND");
export const accountSmsSignCheckRequestAction = createAction<{ otp: string }>("ACCOUNT_SMS_SIGN_CHECK");
export const accountClearAction = createAction("ACCOUNT_CLEAR");

export const accountSavePrintFormsAction =
  createAction<{ filename?: string; data: string }[]>("ACCOUNT_SAVE_PRINT_FORMS");
