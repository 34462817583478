import { cast, Yup } from "~/lib/validation";

export enum DocumentCode {
  foreignPassport = "foreignPassport",
  registrationDoc = "registrationDoc",
  passportRfPhoto = "passportRfPhoto",
  dop1 = "dop1",
  dop2 = "dop2",
  dop3 = "dop3",
  dop4 = "dop4",
  dop5 = "dop5",
  dop6 = "dop6",
  dop7 = "dop7",
  dop8 = "dop8",
  dop9 = "dop9",
  dop10 = "dop10",
}

export interface MetaDocRequests {
  docType: DocumentCode;
}

export interface UploadDocRequest {
  docType: DocumentCode;
  file: File;
}

export interface DeleteDocRequest {
  docType: DocumentCode;
}

export interface UploadDocResponse {
  fileLink: string;
}

export const schema = Yup.object({
  fileLink: Yup.string().required(),
}).label("UploadDocResponse");

export const castUploadDoc = cast<UploadDocResponse>(schema);
