import { createAsyncReducer } from "~/lib/redux";
import { appBootErrorAction, appBootStartAction, appBootSuccessAction } from "../actions";

export const STATE_NAME = "boot";

const [reducer, defaultState] = createAsyncReducer(
  appBootStartAction,
  appBootSuccessAction,
  appBootErrorAction,
  null,
  false
);

export type AppBootState = typeof defaultState;

export default reducer;
