import PropTypes from 'prop-types';

export enum AsyncDataPhase {
  Initial = 'Initial',
  Pending = 'Pending',
  Success = 'Success',
  Error = 'Error',
}

export interface AsyncDataInitial {
  readonly phase: AsyncDataPhase.Initial;
  readonly data: null;
  readonly error: null;
}

export interface AsyncDataPending<T = null, E extends Error = Error> {
  readonly phase: AsyncDataPhase.Pending;
  readonly data: T | null;
  readonly error: E | null;
}

export interface AsyncDataError<T = null, E extends Error = Error> {
  readonly phase: AsyncDataPhase.Error;
  readonly data: T | null;
  readonly error: E;
}

export interface AsyncDataSuccess<T = null> {
  readonly phase: AsyncDataPhase.Success;
  readonly data: T;
  readonly error: null;
}

export type AsyncData<T = null, E extends Error = Error> =
  | AsyncDataInitial
  | AsyncDataPending<T, E>
  | AsyncDataError<T, E>
  | AsyncDataSuccess<T>;

// prop-types

export const asyncPropType = (data = PropTypes.any, error = PropTypes.instanceOf(Error)): typeof PropTypes.any =>
  PropTypes.shape({
    phase: PropTypes.oneOf([
      AsyncDataPhase.Initial,
      AsyncDataPhase.Pending,
      AsyncDataPhase.Success,
      AsyncDataPhase.Error,
    ]).isRequired,
    data,
    error,
  });

export const asyncExtPropType = (data = PropTypes.any, error = PropTypes.instanceOf(Error)): typeof PropTypes.any =>
  PropTypes.shape({
    phase: PropTypes.oneOf([
      AsyncDataPhase.Initial,
      AsyncDataPhase.Pending,
      AsyncDataPhase.Success,
      AsyncDataPhase.Error,
    ]).isRequired,
    data,
    error,
    isPending: PropTypes.func,
  });
