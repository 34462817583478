import { Domain, Store } from "effector";
import { createForm } from "effector-forms";
import moment from "moment";
import { nanoid } from "nanoid";

import { countryEnum, dateTypes } from "~/constants/enums";
import {
  apartmentRules,
  buildingRules,
  cityRules,
  houseRules,
  rules,
  stateRules,
  streetRules,
  zipCodeRules,
} from "~/lib/validation/rules";
import { TCountries } from "~/modules/countries/types";
import { newDateRules } from "./rules";

export const createClientIoPepInfoForm = (domain: Domain, booleanSource?: Store<boolean>) =>
  createForm({
    domain,
    fields: {
      pepFunction: {
        init: "",
        rules: [rules.required(booleanSource), rules.less(50)],
      },
      pepPeriodStart: {
        init: null as Date | null | string,
        rules: [
          newDateRules.required(booleanSource),
          newDateRules.lessToday(booleanSource),
          newDateRules.minDate(booleanSource),
          {
            name: "period start less period end",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepPeriodEnd && date) {
                const valid = moment(date).isBefore(
                  moment(form.pepPeriodEnd).set({ hour: 23, minute: 59, second: 59 })
                );
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.valid_period",
          },
          {
            name: "period start less period end",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepBirthDay && date) {
                const valid = moment(date).isAfter(moment(form.pepBirthDay).set({ hour: 23, minute: 59, second: 59 }));
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.date_less_birth",
          },
        ],
      },
      pepPeriodEnd: {
        init: null as Date | null | string,
        rules: [
          newDateRules.minDate(booleanSource),
          {
            name: "period end less period start",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepPeriodStart && date) {
                const valid = moment(date).isAfter(
                  moment(form.pepPeriodStart).set({ hour: 23, minute: 59, second: 59 })
                );
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.dateEnd_more_dateStart",
          },
          {
            name: "period start less period end",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepBirthDay && date) {
                const valid = moment(date).isAfter(moment(form.pepBirthDay).set({ hour: 23, minute: 59, second: 59 }));
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.date_less_birth_end",
          },
        ],
      },
    },
    validateOn: ["submit"],
  });

export const createIoPepInfoForm = (domain: Domain, booleanSource?: Store<boolean>) =>
  createForm({
    domain: domain,
    fields: {
      pepFirstName: {
        init: "",
        rules: [rules.required(booleanSource), rules.trueName(), rules.less(50)], // Нужна проверка, т.к. в карте клиента могут приходить кириллические символы, а они запрещены только на ввод
      },
      pepLastName: {
        init: "",
        rules: [rules.required(booleanSource), rules.trueName(), rules.less(50)],
      },
      pepMiddleName: {
        init: "",
        rules: [rules.trueName(), rules.less(50)],
      },
      relationship: {
        init: "",
        rules: [rules.required(booleanSource), rules.less(50), rules.latinOnly(booleanSource)],
      },
      pepPassSeries: {
        init: "",
        rules: [rules.less(50)],
      },
      pepPassNumber: {
        init: "",
        rules: [rules.required(booleanSource), rules.less(50)],
      },
      pepPassIssuedWhen: {
        init: null as Date | null | string,
        rules: [
          newDateRules.required(),
          newDateRules.lessToday(),
          {
            name: "period start less period end",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepBirthDay && date) {
                const valid = moment(date).isAfter(moment(form.pepBirthDay).set({ hour: 23, minute: 59, second: 59 }));
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.date_less_birth_pass",
          },
        ],
      },
      pepBirthDay: {
        init: null as Date | null | string,
        rules: [
          newDateRules.required(booleanSource),
          newDateRules.minDate(booleanSource),
          newDateRules.lessToday(booleanSource),
        ],
      },
      pepPlaceOfBirth: {
        init: "",
        rules: [rules.required(booleanSource), rules.less(100), rules.city(booleanSource)],
      },
      pepBirthCountryCode: {
        init: "",
        rules: [rules.required(booleanSource)],
      },
      pepFunction: {
        init: "",
        rules: [rules.required(booleanSource), rules.less(50)],
      },
      pepPeriodStart: {
        init: null as Date | null | string,
        rules: [
          newDateRules.required(booleanSource),
          newDateRules.lessToday(booleanSource),
          newDateRules.minDate(booleanSource),
          {
            name: "period start less period end",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepPeriodEnd && date) {
                const valid = moment(date).isBefore(
                  moment(form.pepPeriodEnd).set({ hour: 23, minute: 59, second: 59 })
                );
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.valid_period",
          },
          {
            name: "period start less period end",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepBirthDay && date) {
                const valid = moment(date).isAfter(moment(form.pepBirthDay).set({ hour: 23, minute: 59, second: 59 }));
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.date_less_birth",
          },
        ],
      },
      pepPeriodEnd: {
        init: null as Date | null | string,
        rules: [
          newDateRules.minDate(booleanSource),
          {
            name: "period end less period start",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepPeriodStart && date) {
                const valid = moment(date).isAfter(
                  moment(form.pepPeriodStart).set({ hour: 23, minute: 59, second: 59 })
                );
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.dateEnd_more_dateStart",
          },
          {
            name: "period start less period end",
            source: booleanSource,
            validator: (date, form, needToValidate) => {
              if (form.pepBirthDay && date) {
                const valid = moment(date).isAfter(moment(form.pepBirthDay).set({ hour: 23, minute: 59, second: 59 }));
                if (needToValidate !== null) return needToValidate ? valid : true;
                return valid;
              }
              return true;
            },
            errorText: "OPEN_ACCOUNT.errors.date_less_birth_end",
          },
        ],
      },
    },
    validateOn: ["submit"],
  });

export const createAddressForm = (
  type: string,
  domain: any,
  validation: any,
  isOnline: any,
  offlineValidation?: any
): any => {
  return createForm({
    domain: domain,
    fields: {
      country: {
        init: "",
        rules: [
          {
            name: "required_if",
            validator: (val: string, form) => {
              if (!!form.address) return true;
              return Boolean(val);
            },
            errorText: "VALIDATION.required-field",
          },
        ],
      },
      city: {
        init: "",
        rules: [
          ...cityRules(offlineValidation),
          {
            name: "required_if",
            validator: (val: string, form) => {
              if (!!form.address) return true;
              return Boolean(val);
            },
            errorText: "VALIDATION.required-field",
          },
        ],
      },
      type: {
        init: type,
      },
      state: {
        init: "",
        rules: [
          ...stateRules(offlineValidation),
          {
            name: "required_if",
            validator: (val: string, form) => {
              if (!!form.address) return true;
              return Boolean(val);
            },
            errorText: "VALIDATION.required-field",
          },
        ],
      },
      street: {
        init: "",
        rules: [
          ...streetRules(offlineValidation),
          {
            name: "required_if",
            validator: (val: string, form) => {
              if (!!form.address) return true;
              return Boolean(val);
            },
            errorText: "VALIDATION.required-field",
          },
        ],
      },
      house: {
        init: "",
        rules: [
          ...houseRules(validation),
          {
            name: "required_if",
            validator: (val: string, form) => {
              if (!!form.address) return true;
              return Boolean(val);
            },
            errorText: "VALIDATION.required-field",
          },
        ],
      },
      building: {
        init: "",
        rules: buildingRules(offlineValidation),
      },
      apartment: {
        init: "",
        rules: apartmentRules(offlineValidation),
      },
      zipcode: {
        init: "",
        rules: [
          ...zipCodeRules(offlineValidation),
          {
            name: "required_if",
            validator: (val: string, form) => {
              if (!!form.address) return true;
              return Boolean(val);
            },
            errorText: "VALIDATION.required-field",
          },
        ],
      },
      address: {
        init: "",
        rules: [rules.required(isOnline)],
      },
    },
    validateOn: ["submit"],
  });
};

export const createUnionAddressString = (addressObj: any, isOnline: boolean) => {
  return isOnline
    ? addressObj?.address ??
        `${addressObj?.country}, ${
          addressObj?.state || ""
        }, ${addressObj?.street}, ${addressObj?.city}, ${addressObj?.house}${
          addressObj?.building || ""
        }, ${addressObj?.apartment || ""}`
    : "";
};

export const documentForm = (type: string, domain: any, validation: any) => {
  return createForm({
    domain: domain,
    fields: {
      type: {
        init: type,
      },
      number: {
        init: "",
      },
      serial: {
        init: "",
      },
      issueDate: {
        init: "",
      },
      expireDate: {
        init: "",
      },
      issuedBy: {
        init: "",
        rules: [
          {
            name: "requrired",
            validator: (val, form) => (form.number && validation ? !!val : true),
            errorText: "VALIDATION.required-field",
          },
        ],
      },
    },
    validateOn: ["submit"],
  });
};

export const formatDTOToDate = (date: string | null | any): Date | null =>
  date ? moment(date, dateTypes.EN_DATE).toDate() : null;

export const formatDTOToDateRu = (date: string | null | any): Date | null =>
  date ? moment(date, dateTypes.RUS_DATE).toDate() : null;

export const booleanArrayToSelect = (values: boolean[] | null): number | null => {
  if (values) {
    const index = values.findIndex((value) => value);
    if (index >= 0) return index;
    else return null;
  }
  return null;
};

export const changeBooleanArray = (arr: boolean[], itemsCount: number): boolean[] => {
  const templateArray = Array(itemsCount)
    .fill(false)
    .map((item, index) => {
      if (arr[index] === true) {
        return arr[index];
      }
      return item;
    });
  return templateArray;
};

export const selectToBooleanArray = (selectedValue: any, itemsCount: number): boolean[] => {
  const templateArray = Array(itemsCount).fill(false);
  templateArray[selectedValue] = true;
  return templateArray;
};

export const substringFrom = (str: string, fromString: string) => {
  const from = str.search(fromString) + fromString.length;
  const to = str.length;
  return str.substring(from, to);
};

type NoTinReason = {
  value: string;
  reasonType: string;
};

export const noTinReasons: NoTinReason[] = [
  { reasonType: "A", value: "OPEN_ACCOUNT.common_section.crcForm.noTinReasonA" },
  { reasonType: "B", value: "OPEN_ACCOUNT.common_section.crcForm.noTinReasonB" },
  { reasonType: "C", value: "OPEN_ACCOUNT.common_section.crcForm.noTinReasonC" },
];

export const createCrsInfoForm = (domain: Domain, lastForm?: boolean) =>
  createForm({
    domain: domain,
    fields: {
      id: {
        init: nanoid(7),
      },
      taxResidencyCountry: {
        init: "",
        rules: [
          {
            name: "required",
            validator: (val, form) => {
              if (lastForm) {
                if (!form.tinNotRequired) {
                  return form.tinNumber?.trim() ? !!val : true;
                } else {
                  return !!val;
                }
              } else {
                return !!val;
              }
            },
            errorText: "VALIDATION.required-field",
          },
        ],
      },
      tinNumber: {
        init: "",
        rules: [
          {
            name: "required",
            validator: (val, form) => {
              if (lastForm) {
                if (!form.tinNotRequired) {
                  return form.taxResidencyCountry ? !!val : true;
                } else {
                  return true;
                }
              } else {
                return !form.tinNotRequired ? !!val : true;
              }
            },
            errorText: "VALIDATION.required-field",
          },
          rules.less(50),
        ],
      },
      tinNotRequired: {
        init: false,
      },
    },
  });

export const getCrsValuesToDTO = (forms: CrsInfoForm[]) => {
  return forms.map((form) => {
    const formData = form.$values.getState();
    return {
      tinNumber: formData.tinNumber?.trim() || null,
      tinNotRequired: formData.tinNotRequired || false,
      taxResidencyCountry: formData.taxResidencyCountry,
    };
  });
};

export const checkSameForms = (forms: CrsInfoForm[]): number[] => {
  const indexes = [];
  const formsValues = forms.map((form) => form.$values.getState());
  for (let i = 0; i < formsValues.length; i++) {
    for (let j = 0; j < formsValues.length; j++) {
      if (i !== j && formsValues[i].taxResidencyCountry === formsValues[j].taxResidencyCountry) {
        if (
          !formsValues[i].tinNotRequired &&
          !formsValues[j].tinNotRequired &&
          formsValues[i].tinNumber !== "" &&
          formsValues[i].tinNumber === formsValues[j].tinNumber
        )
          indexes.push(i);
      }
    }
  }
  return indexes;
};

export const formatCountrySearch = (countries: TCountries[]): TCountries[] => {
  const rusElem = countries.find((country) => country.codeIso === countryEnum.RF);
  const areElem = countries.find((country) => country.codeIso === countryEnum.ARE);

  if (rusElem && areElem) {
    const filtredCountries = countries
      .filter((country) => country.codeIso !== countryEnum.RF && country.codeIso !== countryEnum.ARE)
      .sort((a, b) => (a.englishName > b.englishName ? 1 : -1));
    filtredCountries.unshift(areElem, rusElem);
    return filtredCountries;
  }
  return countries.sort((a, b) => (a.englishName > b.englishName ? 1 : -1));
};

export type CrsInfoForm = ReturnType<typeof createCrsInfoForm>;

export type IoPepInfoForm = ReturnType<typeof createIoPepInfoForm>;
export type IoClientPepInfoForm = ReturnType<typeof createClientIoPepInfoForm>;

export const onChange = (func: any, value: string | number) => {
  if (typeof value === "string" && !/^[a-zA-Z0-9!@"'#$%:^,&.*;)(_+=\-\s\\/]*$/.test(value) && !!value) {
    return;
  }

  func(value);
};

export const scrollToMyRefActivityBlock = (ref: any) => {
  if (ref) {
    window.scrollTo(0, ref?.current?.offsetTop);
  }
};

export const firstCharUpper = (string: string) => {
  if (string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }
  return "";
};

export function formattedValue(target: string | undefined, onChange: any) {
  if (target) {
    const formattedValue = firstCharUpper(target);
    const value = formattedValue?.toString();
    onChange(value);
  } else {
    onChange(target);
  }
}

export const scrollToMyRef = (ref: any) => {
  if (ref?.current) {
    window.scrollTo(0, ref?.current?.offsetParent?.offsetTop);
  }
};
