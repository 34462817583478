import { FormikErrors } from "formik";

export interface ResetPasswordFormValues {
  password: string;
  confirmPassword: string;
  answer: string;
}

export enum PasswordRules {
  PASSWORD_SHORT = "password short",
  PASSWORD_LONG = "password long",
  PASSWORD_IS_VALID = "password is valid",
}

export type ResetPasswordFormErrors = FormikErrors<ResetPasswordFormValues>;
