import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";

export const useStyles = makeStyles()(() => ({
  documentList: {
    display: "grid",
    width: "100%",
    gap: "16px 30px",
    gridTemplateColumns: "repeat(2, 1fr)",
    [themeV5.breakpoints.down(768)]: {
      gridTemplateColumns: "100%",
      gap: "16px 0",
    },
  },
  checkbox: {
    marginTop: "40px",
  },
}));
