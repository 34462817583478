import { createAsyncReducer } from '~/lib/redux';

import {
  keycloakSecurityQuestionsErrorAction,
  keycloakSecurityQuestionsStartAction,
  keycloakSecurityQuestionsSuccessAction,
} from '../actions';

export const STATE_NAME = 'securityQuestions';

const [reducer, defaultState] = createAsyncReducer(
  keycloakSecurityQuestionsStartAction,
  keycloakSecurityQuestionsSuccessAction,
  keycloakSecurityQuestionsErrorAction,
);

export type KeycloakSecurityQuestionsState = typeof defaultState;

export default reducer;
