import { CommonSectionDto } from "~/entities/anketa";

import { createReducer, Reducer } from "~/lib/redux";
import { AsyncData, AsyncDataPhase, error, initial, success } from "~/lib/redux/async";
import {
  anketaSaveCommonSectionErrorAction,
  anketaSaveCommonSectionStartAction,
  anketaSaveCommonSectionSuccessAction,
  anketaUpdateCommonSectionErrorAction,
  anketaUpdateCommonSectionStartAction,
  anketaUpdateCommonSectionSuccessAction,
} from "../actions";

export const STATE_NAME = "saveOrUpdateCommonSection";

export type AnketaSaveOrUpdateCommonSectionState = AsyncData<CommonSectionDto>;

export const defaultState: AnketaSaveOrUpdateCommonSectionState = initial();

const reducer: Reducer<AnketaSaveOrUpdateCommonSectionState> = createReducer<AnketaSaveOrUpdateCommonSectionState>(
  {},
  defaultState
);

const pendingReducer = (state: AnketaSaveOrUpdateCommonSectionState): AnketaSaveOrUpdateCommonSectionState => ({
  ...state,
  phase: AsyncDataPhase.Pending,
});

const successReducer = (_: unknown, payload: CommonSectionDto): AnketaSaveOrUpdateCommonSectionState =>
  success<CommonSectionDto>(payload);

const errorReducer = (state: AnketaSaveOrUpdateCommonSectionState, err: Error): AnketaSaveOrUpdateCommonSectionState =>
  error<CommonSectionDto>(state.data || null, err);

reducer.on(anketaSaveCommonSectionStartAction, pendingReducer);
reducer.on(anketaUpdateCommonSectionStartAction, pendingReducer);

reducer.on(anketaSaveCommonSectionSuccessAction, successReducer);
reducer.on(anketaUpdateCommonSectionSuccessAction, successReducer);

reducer.on(anketaSaveCommonSectionErrorAction, errorReducer);
reducer.on(anketaUpdateCommonSectionErrorAction, errorReducer);

export default reducer;
