import { cast, Yup } from "~/lib/validation";

export interface BankSectionDtoType {
  // step 1
  addInfo: string;
  beneficiary: string;
  beneficiaryAcc: string;
  beneficiaryBank: string;
  beneficiaryLocationCity: string;
  beneficiaryLocationCountry: string;
  beneficiarySwift: string;
  correspAccount: string;
  correspBank: string;
  correspSwift: string;
}

export type BankSectionDto = Nullable<BankSectionDtoType>;
export type BankSectionSaveRequest = { step: 1; data: BankSectionDto };

export const schema = Yup.object<BankSectionDto>({
  // step 1
  addInfo: Yup.string().nullable(),
  beneficiary: Yup.string().nullable(),
  beneficiaryAcc: Yup.string().nullable(),
  beneficiaryBank: Yup.string().nullable(),
  beneficiaryLocationCity: Yup.string().nullable(),
  beneficiaryLocationCountry: Yup.string().nullable(),
  beneficiarySwift: Yup.string().nullable(),
  correspAccount: Yup.string().nullable(),
  correspBank: Yup.string().nullable(),
  correspSwift: Yup.string().nullable(),
}).label("BankSectionDto");

export const castBankSectionDto = cast<BankSectionDto>(schema);

export const bankSectionNullable: BankSectionDto = {
  // step 1
  addInfo: null,
  beneficiary: null,
  beneficiaryAcc: null,
  beneficiaryBank: null,
  beneficiaryLocationCity: null,
  beneficiaryLocationCountry: null,
  beneficiarySwift: null,
  correspAccount: null,
  correspBank: null,
  correspSwift: null,
};
