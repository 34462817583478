import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { useStore } from "effector-react";

import { headerModel } from "~/modules/header-model";
import colorTable from "~/shared/ui-kit/constants/colors";
import Icon, { IconSize } from "~/shared/ui-kit/constants/icons/Icon";
import { useStyles } from "./styles";

export interface ContactUsProps {
  to: string;
  size?: IconSize;
  colorImg?: "white" | "black";
}

export const ContactUs = ({ to, size = 20 }: ContactUsProps): ReactElement => {
  const { classes } = useStyles();
  const { $headerTheme } = headerModel;
  const headerTheme = useStore($headerTheme);

  return (
    <Link to={to} className={classes.link}>
      <Icon size={size} name="contact" color={headerTheme.isDarkColorTheme ? colorTable.white : colorTable.moonGrey} />
    </Link>
  );
};
