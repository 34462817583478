import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import UnauthorizedLayout from "~/app/layouts/UnauthorizedLayout";
import { RouteT } from "~/app/router";
import { links, routes } from "~/constants/enums";
import Loader from "~/features/Loader/Loader";
import { useScrollToTop } from "~/lib/hooks/useScrollToTop";
import UnBlockingUser from "~/pages/UnauthAndBlocked/UnBlockingUser";

const Login = lazy(() => import("~/pages/UnauthAndBlocked/Login"));
const Registration = lazy(() => import("~/pages/UnauthAndBlocked/Registration/screens/RegistationScreen"));
const ForgotPasswordIndex = lazy(() => import("~/pages/UnauthAndBlocked/ForgotPassword/screens/ForgotPasswordIndex"));
const NotAuthBlocked = lazy(() => import("~/pages/NotAuthBlocked"));
const TestingCanvas = lazy(() => import("~/pages/TestingCanvas"));

export const unauthorizedRoutes: RouteT[] = [
  { path: routes.forgotPassword, component: <ForgotPasswordIndex /> },
  { path: routes.login, component: <Login /> },
  { path: routes.registration, component: <Registration /> },
  { path: routes.unblocking, component: <UnBlockingUser /> },
  { path: routes.blocked, component: <NotAuthBlocked /> },
  { path: "stamp_testing", component: <TestingCanvas /> },
];

export const NotAuthRoutes = () => {
  useScrollToTop();

  return (
    <Routes>
      <Route element={<UnauthorizedLayout />}>
        {unauthorizedRoutes.map((route) => (
          <Route
            path={route.path}
            key={route.path}
            element={<Suspense fallback={<Loader />}>{route.component} </Suspense>}
          />
        ))}
      </Route>

      <Route path="*" element={<Navigate to={links.login} replace />} />
    </Routes>
  );
};
