import { get } from "~/lib/request";

export const getAllCountries = async (): Promise<any> => {
  const res = await get("@auth/country/countries", { params: { size: 500 } });
  return res.data;
};

export const getCountriesCitizenship = async (): Promise<any> => {
  const res = await get("@auth/dictionary/countries/citizenship", { params: { size: 500 } });
  return res.data;
};
