import Big from "big.js";
import { get, isString, map, toNumber, toString, trim } from "lodash";
import moment from "moment";
import * as Yup from "yup";
import { ValidationError } from "yup";

import { countries } from "~/constants/countries";
import { AppLogicError, AppLogicErrorVariant, AppValidationError } from "~/entities/app";

type CastFunc<T> = (value: unknown, throwError?: boolean) => T | null | undefined;
type CastArrayFunc<T> = (value: unknown, throwError?: boolean) => T[] | null | undefined;

export { AppLogicError, AppValidationError, Yup };

export const DATE_FORMAT = "DD.MM.YYYY";

export const cast = <T extends object | null | undefined = object>(schema: Yup.ObjectSchema<T>): CastFunc<T> => {
  const s = schema.required();
  return (value: unknown, throwError = false): T | null => {
    try {
      return s.validateSync(value, { strict: false, stripUnknown: true });
    } catch (error) {
      if (throwError) {
        throw new AppValidationError(error as ValidationError);
      }
    }

    return null;
  };
};

export const castNumber = (schema: Yup.NumberSchema): CastFunc<number> => {
  const s = schema.required();
  return (value: unknown, throwError = false): number | null => {
    try {
      return s.validateSync(value, { strict: false, stripUnknown: true });
    } catch (error) {
      if (throwError) {
        throw new AppValidationError(error as ValidationError);
      }
    }

    return null;
  };
};

export const castArray = <T extends object | null | undefined = object>(
  schema: Yup.ArraySchema<T>
): CastArrayFunc<T> => {
  return (value: unknown, throwError = false): T[] | null | undefined => {
    try {
      return schema.validateSync(value, { strict: false, stripUnknown: true });
    } catch (error) {
      if (throwError) {
        throw new AppValidationError(error as ValidationError);
      }
    }

    return null;
  };
};

export const castNoop = <T>(value: T): T => value;

export const result = <T>(value: unknown, castFunc: CastFunc<T>): T => {
  const result = castFunc(get(value, "data"), true);
  if (result) {
    return result;
  }

  throw new AppLogicError(AppLogicErrorVariant.Unreachable);
};

export const isEmpty = (value: string, useTrim = true): boolean => {
  return (useTrim ? trim(value) : value) === "";
};

export const isEmptyUsername = (value: string): boolean => {
  return isEmpty(value, true) || trim(value) === "d-";
};

export const isEmptyPassword = (value: string): boolean => {
  return isEmpty(value, false);
};

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  "matchLatinsNumbersAndSpecSymbols",
  function (message?: Yup.TestOptionsMessage): Yup.StringSchema {
    return this.matches(/^[a-zA-Z0-9!@"'#$%:^,&.*;)(_+=\-\s\\/]*$/, message);
  }
);

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  "matchSwift",
  function (message?: Yup.TestOptionsMessage): Yup.StringSchema {
    return this.matches(/^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/, message);
  }
);

Yup.addMethod<Yup.StringSchema>(Yup.string, "countries", function (message?: Yup.TestOptionsMessage): Yup.StringSchema {
  return this.oneOf(
    map(countries, (_, key) => toString(key)),
    message
  );
});

export function testMaxDate(value: string, maxDate: string, format: string = DATE_FORMAT): boolean {
  return moment(value, format).isSameOrBefore(moment(maxDate, format));
}

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  "maxDate",
  function (maxDate: string, message: Yup.TestOptionsMessage, format: string = DATE_FORMAT): Yup.StringSchema {
    return this.test("maxDate", message, (value: string): boolean => testMaxDate(value, maxDate, format));
  }
);

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  "minValue",
  function (minValue: number, message: Yup.TestOptionsMessage): Yup.StringSchema {
    return this.test("minValue", message, (value: string): boolean => new Big(value).gte(minValue));
  }
);

export function yupNumberNanTransform(this: Yup.MixedSchema, value: string | number): number | null {
  if (this.isType(value)) {
    return value as number;
  }

  return null === value || isNaN(value) ? null : value;
}

// need transform empty strings to 0 for all price fields
export function yupNumberNullableTransform(this: Yup.MixedSchema, value: string, originalValue: string): string {
  if (this.isType(value)) {
    return value as string;
  }

  if (isString(originalValue) && originalValue.trim() === "") return "0";

  return value;
}

export function emptyStringToNullTransform(value: string | number, originalValue: string | number): number | null {
  if (typeof originalValue === "string" && originalValue === "") {
    return null;
  }

  return toNumber(value);
}
