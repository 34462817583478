import { createAsyncReducer } from '~/lib/redux';

import {
  requestsPostFundsWithdrawalClearAction,
  requestsPostFundsWithdrawalErrorAction,
  requestsPostFundsWithdrawalStartAction,
  requestsPostFundsWithdrawalSuccessAction,
} from '../actions';

export const STATE_NAME = 'postFundsWithdrawal';

const [reducer, defaultState] = createAsyncReducer(
  requestsPostFundsWithdrawalStartAction,
  requestsPostFundsWithdrawalSuccessAction,
  requestsPostFundsWithdrawalErrorAction,
  requestsPostFundsWithdrawalClearAction,
);

export type RequestsPostFundsWithdrawalState = typeof defaultState;

export default reducer;
