import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles<{ variant: "login" | "profile" }>()((_, { variant }) => ({
  root: {
    textAlign: "initial",
    lineHeight: "20px",
    fontSize: "14px",
    fontWeigth: 400,
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    paddingLeft: 0,
    margin: 0,
    color: variant === "profile" ? colorTable.darkGrey_20 : "inherit",
    "& li": {
      listStyleType: "none",
    },
  },
  itemBox: {
    display: "flex",
    gap: "8px",
  },
  success: {
    color: variant === "profile" ? colorTable.green_20 : colorTable.green_10,
  },
  error: {
    color: variant === "profile" ? colorTable.red_10 : colorTable.red_30,
  },
  labelMobile: {
    fontSize: "12px",
    lineHeight: "14px",
  },
}));
