import { createDomain } from "effector";

import tokenService from "~/lib/token";

const logoutDomain = createDomain("logout");

const logout = logoutDomain.createEvent();
const setLogin = logoutDomain.createEvent<boolean>();

logout.watch(() => {
  setLogin(false);
  tokenService.clear();
});

export const logoutModel = { logout, setLogin };
