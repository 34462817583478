import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";

export const tableFont = {
  bold: {
    fontWeight: 700,
  },
  semiBold: {
    fontWeight: 600,
  },
  medium: {
    fontWeight: 500,
  },
  regular: {
    fontWeight: 400,
  },
  title_1: {
    fontSize: "32px",
    lineHeight: "40px",
  },
  title_1_mobile: {
    fontSize: "24px",
    lineHeight: "32px",
  },
  title_2: {
    fontSize: "24px",
    lineHeight: "32px",
  },
  title_2_mobile: {
    fontSize: "20px",
    lineHeight: "28px",
  },
  headline: {
    fontSize: "20px",
    lineHeight: "28px",
  },
  headline_mobile: {
    fontSize: "20px",
    lineHeight: "28px",
  },
  text: {
    fontSize: "16px",
    lineHeight: "24px",
  },
  text_mobile: {
    fontSize: "16px",
    lineHeight: "24px",
  },
  subhead: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  subhead_mobile: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  caption: {
    fontSize: "12px",
    lineHeight: "16px",
  },
  caption_mobile: {
    fontSize: "12px",
    lineHeight: "16px",
  },
};

export const useStyleTypography = makeStyles()(() => ({
  title_1: {
    ...tableFont.title_1,
    ...tableFont.semiBold,

    [themeV5.breakpoints.down(768)]: {
      ...tableFont.title_1_mobile,
    },
  },
  title_1_bold: {
    ...tableFont.title_1,
    ...tableFont.bold,

    [themeV5.breakpoints.down(768)]: {
      ...tableFont.title_1,
    },
  },
  title_2: {
    ...tableFont.title_2,
    ...tableFont.regular,
    [themeV5.breakpoints.down(768)]: {
      ...tableFont.title_2_mobile,
    },
  },
  title_2_bold: {
    ...tableFont.title_2,
    ...tableFont.bold,
    [themeV5.breakpoints.down(768)]: {
      ...tableFont.title_2_mobile,
    },
  },
  title_2_semiBold: {
    ...tableFont.title_2,
    ...tableFont.semiBold,
    [themeV5.breakpoints.down(768)]: {
      ...tableFont.title_2_mobile,
    },
  },
  title_2_medium: {
    ...tableFont.title_2,
    ...tableFont.medium,
    [themeV5.breakpoints.down(768)]: {
      ...tableFont.title_2_mobile,
    },
  },
  headline: {
    ...tableFont.headline,

    [themeV5.breakpoints.down(768)]: {
      ...tableFont.headline_mobile,
    },
  },
  headline_semiBold: {
    ...tableFont.headline,
    ...tableFont.semiBold,
    ...tableFont.headline,

    [themeV5.breakpoints.down(768)]: {
      ...tableFont.headline_mobile,
    },
  },
  headline_medium: {
    ...tableFont.headline,
    ...tableFont.medium,
    ...tableFont.headline,

    [themeV5.breakpoints.down(768)]: {
      ...tableFont.headline_mobile,
    },
  },
  text: {
    ...tableFont.text,

    [themeV5.breakpoints.down(768)]: {
      ...tableFont.text_mobile,
    },
  },
  text_semiBold: {
    ...tableFont.semiBold,
    ...tableFont.text,

    [themeV5.breakpoints.down(768)]: {
      ...tableFont.text_mobile,
    },
  },
  text_medium: {
    ...tableFont.medium,
    ...tableFont.text,

    [themeV5.breakpoints.down(768)]: {
      ...tableFont.text_mobile,
    },
  },
  subhead: {
    ...tableFont.subhead,

    [themeV5.breakpoints.down(768)]: {
      ...tableFont.subhead_mobile,
    },
  },
  subhead_semiBold: {
    ...tableFont.semiBold,
    ...tableFont.subhead,

    [themeV5.breakpoints.down(768)]: {
      ...tableFont.subhead_mobile,
    },
  },
  caption: {
    ...tableFont.caption,

    [themeV5.breakpoints.down(768)]: {
      ...tableFont.caption_mobile,
    },
  },
  caption_semiBold: {
    ...tableFont.semiBold,
    ...tableFont.caption,

    [themeV5.breakpoints.down(768)]: {
      ...tableFont.caption_mobile,
    },
  },
  default: {},
}));
