import { createReducer, onReset } from '~/lib/redux';
import { timestamp } from '~/lib/utils';

import { KeycloakResponseModel, KeycloakResponseModelCode } from '~/entities/keycloak';

import {
  keycloakChangePasswordSuccessAction,
  keycloakChangeSecurityQuestionSuccessAction,
  keycloakInitialCredentialsSuccessAction,
  keycloakResetPasswordSuccessAction,
  keycloakSecurityQuestionSuccessAction,
} from '../actions';

export const STATE_NAME = 'otpTimestamp';

const defaultState = 0;

const reducer = createReducer({}, defaultState);

const updateOnSuccess = (state: number, payload: KeycloakResponseModel): number => {
  if (payload.code === KeycloakResponseModelCode.Otp) {
    return timestamp();
  }

  return state;
};

reducer.on(keycloakChangePasswordSuccessAction, updateOnSuccess);
reducer.on(keycloakChangeSecurityQuestionSuccessAction, updateOnSuccess);
reducer.on(keycloakResetPasswordSuccessAction, updateOnSuccess);
reducer.on(keycloakSecurityQuestionSuccessAction, updateOnSuccess);
reducer.on(keycloakInitialCredentialsSuccessAction, updateOnSuccess);

onReset(reducer, defaultState);

export type KeycloakOtpTimestampState = number;

export default reducer;
