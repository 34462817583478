import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export default makeStyles()(() => ({
  controlButtons: {
    maxWidth: "640px",

    "& .MuiBox-root:first-child": {
      margin: 0,
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "16px 0",
    marginBottom: "32px",
  },
  formLabel: {
    marginBottom: "8px",
    color: colorTable.moonGrey,
  },
}));
