import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import cn from "classnames";

import { links } from "~/constants/enums";
import { Trans, useTranslation } from "~/i18n";
import { useRouterHooks } from "~/lib/router";
import { registrationModel } from "~/modules/keycloak/registration-model";
import { Steps } from "~/modules/keycloak/registration-model/types";
import { authStyles } from "~/pages/UnauthAndBlocked/Login/screens/LoginScreen/AuthStyles";
import Icon from "~/shared/Icon";
import { BaseButton, BaseTypography } from "~/shared/ui-kit/BaseComponents";
import { useStyles } from "./styles";

type TRegistrationErrorProps = {
  type?: Steps;
};
export const RegistrationError = ({ type }: TRegistrationErrorProps) => {
  const { classes } = authStyles();
  const { t, i18n } = useTranslation();
  const { classes: localClasses } = useStyles();
  const { setErrorAlreadyExist, setStep } = registrationModel;
  const { navigate } = useRouterHooks();

  const goToLogin = () => {
    if (type === Steps.TIME_EXPIRED) {
      return setStep(Steps.EnterUserCredentials);
    }

    navigate(links.login);
    setErrorAlreadyExist(false);
  };

  const title = useMemo(() => {
    if (type === Steps.TIME_EXPIRED) {
      return t("REGISTRATION.error.time_error_title");
    }
    return t("REGISTRATION.ALREADY-REGISTERED.title");
  }, [type, i18n.language]);

  const description = useMemo(() => {
    if (type === Steps.TIME_EXPIRED) {
      return <Box>{t("REGISTRATION.error.time_error")}</Box>;
    }

    return (
      <BaseTypography tag="p" fontType="text" className={localClasses.text}>
        <Trans
          i18nKey="REGISTRATION.ALREADY-REGISTERED.change-number"
          components={[
            <a key="0" href="mailto:clients@bcscapitaldifc.com"></a>,
            <a key="1" href="tel:+971-443-96756"></a>,
          ]}
        />
      </BaseTypography>
    );
  }, [type, i18n.language]);

  const buttonText = useMemo(() => {
    if (type === Steps.TIME_EXPIRED) {
      return t("REGISTRATION.buttons.start_again");
    }
    return t("BLOCKED.button-otp-block");
  }, [type, i18n.language]);

  return (
    <Box className={localClasses.alreadyExistsBox}>
      <Icon size={70} name="accountAlreadyRegistered" />

      <Typography className={localClasses.header}>{title}</Typography>
      <Box mb="40px">{description}</Box>
      <Box className={localClasses.buttonWrapper}>
        <BaseButton className={cn(classes.buttonDesktop, localClasses.btn)} onClick={goToLogin} typeStyle="black">
          {buttonText}
        </BaseButton>
      </Box>
    </Box>
  );
};
