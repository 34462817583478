import { AsyncDataExt, createAsyncSelector, extSelector } from './ext';
import {
  AsyncData,
  AsyncDataError,
  AsyncDataInitial,
  AsyncDataPending,
  AsyncDataPhase,
  AsyncDataSuccess,
  asyncExtPropType as asyncDataExtPropType,
  asyncPropType as asyncDataPropType,
} from './models';

export {
  AsyncData,
  AsyncDataError,
  AsyncDataSuccess,
  AsyncDataPhase,
  AsyncDataExt,
  asyncDataPropType,
  asyncDataExtPropType,
  extSelector,
  createAsyncSelector,
};

export const initial = (): AsyncDataInitial => ({
  phase: AsyncDataPhase.Initial,
  data: null,
  error: null,
});

export const pending = <T, E extends Error = Error>(data: T | null, error: E | null): AsyncDataPending<T, E> => ({
  phase: AsyncDataPhase.Pending,
  data,
  error,
});

export const success = <T>(data: T): AsyncDataSuccess<T> => ({
  phase: AsyncDataPhase.Success,
  data,
  error: null,
});

export const error = <T, E extends Error = Error>(data: T | null, error: E): AsyncDataError<T, E> => ({
  phase: AsyncDataPhase.Error,
  data,
  error,
});

export const isInitial = (data: AsyncData<unknown>): data is AsyncDataInitial => data.phase === AsyncDataPhase.Initial;

export const isPending = <T = null, E extends Error = Error>(data: AsyncData<T, E>): data is AsyncDataPending<T, E> =>
  data.phase === AsyncDataPhase.Pending;

export const isError = <T = null, E extends Error = Error>(data: AsyncData<T, E>): data is AsyncDataError<T, E> =>
  data.phase === AsyncDataPhase.Error;

export const isSuccess = <T = null>(data: AsyncData<T>): data is AsyncDataSuccess<T> =>
  data.phase === AsyncDataPhase.Success;

export const isReady = (data: AsyncData<unknown>): boolean => isSuccess(data) || isError(data);

export const isNotReady = (data: AsyncData<unknown>): boolean => isInitial(data) || isPending(data);

export const needToLoad = (data: AsyncData<unknown>, includeErrorState = false): boolean =>
  isInitial(data) || (includeErrorState && isError(data));

export const toAsyncState = (
  data: AsyncData<unknown>,
  includeInitialState = false,
): 'pending' | 'error' | undefined => {
  if (isError(data)) {
    return 'error';
  } else if (isPending(data) || (includeInitialState && isInitial(data))) {
    return 'pending';
  }
};
