import { createDomain, sample } from "effector";
import { createGate } from "effector-react";

import { saltTypes } from "~/constants/enums";
import { dmaModel } from "~/modules/dma/dma-model";
import { chatServiceApi } from "~/pages/Chat/services/api";

const chatServiceDomain = createDomain("chat service domain");
const chatGate = createGate({ domain: chatServiceDomain });

const getHashFx = chatServiceDomain.createEffect({ handler: chatServiceApi.getHash });

const $hashInfo = chatServiceDomain.createStore<any>("");
$hashInfo.reset(chatGate.close);
$hashInfo.on(getHashFx.doneData, (_, data) => data);

sample({
  source: dmaModel.getCurrentClientFx.doneData,
  clock: dmaModel.getCurrentClientFx.doneData,
  fn: (clientProfile) => {
    return {
      sourceString: clientProfile?.id || "",
      saltType: saltTypes.DEV_SALT,
    };
  },
  target: getHashFx,
});

export const chatService = {
  chatGate,
  $hashInfo,
  $getHashPending: getHashFx.pending,
};
