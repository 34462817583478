import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    flexGrow: 1,
    position: "relative",
    maxWidth: "463px",
  },
  arrow: {
    transition: "transform 0.3s ease-out",
  },
  arrowRotate: {
    transform: "rotate(180deg)",
  },
  paper: {
    position: "absolute",
    zIndex: 2,
    marginTop: "8px",
    left: 0,
    right: 0,
    [theme.breakpoints.down(1200)]: {
      "& > div": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "flex-start",
        alignItems: "flex-start",
      },
      "& .MuiMenuItem-gutters": {
        paddingLeft: "12px",
        paddingRight: "12px",
        height: "30px !important",
        minHeight: "30px !important",
        width: "100% !important",
        display: "block",
        "& div": {
          fontSize: "12px !important",
          lineHeight: "18px !important",
        },
        "& span": {
          fontSize: "12px !important",
          lineHeight: "18px !important",
        },
      },
    },
  },
  textFieldInput: {
    width: "100%",
    "& .MuiFilledInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-underline.Mui-focused:after": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-root": {
      background: colorTable.white,
      border: `1px solid ${borderColors.greyContour}`,
      borderRadius: "4px",
      height: "48px",
      "&.Mui-focused": {
        border: `1px solid ${borderColors.nobleOchre}`,
      },

      "&.Mui-error": {
        border: `1px solid ${borderColors.red}`,
      },

      "&.Mui-disabled": {
        background: colorTable.lightGrey,
      },

      "& .MuiIconButton-root": {
        padding: 0,
      },
    },
    "& .MuiFilledInput-root .MuiInputBase-input": {
      padding: "0 0 0 16px",
      color: colorTable.charcoalBlack,
      fontSize: "14px",
      lineHeight: "20px",

      "&.Mui-disabled": {
        color: colorTable.lightGrey_90,
      },
    },
    "& .MuiSelect-iconFilled": {
      right: "7px",
      width: "8px",
      height: "4px",
    },
    "& .MuiInputBase-input": {
      textOverflow: "ellipsis",
    },
  },
  mobileHelperText: {
    fontSize: "20px",
    lineHeight: "20px",
  },
  inputLabel: {
    color: colorTable.lightGrey_40,
    textAlign: "left",
    marginBottom: "8px",
    fontSize: "14px",
    lineHeight: "20px",
    whiteSpace: "normal",
  },
}));
