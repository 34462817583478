import { cast, Yup } from "~/lib/validation";

interface CrsInfoDtoType {
  crsCountryCode: string;
  crsTin: string | null;
  noTin: string | null;
  noTinDesc: string | null;
}

export type CrsInfoDto = CrsInfoDtoType;

export const schema = Yup.object<CrsInfoDto>({
  crsCountryCode: Yup.string().required(),
  crsTin: Yup.string().nullable(),
  noTin: Yup.string().nullable(),
  noTinDesc: Yup.string().nullable(),
}).label("CrsInfoDto");

export const castCrsInfoDto = cast<CrsInfoDto>(schema);
