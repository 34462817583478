import React, { ReactNode } from "react";
import { Box } from "@mui/material";

import { BaseRadio } from "~/shared/ui-kit/BaseComponents";
import { useStyles } from "./styles";

type RadioItemProps = {
  label: string;
  checked: boolean;
  onChange: () => void;
  children?: ReactNode;
};
export const RadioItem = ({ label, children, checked, onChange }: RadioItemProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.radioBox}>
        <BaseRadio onChange={onChange} checked={checked}>
          {label}
        </BaseRadio>
      </Box>
      {children && <Box className={classes.content}>{children}</Box>}
    </Box>
  );
};
