import JwtDecode from "jwt-decode";
import token from "~/lib/token";

export const getUserTokenInfo = () => {
  const parsedToken: any = JwtDecode(token.getAuthorizationBearerToken());
  // TODO: должен быть additional_login, на данный момент не реализовано УКФ на некоторых стендах, временно используется preferred_username

  return {
    id: parsedToken.evaId || parsedToken.master_system_client_id,
    login: parsedToken.additional_login || parsedToken.evaId || parsedToken.master_system_client_id,
  };
};
