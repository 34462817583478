import { createDomain, forward, sample, split } from "effector";
import { createForm } from "effector-forms";
import { createGate } from "effector-react";

import { CHANGE_PASSWORD_ENUM, ChangePasswordGetProcessResponse, ChangePasswordRequest } from "~/entities/keycloak";
import { changePasswordApi } from "~/entities/keycloak/api";
import { processResultEnum } from "~/entities/productBuy/types";
import { t } from "~/i18n";
import { autofillOtpCode } from "~/lib/autofillOtpCode";
import { maxLengthPassword, minLengthPassword, rules } from "~/lib/validation/rules";
import { logoutModel } from "~/modules/keycloak/logout-model";
import { notificationModel } from "~/modules/notification-model";

const domain = createDomain("change password domain");
const gate = createGate({ domain });

const form = createForm({
  domain,
  fields: {
    currentPassword: {
      init: "",
      rules: [
        {
          name: "oldPassword not empty",
          validator: (value: string) => value.trim() !== "",
          errorText: "VALIDATION.required-field",
        },
        {
          name: "oldPassword length",
          validator: (value: string) => value.trim().length >= 8,
        },
        {
          name: "newPassword check symbols",
          validator: (value: string) => /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).*$/.test(value),
        },
      ],
    },
    newPassword: {
      init: "",
      rules: [
        rules.required(),
        rules.password(undefined, false),
        rules.lessAndMore(maxLengthPassword, minLengthPassword),
      ],
    },
    confirmPassword: {
      init: "",
      rules: [
        {
          name: "confirmPassword not empty",
          validator: (value: string) => value.trim() !== "",
          errorText: "VALIDATION.required-field",
        },
      ],
    },
  },
  validateOn: ["submit", "change"],
});

const otpForm = createForm({
  domain,
  fields: {
    otp: {
      init: "",
      rules: [
        {
          name: "otp",
          validator: (val: string) => val.trim() !== "",
        },
      ],
    },
  },
  validateOn: ["change", "submit"],
});

const setActiveTaskId = domain.createEvent<string>();
const setLoading = domain.createEvent<boolean>();
const setShowOtpField = domain.createEvent<boolean>();
const setOtpError = domain.createEvent<string>();
const setSuccessfully = domain.createEvent<boolean>();
const setTemporaryBlocking = domain.createEvent<boolean>();
const updateOtpCode = domain.createEvent();

const setBusinessKeyProcess = domain.createEvent<string>();
setBusinessKeyProcess.watch((data) => getStateProcessFx(data));

const handlerStateProcess = domain.createEvent<ChangePasswordGetProcessResponse>();
handlerStateProcess.watch((data) => {
  if (data.processResult === CHANGE_PASSWORD_ENUM.THE_CURRENT_PASSWORD_VALUE_MISMATCH) {
    $activeTaskId.reset();
    $businessKeyProcess.reset();
    form.fields.currentPassword.addError({ rule: "currentPassword", errorText: "VALIDATION.not-correct" });
    setLoading(false);
    return;
  }

  if (data.processResult === CHANGE_PASSWORD_ENUM.PASSWORD_MATCHES_5_PREVIOUS) {
    setLoading(false);
    setShowOtpField(false);
    $activeTaskId.reset();
    $businessKeyProcess.reset();
    $otpError.reset();
    form.fields.newPassword.addError({ rule: "newPassword", errorText: "VALIDATION.same-password" });
    return;
  }

  if (data.processResult === processResultEnum.SERVER_ERROR) {
    setLoading(false);
    setShowOtpField(false);
    $activeTaskId.reset();
    $businessKeyProcess.reset();
    $otpError.reset();
    notificationModel.error(t("ERRORS.service_temp_unavailable"));
    return;
  }

  if (data.processResult === CHANGE_PASSWORD_ENUM.USER_NOT_FOUND) {
    setLoading(false);
    setShowOtpField(false);
    $activeTaskId.reset();
    $businessKeyProcess.reset();
    $otpError.reset();
    notificationModel.error(t("ERRORS.please_connect_support"));
    return;
  }

  if (data.activeTasks.length) {
    setActiveTaskId(data.activeTasks[0].id);

    if (data.resultData?.error_message === "entered and granted otp don't match") {
      setLoading(false);
      setOtpError("KEYCLOAK_ERRORS.INCORRECT_OTP");
      return;
    }

    if (data.activeTasks[0].taskDefinitionKey === "OTP_CONFIRMATION") {
      setLoading(false);
      setShowOtpField(true);
      return;
    }

    return;
  }

  if (data.processResult === "INVALID_OTP_FIRST_BLOCK") {
    setLoading(false);
    setTemporaryBlocking(true);
    return;
  }

  if (!data.resultData?.error_message && data.state === "DONE") {
    setLoading(false);
    setSuccessfully(true);
    return;
  }

  return getStateProcessFx($businessKeyProcess.getState());
});

const startProcessFx = domain.createEffect(changePasswordApi.changePassword);
startProcessFx.fail.watch((failData: any) => {
  notificationModel.errorDefaultServer(failData);
});

const getStateProcessFx = domain.createEffect(changePasswordApi.getProcess);
getStateProcessFx.fail.watch((failData: any) => {
  notificationModel.errorDefaultServer(failData);
});

getStateProcessFx.doneData.watch((data) => {
  autofillOtpCode(data?.resultData?.debugData, () => {
    setShowOtpField(true);
    otpForm.setForm({ otp: data.resultData.debugData });
  });
});

const sendTaskCompleteFx = domain.createEffect(changePasswordApi.sendOtpCode);
sendTaskCompleteFx.fail.watch((failData: any) => {
  notificationModel.errorDefaultServer(failData);
});

const $businessKeyProcess = domain.createStore("");
$businessKeyProcess.on(setBusinessKeyProcess, (_, payload) => payload);
$businessKeyProcess.reset(gate.close);

const $activeTaskId = domain.createStore("");
$activeTaskId.on(setActiveTaskId, (_, payload) => payload);
$activeTaskId.reset(gate.close);

const $isShowOtpField = domain.createStore(false);
$isShowOtpField.on(setShowOtpField, (_, payload) => payload);
$isShowOtpField.reset(gate.close);

const $isLoading = domain.createStore(false);
$isLoading.on(setLoading, (_, payload) => payload);
$isLoading.reset(gate.close);

const $otpError = domain.createStore("");
$otpError.on(setOtpError, (_, payload) => payload);
$otpError.reset([gate.close, updateOtpCode]);

const $isSuccessfully = domain.createStore(false);
$isSuccessfully.on(setSuccessfully, (_, payload) => payload);
$isSuccessfully.reset(gate.close);

const $isTemporaryBlocking = domain.createStore(false);
$isTemporaryBlocking.on(setTemporaryBlocking, (_, payload) => payload);
$isTemporaryBlocking.reset(gate.close);

sample({
  clock: form.formValidated,
  source: form.$values,
  fn: (form): ChangePasswordRequest => ({
    oldPassword: form.currentPassword,
    password: form.newPassword,
  }),
  target: startProcessFx,
});

sample({
  clock: form.formValidated,
  fn: () => true,
  target: setLoading,
});

sample({
  source: startProcessFx.doneData,
  fn: (data) => data.businessKey,
  target: setBusinessKeyProcess,
});

sample({
  source: getStateProcessFx.doneData,
  target: handlerStateProcess,
});

sample({
  clock: otpForm.formValidated,
  source: [otpForm.$values, $activeTaskId] as const,
  fn: ([form, taskId]) => ({
    taskId,
    variables: {
      enteredCode: form.otp,
    },
  }),
  target: sendTaskCompleteFx,
});

sample({
  clock: otpForm.formValidated,
  fn: () => true,
  target: setLoading,
});

sample({
  clock: updateOtpCode,
  source: $activeTaskId,
  fn: (taskId) => ({
    taskId,
    variables: {
      enteredCode: null,
    },
  }),
  target: sendTaskCompleteFx,
});

sample({
  clock: updateOtpCode,
  fn: () => true,
  target: setLoading,
});

sample({
  clock: [otpForm.formValidated, form.formValidated],
  fn: () => "",
  target: setOtpError,
});

sample({
  clock: sendTaskCompleteFx.doneData,
  source: $businessKeyProcess,
  target: getStateProcessFx,
});

split({
  //@ts-ignore
  source: startProcessFx.failData,
  match: {
    badStatus: (fail: any) => !fail?.error?.response?.status,
    notFound: (fail: any) => fail.data.code === CHANGE_PASSWORD_ENUM.USER_NOT_FOUND,
    currentNotValid: (fail: any) => fail.data.code === "CURRENT_PASS_NOT_VALID",
    notValid: (fail: any) => fail.data.code === "PASS_NOT_VALID",
    badRequest: (fail: any) => fail.data.code === "BAD_REQUEST",
    badParams: (fail: any) => fail.data.code === "BAD_PARAMS",
  },
  cases: {
    badStatus: logoutModel.setLogin.prepend(() => {
      localStorage.removeItem("tokens");
      return false;
    }),
    notFound: notificationModel.error.prepend(() => t("ERRORS.please_connect_support")),
    currentNotValid: notificationModel.error.prepend(() => t("KEYCLOAK_ERRORS.CURRENT_PASS_NOT_VALID")),
    notValid: notificationModel.error.prepend(() => t("KEYCLOAK_ERRORS.PASS_NOT_VALID")),
    badRequest: notificationModel.error.prepend(() => t("KEYCLOAK_ERRORS.BAD_REQUEST")),
    badParams: notificationModel.error.prepend(() => t("KEYCLOAK_ERRORS.BAD_PARAMS")),
    __: notificationModel.defaultError,
  },
});

split({
  source: sendTaskCompleteFx.failData,
  match: {
    incorrectOtp: (fail: any) => fail.data.code === "INCORRECT_OTP" || fail.data.code === "OTP_FAIL",
  },
  cases: {
    incorrectOtp: [
      notificationModel.error.prepend(() => t("KEYCLOAK_ERRORS.INCORRECT_OTP")),
      setActiveTaskId.prepend(() => ""),
      otpForm.reset,
    ],
    __: [notificationModel.defaultError, setActiveTaskId.prepend(() => ""), otpForm.reset],
  },
});

forward({ to: gate.close, from: form.reset });
forward({ to: gate.close, from: otpForm.reset });

export {
  $isLoading,
  $isShowOtpField,
  $isSuccessfully,
  $isTemporaryBlocking,
  $otpError,
  form,
  gate,
  otpForm,
  updateOtpCode,
};
