import { createDomain, forward } from "effector";
import { createGate } from "effector-react";

import { EnumCurrencies } from "~/constants/enums";
import { replenishmentBrokerageAccountApi } from "~/entities/replenishment-brokerage-account";
import { TRequisit } from "~/entities/replenishment-brokerage-account/types";

const domain = createDomain("replenishmentBrokerageAccount");
const gate = createGate({ domain });

const getRequisitesBoFx = domain.createEffect(replenishmentBrokerageAccountApi.getRequisitesBo);

const $requisitesBo = domain.createStore<TRequisit[]>([]);
$requisitesBo.reset(gate.close);
$requisitesBo.on(getRequisitesBoFx.doneData, (_, data) => data.filter((item) => item.currency === EnumCurrencies.USD));

forward({
  from: gate.open,
  to: getRequisitesBoFx,
});

export const requisitesModel = {
  gate,
  $requisitesBo,
  $getRequisitesBoPending: getRequisitesBoFx.pending,
};
