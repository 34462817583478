import { createAsyncReducer } from "~/lib/redux";
import { anketaGetProfileErrorAction, anketaGetProfileStartAction, anketaGetProfileSuccessAction } from "../actions";

export const STATE_NAME = "profile";

const [reducer, defaultState] = createAsyncReducer(
  anketaGetProfileStartAction,
  anketaGetProfileSuccessAction,
  anketaGetProfileErrorAction
);

export type AnketaProfileState = typeof defaultState;

export default reducer;
