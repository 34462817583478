export enum ProductTypeName {
  PHOENIX_STANDARD = "Феникс (расчетный) Dubai",
  PHOENIX_WITH_FEATURES = "Феникс расчетный с опциями Dubai",
}

export enum FrontAttrsType {
  KIT = "kit",
  KID = "kid",
  TS = "ts",
  TMD = "tmd",
  TERM_SHEET = "term_sheet",
  MAX_PROFITABILITY = "maxProfitability",
}

type FrontAttrs = {
  attributeName: string;
  businessType: string;
  descriptionEng: string;
  descriptionRus: string;
  name: FrontAttrsType;
  order: number;
  templateName: string;
  type: string;
  value: string;
  valueType: string;
};

export type UnderlyingAsset = {
  emblemFile: string;
  name: string;
  ticker: string;
  isin: string;
  id: string;
};

type RiskProfile = {
  extId: string;
  foreignName: string;
  id: string;
  riskLevel: number;
  riskProfile: string;
};

type ProductType = {
  id: string;
  jurisdiction?: { id: string; name?: string };
  name: ProductTypeName;
  nameEva: string;
  priceExecution: boolean;
  productCodeDwh: string;
  scheduleOn: boolean;
  weightCoeff: boolean;
};

export type Attrs = {
  KID: string;
  TMD: string;
  barrierReview: string;
  correctionFactor: string;
  currencySettlement: string;
  datePool: string;
  lastDate: string;
  periodCount: string;
  periodLength: string;
  productName: string;
  profitability: string;
  termSheet: string;
  thresholdFactor: string;
};

type Channel = {
  minBuyAmount: number;
  maxBuyAmount: number;
};

type Currency = {
  currencyCode: string;
  currencyNumber: string;
};

type MarketingName = {
  marketingNameProduct: string;
  descriptionProduct: string;
  riskProfile: RiskProfile;
  productType: ProductType;
};

export type Period = {
  month: number;
  year: number;
  day: number;
};

export type OfferRequest = {
  id: string;
};

type FirstCounterparty = {
  agreementNumber?: string;
};

export type Offer = {
  price: number;
  id: string;
  frontAttrs: FrontAttrs[];
  attrs: Attrs;
  period: Period;
  marketingName: MarketingName;
  channel: Channel;
  currency: Currency;
  dateEnd: string;
  dateStart: string;
  createDate: string;
  underlyingAssets: UnderlyingAsset[];
  masterAgreement: string[];
  firstCounterparty?: FirstCounterparty;
  num: number;
};

export type PoolOffer = {
  offer: Offer;
  isPool: boolean;
};

export type UnifiedOffer = {
  //Унифицированная оферта для разных типов
  id: string;
  masterAgreement: string[];
  name: string;
  kid?: string;
  ts?: string;
  tmd?: string;
  period: Period;
  minimumBuyAmount: number;
  maximumBuyAmount: number;
  profitability?: string | number;
  offeredTill: string;
  createDate: string;
  currencyCode: string;
  riskLevel: number;
  num: number;
  type: string;
  underlyingAssets: UnderlyingAsset[];
  countSent?: number;
  price: number;
  currencyNumber: string;
  dateStart: string;
  dateEnd: string;
};

export type UnifiedPullOffer = {
  offer: UnifiedOffer;
  isPool: boolean;
};
