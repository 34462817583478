import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export default makeStyles()(() => ({
  radioItem: {
    display: "flex",
    padding: "24px",
    color: colorTable.charcoalBlack,
    alignItems: "center",
    justifyContent: "space-between",
    gap: "16px",
    background: colorTable.lightGrey_10,

    [themeV5.breakpoints.down(768)]: {
      alignItems: "start",
    },
    cursor: "pointer",
    borderRadius: "4px",
  },

  radio: {
    minWidth: "24px",
    minHeight: "24px",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    background: colorTable.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${borderColors.lightGrey_50}`,

    "&.checked": {
      background: colorTable.charcoalBlack,
    },
  },
  circle: {
    minWidth: "8px",
    minHeight: "8px",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    background: colorTable.white,
  },
  input: {
    position: "absolute",
    zIndex: "-1000",
    opacity: 0,
  },
  content: {
    paddingTop: "16px",
  },
}));
