import React from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import { useStore } from "effector-react";

import { useTranslation } from "~/i18n";
import { registrationModel } from "~/modules/keycloak/registration-model";
import { Steps } from "~/modules/keycloak/registration-model/types";
import { useStyles } from "./styles";

export const RegistrationProgressBar = () => {
  const { classes } = useStyles();
  const { $currentStep, $hasErrorUserAlreadyExist } = registrationModel;
  const { t, i18n } = useTranslation();
  const currentStep = useStore($currentStep);
  const firstStep = currentStep === Steps.EnterUserCredentials;
  const secondStep =
    currentStep === Steps.EnterAdditionalCredentials ||
    currentStep === Steps.INVALID_OTP_FIRST_BLOCK ||
    currentStep === Steps.INVALID_OTP_SECOND_BLOCK;
  const otpStep = currentStep === Steps.EnterOtpCode;
  const alreadyExist = useStore($hasErrorUserAlreadyExist);
  const successRegister = currentStep === Steps.SUCCESSFUL_REGISTRATION;
  const thirdStep = currentStep === Steps.EnterSecurityQuestion;
  const anyStepActive = firstStep || alreadyExist || secondStep || otpStep || thirdStep || successRegister;
  const secondAndHightStep = secondStep || otpStep || thirdStep || successRegister || alreadyExist;
  const otpOrExistStep = otpStep || alreadyExist;
  const thridOrSuccsessStep = thirdStep || successRegister;

  return (
    <Box className={classes.progressBarWrapper}>
      <div className={classes.container}>
        <div className={classes.progressContainer}>
          <div className={classes.progress} />
          <div className={classes.progressActiveFirst} />
          {secondStep && <div className={classes.progressActiveSecond} />}
          {otpOrExistStep && <div className={classes.progressActiveSecond} />}
          {thridOrSuccsessStep && <div className={classes.progressActiveThird} />}
          <div className={cn(classes.box, anyStepActive && classes.active)}>1</div>
          <div
            className={cn(classes.box, {
              [classes.active]: secondAndHightStep,
            })}
          >
            2
          </div>
          <div className={cn(classes.box, thridOrSuccsessStep && classes.active)}>3</div>
        </div>
      </div>
      <Box className={classes.textWrapper}>
        <span className={classes.text}>{t("REGISTRATION.FIRST_STEP.progress-title").toUpperCase()}</span>
        <span
          style={i18n.language === "ru" ? { marginRight: "45px" } : { marginRight: "192px" }}
          className={classes.text}
        >
          {t("REGISTRATION.SECOND-STEP.progress-title").toUpperCase()}
        </span>
        <span className={classes.text}>{t("REGISTRATION.THIRD-STEP.progress-title").toUpperCase()}</span>
      </Box>
    </Box>
  );
};
