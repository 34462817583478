enum borderColors {
  blackDefault = "#000000",
  charcoalBlack = "#222222",
  darkBlue = "#0F222B",
  red = "#d3312f",
  red_10 = "#D72D40",
  red_20 = "#d3312e",
  white = "#FFFFFF",
  white_10 = "#F2F5FA",
  greyContour = "#E7E7E7",
  lightGrey = "#DFE7F1",
  lightGrey_10 = "#dde1e6",
  lightGrey_30 = "#E9EFF5",
  lightGrey_50 = "#CFDBEA",
  lightGrey_55 = "#E4E6EA",
  lightGrey_60 = "#F0F4F8",
  lightGrey_85 = "#F0F4F9",
  lightBlue = "#566C91",
  lightGrey_100 = "#D2D8E2",
  misteryBlue = "#5A7494",
  blue_20 = "#006AEF",
  blue_40 = "#006cef",
  blue_50 = "#1A49D7",
  nobleOchre = "#DAC481",
}

export default borderColors;
