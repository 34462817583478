import React, { FormEvent, useMemo } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Box, Grid, InputLabel, Typography, useMediaQuery } from "@mui/material";
import cn from "classnames";
import { useForm } from "effector-forms";
import { useStore } from "effector-react";
import { makeStyles } from "tss-react/mui";

import { links } from "~/constants/enums";
import { CountryPhoneNumberField } from "~/features/CountryPhoneNumberField";
import { useTranslation } from "~/i18n";
import parser from "~/lib/inputMaskParser";
import { getCountryByPhonePrefix, setCountry } from "~/lib/phoneWithCountryUtils";
import { useRouterHooks } from "~/lib/router";
import { unblockingModel } from "~/modules/keycloak/unblocking-user-model/unblocking-user-model";
import { authStyles } from "~/pages/UnauthAndBlocked/Login/screens/LoginScreen/AuthStyles";
import Icon from "~/shared/Icon";
import { MaskedInput } from "~/shared/ui/MaskedInput";
import { BaseButton, BaseTypography } from "~/shared/ui-kit/BaseComponents";
import colorTable from "~/shared/ui-kit/constants/colors";

const useStyles = makeStyles()((theme) => ({
  buttonsWrapper: {
    marginTop: "130px",
    [theme.breakpoints.down(768)]: {
      marginTop: "32px",
      marginBottom: "40px",
      flexDirection: "column",
      gap: "8px",
    },
  },
  mobileButton: {
    height: "12vw",
    fontSize: "40px",
  },
  mobileInput: {
    "& label": {
      fontSize: "30px",
    },
    "& div": {
      height: "12vw",
      fontSize: "40px",
    },
    "& div:hover": {
      background: colorTable.white,
    },
    "& input": {
      height: "100%",
    },
    "& div > button > span > svg": {
      width: "50px",
      height: "50px",
    },
    "& p": {
      fontSize: "32px",
    },
  },
  icon: {
    position: "absolute",
    top: "25%",
    right: "3%",
  },
  root: {
    width: 480,
    color: colorTable.white,
    textAlign: "center",
  },
  mobileRoot: {
    width: "100%",
  },
  label: {
    fontSize: 24,
    [theme.breakpoints.down(768)]: {
      fontSize: 20,
    },
  },
  black: {
    color: colorTable.blackDefault,
  },
  white: {
    color: colorTable.white,
  },
  mobileLabel: {
    fontSize: "50px",
    width: "100%",
  },
  mobileText: {
    fontSize: "40px",
    lineHeight: "40px",
  },
  inputLabel: {
    color: colorTable.misteryBlue,
    textAlign: "left",
    marginBottom: "8px",
    fontSize: 14,
  },
}));

const EnterPhoneNumber = () => {
  const { classes } = useStyles();
  const { classes: authClasses } = authStyles();
  const { t } = useTranslation();
  const { navigate } = useRouterHooks();

  const {
    setCountryCode,
    $countryCodes,
    $codeIso,
    form,
    $notRegisterNumber,
    unBlockUser,
    $unblockingUserFxPending,
    $checkStatusProcessAfterUnblockingUserFxPending,
    setRecaptchaValue,
  } = unblockingModel;

  const { values, fields, submit } = useForm(form);

  const countryCodes = useStore($countryCodes);
  const codeIso = useStore($codeIso);
  const notRegisterNumber = useStore($notRegisterNumber);
  const unblockingUserFxPending = useStore($unblockingUserFxPending);
  const checkStatusProcessAfterUnblockingUserFxPending = useStore($checkStatusProcessAfterUnblockingUserFxPending);

  const backEndMask = useMemo(() => {
    const countryCode = countryCodes.find((code) => code.codeIso === codeIso);
    const phoneCodeLength = countryCode?.phoneCode?.length ? countryCode?.phoneCode?.length - 1 : 0;

    return countryCode?.phoneMask ? `1[${phoneCodeLength}] ` + countryCode?.phoneMask : null;
  }, [countryCodes, codeIso]);

  const clearInput = () => {
    fields.phoneNumber.onChange("");
  };

  const handleInputPhoneChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    getCountryByPhonePrefix(e.target.value, countryCodes, codeIso, setCountryCode);

    fields.phoneNumber.onChange(e.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submit();
  };

  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const onChangeRecaptcha = (token: string | null) => {
    if (token) setRecaptchaValue(token);
  };

  const unLock = async () => {
    await recaptchaRef.current?.execute();
    unBlockUser();
    submit();
  };

  const onBack = () => navigate(links.login);

  const isTabletOrMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item className={cn(classes.root)}>
        <Box className={authClasses.header} style={isTabletOrMobile ? { marginTop: "64px" } : { marginTop: "150px" }}>
          <BaseTypography tag="h1" fontType="title_1" className={cn(classes.black, classes.label)}>
            {t("FORGOT_PASSWORD.phone-number")}
          </BaseTypography>
        </Box>
        {notRegisterNumber && (
          <Box style={{ marginTop: "16px" }} className={authClasses.authError}>
            <Icon size={24} name="authError" />
            <Typography>{t("LOGIN.ERRORS.invalid-phone-credentials")}</Typography>
          </Box>
        )}
        <Box mt={isTabletOrMobile ? 2 : 4}>
          <InputLabel className={classes.inputLabel}>{t("LOGIN.phone-number")}</InputLabel>
        </Box>
        <Box>
          <form className={authClasses.form} onSubmit={(e) => handleSubmit(e)} noValidate>
            <Box className={authClasses.field}>
              <Box>
                <Box style={{ display: "flex" }} mb="12px" className={authClasses.field}>
                  <CountryPhoneNumberField
                    countryCodes={countryCodes}
                    codeIso={codeIso}
                    setCountry={(e) =>
                      setCountry(
                        e,
                        countryCodes,
                        values.phoneNumber,
                        fields.phoneNumber.onChange,
                        setCountryCode,
                        codeIso
                      )
                    }
                  />
                  <MaskedInput
                    alwaysShowMask
                    mask={backEndMask ? `+${parser.parse(backEndMask)}` : ""}
                    value={values.phoneNumber}
                    error={fields.phoneNumber.hasError()}
                    helperText={t(fields.phoneNumber.errorText())}
                    className={authClasses.numberTextField}
                    fullWidth
                    style={{ marginLeft: "16px", width: "100%" }}
                    clearInput
                    clearCallBack={clearInput}
                    disabled={!backEndMask}
                    onChange={(e) => handleInputPhoneChange(e)}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={cn(authClasses.buttonsWrapper, classes.buttonsWrapper)}>
              <Box>
                <BaseButton type="button" typeStyle="white" onClick={onBack} className={authClasses.formBtn}>
                  {t("GENERAL.back")}
                </BaseButton>
              </Box>
              <ReCAPTCHA
                size="invisible"
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY as string}
                onChange={onChangeRecaptcha}
              />
              <Box>
                <BaseButton
                  className={cn(authClasses.buttonDesktop, authClasses.formBtn)}
                  typeStyle="black"
                  onClick={unLock}
                  disabled={unblockingUserFxPending || checkStatusProcessAfterUnblockingUserFxPending}
                  type="submit"
                  isLoader={unblockingUserFxPending || checkStatusProcessAfterUnblockingUserFxPending}
                >
                  <span>{t("GENERAL.enter")}</span>
                </BaseButton>
              </Box>
            </Box>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EnterPhoneNumber;
