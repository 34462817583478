import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { routes } from "~/constants/enums";
import Loader from "~/features/Loader";
import { NotAvailableBrowser } from "./NotAvailableBrowser";

export const InvalidBrowser = () => {
  return (
    <Routes>
      <Route
        path={routes.invalidBrowser}
        element={
          <Suspense fallback={<Loader />}>
            <NotAvailableBrowser />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to={routes.invalidBrowser} replace />} />
    </Routes>
  );
};
