import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()(() => ({
  card: {
    cursor: "pointer",
    display: "flex",
    padding: "20px 24px",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.04), 0px 2px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    color: colorTable.charcoalBlack,
    "&:hover": {
      boxShadow: "0px 0px 10px 10px rgb(0 0 0 / 20%)",
    },
    [themeV5.breakpoints.down(768)]: {
      marginTop: "0",
      width: "100%",
    },
  },
  icon: {
    color: colorTable.charcoalBlack,
  },
  wrapperIcon: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    background: colorTable.lightGrey,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
