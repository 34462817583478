import { AppLogicError, AppLogicErrorVariant } from '~/entities/app';

import { AuthLoginModel } from '../models/AuthLoginModel';

export const isTemporaryPassword = (data?: AuthLoginModel | null): boolean =>
  !data || (data.result && !!data.isTemporaryPassword);

export const isSecurityQuestion = (data?: AuthLoginModel | null): boolean =>
  !data || (data.result && !!data.securityQuestion);

export const isRequiredToSetSecurityQuestion = (data?: AuthLoginModel | null): boolean => {
  return isTemporaryPassword(data) && !isSecurityQuestion(data);
};

export const isSuccessfulAuthorized = (data?: AuthLoginModel | null): boolean => {
  return !!data && data.result && !isTemporaryPassword(data);
};

export const isOtpError = (data?: Error): boolean => {
  return AppLogicError.is(data) && data.variant === AppLogicErrorVariant.Otp;
};
