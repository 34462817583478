export enum AccountRequestStatus {
  INITIALIZING = "INITIALIZING",
  SMS_SIGNING = "SMS_SIGNING",
  ALERT = "ALERT",
  SENT = "SENT",
  ERROR = "ERROR",
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
  ACTIVATED = "ACTIVATED",
  REJECTED = "REJECTED",
  TIMEOUT_REJECTED = "TIMEOUT_REJECTED",
  AP_SMS_SIGNING = "AP_SMS_SIGNING",
  RD_READY = "RD_READY",
  RD_SMS_SIGNING = "RD_SMS_SIGNING",
  EMPTY = "EMPTY",
}

export type AccountRequest = {
  status?: AccountRequestStatus | string;
  comment?: string | null;
  code?: number;
  message?: string | null;
  id: string;
  clientId: string;
  isFirst?: boolean;
};

type File = {
  absolute: boolean;
  absolutePath: string;
  canonicalPath: string;
  directory: boolean;
  executable: boolean;
  file: boolean;
  freeSpace: number;
  hidden: boolean;
  lastModified: number;
  name: string;
  parent: string;
  path: string;
  readable: string;
  totalSpace: number;
  usableSpace: number;
  writable: boolean;
};

type Uri = {
  absolute: boolean;
  authority: string;
  fragment: string;
  host: string;
  opaque: boolean;
  path: string;
  port: number;
  query: string;
  rawAuthority: string;
  rawFragment: string;
  rawPath: string;
  rawQuery: string;
  rawSchemeSpecificPart: string;
  rawUserInfo: string;
  scheme: string;
  schemeSpecificPArt: string;
  userInfo: string;
};

type Url = {
  content: {};
  authority: string;
  defaultPort: number;
  deserializedFields: {};
  file: string;
  host: string;
  path: string;
  port: number;
  protocol: string;
  query: string;
  ref: string;
  serializedHashCode: number;
  userInfo: string;
};

export type PrintForm = {
  description: string;
  file: File;
  filename: string;
  open: boolean;
  readable: boolean;
  uri: Uri;
  url: Url;
};

export type SmsDocuments = "profile_and_acceptance" | "risk_disclosure";

export type SmsOtpSignRequest = {
  documents: SmsDocuments;
  accountRequestId: string;
};

export type SmsOtpCheckRequest = {
  documents: SmsDocuments;
  otp: string;
  accountRequestId: string;
};

export enum SmsSign {
  EXPIRED = "EXPIRED",
  VALID = "VALID",
  INCORRECT = "INCORRECT",
}

export type SmsOtpCheckResponse = {
  message: SmsSign;
};
