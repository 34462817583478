import React, { ReactElement, ReactNode } from "react";
import { useInView } from "react-hook-inview";
import { noop } from "lodash";

interface IProps<T = unknown> {
  value?: T;
  onEnter?: (value: T) => void;
  onLeave?: (value: T) => void;
  children?: ReactNode;
}

const InView = ({ value, onEnter, onLeave, children }: IProps): ReactElement => {
  const [ref] = useInView({
    onEnter: () => (onEnter || noop)(value),
    onLeave: () => (onLeave || noop)(value),
  });

  return <div ref={ref}>{children}</div>;
};

export default InView;
