import { combineReducers } from 'redux';

export const STATE_NAME = 'anketa';

import profile, { STATE_NAME as PROFILE_STATE_NAME, AnketaProfileState } from './reducers/profile';

import profileExistence, {
  STATE_NAME as PROFILE_EXISTENCE_STATE_NAME,
  AnketaProfileExistenceState,
} from './reducers/profileExistence';

import commonSection, {
  STATE_NAME as COMMON_SECTION_STATE_NAME,
  AnketaCommonSectionState,
} from './reducers/commonSection';

import saveOrUpdateCommonSection, {
  STATE_NAME as SAVE_OR_UPDATE_COMMON_SECTION_STATE_NAME,
  AnketaSaveOrUpdateCommonSectionState,
} from './reducers/saveOrUpdateCommonSection';

import economySection, {
  STATE_NAME as ECONOMY_SECTION_STATE_NAME,
  AnketaEconomySectionState,
} from './reducers/economySection';

import saveOrUpdateEconomySection, {
  STATE_NAME as SAVE_OR_UPDATE_ECONOMY_SECTION_STATE_NAME,
  AnketaSaveOrUpdateEconomySectionState,
} from './reducers/saveOrUpdateEconomySection';

import investSection, {
  STATE_NAME as INVEST_SECTION_STATE_NAME,
  AnketaInvestSectionState,
} from './reducers/investSection';

import saveOrUpdateInvestSection, {
  STATE_NAME as SAVE_OR_UPDATE_INVEST_SECTION_STATE_NAME,
  AnketaSaveOrUpdateInvestSectionState,
} from './reducers/saveOrUpdateInvestSection';

import bankSection, { STATE_NAME as BANK_SECTION_STATE_NAME, AnketaBankSectionState } from './reducers/bankSection';

import saveOrUpdateBankSection, {
  STATE_NAME as SAVE_OR_UPDATE_BANK_SECTION_STATE_NAME,
  AnketaSaveOrUpdateBankSectionState,
} from './reducers/saveOrUpdateBankSection';

import docsSection, { STATE_NAME as DOCS_SECTION_STATE_NAME, DocsState } from './reducers/docsSection';

export interface AnketaState {
  [PROFILE_EXISTENCE_STATE_NAME]: AnketaProfileExistenceState;
  [PROFILE_STATE_NAME]: AnketaProfileState;
  [COMMON_SECTION_STATE_NAME]: AnketaCommonSectionState;
  [SAVE_OR_UPDATE_COMMON_SECTION_STATE_NAME]: AnketaSaveOrUpdateCommonSectionState;
  [ECONOMY_SECTION_STATE_NAME]: AnketaEconomySectionState;
  [SAVE_OR_UPDATE_ECONOMY_SECTION_STATE_NAME]: AnketaSaveOrUpdateEconomySectionState;
  [DOCS_SECTION_STATE_NAME]: DocsState;
  [INVEST_SECTION_STATE_NAME]: AnketaInvestSectionState;
  [SAVE_OR_UPDATE_INVEST_SECTION_STATE_NAME]: AnketaSaveOrUpdateInvestSectionState;
  [BANK_SECTION_STATE_NAME]: AnketaBankSectionState;
  [SAVE_OR_UPDATE_BANK_SECTION_STATE_NAME]: AnketaSaveOrUpdateBankSectionState;
}

export default combineReducers<AnketaState>({
  [PROFILE_EXISTENCE_STATE_NAME]: profileExistence,
  [PROFILE_STATE_NAME]: profile,
  [COMMON_SECTION_STATE_NAME]: commonSection,
  [SAVE_OR_UPDATE_COMMON_SECTION_STATE_NAME]: saveOrUpdateCommonSection,
  [ECONOMY_SECTION_STATE_NAME]: economySection,
  [SAVE_OR_UPDATE_ECONOMY_SECTION_STATE_NAME]: saveOrUpdateEconomySection,
  [DOCS_SECTION_STATE_NAME]: docsSection,
  [INVEST_SECTION_STATE_NAME]: investSection,
  [SAVE_OR_UPDATE_INVEST_SECTION_STATE_NAME]: saveOrUpdateInvestSection,
  [BANK_SECTION_STATE_NAME]: bankSection,
  [SAVE_OR_UPDATE_BANK_SECTION_STATE_NAME]: saveOrUpdateBankSection,
});
