import { makeStyles } from "tss-react/mui";

import theme from "~/configs/theme";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  wrapperSelect: {
    maxWidth: "463px",
    width: "100%",
    [theme.breakpoints.down(768)]: {
      maxWidth: "100%",
    },
  },
  arrow: {
    transition: "transform 0.3s ease-out",
  },
  arrowRotate: {
    transform: "rotate(180deg)",
  },
  menu: {
    border: "none !important",
    "& > li": {
      whiteSpace: "normal",
    },
    "& li.Mui-selected": {
      backgroundColor: colorTable.white_80,
    },
    "& li.MuiListItem-button:hover": {
      backgroundColor: colorTable.white_80,
    },
  },
  selectLabel: {
    color: colorTable.lightGrey_40,
    textAlign: "left",
    marginBottom: "8px",
    fontSize: "14px",
    lineHeight: "20px",
    whiteSpace: "normal",
  },
  selectField: {
    width: "100%",
    marginBottom: "8px",
    "& .MuiFilledInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-underline.Mui-focused": {
      "&:after": {
        borderBottom: "none",
      },
      "& > div": {
        backgroundColor: colorTable.transparent,
      },
    },
    "& svg": {
      display: "none",
    },
    "& .MuiFilledInput-root": {
      background: colorTable.white,
      border: `1px solid ${borderColors.lightGrey_50}`,
      borderRadius: "4px",
      height: "48px",
      "&.Mui-focused": {
        border: `1px solid ${borderColors.misteryBlue}`,
      },

      "&.Mui-error": {
        border: `1px solid ${borderColors.red}`,
      },

      "&.Mui-disabled": {
        background: colorTable.white_10,
      },

      "& .MuiIconButton-root": {
        padding: 0,
      },
    },

    "& .MuiFilledInput-root .MuiInputBase-input": {
      padding: "0 0 0 16px",
      color: colorTable.charcoalBlack,
      fontSize: "14px",
      lineHeight: "20px",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "&.Mui-disabled": {
        color: colorTable.lightGrey_90,
      },
    },
    "& .MuiSelect-iconFilled": {
      display: "none",
    },
  },
  menuItem: {
    cursor: "pointer",
    margin: "20px",
    fontSize: "32px",
    color: "black",
    width: "80vw",
  },
  mobileField: {
    [theme.breakpoints.down(768)]: {
      "& .MuiSelect-selectMenu": {
        overflow: "unset",
        "white-space": "unset",
        "text-overflow": "unset",
        padding: "14px 0 14px 16px !important",
      },

      "& .MuiFilledInput-root": {
        height: "auto",
        minHeight: "48px",
      },
    },
  },
});
