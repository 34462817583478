import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { links, LocalStorageKeysEnum } from "~/constants/enums";

/**
 * Methods from react router hooks
 * @returns object with methods
 */
export const useRouterHooks = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const params = useParams();

  return {
    navigate,
    location,
    params,
  };
};
export const urlBack = -1;

export const useClearLocalStorage = () => {
  const { location } = useRouterHooks();

  useEffect(() => {
    const path = location?.pathname?.split("/")?.[1];
    if (path !== links.openAccount.substring(1)) {
      localStorage.setItem(LocalStorageKeysEnum.onboardingBusinessKey, "");
    }
    if (path !== links.registration.substring(1)) {
      localStorage.removeItem(LocalStorageKeysEnum.registrationBusinessKey);
    }
  }, [location.pathname]);
};
