import i18n from "~/i18n";
import { get } from "~/lib/request";
import { buildQueryParams } from "~/lib/request/utils";
import { applicationsTypeEnum } from "~/modules/operationIndex/const";
import { getUserTokenInfo } from "~/modules/userToken/api";
import { OrderStatusesResponse } from "../order-status";

export const applicationsApi = {
  applicationsRequest: async (): Promise<OrderStatusesResponse> => {
    const clientId = getUserTokenInfo().id;
    const applicationStatuses = [
      applicationsTypeEnum.NEW,
      applicationsTypeEnum.CREATED,
      applicationsTypeEnum.ACTIVE,
      applicationsTypeEnum.INTIALIZING,
      applicationsTypeEnum.SENT,
      applicationsTypeEnum.PENDING,
      applicationsTypeEnum.PROCESSING,
    ];
    const params = {
      clientId,
      status: applicationStatuses,
      page: 0,
      size: 3,
      sort: "lastModifiedDate%2Cdesc",
    };
    const queryString = buildQueryParams(params);
    const res = await get(`@auth/service-activation/view/history?${queryString}`, undefined, {
      headers: {
        "Accept-Language": i18n.language,
      },
    });

    return res.data;
  },
};
