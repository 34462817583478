import { createDomain, forward, sample } from "effector";
import { createGate } from "effector-react";
import { pending } from "patronum";

import { productCategory, usStocksStatus } from "~/constants/enums";
import { PrintForms } from "~/entities/common";
import { $base64PdfStore, dmaApi } from "~/entities/dma";
import { Remittance } from "~/entities/dma/types";
import { t } from "~/i18n";
import { notificationModel } from "~/modules/notification-model";
import { productListModel } from "~/modules/productsService";
import { checkMaxRequestCount } from "./helpers";

const dmaDomain = createDomain("dma domain");
const gate = createGate({ domain: dmaDomain });

const closePrintFormsModal = dmaDomain.createEvent();
const getUsStatus = dmaDomain.createEvent();
const sendRemittance = dmaDomain.createEvent<boolean>();
const setRemittance = dmaDomain.createEvent<Remittance | null>();
const setShowContent = dmaDomain.createEvent<boolean>();
const setIsOpenSuccessModal = dmaDomain.createEvent<boolean>();
const setAccepted = dmaDomain.createEvent<boolean>();

const getUsStocksStatusFx = dmaDomain.createEffect({ handler: dmaApi.searchUsProductApplication });
const getUsStocksPdfFx = dmaDomain.createEffect({ handler: dmaApi.openingUsProductStatus });
const sendUsStocksApplication = dmaDomain.createEvent();
const sendUsStocksApplicationFx = dmaDomain.createEffect({ handler: dmaApi.openingUsProduct });
const $isOpenDocsPage = dmaDomain.createStore<boolean>(false);
const getUsStocksId = dmaDomain.createEvent();
const getUsStocksIdFx = dmaDomain.createEffect({ handler: dmaApi.searchUsProductId });
const taskRecieved = dmaDomain.createEvent();
const openingUsPdfModalFx = dmaDomain.createEffect({ handler: dmaApi.openingUsStockPdfModal });
const closeTaskFx = dmaDomain.createEffect({ handler: dmaApi.clearStatus });
const getCurrentClient = dmaDomain.createEvent();
const getCurrentClientFx = dmaDomain.createEffect({ handler: dmaApi.getCurrentClient });
const sendRemittanceFx = dmaDomain.createEffect({ handler: dmaApi.remittance });

const $UsStocksStatus = dmaDomain.createStore<usStocksStatus>(usStocksStatus.NO_APPLICATION);
const $UsStocksExternalId = dmaDomain.createStore<string>("");
const $usStocksBusinessKey = dmaDomain.createStore("");
$usStocksBusinessKey.reset(closePrintFormsModal);
const $taskId = dmaDomain.createStore<string>("");
const $usPrintForm = dmaDomain.createStore<any>([]);
$usPrintForm.reset([closePrintFormsModal, gate.close]);
$usPrintForm.on(openingUsPdfModalFx.doneData, (_, printForm) => printForm);
$base64PdfStore.reset([closePrintFormsModal, gate.close]);
const $currentClientStatus = dmaDomain.createStore<string>("");

const $remittanceData = dmaDomain.createStore<Remittance | null>(null);
$remittanceData.on(setRemittance, (_, remittanceData) => remittanceData);

const $isRefill = dmaDomain.createStore<boolean>(false);
$isRefill.on(sendRemittance, (_, value) => value);

const $isAccepted = dmaDomain.createStore<boolean>(false);
$isAccepted.on(setAccepted, (_, isAccepted) => isAccepted);
$isAccepted.reset(gate.close);

const $showContent = dmaDomain.createStore<boolean>(false);
$showContent.on(setShowContent, (_, showContent) => showContent);
$showContent.reset(gate.close, productListModel.closeProduct);

const $isOpenSuccessModal = dmaDomain.createStore<boolean>(false);
$isOpenSuccessModal.on(setIsOpenSuccessModal, (_, value) => value);

// получение статуа клиента по БО и ДУ

forward({ from: getCurrentClient, to: getCurrentClientFx });
sample({
  source: getCurrentClientFx.doneData,
  fn: (data) => data.clientStatus,
  target: $currentClientStatus,
});

// получение ID таски
sample({
  source: $taskId,
  clock: closePrintFormsModal,
  fn: (taskId) => {
    return {
      taskId,
      accepted: $isAccepted.getState(),
    };
  },
  target: closeTaskFx,
});

const $requestCounter = dmaDomain.createStore<number>(0);
$requestCounter.watch((data) => {
  if (!checkMaxRequestCount(data)) {
    notificationModel.error(t("ERRORS.default", { tel: "+971-4-439-6756" }));
  }
});
const incrementRequest = dmaDomain.createEvent<number | string>();
$requestCounter.on(incrementRequest, (state, _) => state + 1);
$requestCounter.reset([closePrintFormsModal, gate.close, openingUsPdfModalFx, taskRecieved]);
$isOpenDocsPage.on(sendUsStocksApplication, () => true);
$isOpenDocsPage.reset([closePrintFormsModal]);

// открытие формы

const $printForms = dmaDomain.createStore<PrintForms | null>(null);
$printForms.reset([closePrintFormsModal, gate.close]);

// получение статуса клиента

forward({ from: getUsStatus, to: [getUsStocksStatusFx, getUsStocksIdFx] });
forward({ from: getUsStocksId, to: getUsStocksIdFx });

sample({
  source: getUsStocksIdFx.doneData,
  fn: (data) => data.content.find((el: any) => el.productCategory === productCategory.STOCK)?.externalProductId,
  target: $UsStocksExternalId,
});

sample({
  source: getUsStocksStatusFx.doneData,
  fn: (data: any) => {
    return data.content.length
      ? data.content[0]?.status ?? usStocksStatus.NO_APPLICATION
      : usStocksStatus.NO_APPLICATION;
  },
  target: $UsStocksStatus,
});

// отправка заявки на подключение ДМА

sample({
  source: $UsStocksExternalId,
  clock: sendUsStocksApplication,
  target: sendUsStocksApplicationFx,
});

sample({
  source: sendUsStocksApplicationFx.doneData,
  fn: (data) => data.businessKey,
  target: [$usStocksBusinessKey, getUsStocksPdfFx],
});

sample({
  // Если приходит пустая таска, то отправляем запрос ещё раз
  source: $usStocksBusinessKey,
  clock: getUsStocksPdfFx.doneData,
  filter: (_, data: any) => data.activeTasks.length === 0 && checkMaxRequestCount($requestCounter.getState()),
  target: [getUsStocksPdfFx, incrementRequest],
});

sample({
  source: $usStocksBusinessKey,
  clock: getUsStocksPdfFx.doneData,
  filter: (_, data: any) => data.activeTasks.length !== 0,
  fn: (usStocksBusinessKey, pdfTaskData) => ({
    businessKey: usStocksBusinessKey,
    variableNames: pdfTaskData.inputData.printForms,
  }),
  target: openingUsPdfModalFx,
});

sample({
  source: getUsStocksPdfFx.doneData,
  filter: (data: any) => data.activeTasks.length !== 0,
  fn: (data) => data.activeTasks[0].id,
  target: [$taskId, taskRecieved],
});

sample({
  source: $remittanceData,
  clock: sendRemittance,
  target: sendRemittanceFx,
});

sample({
  source: sendRemittanceFx.doneData,
  fn: (data) => data.businessKey,
  target: [$usStocksBusinessKey, getUsStocksPdfFx],
});

forward({
  from: $base64PdfStore,
  to: $usPrintForm,
});

forward({ from: openingUsPdfModalFx.doneData, to: $usPrintForm });

export const dmaModel = {
  sendUsStocksApplication,
  getUsStatus,
  getCurrentClient,
  getCurrentClientFx,
  $getCurrentClientPending: getCurrentClientFx.pending,
  $UsStocksStatus,
  $currentClientStatus,
  $usStocksBusinessKey,
  $usPrintForm,
  $taskId,
  $printForms,
  closePrintFormsModal,
  $dmaPending: pending({
    effects: [getUsStocksStatusFx, getUsStocksPdfFx, sendUsStocksApplicationFx, sendRemittanceFx],
  }),
  setRemittance,
  sendRemittance,
  $UsStocksExternalId,
  gate,
  $isRefill,
  $showContent,
  setShowContent,
  $isOpenSuccessModal,
  setIsOpenSuccessModal,
  setAccepted,
  $isOpenDocsPage,
  getUsStocksId,
};
