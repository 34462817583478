export enum ComponentsTypeEnum {
  PARAMETERS = "PARAMETERS",
  LISTS = "LISTS",
  BUTTON = "BUTTON",
}
export enum CBSRequestStatusEnum {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
}
export enum ServiceStatusEnum {
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
}
