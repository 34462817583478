import React from "react";
import { Box, Tooltip, TooltipProps } from "@mui/material";
import cn from "classnames";

import { BaseButton } from "~/shared/ui-kit/BaseComponents";
import { QuestionMarkIcon } from "~/shared/ui-kit/constants/icons";
import { useStyles } from "./styles";

export interface ITooltipProps {
  tooltipText: React.ReactNode;
  classNameWrapIcon?: string;
  children?: React.ReactElement;
}

export interface FormBlockProps extends Omit<TooltipProps, "children" | "title">, ITooltipProps {}

export default (props: FormBlockProps) => {
  const { classes } = useStyles();
  const { tooltipText, classNameWrapIcon, onOpen, children, ...otherProps } = props;

  return (
    <Tooltip
      {...otherProps}
      title={tooltipText}
      classes={{
        tooltip: classes.tooltip,
      }}
    >
      <BaseButton onClick={onOpen} className={classes.button}>
        <Box className={cn(classes.wrapIconTooltip, classNameWrapIcon)}>
          {children ? children : <QuestionMarkIcon />}
        </Box>
      </BaseButton>
    </Tooltip>
  );
};
