import React, { ReactNode } from "react";
import { toast } from "react-toastify";
import { concat, filter } from "lodash";

import { AppMessageModel, AppMessageOptionsKey } from "~/entities/app";
import { createReducer } from "~/lib/redux";
import colorTable from "~/shared/ui-kit/constants/colors";
import Icon from "~/shared/ui-kit/constants/icons/Icon";
import { appMessageAction, appMessageCloseAction } from "../actions";

export const STATE_NAME = "messages";
interface AppMessageDataState extends AppMessageModel {
  id: AppMessageOptionsKey;
}

export type AppMessageState = AppMessageDataState[];

const defaultState: AppMessageState = [];

let id = 0;
const reducer = createReducer({}, defaultState);

interface ICustomToastProps {
  message: string | ReactNode;
  icon?: ReactNode;
}

const CustomToast = ({ message, icon }: ICustomToastProps) => (
  <div style={{ display: "flex", gap: "8px" }}>
    {icon}
    {message}
  </div>
);

reducer.on(appMessageAction, (state, payload: AppMessageModel): AppMessageState => {
  switch (payload.options?.variant) {
    case "info":
      toast(<CustomToast message={payload.message} icon={<Icon name="infoNoticeIcon" color={colorTable.white} />} />, {
        icon: false,
      });
      break;
    case "error":
      toast.error(
        <CustomToast message={payload.message} icon={<Icon name="noticeAlert" color={colorTable.white} />} />,
        { icon: false }
      );
      break;
    case "success":
      toast.success(
        <CustomToast message={payload.message} icon={<Icon name="doneIcon" color={colorTable.white} />} />,
        { icon: false }
      );
      break;
    case "warning":
      toast.warning(
        <CustomToast message={payload.message} icon={<Icon name="noticeAlert" color={colorTable.white} />} />,
        { icon: false }
      );
      break;
    default:
      toast(<CustomToast message={payload.message} icon={<Icon name="infoNoticeIcon" color={colorTable.white} />} />, {
        icon: false,
      });
  }
  return concat(state, { id: id, ...payload });
});
reducer.on(appMessageCloseAction, (state, payload: AppMessageOptionsKey): AppMessageState => {
  return filter(state, (item): boolean => item.id !== payload);
});

export default reducer;
