import React, { useMemo } from "react";
import { Box, Button, Link } from "@mui/material";
import cn from "classnames";
import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import { links } from "~/constants/enums";
import ContentWrapperViewport from "~/features/ContentWrapperViewport";
import { useTranslation } from "~/i18n";
import { useRouterHooks } from "~/lib/router";
import { loginModel } from "~/modules/keycloak/login-model";
import { LoginStepsTypes } from "~/modules/keycloak/login-model/types";
import Icon from "~/shared/Icon";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    [theme.breakpoints.down(768)]: {
      marginTop: "64px",
      marginBottom: "64px",
    },
  },
  buttonWrapper: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  title: {
    textAlign: "center",
    display: "inline-block",
    fontSize: "24px",
    color: colorTable.blackDefault,
    lineHeight: "28px",
    fontWeight: 600,
    maxWidth: "480px",
  },
  titleMobile: {
    fontSize: "48px",
    color: colorTable.blackDefault,
    lineHeight: "48px",
    fontWeight: 600,
  },
  button: {
    width: "150px",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100‰",
  },
  link: {
    color: colorTable.blue_20,
    textDecoration: "none",
    fontSize: "16px",
    marginLeft: "4px",
  },
  linkMobile: {
    color: colorTable.blue_20,
    textDecoration: "none",
    fontSize: "32px",
    marginLeft: "8px",
  },
  icon: {
    marginBottom: "16px",
    display: "flex",
    justifyContent: "center",
  },
  buttonMobile: {
    height: "12vw",
    width: "100%",
    borderRadius: "4px",
    background: colorTable.darkBlue,
    "& > span": {
      fontSize: "28px",
      color: colorTable.white,
    },
    "&.MuiButton-contained": {
      boxShadow: "none",
    },
    "&:hover": {
      background: colorTable.blue_10,
    },
  },
  buttonDesktop: {
    height: "48px",
    width: "280px",
    border: `1px solid ${borderColors.lightGrey_50}`,
    background: colorTable.darkBlue,
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
    "&:hover": {
      background: colorTable.blue_10,
    },
    "&.MuiButton-contained": {
      boxShadow: "none",
    },
    "& > span": {
      color: colorTable.white,
      fontSize: "14px",
    },
  },
  text: {
    marginBottom: "40px",
    textAlign: "center",
    fontSize: "16px",
    maxWidth: "480px",
    color: colorTable.lightGrey_90,
  },
  textMobile: {
    marginBottom: "80px",
    fontSize: "32px",
    color: colorTable.lightGrey_90,
  },
  flex: {
    display: "flex",
    justifyContent: "center",
  },
}));

type BlockedPropsTypes = {
  header?: string;
  subtitle?: string;
  subtitleSecond?: string;
  customButtonText?: string;
};

const Blocked = ({ header, subtitle, subtitleSecond, customButtonText }: BlockedPropsTypes) => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { navigate, location } = useRouterHooks();

  const { setStep } = loginModel;
  const chooseButtonText = (): string => customButtonText || t("BLOCKED.button");

  const title = useMemo(() => {
    if (header) return header;
    if (location.state?.header) return t(location.state.header);
    return t("ERRORS.unknown");
  }, [i18n.language, header]);

  const subtitleText = useMemo(() => {
    if (subtitle) return subtitle;
    if (location.state?.subtitle) return t(location.state.subtitle);
    return "";
  }, [i18n.language, subtitle]);

  const subtitleSecondText = useMemo(() => {
    if (subtitleSecond) return subtitleSecond;
    if (location.state?.subtitleSecond) return t(location.state.subtitleSecond);
    return "";
  }, [i18n.language, subtitleSecond]);

  const backToLogin = () => {
    setStep(LoginStepsTypes.LOGIN);
    navigate(links.login);
  };

  return (
    <ContentWrapperViewport className={classes.flex}>
      <Box className={classes.wrapper}>
        <Box className={classes.icon}>
          <Icon name="accountAlreadyRegistered" size={70} />
        </Box>
        <Box mb="16px" className={cn(classes.title, classes.flex)}>
          {title}
        </Box>
        <Box className={cn(classes.text)}>
          {subtitleText}
          <Link className={classes.link}>{subtitleSecondText}</Link>
        </Box>
        <Box className={cn(classes.buttonWrapper, classes.flex)}>
          <Button className={classes.buttonDesktop} onClick={backToLogin} variant="contained" color="primary">
            {chooseButtonText()}
          </Button>
        </Box>
      </Box>
    </ContentWrapperViewport>
  );
};

export default Blocked;
