type TMonths = {
  [key: number]: {
    eng: string;
    rus: string;
  };
};

export const months: TMonths = {
  1: { eng: "January", rus: "Января" },
  2: { eng: "February", rus: "Февраля" },
  3: { eng: "March", rus: "Марта" },
  4: { eng: "April", rus: "Апреля" },
  5: { eng: "May", rus: "Мая" },
  6: { eng: "June", rus: "Июня" },
  7: { eng: "July", rus: "Июля" },
  8: { eng: "August", rus: "Августа" },
  9: { eng: "September", rus: "Сентября" },
  10: { eng: "October", rus: "Октября" },
  11: { eng: "November", rus: "Ноября" },
  12: { eng: "December", rus: "Декабря" },
};

export const shortMonths = {
  1: { eng: "Jan.", rus: "Янв." },
  2: { eng: "Feb.", rus: "Февр." },
  3: { eng: "Mar.", rus: "Март" },
  4: { eng: "Apr.", rus: "Апр." },
  5: { eng: "May", rus: "Мая" },
  6: { eng: "Jun.", rus: "Июня" },
  7: { eng: "Jul.", rus: "Июля" },
  8: { eng: "Aug.", rus: "Авг." },
  9: { eng: "Sep.", rus: "Сент." },
  10: { eng: "Oct.", rus: "Окт." },
  11: { eng: "Nov.", rus: "Нояб." },
  12: { eng: "Dec.", rus: "Дек." },
};
