import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export default makeStyles()(() => ({
  mainWrap: {
    padding: "24px",
    borderRadius: "8px",
    background: colorTable.lightBlue,

    [themeV5.breakpoints.down(768)]: {
      padding: "24px 16px",
    },
  },
  icon: {
    minWidth: "22px",
    maxWidth: "22px",
    maxHeight: "22px",
    minHeight: "22px",
    color: colorTable.blue_30,
  },
  warningText: {
    color: colorTable.charcoalBlack,
  },
  wrapTextWarning: {
    display: "flex",
    gap: "0 16px",
    marginBottom: "24px",

    [themeV5.breakpoints.down(768)]: {
      flexDirection: "column",
      gap: "16px 0",
      marginBottom: "16px",
    },
  },
  boxImg: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  img: {
    minWidth: "156px",
    maxWidth: "156px",
    maxHeight: "163px",
    minHeight: "163px",
    [themeV5.breakpoints.down(768)]: {
      minWidth: "113px",
      maxWidth: "113px",
      maxHeight: "118px",
      minHeight: "118px",
    },
  },
}));
