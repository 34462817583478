import React, { ReactNode, useRef, useState } from "react";
import { Box } from "@mui/material";
import cn from "classnames";

import { useTranslation } from "~/i18n";
import { BaseButton, BaseTypography } from "~/shared/ui-kit/BaseComponents";
import Icon from "~/shared/ui-kit/constants/icons/Icon";
import { IconProps } from "~/shared/ui-kit/constants/icons/Icon/Icon";
import { useStyleTypography } from "~/shared/ui-kit/constants/typography";
import useStyles from "./styles";

interface ItemRadioProps {
  label?: string;
  iconName?: IconProps["name"];
  title?: string;
  isChecked: boolean;
  children?: ReactNode | ReactNode[];
  className?: string;
  classNameTitleText?: string;
  classNameLabelText?: string;
  onChange: () => void;
  onClickHide?: () => void;
  onClickMore?: () => void;
  isNoMoreButton?: boolean;
}

export default (props: ItemRadioProps) => {
  const { classes } = useStyles();
  const { classes: styleTypography } = useStyleTypography();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const [isMore, setMore] = useState(false);

  const onClickInput = () => {
    if (!inputRef.current) return;

    inputRef.current.click();
  };

  const onClickBtn = () => {
    if (isMore) {
      props.onClickHide && props.onClickHide();
    } else {
      props.onClickMore && props.onClickMore();
    }

    setMore((state) => !state);
  };

  return (
    <Box className={cn(classes.radioItem, props.className)} onClick={() => onClickInput()}>
      {props.iconName && (
        <Box className={classes.wrapIcon}>
          <Icon name={props.iconName} size={32} />
        </Box>
      )}
      <Box className={classes.wrapContent}>
        <Box className={classes.title}>
          <Box>
            {props.title && (
              <p
                className={cn(classes.titleText, props.classNameTitleText)}
                dangerouslySetInnerHTML={{ __html: props.title }}
              />
            )}
            {props.label && (
              <BaseTypography tag="p" fontType="subhead" className={cn(classes.labelText, props.classNameLabelText)}>
                {props.label}
              </BaseTypography>
            )}
          </Box>
          <Box className={cn(classes.radio, props.isChecked && "checked")}>
            <Box className={classes.circle} />
            <input
              ref={inputRef}
              className={classes.input}
              checked={props.isChecked}
              onClick={() => props.onChange()}
            />
          </Box>
        </Box>
        {props.children && (
          <Box className={classes.content} onClick={(e) => e.stopPropagation()}>
            <>
              {isMore && props.children}
              <BaseButton
                className={cn(classes.btnMore, styleTypography.subhead_semiBold)}
                onClick={() => onClickBtn()}
                typeStyle="default"
              >
                {isMore ? t("PROFILE.hide") : t("OPEN_ACCOUNT.more")}
              </BaseButton>
            </>
          </Box>
        )}
      </Box>
    </Box>
  );
};
