import React, { ReactElement } from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import cn from "classnames";

import Icon from "~/shared/Icon";
import { IconNameType } from "~/shared/Icon/Icon";
import { useStyles } from "./styles";

export interface BaseCheckboxProps {
  name: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  onChange?: any | (() => any);
  style?: object;
  className?: string;
  color?: string;
}

export const BaseCheckbox = ({
  name = "",
  label = "",
  checked = false,
  disabled = false,
  onChange = () => {},
  style = {},
  className = "",
  color,
}: BaseCheckboxProps): ReactElement => {
  const { classes } = useStyles();
  const checkboxName = (color ? `checkbox${color}` : "checkbox") as IconNameType;

  return (
    <FormControlLabel
      className={cn(classes.checkboxWrapper, className)}
      style={style}
      control={
        <Checkbox
          name={name}
          icon={!disabled ? <Icon size={24} name={checkboxName} /> : <Icon size={24} name="checkboxDisable" />}
          checkedIcon={
            !disabled ? <Icon size={24} name="checkedCheckbox" /> : <Icon size={24} name="checkedCheckboxDisable" />
          }
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      }
      label={<Box className={cn(classes.checkboxLabel, color === "Red" ? classes.redLabel : undefined)}>{label}</Box>}
      onFocus={() => {}}
    />
  );
};
