import { createDomain, forward } from "effector";
import { RemoteConfig } from "firebase/remote-config";

import { getFirebaseOtpSecret } from "~/entities/firebase";
import { t } from "~/i18n";
import colorTable from "~/shared/ui-kit/constants/colors";
import { notificationModalModel } from "../notification-modal-model";
import { notificationModel } from "../notification-model";

const firebaseDomain = createDomain("firebase");

const getOtpSecret = firebaseDomain.createEvent<RemoteConfig>();
const otpSecretIsIncorrect = firebaseDomain.createEvent();
const otpSecretDoesNotMatch = firebaseDomain.createEvent();

const getFirebaseOtpSecretFx = firebaseDomain.createEffect(getFirebaseOtpSecret);

const $otpSecret = firebaseDomain.createStore<string | null>(null);
$otpSecret.on(getFirebaseOtpSecretFx.doneData, (_, data) => data);

forward({ from: getOtpSecret, to: getFirebaseOtpSecretFx });
forward({
  from: otpSecretIsIncorrect,
  to: [
    notificationModalModel.setNotificationParams.prepend(() => ({
      icon: "attentionIcon",
      iconBackground: { backgroundColor: colorTable.redLight },
      title: t("OTP_SECRET.otpSecret_is_incorrect.title"),
      descriptions: [t("OTP_SECRET.otpSecret_is_incorrect.description")],
      buttons: [
        {
          typeStyle: "black",
          buttonHandler: () => {
            window.location.reload();
          },
          buttonText: t("OPEN_ACCOUNT.ok"),
        },
      ],
      onCloseModal: () => window.location.reload(),
    })),
    notificationModalModel.setShowNotificationModal.prepend(() => true),
  ],
});
forward({
  from: otpSecretDoesNotMatch,
  to: [
    notificationModalModel.setNotificationParams.prepend(() => ({
      icon: "attentionIcon",
      iconBackground: { backgroundColor: colorTable.redLight },
      title: t("OTP_SECRET.otp_secret_does_not_match.title"),
      descriptions: [t("OTP_SECRET.otp_secret_does_not_match.description")],
      buttons: [
        {
          typeStyle: "black",
          buttonHandler: () => {
            window.location.reload();
          },
          buttonText: t("OPEN_ACCOUNT.ok"),
        },
      ],
      onCloseModal: () => window.location.reload(),
    })),
    notificationModalModel.setShowNotificationModal.prepend(() => true),
  ],
});

getFirebaseOtpSecretFx.fail.watch(() => {
  notificationModel.error("ERRORS.unknown");
});

export const firebaseModel = {
  $otpSecret,
  getOtpSecret,
  otpSecretIsIncorrect,
  otpSecretDoesNotMatch,
};
