import { createReducer, Reducer } from '~/lib/redux';
import { AsyncData, AsyncDataPhase, initial, error, success } from '~/lib/redux/async';

import { EconomySectionDto } from '~/entities/anketa';

import {
  anketaSaveEconomySectionStartAction,
  anketaSaveEconomySectionSuccessAction,
  anketaSaveEconomySectionErrorAction,
  anketaUpdateEconomySectionStartAction,
  anketaUpdateEconomySectionSuccessAction,
  anketaUpdateEconomySectionErrorAction,
} from '../actions';

export const STATE_NAME = 'saveOrUpdateEconomySection';

export type AnketaSaveOrUpdateEconomySectionState = AsyncData<EconomySectionDto>;

export const defaultState: AnketaSaveOrUpdateEconomySectionState = initial();

const reducer: Reducer<AnketaSaveOrUpdateEconomySectionState> = createReducer<AnketaSaveOrUpdateEconomySectionState>(
  {},
  defaultState,
);

const pendingReducer = (state: AnketaSaveOrUpdateEconomySectionState): AnketaSaveOrUpdateEconomySectionState => ({
  ...state,
  phase: AsyncDataPhase.Pending,
});

const successReducer = (_: unknown, payload: EconomySectionDto): AnketaSaveOrUpdateEconomySectionState =>
  success<EconomySectionDto>(payload);

const errorReducer = (
  state: AnketaSaveOrUpdateEconomySectionState,
  err: Error,
): AnketaSaveOrUpdateEconomySectionState => error<EconomySectionDto>(state.data || null, err);

reducer.on(anketaSaveEconomySectionStartAction, pendingReducer);
reducer.on(anketaUpdateEconomySectionStartAction, pendingReducer);

reducer.on(anketaSaveEconomySectionSuccessAction, successReducer);
reducer.on(anketaUpdateEconomySectionSuccessAction, successReducer);

reducer.on(anketaSaveEconomySectionErrorAction, errorReducer);
reducer.on(anketaUpdateEconomySectionErrorAction, errorReducer);

export default reducer;
