import { Store } from "effector";
import { Rule } from "effector-forms";
import moment, { Moment } from "moment";

import {
  edmCityPattern,
  edmTextPattern,
  housePattern,
  housingPattern,
  postIndexPattern,
  statePattern,
  streetPattern,
  zipCodePattern,
} from "~/constants/regexp";

export const rules = {
  required: (booleanSource?: Store<boolean>, customError = "VALIDATION.required-field"): Rule<string> => ({
    name: "required",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate ? Boolean(val?.trim()) : true;
      else return Boolean(val?.trim());
    },
    errorText: customError,
  }),

  requiredDate: (): Rule<null | string | Moment> => ({
    name: "requiredDate",
    validator: (val) => Boolean(val),
    errorText: "VALIDATION.required-field",
  }),

  requiredDateEDM: (): Rule<null | any> => ({
    name: "requiredDate",
    validator: (val) => Boolean(val.value),
    errorText: "VALIDATION.required-field",
  }),

  less: (max: number, booleanSource?: Store<boolean>): Rule<string> => ({
    name: "less",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate ? val?.trim().length <= max : true;
      else return val?.trim().length <= max;
    },
    errorText: `OPEN_ACCOUNT.errors.less_${max}`,
  }),

  lessAndMore: (max: number, min: number, booleanSource?: Store<boolean>): Rule<string> => ({
    name: "less and more",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null)
        return needToValidate ? val?.trim().length <= max && val?.trim().length >= min : true;
      else return val?.trim().length <= max && val?.trim().length >= min;
    },
    errorText: `OPEN_ACCOUNT.errors.more_${min}_less_${max}`,
  }),

  trueName: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "true name",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate && val ? edmTextPattern.test(val) : true;
      return val ? edmTextPattern.test(val) : true;
    },
    errorText: "VALIDATION.edm-name-pattern",
  }),

  someRule: (): Rule<boolean[]> => ({
    name: "one checked",
    validator: (val) => val.some((k) => k === true),
  }),

  notNull: (): Rule<number | null> => ({
    name: "not null",
    validator: (val) => val !== null,
    errorText: "OPEN_ACCOUNT.errors.select_answer",
  }),

  phone: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "valid phone",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      const re = /\+[1-9][0-9]{3,28}$/;
      if (needToValidate !== null) return needToValidate && val ? re.test(val) : true;
      return re.test(val);
    },
    errorText: "VALIDATION.phone-number-field",
  }),

  email: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "valid email",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      const re =
        /^((([0-9A-Za-z]{1}[-0-9A-z\.]{1,}[0-9A-Za-z]{1})|([0-9А-Яа-я]{1}[-0-9А-я\.]{1,}[0-9А-Яа-я]{1}))@([-A-Za-z]{1,}\.){1,2}[-A-Za-z]{2,})$/u;
      if (needToValidate !== null) return needToValidate && val ? re.test(val) : true;
      return re.test(val);
    },
    errorText: "VALIDATION.correct-format-spec-field",
  }),

  checkAdultAge: (): Rule<null | any> => ({
    name: "adultDate",
    validator: (val) => {
      const currentDate = new Date();
      currentDate.setFullYear(currentDate.getFullYear() - 18);
      return moment(val.value, "DD.MM.YYYY").isSameOrBefore(moment(currentDate, "DD.MM.YYYY"));
    },
    errorText: "VALIDATION.datebirth-field_18",
  }),

  checkPostIndex: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "zip code",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate && val ? postIndexPattern.test(val) : true;
      return val ? postIndexPattern.test(val) : true;
    },
    errorText: "VALIDATION.zipcode-pattern",
  }),

  checkRegion: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "region",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate && val ? statePattern.test(val) : true;
      return val ? statePattern.test(val) : true;
    },
    errorText: "VALIDATION.edm-region-pattern",
  }),

  checkStreet: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "street",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate && val ? streetPattern.test(val) : true;
      return val ? streetPattern.test(val) : true;
    },
    errorText: "VALIDATION.edm-street-pattern",
  }),

  checkHouse: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "street",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate && val ? housePattern.test(val) : true;
      return val ? housePattern.test(val) : true;
    },
    errorText: "VALIDATION.edm-house-pattern",
  }),

  checkZipcode: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "zipcode",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate && val ? zipCodePattern.test(val) : true;
      return val ? zipCodePattern.test(val) : true;
    },
    errorText: "OPEN_ACCOUNT.errors.zipcode",
  }),

  checkCity: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "city",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate && val ? edmCityPattern.test(val) : true;
      return val ? edmCityPattern.test(val) : true;
    },
    errorText: "VALIDATION.edm-city-pattern",
  }),

  checkApartment: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "apartment",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate && val ? housePattern.test(val) : true;
      return val ? housePattern.test(val) : true;
    },
    errorText: "VALIDATION.edm-apartment-pattern",
  }),

  checkHousing: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "housing",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate && val ? housingPattern.test(val) : true;
      return val ? housingPattern.test(val) : true;
    },
    errorText: "VALIDATION.edm-housing-pattern",
  }),
};
