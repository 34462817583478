import { createAsyncReducer } from "~/lib/redux";
import { getOfferErrorAction, getOfferStartAction, getOfferSuccessAction } from "../actions";

export const STATE_NAME = "details";

const [reducer, defaultState] = createAsyncReducer(getOfferStartAction, getOfferSuccessAction, getOfferErrorAction);

export type DetailsState = typeof defaultState;

export default reducer;
