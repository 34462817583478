import { createAsyncReducer } from '~/lib/redux';

import {
  requestsPostBrokerReportClearAction,
  requestsPostBrokerReportErrorAction,
  requestsPostBrokerReportStartAction,
  requestsPostBrokerReportSuccessAction,
} from '../actions';

export const STATE_NAME = 'postBrokerReport';

const [reducer, defaultState] = createAsyncReducer(
  requestsPostBrokerReportStartAction,
  requestsPostBrokerReportSuccessAction,
  requestsPostBrokerReportErrorAction,
  requestsPostBrokerReportClearAction,
);

export type RequestsPostBrokerReportState = typeof defaultState;

export default reducer;
