import React, { ReactElement } from "react";

import { modalTypes } from "~/constants/enums";
import { INotificationButtonProps } from "~/shared/types";
import BaseModal from "~/shared/ui/BaseModal/BaseModal";
import { ModalButtonGroup, ModalDescriptionGroup, ModalTitleGroup } from "~/shared/ui/Modal";
import { IconNameType } from "~/shared/ui-kit/constants/icons/Icon";
import { IconProps } from "~/shared/ui-kit/constants/icons/Icon/Icon";
import { MistakeContent } from "./components/MistakeContent";
import { OperationContent } from "./components/OperationContent";
import { SuccessModalContent } from "./components/SuccessModalContent";
import { DownloadDocW8Btn } from "./helper";

interface TNotificationModalWithMobileProps {
  isOpen: boolean;
  onClose: () => void;
  type: modalTypes;
  title?: string;
  arrDescription?: (string | ReactElement)[];
  className?: string;
  iconName?: IconNameType;
  iconBackground?: IconProps["withBackground"] | null;
  buttons?: INotificationButtonProps[];
  withDocBtn?: boolean;
  checkSentStatus?: string;
  mainBtnHandler?: () => void;
  maxWidthContent?: string | null;
  errorCode?: string;
}

export const ModalNotification = ({
  isOpen,
  onClose,
  type,
  title,
  checkSentStatus,
  arrDescription,
  iconName,
  iconBackground,
  buttons,
  mainBtnHandler,
  withDocBtn,
  maxWidthContent,
  errorCode,
}: TNotificationModalWithMobileProps) => {
  return (
    <BaseModal open={isOpen} onClose={onClose} maxWidthContent={maxWidthContent}>
      {type === modalTypes.any && (
        <>
          <ModalTitleGroup title={title} iconName={iconName} withBackground={iconBackground} />
          {arrDescription && <ModalDescriptionGroup arrDescr={arrDescription} />}
          {buttons && !!buttons.length && (
            <ModalButtonGroup buttons={buttons} docBtn={withDocBtn ? <DownloadDocW8Btn /> : undefined} />
          )}
        </>
      )}
      {type === modalTypes.opertions && mainBtnHandler && checkSentStatus && (
        <OperationContent checkSentStatus={checkSentStatus} btnHandler={mainBtnHandler} />
      )}
      {type === modalTypes.mistake && mainBtnHandler && (
        <MistakeContent btnHandler={mainBtnHandler} errorCode={errorCode} />
      )}
      {type === modalTypes.success && mainBtnHandler && checkSentStatus && (
        <SuccessModalContent checkSentStatus={checkSentStatus} btnHandler={mainBtnHandler} />
      )}
    </BaseModal>
  );
};
