import React from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material/styles";

import fonts from "~/configs/fonts";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

type Props = {
  children: React.ReactNode;
};

declare module "@mui/material/styles" {
  interface TypeGradient {
    [key: string]: string;
  }

  interface Palette {
    gradient: TypeGradient;
    text: TypeText2;
  }

  interface TypeColor {
    Darkest?: string;
    Darker?: string;
    Dark?: string;
    Base?: string;
    Light?: string;
    Lighter?: string;
    Lightest?: string;
    White?: string;
  }

  interface TypeText2 {
    primary: string;
    secondary: string;
    disabled: string;
  }

  interface PaletteOptions {
    gradient: TypeGradient;
    Ink: TypeColor;
    Sky: TypeColor;
    Red: TypeColor;
    Green: TypeColor;
  }

  interface TypeBackground {
    opposite: string;
  }

  interface TypographyVariants {
    CTA1: React.CSSProperties;
    CTA2: React.CSSProperties;
    CTA3: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    Body1: React.CSSProperties;
    Body2: React.CSSProperties;
    Body3: React.CSSProperties;
    Body1Medium: React.CSSProperties;
    Body1SemiBold: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    CTA1?: React.CSSProperties;
    CTA2?: React.CSSProperties;
    CTA3?: React.CSSProperties;
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    Body1?: React.CSSProperties;
    Body2?: React.CSSProperties;
    Body3?: React.CSSProperties;
    Body1Medium?: React.CSSProperties;
    Body1SemiBold?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    CTA1: true;
    CTA2: true;
    CTA3: true;
    body3: true;
    body4: true;
    Body1: true;
    Body2: true;
    Body3: true;
    Body1Medium: true;
    Body1SemiBold: true;
  }
}

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: colorTable.charcoalBlack,
      },
      secondary: {
        main: colorTable.white,
      },
      error: {
        main: colorTable.red,
      },
      background: {
        default: colorTable.white,
      },
      gradient: {},
      Ink: {
        Base: colorTable.charcoalBlack,
      },
      Sky: {
        Base: colorTable.white,
      },
      Red: {
        Base: colorTable.red,
      },
      Green: {
        Base: colorTable.green,
      },
    },
    typography: {
      fontFamily: "Suisse Intl, sans-serif!important",
      fontSize: 14,
      h1: {
        fontSize: "26px",
        fontWeight: 600,
      },
      h2: {
        fontSize: "22px",
        fontWeight: 600,
      },
      h3: {
        fontSize: "20px",
        fontWeight: 600,
      },
      h4: {
        fontSize: "18px",
        fontWeight: 600,
      },
      h5: {
        fontSize: "16px",
        fontWeight: 500,
      },
      CTA1: {
        fontSize: "28px",
        fontWeight: 500,
      },
      CTA2: {
        fontSize: "18px",
        fontWeight: 500,
      },
      CTA3: {
        fontSize: "16px",
        fontWeight: 400,
      },
      Body1: {
        fontSize: "14px",
        fontWeight: 400,
      },
      Body2: {
        fontSize: "13px",
        fontWeight: 400,
      },
      Body3: {
        fontSize: "12px",
        fontWeight: 400,
      },
      Body1Medium: {
        fontSize: "14px",
        fontWeight: 500,
      },
      Body1SemiBold: {
        fontSize: "14px",
        fontWeight: 600,
      },
      body3: {
        fontSize: "12px",
        display: "block",
      },
      body4: {
        fontSize: "10px",
        display: "block",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            "@global": {
              "@font-face": fonts as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            },
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            backgroundColor: colorTable.transparent,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            "& fieldset": {
              border: `1px solid ${borderColors.greyContour}!important`,
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: "pointer",
            textDecoration: "none",
            lineHeight: "16px",
            transition: "all 0.1s ease-in-out",
            "&:hover": {
              opacity: 0.8,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            aspectRatio: "1/1",
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            disableUnderline: true,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            focusRipple: false,
            color: "primary",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            focusRipple: false,
            color: "primary",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            disableRipple: true,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          gutterBottom: {
            marginBottom: "24px",
          },
          paragraph: {
            marginBottom: "24px",
          },
          body1: {
            fontSize: "16px",
            lineHeight: "24px",
            fontFamily: "Suisse Intl, sans-serif",
          },
          body2: {
            fontSize: "14px",
            lineHeight: "22px",
          },
          h1: {
            fontSize: "24px",
            lineHeight: "32px",
            color: colorTable.charcoalBlack,
            fontWeight: 500,
          },
          subtitle1: {
            fontSize: "12px",
            color: colorTable.darkGrey_20,
          },
        },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            minWidth: 450,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "26px 0",
            fontSize: 16,
          },
          head: {
            textTransform: "uppercase",
            fontSize: 10,
            fontWeight: 600,
            lineHeight: 1,
            padding: "16px 0",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            height: 56,
            fontSize: 16,
            fontWeight: 500,
            textTransform: "none",
            padding: "14px 32px",
          },
          outlined: {
            color: colorTable.white,
            borderColor: colorTable.white,
          },
          text: {
            padding: "14px 32px",
          },
          sizeSmall: {
            fontSize: 12,
            padding: 0,
            height: 32,
            lineHeight: 0,
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          filled: {
            "&.MuiInputAdornment-positionStart": {
              marginTop: "inherit",
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            overflow: "hidden",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            whiteSpace: "nowrap",
          },
          filled: {
            transform: "translate(24px, 28px) scale(1)",
            "&.MuiInputLabel-shrink": {
              transform: "translate(24px, 10px) scale(0.75)",
            },
          },
          shrink: {
            transform: "translate(24px, 10px) scale(0.75)",
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: "8px 0!important",
            display: "flex",
            flexDirection: "column",
            border: "none",
            boxShadow: "0 4px 8px 0 rgba(44, 46, 51, 0.03), 0 8px 16px 0 rgba(44, 46, 51, 0.1)",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            minWidth: "208px",
            display: "flex",
            justifyContent: "start",
            width: "100%",
            textAlign: "start",
            padding: "8px 12px",
            minHeight: "fit-content",
            height: "fit-content!important",
            fontFamily: "Suisse Intl, sans-serif",
            fontWeight: 400,
            fontSize: "14px",
            paddingRight: "32px",
            "@media (max-width: 576px)": {
              minWidth: "160px",
            },
            "&.Mui-selected": {
              backgroundColor: `${colorTable.white_20}!important`,
            },
            "&.Mui-selected:hover": {
              backgroundColor: `${colorTable.white_20}`,
            },
            "&:hover": {
              backgroundColor: colorTable.white_20,
            },
            "&::after": {
              display: "none",
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: 0,
          },
          flexContainer: {
            height: "100%",
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            minHeight: 80,
            height: 80,
            alignItems: "center",
            flexDirection: "row",
          },
          colorPrimary: {
            background: "none",
            backgroundColor: colorTable.white,
            color: colorTable.moonGrey,
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: 90,
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          circleIndeterminate: {
            color: colorTable.charcoalBlack,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          contained: {
            marginRight: 0,
            marginLeft: 0,
          },
        },
      },
    },
  })
);

export const AppThemeProvider = ({ children }: Props) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);
