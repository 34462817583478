import { combineReducers } from 'redux';

import changePassword, {
  KeycloakChangePasswordState,
  STATE_NAME as CHANGE_PASSWORD_STATE_NAME,
} from './reducers/changePassword';
import changeSecurityQuestion, {
  KeycloakChangeSecurityQuestionState,
  STATE_NAME as CHANGE_SECURITY_QUESTION_STATE_NAME,
} from './reducers/changeSecurityQuestion';
import checkOtp, { KeycloakCheckOtpState, STATE_NAME as CHECK_OTP_STATE_NAME } from './reducers/checkOtp';
import initialCredentials, {
  KeycloakInitialCredentialsState,
  STATE_NAME as INITIAL_CREDENTIALS_STATE_NAME,
} from './reducers/initialCredentials';
import otpTimestamp, {
  KeycloakOtpTimestampState,
  STATE_NAME as OTP_TIMESTAMP_STATE_NAME,
} from './reducers/otpTimestamp';
import resetPassword, {
  KeycloakResetPasswordState,
  STATE_NAME as RESET_PASSWORD_STATE_NAME,
} from './reducers/resetPassword';
import resetPasswordQuestion, {
  KeycloakResetPasswordQuestionState,
  STATE_NAME as RESET_PASSWORD_QUESTION_STATE_NAME,
} from './reducers/resetPasswordQuestion';
import securityQuestion, {
  KeycloakSecurityQuestionState,
  STATE_NAME as SECURITY_QUESTION_STATE_NAME,
} from './reducers/securityQuestion';
import securityQuestions, {
  KeycloakSecurityQuestionsState,
  STATE_NAME as SECURITY_QUESTIONS_STATE_NAME,
} from './reducers/securityQuestions';

export const STATE_NAME = 'keycloak';

export { CHANGE_PASSWORD_STATE_NAME, KeycloakChangePasswordState };
export { CHANGE_SECURITY_QUESTION_STATE_NAME, KeycloakChangeSecurityQuestionState };
export { RESET_PASSWORD_STATE_NAME, KeycloakResetPasswordState };
export { CHECK_OTP_STATE_NAME, KeycloakCheckOtpState };
export { SECURITY_QUESTIONS_STATE_NAME, KeycloakSecurityQuestionsState };
export { OTP_TIMESTAMP_STATE_NAME, KeycloakOtpTimestampState };
export { INITIAL_CREDENTIALS_STATE_NAME, KeycloakInitialCredentialsState };
export { SECURITY_QUESTION_STATE_NAME, KeycloakSecurityQuestionState };
export { RESET_PASSWORD_QUESTION_STATE_NAME, KeycloakResetPasswordQuestionState };

export interface KeycloakState {
  [CHANGE_PASSWORD_STATE_NAME]: KeycloakChangePasswordState;
  [CHANGE_SECURITY_QUESTION_STATE_NAME]: KeycloakChangeSecurityQuestionState;
  [RESET_PASSWORD_STATE_NAME]: KeycloakResetPasswordState;
  [CHECK_OTP_STATE_NAME]: KeycloakCheckOtpState;
  [SECURITY_QUESTIONS_STATE_NAME]: KeycloakSecurityQuestionsState;
  [OTP_TIMESTAMP_STATE_NAME]: KeycloakOtpTimestampState;
  [INITIAL_CREDENTIALS_STATE_NAME]: KeycloakInitialCredentialsState;
  [SECURITY_QUESTION_STATE_NAME]: KeycloakSecurityQuestionState;
  [RESET_PASSWORD_QUESTION_STATE_NAME]: KeycloakResetPasswordQuestionState;
}

export default combineReducers<KeycloakState>({
  [CHANGE_PASSWORD_STATE_NAME]: changePassword,
  [CHANGE_SECURITY_QUESTION_STATE_NAME]: changeSecurityQuestion,
  [RESET_PASSWORD_STATE_NAME]: resetPassword,
  [CHECK_OTP_STATE_NAME]: checkOtp,
  [SECURITY_QUESTIONS_STATE_NAME]: securityQuestions,
  [OTP_TIMESTAMP_STATE_NAME]: otpTimestamp,
  [INITIAL_CREDENTIALS_STATE_NAME]: initialCredentials,
  [SECURITY_QUESTION_STATE_NAME]: securityQuestion,
  [RESET_PASSWORD_QUESTION_STATE_NAME]: resetPasswordQuestion,
});
