import { makeStyles } from "tss-react/mui";

import theme from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()(() => ({
  blockOperationResult: {
    display: "flex",
    flexDirection: "column",
    gap: "16px 0",
    alignItems: "center",
  },
  alignLeft: {
    alignItems: "flex-start",
  },
  wrapperIconStatus: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "68px",
    maxHeight: "68px",
    width: "68px",
    height: "68px",
    borderRadius: "16px",
    background: colorTable.lightGreen,
  },
  statusError: {
    background: colorTable.redLight,
  },
  titleOperationResult: {
    fontWeight: 600,
    lineHeight: "32px",
    fontSize: "24px",
    margin: "0px",
    color: colorTable.charcoalBlack,
    textAlign: "center",
    [theme.breakpoints.down(768)]: {
      fontSize: "20px",
      lineHeight: "28px",
    },
  },
  additionalInformation: {
    lineHeight: "24px",
    fontSize: "16px",
    color: colorTable.textSecondary,
    textAlign: "center",
    margin: "0px",
    width: "100%",
    wordBreak: "break-word",
    [theme.breakpoints.down(768)]: {
      color: colorTable.lightGrey_90,
    },
  },
  buttonNext: {
    padding: "14px 24px",
    height: "48px",
    lineHeight: "20px",
    fontSize: "14px",
    color: colorTable.white,
    marginTop: "24px",
    background: colorTable.charcoalBlack,

    "&:hover": {
      background: colorTable.blue_10,
    },

    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
  },
}));
