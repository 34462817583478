import React, { useEffect } from "react";
import { cssTransition, ToastContainer } from "react-toastify";
import { CssBaseline } from "@mui/material";
import cn from "classnames";
import { makeStyles } from "tss-react/mui";

import { notificationModel } from "~/modules/notification-model";

const ErrorFallback = ({ error }: any) => {
  useEffect(() => {
    notificationModel.error(error.message);
  }, []);

  const useStyles = makeStyles()({
    wrapper: {
      overflow: "initial",
      wordBreak: "break-word",
    },
    mobileStyle: {
      width: "95%",
    },
    mobileStyleBody: {
      fontSize: 30,
    },
  });

  const { classes } = useStyles();
  const zoom = cssTransition({
    enter: "zoom-enter",
    exit: "zoom-exit",
  });

  return (
    <div role="alert">
      <CssBaseline />
      <ToastContainer
        theme="colored"
        className={cn(classes.wrapper)}
        position="top-right"
        transition={zoom}
        hideProgressBar
        autoClose={10000}
        limit={4}
        style={{ top: "1em" }}
      />
    </div>
  );
};

export default ErrorFallback;
