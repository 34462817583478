import { createDomain, forward, sample } from "effector";
import { createForm } from "effector-forms";
import { createGate } from "effector-react";
import moment from "moment";
import { pending } from "patronum";

import {
  dateTypes,
  emdDocsNames,
  lastKeyProcessNames,
  processResults,
  sumSubReviewAnswers,
  sumSubReviewStatus,
  taskDefinitionKeysEDM,
} from "~/constants/enums";
import { edmTextPattern } from "~/constants/regexp";
import { CurrentClientStatusEnum } from "~/entities/current-client/types";
import { authApi } from "~/entities/keycloak/api";
import { processResultEnum } from "~/entities/productBuy/types";
import { t } from "~/i18n";
import { conditionalValue } from "~/lib/utils";
import { countriesModel } from "~/modules/countries";
import { currentClientModel } from "~/modules/current-client-model";
import { headerModel } from "~/modules/header-model";
import { logoutModel } from "~/modules/keycloak/logout-model";
import { notificationModel } from "~/modules/notification-model";
import { getIdentificationV3, getLastKey, getVerificationStatus, putTaskComplete } from "~/pages/EDM/services/api";
import { rules } from "~/pages/EDM/services/rules";
import { formatCountrySearch } from "~/pages/OpenAccount/services/helpers";
import type { Country, goBackFromOtp } from "./types";

const edmDomain = createDomain("edm domain");
const gate = createGate({ domain: edmDomain });

const putTaskCompleteFx = edmDomain.createEffect({ handler: putTaskComplete });
const putTaskCompleteGoBackFx = edmDomain.createEffect({ handler: putTaskComplete });
const getProcessFx = edmDomain.createEffect({ handler: authApi.getProcessStateEDM });
const getIdentificationFx = edmDomain.createEffect({ handler: getIdentificationV3 });
const getLastKeyFx = edmDomain.createEffect({ handler: getLastKey });
const getVerificationStatusFx = edmDomain.createEffect({ handler: getVerificationStatus });
const putTaskCompleteStartChecking = edmDomain.createEffect({ handler: putTaskComplete });

const setIsEdm = edmDomain.createEvent<boolean | null>();
const setHasPatronymicName = edmDomain.createEvent<boolean>();
const setProcessInfo = edmDomain.createEvent();
const setIsActualTheSamePermanent = edmDomain.createEvent<boolean>();
const sendCitizenship = edmDomain.createEvent();
const sendPersonalDetails = edmDomain.createEvent();
const checkPersonalDetails = edmDomain.createEvent();
const sendAddressesForm = edmDomain.createEvent();
const getVerificationStatusEvent = edmDomain.createEvent();
const startChecking = edmDomain.createEvent();
const setSumSubToken = edmDomain.createEvent();
const setCountAttempts = edmDomain.createEvent<number>();

const goBackTo = edmDomain.createEvent<number>();
const goToStepFromThird = edmDomain.createEvent<number>();
const handleManualStepNavigation = edmDomain.createEvent<string | null>();
const goToStepFromFourth = edmDomain.createEvent<goBackFromOtp>();
const goBackFromOTP = edmDomain.createEvent();
const changeStep = edmDomain.createEvent<number>();
const setRepeatedSumSub = edmDomain.createEvent<boolean>();
const changeSubStep = edmDomain.createEvent<number>();
const incStep = edmDomain.createEvent();
const decStep = edmDomain.createEvent();
const incSubStep = edmDomain.createEvent();
const decSubStep = edmDomain.createEvent();
const changeStatusesStep = edmDomain.createEvent<number>();
const setSumSubSuccess = edmDomain.createEvent();
const setCompletedSumSub = edmDomain.createEvent<boolean>();
const setHeaderVisibility = edmDomain.createEvent<boolean>();
const setNumberStepper = edmDomain.createEvent<number>();
const onStepperChange = edmDomain.createEvent();
const setIsOpenAttemptsModal = edmDomain.createEvent<boolean>();

const setVerificationStatus = edmDomain.createEvent();
const setEDMStartScreen = edmDomain.createEvent<boolean>();
const searchCountry = edmDomain.createEvent<string>();
const clearCountries = edmDomain.createEvent();
const changeIsRussianPassportDraft = edmDomain.createEvent<boolean>();
const setEdmDocUrls = edmDomain.createEvent<typeof emdDocsNames>();
const setRedRetrySumSub = edmDomain.createEvent<boolean>();
const setV2Failed = edmDomain.createEvent<boolean>();
const setV1Failed = edmDomain.createEvent<boolean>();
const setIsZipcodeInvalid = edmDomain.createEvent<boolean>();

const setEdmIsBlocked = edmDomain.createEvent<boolean>();

const { $currentClient } = currentClientModel;

forward({ from: gate.open, to: getIdentificationFx });

const $isEdm = edmDomain.createStore<boolean | null>(null);
$isEdm.reset(logoutModel.logout);
$isEdm.on(setIsEdm, (_, data) => data);
headerModel.$headerTheme.on($isEdm, (state, data) => ({ ...state, isHiddenTabMenu: Boolean(data) }));

const $hasPatronymicName = edmDomain.createStore<boolean>(true);
$hasPatronymicName.on(setHasPatronymicName, (_, data) => data);

const $isActualTheSamePermanent = edmDomain.createStore<boolean>(true);
$isActualTheSamePermanent.on(setIsActualTheSamePermanent, (_, data) => data);

const $processInfo = edmDomain.createStore<any>("");
$processInfo.reset(gate.close);
$processInfo.on(setProcessInfo, (_, data) => data);

const $completedSumSub = edmDomain.createStore<boolean>(false);
$completedSumSub.reset(gate.close);
$completedSumSub.on(setCompletedSumSub, () => true);

const $businessKey = edmDomain.createStore<string>("");
$businessKey.reset(gate.close);
$businessKey.on([getIdentificationFx.doneData, getLastKeyFx.doneData], (_, data) => data?.businessKey);

const $sumSubToken = edmDomain.createStore<string>("");
$sumSubToken.reset(gate.close);
$sumSubToken.on(setSumSubToken, (_, data: any) => data?.resultData?.accessToken || data?.token);

const $step = edmDomain.createStore<number | null>(null);
$step.reset(gate.close);
$step.on(changeStep, (_, stepNumber) => stepNumber);

const $subStep = edmDomain.createStore<number>(1);
$subStep.reset(gate.close);
$subStep.on(changeSubStep, (_, subStepNumber) => subStepNumber);

const $isRussianPassportDraft = edmDomain.createStore<boolean | null>(null);
$isRussianPassportDraft.reset(gate.close);
$isRussianPassportDraft.on(changeIsRussianPassportDraft, (_, status) => status);

const $verificationStatus = edmDomain.createStore<any>(null);
$verificationStatus.reset(gate.close);
$verificationStatus.on(setVerificationStatus, (_, data) => data);

const $statusStep = edmDomain.createStore<number>(1);
$statusStep.reset(gate.close);
$statusStep.on(changeStatusesStep, (_, step) => step);

const $isEDMStartScreen = edmDomain.createStore<boolean>(false);
$isEDMStartScreen.reset(gate.close);
$isEDMStartScreen.on(setEDMStartScreen, (_, data) => data);

const $searchedCountries = edmDomain.createStore<null | Country[]>(null);
$searchedCountries.reset([gate.close, clearCountries]);

const $goBackStep = edmDomain.createStore<number>(1);
$goBackStep.reset(gate.close);
$goBackStep.on(goBackTo, (_, data) => data);

const $edmDocURLs = edmDomain.createStore<typeof emdDocsNames | {}>({});
$edmDocURLs.reset(gate.close);
$edmDocURLs.on(setEdmDocUrls, (_, data) => data);

const $headerVisibility = edmDomain.createStore<boolean>(true);
$headerVisibility.reset(gate.close);
$headerVisibility.on(setHeaderVisibility, (_, data) => data);

const $repeatedSumSub = edmDomain.createStore<boolean>(false);
$repeatedSumSub.reset(gate.close);
$repeatedSumSub.on(setRepeatedSumSub, (_, data) => data);

const $countAttempts = edmDomain.createStore<number>(0);
$countAttempts.reset(gate.close);
$countAttempts.on(setCountAttempts, (_, data) => data);

const $numberStepper = edmDomain.createStore<number>(0);
$numberStepper.reset(gate.close);
$numberStepper.on(setNumberStepper, (_, data) => data);

const $isOpenAttemptsModal = edmDomain.createStore<boolean>(false);
$isOpenAttemptsModal.reset(gate.close);
$isOpenAttemptsModal.on(setIsOpenAttemptsModal, (_, data) => data);

const $processResult = edmDomain.createStore<string>("");
$processResult.reset(gate.close);
$processResult.on(getProcessFx.doneData, (_, data) => data?.processResult);

const $isRedRetrySumSub = edmDomain.createStore<boolean>(false);
$isRedRetrySumSub.on(setRedRetrySumSub, (_, data) => data);

const $edmIsBlocked = edmDomain.createStore<boolean>(false);
$edmIsBlocked.reset(gate.close);
$edmIsBlocked.on(setEdmIsBlocked, (_, data) => data);

const $isV2Failed = edmDomain.createStore<boolean>(false);
$isV2Failed.on(setV2Failed, (_, data) => data);
$isV2Failed.reset(gate.close);

const $isV1Failed = edmDomain.createStore<boolean>(false);
$isV1Failed.on(setV1Failed, (_, data) => data);
$isV1Failed.reset(gate.close);

const $isZipcodeInvalid = edmDomain.createStore<boolean>(false);
$isZipcodeInvalid.on(setIsZipcodeInvalid, (_, data) => data);
$isZipcodeInvalid.reset(gate.close);

const identityDocumentFormNew = createForm({
  domain: edmDomain,
  fields: {
    lastNameFirstName: {
      init: "",
    },
    middleName: {
      init: "",
      rules: [
        {
          name: "required_if",
          validator: (val: string, form) => {
            if (!!form.hasNoMiddleName) return true;
            return Boolean(val);
          },
          errorText: "VALIDATION.required-field",
        },
        {
          name: "true name",
          validator: (val: string, form) => {
            if (!!form.hasNoMiddleName) return true;
            return val ? edmTextPattern.test(val) : true;
          },
          errorText: "VALIDATION.edm-name-pattern",
        },
        {
          name: "less",
          validator: (val: string, form) => {
            if (!!form.hasNoMiddleName) return true;
            else return val?.trim().length <= 50;
          },
          errorText: `OPEN_ACCOUNT.errors.less_${50}`,
        },
      ],
      validateOn: ["submit"],
    },
    birthDay: {
      init: "",
    },
    hasNoMiddleName: {
      init: false,
    },
    numberAndSerial: {
      init: "",
    },
    address: {
      init: "",
    },
    gender: {
      init: "",
    },
    zipcode: {
      init: "",
      rules: [
        rules.required($isZipcodeInvalid),
        rules.lessAndMore(14, 4, $isZipcodeInvalid),
        rules.checkZipcode($isZipcodeInvalid),
      ],
    },
  },
});

const addressCheckboxForm = createForm({
  domain: edmDomain,
  fields: {
    isMailingAddressMatchesRegisteredAddress: {
      init: false,
    },
    isActualAddressMatchesRegisteredAddress: {
      init: false,
    },
  },
});

const citizenshipForm = createForm({
  domain: edmDomain,
  fields: {
    citizenship: {
      init: "",
      rules: [rules.required()],
      validateOn: ["submit"],
    },
  },
});

forward({
  from: gate.open,
  to: getVerificationStatusEvent,
});

sample({
  clock: getIdentificationFx.failData,
  fn: () => {
    setEDMStartScreen(true);
    return { processName: lastKeyProcessNames.PROCESS_KEY_USER_IDENTIFICATION_V3 };
  },
  target: getLastKeyFx,
});

sample({
  source: getLastKeyFx.doneData,
  clock: setV2Failed,
  filter: (data) => data?.businessKey === null,
  fn: () => {
    setEDMStartScreen(true);
    return { processName: lastKeyProcessNames.PROCESS_KEY_USER_IDENTIFICATION_V2 };
  },
  target: getLastKeyFx,
});

sample({
  source: getLastKeyFx.doneData,
  clock: setV1Failed,
  filter: (data) => data?.businessKey === null,
  fn: () => {
    setEDMStartScreen(true);
    return { processName: lastKeyProcessNames.PROCESS_KEY_USER_IDENTIFICATION };
  },
  target: getLastKeyFx,
});

sample({
  clock: [getIdentificationFx.doneData, getLastKeyFx.doneData],
  filter: (data) => {
    const verificationStatus = $verificationStatus.getState();
    if (!data.businessKey && verificationStatus?.reviewStatus === sumSubReviewStatus.COMPLETED) {
      setCompletedSumSub(true);
      changeStep(2);
      if (
        $currentClient.getState()?.clientStatus !== CurrentClientStatusEnum.IDENTIFICATION_FAILED &&
        $currentClient.getState()?.clientStatus !== CurrentClientStatusEnum.IDENTIFICATION_COMPLETED
      ) {
        changeStatusesStep(2);
      }
    }
    return !!data.businessKey;
  },
  fn: (data) => ({ processBusinessKey: data.businessKey, sumSubToken: $sumSubToken.getState() || undefined }),
  target: getProcessFx,
});

sample({
  source: $businessKey,
  clock: putTaskCompleteFx.doneData,
  fn: (data) => ({ processBusinessKey: data, sumSubToken: $sumSubToken.getState() || undefined }),
  target: getProcessFx,
});

sample({
  source: [$countAttempts, $numberStepper],
  clock: onStepperChange,
  fn: ([countAttempts, numberStepper]) => {
    if (countAttempts === 1 && numberStepper < 3) {
      setIsOpenAttemptsModal(true);
    }
    if (countAttempts === 2 && numberStepper < 3) {
      goToStepFromThird(numberStepper);
    }
  },
  target: getProcessFx,
});

sample({
  source: $businessKey,
  clock: setSumSubSuccess,
  fn: (data) => {
    return {
      processBusinessKey: data,
      sumSubToken: $sumSubToken.getState() || undefined,
      emptyProcessResultRefresh: true,
    };
  },
  target: getProcessFx,
});

sample({
  source: getProcessFx.doneData,
  clock: [getProcessFx.doneData],
  fn: (data) => {
    const identityInfo = data?.inputData?.identityDocument;
    const addresses = data?.inputData?.addresses;
    if (
      data?.activeTasks[0]?.taskDefinitionKey === taskDefinitionKeysEDM.VALIDATED_DATA_CONFIRMATION &&
      !!identityInfo
    ) {
      const permanentResidentialAddress = data?.inputData?.registrationAddress;
      setIsZipcodeInvalid(!permanentResidentialAddress.zipcode);
      identityDocumentFormNew.setForm({
        ...identityInfo,
        lastNameFirstName: identityInfo?.lastName ? `${identityInfo?.lastName} ${identityInfo?.firstName}` : null,
        hasNoMiddleName: conditionalValue(identityInfo?.hasNoMiddleName, null),
        numberAndSerial: identityInfo?.serial
          ? `${identityInfo?.serial} ${identityInfo?.number}`
          : identityInfo?.number,
        address: permanentResidentialAddress?.address || "",
        birthDay: identityInfo.birthDay ? moment(identityInfo.birthDay).format(dateTypes.RUS_DATE) : null,
        expiryDate: identityInfo.expiryDate ? moment(identityInfo.expiryDate).format(dateTypes.RUS_DATE) : null,
        issueDate: identityInfo.issueDate ? moment(identityInfo.issueDate).format(dateTypes.RUS_DATE) : null,
        gender: conditionalValue(identityInfo?.gender, null),
        zipcode: conditionalValue(identityInfo?.zipcode),
      });
      setHasPatronymicName(identityInfo?.hasNoMiddleName);
      setIsActualTheSamePermanent(addresses?.isMailingAddressMatchesCurrentResidentialAddress);
    }
    if (
      (data?.processResult === taskDefinitionKeysEDM.SUMSUB_CLIENT_IDENTIFICATION &&
        !data?.resultData?.accessToken?.length) ||
      data?.activeTasks[0]?.taskDefinitionKey === taskDefinitionKeysEDM.ACCEPTED_PRINT_FORMS ||
      data?.activeTasks[0]?.taskDefinitionKey === taskDefinitionKeysEDM.OTP_CONFIRMATION
    ) {
      const filteredKeys = Object.keys(emdDocsNames);
      const filteredObj = Object.keys(data?.resultData)
        .filter((key) => filteredKeys.includes(key))
        .reduce((acc: any, key) => {
          acc[key] = data?.resultData[key];
          return acc;
        }, {});
      setEdmDocUrls(filteredObj as typeof emdDocsNames);
    }
  },
});

checkPersonalDetails.watch(() => {
  identityDocumentFormNew.validate();
  sendPersonalDetails();
});

getLastKeyFx.doneData.watch((data) => {
  if (data?.businessKey !== null) {
    return;
  }
  if (data?.businessKey === null && $isV2Failed.getState() && !$isV1Failed.getState()) {
    setV1Failed(true);
  }
  if (data?.businessKey === null && !$isV2Failed.getState()) {
    setV2Failed(true);
  }
});

sample({
  source: getProcessFx.doneData,
  clock: getProcessFx.doneData,
  filter: (data) =>
    data?.processResult === processResultEnum.INVALID_OTP_FIRST_BLOCK ||
    data?.processResult === processResultEnum.INVALID_OTP_SECOND_BLOCK,
  fn: () => {
    setEdmIsBlocked(true);
    changeStep(2);
    changeStatusesStep(3);
  },
});

sample({
  source: [$processInfo, $isActualTheSamePermanent] as const,
  clock: sendPersonalDetails,
  filter: ([processInfo]) => !!processInfo?.activeTasks[0]?.id,
  fn: ([processInfo, isActualTheSamePermanent]) => {
    const registrationInputData = processInfo?.inputData?.registrationAddress;
    const isValid = isActualTheSamePermanent && identityDocumentFormNew.$isValid.getState();
    if (isValid) {
      let personalStepData: any = {
        taskId: conditionalValue(processInfo?.activeTasks[0]?.id),
        variables: {
          isClientDataMatch: true,
          goBackTo: 0,
          validatedData: {
            middleName: identityDocumentFormNew?.$values.getState().middleName?.trim() ?? null,
            registrationAddress: registrationInputData,
            isMailingAddressMatchesRegistrationAddress: $isActualTheSamePermanent.getState(),
            identityDocumentHasNoMiddleName: !!identityDocumentFormNew.$values.getState().hasNoMiddleName,
            zipcode: $isZipcodeInvalid.getState() ? identityDocumentFormNew.fields.zipcode.$value.getState() : null,
          },
        },
      };

      if (!isActualTheSamePermanent) {
        personalStepData = {
          taskId: conditionalValue(processInfo?.activeTasks[0]?.id),
          variables: {
            isClientDataMatch: true,
            goBackTo: 0,
            validatedData: {
              ...personalStepData?.variables?.validatedData,
            },
          },
        };
      }

      return personalStepData;
    }
  },
  target: putTaskCompleteFx,
});

sample({
  source: countriesModel.$filteredAllCountries,
  clock: searchCountry,
  fn: (countryList, searchQuery): null | Country[] => {
    if (!countryList) return [];

    if (!searchQuery) return formatCountrySearch(countryList).slice(0, 7);

    return formatCountrySearch(
      countryList.filter(
        (country: any) => country.englishName.toLocaleLowerCase().indexOf(searchQuery?.toLocaleLowerCase()) >= 0
      )
    );
  },
  target: $searchedCountries,
});

sample({
  source: getProcessFx.doneData,
  clock: getProcessFx.doneData,
  filter: (data) =>
    !data?.activeTasks?.length &&
    !data?.resultData?.accessToken &&
    !$sumSubToken.getState() &&
    data?.processResult !== processResultEnum.INVALID_OTP_FIRST_BLOCK &&
    data?.processResult !== processResultEnum.INVALID_OTP_SECOND_BLOCK,
  target: getProcessFx.prepend(() => ({
    processBusinessKey: $businessKey.getState(),
    sumSubToken: $sumSubToken.getState() || undefined,
  })),
});

sample({
  source: getProcessFx.doneData,
  clock: getProcessFx.doneData,
  filter: (data) =>
    data?.processResult !== processResultEnum.INVALID_OTP_FIRST_BLOCK &&
    data?.processResult !== processResultEnum.INVALID_OTP_SECOND_BLOCK &&
    (data?.activeTasks?.length || !!data?.resultData),
  target: setProcessInfo,
});

sample({
  source: getProcessFx.doneData,
  clock: getProcessFx.doneData,
  filter: (data) =>
    data?.processResult !== processResultEnum.INVALID_OTP_FIRST_BLOCK &&
    data?.processResult !== processResultEnum.INVALID_OTP_SECOND_BLOCK &&
    data?.resultData?.accessToken,
  target: getVerificationStatusFx,
});

sample({
  source: [citizenshipForm.$values, $processInfo] as const,
  clock: sendCitizenship,
  fn: ([citizenshipForm, processInfo]) => {
    return {
      taskId: conditionalValue(processInfo?.activeTasks[0]?.id),
      variables: {
        citizenshipCode: citizenshipForm.citizenship || null,
      },
    };
  },
  target: putTaskCompleteFx,
});

sample({
  source: [$processInfo, $goBackStep],
  clock: goBackTo,
  fn: ([processInfo, goBackStep]) => {
    return {
      taskId: conditionalValue(processInfo?.activeTasks[0]?.id),
      variables: {
        goBackTo: goBackStep,
      },
    };
  },
  target: putTaskCompleteFx,
});

handleManualStepNavigation.watch((taskDefinitionKey) => {
  if (taskDefinitionKey === taskDefinitionKeysEDM.NATIONALITY_INPUT || !taskDefinitionKey) {
    changeStep(0);
  }

  if (taskDefinitionKey === taskDefinitionKeysEDM.START_SUMSUB_CHECKING) {
    setRepeatedSumSub(true);
    changeStep(2);
  }

  if (taskDefinitionKey === taskDefinitionKeysEDM.VALIDATED_DATA_CONFIRMATION) {
    changeStep(3);
  }

  if (taskDefinitionKey === taskDefinitionKeysEDM.ACCEPTED_PRINT_FORMS) {
    changeStep(4);
  }
  changeSumSubError(null);
  changeTaskDefinitionKey(null);
});

goToStepFromThird.watch((page) => {
  const processInfo = $processInfo.getState();
  putTaskCompleteFx({
    taskId: conditionalValue(processInfo?.activeTasks[0]?.id),
    variables: {
      isClientDataMatch: false,
      goBackTo: page,
    },
  })
    .then()
    .catch((error) => console.warn(error));
});

goToStepFromFourth.watch(({ page, otpOpen }) => {
  const processInfo = $processInfo.getState();
  putTaskCompleteFx({
    taskId: conditionalValue(processInfo?.activeTasks[0]?.id),
    variables: !otpOpen
      ? {
          accept: false,
          goBackTo: page,
        }
      : {
          enteredCode: "",
          cancelled: true,
          goBackTo: page,
        },
  })
    .then()
    .catch((error) => console.warn(error));
});

sample({
  source: $processInfo,
  clock: startChecking,
  fn: (processInfo) => {
    return {
      taskId: conditionalValue(processInfo?.activeTasks[0]?.id),
      variables: {
        startChecking: true,
      },
    };
  },
  target: putTaskCompleteStartChecking,
});

sample({
  source: $businessKey,
  clock: putTaskCompleteGoBackFx.doneData,
  fn: (data) => ({ processBusinessKey: data, sumSubToken: $sumSubToken.getState() || undefined }),
  target: getProcessFx,
});

sample({
  source: getVerificationStatusFx.doneData,
  filter: (data) => {
    setVerificationStatus(data);
    return data?.token;
  },
  target: setSumSubToken,
});

sample({
  source: $businessKey,
  clock: putTaskCompleteStartChecking.doneData,
  fn: (data) => ({ processBusinessKey: data, sumSubToken: $sumSubToken.getState() || undefined }),
  target: getProcessFx,
});

const $sumSubError = edmDomain.createStore<string | null>(null);
$sumSubError.reset(gate.close);
const changeSumSubError = edmDomain.createEvent<string | null>();

$sumSubError.on(changeSumSubError, (_, error) => error);

const $taskDefinitionKey = edmDomain.createStore<string | null>(null);
$taskDefinitionKey.reset(gate.close);
const changeTaskDefinitionKey = edmDomain.createEvent<string | null>();

$taskDefinitionKey.on(changeTaskDefinitionKey, (_, error) => error);

getProcessFx.doneData.watch((data: any) => {
  const verificationStatus = $verificationStatus.getState();
  const countAttempts = data?.resultData?.countAttempts;
  if (!!countAttempts) {
    setCountAttempts(countAttempts);
  }

  if (data.processResult === processResults.RESET_SUMSUB) {
    changeStep(0);
    getIdentificationFx();
  }

  if (data?.activeTasks?.[0]?.taskDefinitionKey) {
    const taskKey = data.activeTasks[0].taskDefinitionKey;
    changeTaskDefinitionKey(taskKey);

    if (taskKey === taskDefinitionKeysEDM.NATIONALITY_INPUT) {
      changeStep(0);
    }

    if (taskKey === taskDefinitionKeysEDM.NATIONALITY_INPUT && data.processResult === "VALIDATION_ERROR") {
      changeStep(0);
      notificationModel.error(t("EDM.ERRORS.validation_error"));
    }

    if (taskKey === taskDefinitionKeysEDM.START_SUMSUB_CHECKING) {
      if (
        data?.processResult === processResults.CITIZENSHIP_DISMATCH ||
        data?.processResult === processResults.EXISTS_BY_FULLNAME_AND_BIRTH_DATE ||
        data?.processResult === processResults.EXISTS_BY_IDENTIFICATION_DOCUMENT_FULLNUMBER_AND_CITIZENSHIP ||
        data?.processResult === processResults.FULLNAME_NOT_MATCH
      ) {
        changeSumSubError(data?.processResult);
        return;
      }

      if (countAttempts > 0) {
        setRepeatedSumSub(true);
        changeStep(2);
      } else {
        changeStep(2);
      }
    }

    if (taskKey === taskDefinitionKeysEDM.VALIDATED_DATA_CONFIRMATION) {
      changeStep(3);
    }

    if (taskKey === taskDefinitionKeysEDM.ACCEPTED_PRINT_FORMS || taskKey === taskDefinitionKeysEDM.OTP_CONFIRMATION) {
      changeStep(4);
    }
  }
  if (!data?.activeTasks?.length) {
    if (verificationStatus.reviewAnswer === sumSubReviewAnswers.RED) changeStep(2);
  }
});

export const edmService = {
  $isEdm,
  setIsEdm,
  $hasPatronymicName,
  setHasPatronymicName,
  gate,
  sendAddressesForm,
  addressCheckboxForm,
  $sumSubToken,
  $getProcessFxPending: getProcessFx.pending,
  $firstStepPending: pending({
    effects: [putTaskCompleteFx, getProcessFx],
  }),
  $step,
  $subStep,
  changeStep,
  changeSubStep,
  incStep,
  decStep,
  incSubStep,
  decSubStep,
  $processInfo,
  $isRussianPassportDraft,
  changeIsRussianPassportDraft,
  getVerificationStatusEvent,
  startChecking,
  $verificationStatus,
  $statusStep,
  changeStatusesStep,
  $businessKey,
  setProcessInfo,
  setEDMStartScreen,
  identityDocumentFormNew,
  $isActualTheSamePermanent,
  setIsActualTheSamePermanent,
  searchCountry,
  clearCountries,
  $searchedCountries,
  citizenshipForm,
  sendCitizenship,
  setSumSubSuccess,
  goBackTo,
  goBackFromOTP,
  sendPersonalDetails,
  checkPersonalDetails,
  setEdmDocUrls,
  setHeaderVisibility,
  $headerVisibility,
  setCompletedSumSub,
  $completedSumSub,
  goToStepFromFourth,
  goToStepFromThird,
  $repeatedSumSub,
  setRepeatedSumSub,
  $countAttempts,
  $numberStepper,
  setNumberStepper,
  onStepperChange,
  $isOpenAttemptsModal,
  setIsOpenAttemptsModal,
  $processResult,
  setSumSubToken,
  $isRedRetrySumSub,
  setRedRetrySumSub,
  $edmIsBlocked,
  setEdmIsBlocked,
  $isV1Failed,
  $isV2Failed,
  $isZipcodeInvalid,
  $sumSubError,
  changeSumSubError,
  $taskDefinitionKey,
  changeTaskDefinitionKey,
  handleManualStepNavigation,
};
