import React from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import { useStore } from "effector-react";

import history from "~/configs/history";
import { externalRoutes, links } from "~/constants/enums";
import ContentWrapperViewport from "~/features/ContentWrapperViewport";
import { useFooterStyles } from "~/features/Footer/footerStyles";
import { useTranslation } from "~/i18n";
import { loginModel } from "~/modules/keycloak/login-model";
import { viewportFixedButtonService } from "~/modules/viewport-fixed-button-model";
import Chat from "~/pages/Chat/Chat";
import { edmService } from "~/pages/EDM/services/edm-services";
import Icon from "~/shared/ui-kit/constants/icons/Icon";

export const Footer = () => {
  const { t } = useTranslation();

  const { $isLogin } = loginModel;
  const { $isEdm } = edmService;
  const { $isVisibleViewportFixedButton } = viewportFixedButtonService;

  const isVisibleViewportFixedButton = useStore($isVisibleViewportFixedButton);
  const isLogin = useStore($isLogin);
  const isEdm = useStore($isEdm);

  const phone = process.env.REACT_APP_SUPPORT_TELEPHONE;
  const email = isLogin ? process.env.REACT_APP_SUPPORT_EMAIL : process.env.REACT_APP_INFO_EMAIL;

  const { classes } = useFooterStyles();

  return (
    <ContentWrapperViewport>
      {isLogin && !isEdm ? (
        <Box className={classes.topBlock}>
          <div className={classes.menu}>
            <div
              className={cn(classes.menuItem, classes.hoverEffect)}
              onClick={() => history.push(links.operationsAssets)}
            >
              {t("MENU.HEADER.operations")}
            </div>
            <div className={cn(classes.menuItem, classes.hoverEffect)} onClick={() => history.push(links.forYouCommon)}>
              {t("MENU.HEADER.forYou")}
            </div>
            <div className={cn(classes.menuItem, classes.hoverEffect)} onClick={() => history.push(links.servicesAll)}>
              {t("MENU.HEADER.services")}
            </div>
          </div>
          <Box className={classes.phoneWrapper}>
            <a href={`tel:${phone}`} className={cn(classes.phone, classes.hoverEffect)}>
              {phone}
            </a>
            <p className={classes.operatingMode}>{t("GENERAL.operating-mode")}</p>
          </Box>
        </Box>
      ) : (
        <Box className={classes.contactWrapperSlim}>
          <Box className={classes.phoneWrapper}>
            <a href={`tel:${phone}`} className={cn(classes.phone, classes.hoverEffect)}>
              {phone}
            </a>
          </Box>
          <p className={classes.operatingMode}>{t("GENERAL.operating-mode")}</p>
        </Box>
      )}
      <Box className={classes.middleBlock}>
        <Box className={classes.applicationWrapper}>
          <p className={classes.socialTitle}>{t("GENERAL.application")}</p>
          <Box className={classes.applicationsList}>
            <a className={classes.footerLink} href={externalRoutes.appleMarket}>
              <Icon size={32} name="appleApp" />
            </a>
            <a className={classes.footerLink} href={externalRoutes.android}>
              <Icon size={24} name="androidApp" />
            </a>
          </Box>
        </Box>
        <Box className={classes.socialsWrapper}>
          <p className={classes.socialTitle}>{t("GENERAL.social-media")}</p>
          <Box className={classes.socialList}>
            <a className={classes.footerLink} href={externalRoutes.faceBook}>
              <Icon size={24} name="facebook" />
            </a>
            <a className={classes.footerLink} href={externalRoutes.instagram}>
              <Icon size={24} name="instagram" />
            </a>
          </Box>
        </Box>
        <Box className={classes.emailWrapper}>
          <p className={classes.socialTitle}>{t("GENERAL.support")}</p>
          <a href={`mailto:${email}`} className={cn(classes.email, classes.hoverEffect)}>
            {email}
          </a>
        </Box>
      </Box>
      <Box className={classes.bottomBlock}>
        <a className={cn(classes.link, classes.hoverEffect)} href={externalRoutes.terms}>
          {t("OPERATIONS.titles.footerLink")}
        </a>
      </Box>
      <Box className={classes.underFooterTextBlock} mb={isVisibleViewportFixedButton ? "130px" : 0}>
        <div className={classes.footerText} dangerouslySetInnerHTML={{ __html: t("OPERATIONS.titles.footer") }} />
        <div
          className={classes.footerText}
          dangerouslySetInnerHTML={{ __html: t("OPERATIONS.titles.footer_add_new") }}
        />
        <div
          className={classes.footerText}
          dangerouslySetInnerHTML={{ __html: t("OPERATIONS.titles.footer_add_new_2") }}
        />
      </Box>
      {isLogin && <Chat />}
    </ContentWrapperViewport>
  );
};
