import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()(() => ({
  blackButton: {
    minWidth: "135px",
    [themeV5.breakpoints.down(768)]: {
      width: "100%",
    },
  },
  alignedText: {
    textAlign: "center",
    fontSize: "16px",
    color: colorTable.textSecondary,
    marginTop: "3px",
    "& a": {
      textDecoration: "none",
      color: colorTable.charcoalBlack,
      fontWeight: 600,
    },
  },
  errorCode: {
    textAlign: "center",
    color: colorTable.textSecondary,
    marginTop: "16px",
  },
  code: {
    fontSize: "16px",
    color: colorTable.green_50,
    fontWeight: 600,
    textDecoration: "underline",
  },
}));
