import { combineReducers } from 'redux';

import accountRequest, {
  AccountRequestState,
  STATE_NAME as ACCOUNT_REQUEST_STATE_NAME,
} from './reducers/accountRequest';
import printForms, { AccountPrintFormsState, STATE_NAME as PRINT_FORMS_STATE_NAME } from './reducers/printForms';

export const STATE_NAME = 'account';

export interface AccountState {
  [PRINT_FORMS_STATE_NAME]: AccountPrintFormsState;
  [ACCOUNT_REQUEST_STATE_NAME]: AccountRequestState;
}

export default combineReducers<AccountState>({
  [PRINT_FORMS_STATE_NAME]: printForms,
  [ACCOUNT_REQUEST_STATE_NAME]: accountRequest,
});
