import FormularOtf from "~/assets/fonts/BrownfoxFormular.otf";
import FormularBoldOtf from "~/assets/fonts/BrownfoxFormularBold.otf";
import FormularLightOtf from "~/assets/fonts/BrownfoxFormularLight.otf";
import FormularMediumOtf from "~/assets/fonts/BrownfoxFormularMedium.otf";
import FormularTtf from "~/assets/fonts/Formular.ttf";
import FormularWoff from "~/assets/fonts/Formular.woff";
import FormularBoldTtf from "~/assets/fonts/FormularBold.ttf";
import FormularBoldWoff from "~/assets/fonts/FormularBold.woff";
import FormularLightTtf from "~/assets/fonts/FormularLight.ttf";
import FormularLightWoff from "~/assets/fonts/FormularLight.woff";
import FormularMediumTtf from "~/assets/fonts/FormularMedium.ttf";
import FormularMediumWoff from "~/assets/fonts/FormularMedium.woff";

const formular = {
  fontFamily: "Formular",
  fontWeight: 400,
  src: `
    url(${FormularWoff}) format('woff'),
    url(${FormularOtf}) format('opentype'),
    url(${FormularTtf}) format('truetype')
  `,
};

const formularBold = {
  fontFamily: "Formular",
  fontWeight: 600,
  src: `
    url(${FormularBoldWoff}) format('woff'),
    url(${FormularBoldOtf}) format('opentype'),
    url(${FormularBoldTtf}) format('truetype')
  `,
};

const formularLight = {
  fontFamily: "Formular",
  fontWeight: 100,
  src: `
    url(${FormularLightWoff}) format('woff'),
    url(${FormularLightOtf}) format('opentype'),
    url(${FormularLightTtf}) format('truetype')
  `,
};

const formularMedium = {
  fontFamily: "Formular",
  fontWeight: 500,
  src: `
    url(${FormularMediumWoff}) format('woff'),
    url(${FormularMediumOtf}) format('opentype'),
    url(${FormularMediumTtf}) format('truetype')
  `,
};

export default [formular, formularBold, formularLight, formularMedium];
