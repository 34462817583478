import { ValidationError } from "yup";
import { AppError } from "./AppError";

export class AppValidationError extends AppError<ValidationError> {
  public readonly type: string = "AppValidationError";
  public readonly error: ValidationError;

  public static is = (value: unknown): value is AppValidationError => !!value && value instanceof AppValidationError;

  public constructor(error: ValidationError, message?: string) {
    super(error, message);

    this.error = error;
  }
}
