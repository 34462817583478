import { AppError } from "./AppError";

export enum AppLogicErrorVariant {
  Default,
  Otp,
  Unreachable,
}

export class AppLogicError extends AppError {
  public readonly type: string = "AppLogicError";
  public readonly variant: AppLogicErrorVariant;

  public static is = (value: unknown): value is AppLogicError => !!value && value instanceof AppLogicError;

  public constructor(variant: AppLogicErrorVariant, message?: string) {
    super(undefined, message);

    this.variant = variant;
  }
}
