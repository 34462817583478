import { makeStyles } from "tss-react/mui";

import theme from "~/configs/theme";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";
import { tableFont } from "~/shared/ui-kit/constants/typography";

export const useStyles = makeStyles()(() => ({
  textField: {
    all: "unset",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  outlined: {
    all: "unset",
    display: "none",
  },
  label: {
    all: "unset",
    ...tableFont.subhead,
    color: colorTable.moonGrey,
    width: "fit-content",
    marginBottom: "8px",
    "&.Mui-focused": {
      color: colorTable.moonGrey,
    },
    "&.Mui-disabled": {
      color: colorTable.moonGrey,
    },
    "&.Mui-error": {
      color: colorTable.moonGrey,
    },
  },
  helperText: {
    all: "unset",
    ...tableFont.caption,
    color: colorTable.moonGrey,
    paddingTop: "8px",
    textAlign: "start",

    "&.Mui-error": {
      color: colorTable.red,
    },

    "&.Mui-disabled": {
      color: colorTable.moonGrey,
    },
  },
  inputWrapper: {
    all: "unset",
    border: `1px solid ${borderColors.greyContour}`,
    borderRadius: "4px",
    backgroundColor: colorTable.white,
    display: "flex",
    textAlign: "start",
    alignItems: "center",
    maxHeight: "48px",
    boxSizing: "border-box",
    padding: "14px 12px 14px 16px",
    ...tableFont.subhead,

    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    },

    "&:hover": {
      border: `1px solid ${borderColors.nobleOchre}`,
      background: colorTable.white,
    },

    "&.Mui-focused": {
      borderColor: colorTable.nobleOchre,
    },

    "&.Mui-disabled": {
      borderColor: colorTable.greyContour,
      backgroundColor: colorTable.lightGrey,
      cursor: "not-allowed",
    },

    "&.Mui-error": {
      borderColor: colorTable.red,
    },
  },
  input: {
    all: "unset",
    width: "100%",
    textOverflow: "ellipsis",
    ...tableFont.subhead,
    color: colorTable.charcoalBlack,

    "&.MuiInputBase-inputAdornedStart": {
      paddingLeft: "8px",
    },

    "&.MuiInputBase-inputAdornedEnd": {
      paddingRight: "8px",
    },

    "&::placeholder": {
      all: "unset",
      opacity: 1,
      color: colorTable.moonGrey,
      ...tableFont.subhead,
    },

    "&.Mui-disabled": {
      color: colorTable.moonGrey,
    },
  },
  endIcon: {
    width: "24px",
    height: "24px",
    [theme.breakpoints.down(768)]: {
      width: "20px",
      height: "20px",
    },
  },
  endBtn: {
    [theme.breakpoints.down(768)]: {
      padding: "7px",
    },
  },
}));
