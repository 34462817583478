import React, { KeyboardEventHandler, ReactNode, useEffect, useRef, useState } from "react";
import { Box, InputLabel, Tooltip } from "@mui/material";
import cn from "classnames";

import TextField from "~/shared/ui/TextField";
import { useStyles } from "./styles";

export interface BaseInputProps {
  name: string;
  style?: object;
  styleWrapper?: object;
  value: string | null;
  onChange?: any | (() => any);
  onBlur?: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  label?: string | ReactNode;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  className?: string;
  inputProps?: object;
  FormHelperTextProps?: object;
  placeholder?: string;
  classNameInput?: string;
}

export const BaseInput = React.forwardRef((props: BaseInputProps, ref: any) => {
  const { classes } = useStyles();

  const inputRef = useRef<HTMLInputElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const checkOverflow = () => {
    const input = inputRef.current;
    if (input) {
      setIsOverflowing(input.scrollWidth > input.offsetWidth);
    }
  };

  useEffect(() => {
    checkOverflow();
  }, []);

  const handleMouseEnter = () => {
    checkOverflow();
    if (isOverflowing && props.disabled) {
      setTooltipOpen(true);
    }
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
  };

  const handleTouchStart = () => {
    checkOverflow();
    if (isOverflowing && props.disabled) {
      setTooltipOpen(true);
    }
  };

  const handleTouchEnd = () => {
    setTimeout(() => setTooltipOpen(false), 2000); // Keep tooltip open for 2 seconds after touch ends
  };

  return (
    <Box className={cn(classes.wrapperInput, props.className)} style={props?.styleWrapper}>
      <InputLabel className={classes.inputLabel}>{props?.label}</InputLabel>
      <Tooltip
        style={props.style}
        title={isOverflowing && props.disabled ? (props.value ?? "") : ""}
        open={tooltipOpen}
        disableHoverListener
        disableTouchListener
        disableFocusListener
      >
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <TextField
            ref={ref}
            className={cn(classes.textField, props.classNameInput)}
            name={props?.name}
            value={props?.value}
            error={props?.error}
            onChange={props?.onChange}
            onBlur={props?.onBlur}
            onKeyDown={props?.onKeyDown}
            fullWidth
            helperText={props?.helperText}
            FormHelperTextProps={props?.FormHelperTextProps}
            disabled={props?.disabled}
            InputProps={{
              ...props?.inputProps,
              inputProps: {
                ref: inputRef,
              },
            }}
            placeholder={props.placeholder}
          />
        </div>
      </Tooltip>
    </Box>
  );
});

BaseInput.defaultProps = {
  name: "",
  style: {},
  styleWrapper: {},
  value: "",
  onChange: () => {},
  onBlur: () => {},
  onKeyDown: () => {},
  label: "",
  helperText: "",
  error: false,
  disabled: false,
};
