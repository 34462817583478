import React, { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { CircularProgress, InputAdornment, InputLabel, MenuItem, Paper, TextField, useMediaQuery } from "@mui/material";
import cn from "classnames";
import Downshift from "downshift";
import { omit } from "lodash";

import Icon from "~/shared/Icon";
import { AutocompleteItem, AutocompleteItemT } from "~/shared/ui/Autocomplete/components/AutocompleteItem";
import { TextFieldProps } from "~/shared/ui/TextField";
import { useStyles } from "./styles";

interface AutocompleteProps {
  items?: AutocompleteItemT[];
  onItemSelect: any | (() => any);
  pending?: boolean;
  onInputValueChange?: (val: string) => void;
  clearOnBlur?: () => void;
  hideOnLimit?: number;
  hideOnLimitText?: string;
  isNotLatin?: boolean;
  label: string;
  styleWrapper?: object;
}

const itemToString = (item: AutocompleteItemT | null): string => (item ? item.label : "");

export const BaseAutocomplete = React.forwardRef((props: AutocompleteProps & TextFieldProps, ref: any) => {
  const {
    onItemSelect,
    items,
    pending,
    onInputValueChange,
    value,
    clearOnBlur,
    hideOnLimit,
    hideOnLimitText,
    isNotLatin,
    label,
    className,
    styleWrapper,
  } = props;
  const { classes } = useStyles();

  const isTabletOrMobile = useMediaQuery("(max-width: 768px)");

  const [innerValue, setInnerValue] = useState<any>(value ?? "");
  const [innerItems, setInnerItems] = useState<AutocompleteItemT[] | undefined>(undefined);

  useEffect(() => {
    setInnerItems(items);
  }, [items]);

  useEffect(() => {
    setInnerValue(value);
    if (!value) handleSelect(null);
    if (onInputValueChange) onInputValueChange(value as string);
  }, [value]);

  const handleSelect = (selectedValue: AutocompleteItemT | null) => {
    onItemSelect(selectedValue);
    setInnerValue(selectedValue?.label ?? "");
    setInnerItems(undefined);
  };

  return (
    <Downshift itemToString={itemToString} onSelect={handleSelect}>
      {({
        getInputProps,
        clearSelection,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        isOpen,
        openMenu,
        closeMenu,
      }): ReactNode => {
        const handleOnChagne = (val: string) => {
          setInnerValue(val);
          if (onInputValueChange) onInputValueChange(val);
        };

        const handleOnFocus = () => {
          setInnerItems(items);
          if (onInputValueChange) onInputValueChange(value as string);
          openMenu();
        };

        const handleOnBlur = () => {
          if (clearOnBlur) clearOnBlur();
          if (value !== innerValue) setInnerValue(value);
          if (!value) handleSelect(null);
          closeMenu();
        };

        const menuProps = getMenuProps();
        const inputProps = getInputProps({
          onChange: (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.value === "") {
              clearSelection();
            }
          },
          onFocus: handleOnFocus,
          onBlur: handleOnBlur,
          value: innerValue,
        });

        return (
          <div className={cn(classes.container, className)} style={styleWrapper}>
            <InputLabel className={classes.inputLabel}>{label}</InputLabel>
            <TextField
              ref={ref}
              {...props}
              {...omit(inputProps, ["ref", "size"])}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (
                  !isNotLatin &&
                  !/^[a-zA-Z0-9!@"'#$%:^,&.*;)(_+=\-\s\\/]*$/.test(e.target.value) &&
                  !!e.target.value
                ) {
                  return;
                }
                if (onInputValueChange) handleOnChagne(e.target.value);
                inputProps.onChange(e);
              }}
              label=""
              className={cn(classes.textFieldInput, props.className)}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {pending && <CircularProgress size={20} />}
                    <Icon
                      name="selectArrow"
                      size={10}
                      className={cn(classes.arrow, { [classes.arrowRotate]: isOpen })}
                    />
                  </InputAdornment>
                ),
              }}
              color="primary"
            />
            <div {...menuProps}>
              {isOpen && !pending && innerItems ? (
                <Paper className={classes.paper} square>
                  <AutocompleteItem
                    list={hideOnLimit ? innerItems.slice(0, hideOnLimit) : innerItems}
                    highlightedIndex={highlightedIndex}
                    getItemProps={getItemProps}
                  />
                  <MenuItem disabled style={{ fontSize: "11px", textOverflow: "ellipsis" }}>
                    <span className={cn(isTabletOrMobile && classes.mobileHelperText)}>{hideOnLimitText}</span>
                  </MenuItem>
                </Paper>
              ) : null}
            </div>
          </div>
        );
      }}
    </Downshift>
  );
});
