import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Tooltip } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import cn from "classnames";

import useStyles from "./styles";

export type BaseInputProps = Omit<TextFieldProps<"outlined">, "variant"> & {
  fieldRef?: React.RefObject<HTMLDivElement | null>;
  isAllSymbols?: boolean;
  wrapperClassname?: string;
};

const notLatin = /[а-яА-ЯёЁ]+/g;

export default (props: BaseInputProps) => {
  const { isAllSymbols, wrapperClassname, ...inputProps } = props;
  const { classes } = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useImperativeHandle(props.fieldRef, () => ref.current);

  const onInput = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!isAllSymbols) {
      (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.replace(notLatin, "");
    }
    props.InputProps?.inputProps?.onInput && props.InputProps?.inputProps?.onInput(e);
  };

  const checkOverflow = () => {
    const input = props.InputProps?.inputProps?.ref?.current || inputRef?.current;

    if (input) {
      const scrollWidth = input.scrollWidth - 1;

      setIsOverflowing(scrollWidth > input.offsetWidth);
    }
  };

  useEffect(() => {
    checkOverflow();
  }, []);

  const handleMouseEnter = () => {
    checkOverflow();
    if (isOverflowing && props.disabled) {
      setTooltipOpen(true);
    }
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
  };

  const handleTouchStart = () => {
    checkOverflow();
    if (isOverflowing && props.disabled) {
      setTooltipOpen(true);
    }
  };

  const handleTouchEnd = () => {
    setTimeout(() => setTooltipOpen(false), 2000); // Keep tooltip open for 2 seconds after touch ends
  };

  return (
    <Tooltip
      style={props.style}
      title={isOverflowing && props.disabled ? ((inputProps.value || inputProps?.inputProps?.value) ?? "") : ""}
      open={tooltipOpen}
      className={cn(classes.tooltipWrapper, wrapperClassname)}
      disableHoverListener
      disableTouchListener
      disableFocusListener
    >
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <TextField
          autoComplete="off"
          {...inputProps}
          ref={ref}
          className={cn(classes.textField, props.className)}
          InputLabelProps={{
            ...props.InputLabelProps,
            className: cn(classes.label, props.InputLabelProps?.className),
            shrink: false,
          }}
          FormHelperTextProps={{
            ...props.FormHelperTextProps,
            className: cn(classes.helperText, props.FormHelperTextProps?.className),
          }}
          variant="outlined"
          InputProps={{
            ...props.InputProps,
            inputProps: {
              ...props.InputProps?.inputProps,
              className: cn(classes.input, props.InputProps?.inputProps?.className),
              onInput,
              ref: props?.InputProps?.inputProps?.ref || inputRef,
            },
            className: cn(classes.inputWrapper, props.InputProps?.className),
          }}
        />
      </div>
    </Tooltip>
  );
};
