import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, InputLabel, Typography } from "@mui/material";
import cn from "classnames";
import { useGate } from "effector-react";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "~/i18n";
import TextField from "~/shared/ui/TextField";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";
import { CountDown } from "./CountDown";

export interface SmsInputProps {
  pending?: boolean;
  hasError?: boolean;
  errorText?: string;
  otpValue?: string;
  effectorGate?: any;
  resend?: () => void;
  changeOtpCode?: (val: string) => void;
  color?: "inherit" | "primary" | "secondary" | "default";
  textColor?: string;
  textClassName?: string;
  name?: string;
}

const useStyles = makeStyles()({
  inputLabel: {
    color: colorTable.darkGrey_10,
    textAlign: "left",
    marginBottom: "8px",
    fontSize: "14px",
    lineHeight: "20px",
  },
  inputMobile: {
    "& .MuiFilledInput-root": {
      width: "100%",
      height: "48px",
      borderRadius: "4px",
      background: colorTable.white,
      border: `1px solid ${borderColors.lightGrey_50}`,

      "&.Mui-error": {
        border: `1px solid ${borderColors.red}`,
      },

      "&.Mui-disabled": {
        background: colorTable.white_20,
      },
    },

    "& .MuiFilledInput-root .MuiInputBase-input": {
      fontSize: "14px",
      lineHeight: "20px",
      padding: "0 0 0 16px",
    },
  },
  sendAgain: {
    fontSize: "14px",
    color: colorTable.charcoalBlack,
    lineHeight: "20px",
    cursor: "pointer",
  },
});

export const SmsInput = (props: SmsInputProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [counter, setCounter] = useState(60);

  const { effectorGate, errorText, hasError, otpValue, pending, changeOtpCode, resend, textClassName, name } = props;

  effectorGate && useGate(effectorGate);

  useEffect(() => {
    return () => {
      handleChange("");
    };
  }, []);

  const handleChange = (val: string) => {
    if (changeOtpCode) changeOtpCode(val);
  };

  const handleResend = () => {
    if (resend) {
      resend();
      setCounter(60);
    }
  };

  const onChange = (func: any, value: string) => {
    if (!/^[0-9]*$/.test(value) || (!!value && !(value.length < 7))) {
      return;
    }

    func(value.replace(/\s/g, ""));
  };

  return (
    <Box>
      <Box mb={2}>
        <InputLabel className={classes.inputLabel}>{t("SMS_CONFIRM.labels.sms-sent")}</InputLabel>
        <TextField
          autoComplete="one-time-code"
          name={name}
          type="text"
          className={classes.inputMobile}
          fullWidth
          value={otpValue}
          error={hasError}
          disabled={pending}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(handleChange, e.target.value)}
          helperText={t(errorText || "")}
        />
      </Box>
      {counter ? (
        <CountDown counter={counter} setCounter={setCounter} />
      ) : (
        <Box mb={3} onClick={handleResend}>
          <Typography className={cn(classes.sendAgain, textClassName)}>{t("SMS_CONFIRM.actions.new-code")}</Typography>
        </Box>
      )}
    </Box>
  );
};
