import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStrategyStyles = makeStyles()(() => ({
  RadioItem: {
    background: colorTable.lightGrey,
    padding: "24px",
    borderRadius: "4px",
  },
  titleText: {
    fontWeight: "600 !important",
    fontSize: "20px !important",
    lineHeight: "28px !important",
    color: `${colorTable.charcoalBlack} !important`,
  },
  labelText: {
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: `${colorTable.charcoalBlack} !important`,
  },
}));
