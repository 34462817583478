import axios from "axios";

import {
  appResponseErrorInterceptor,
  checkTechnicalWorksInterceptor,
  keycloakResponseModelInterceptor,
  refreshTokenInterceptor,
} from "./interceptors";
import { request } from "./request";
import { ContentType, RequestData, RequestOptions } from "./types";
import { appApiUrl, clearData } from "./utils";

export { appApiUrl, clearData, ContentType };

const instance = axios.create();

// interceptors
const keycloakResponseModelInterceptorId = keycloakResponseModelInterceptor(instance);
const appResponseErrorInterceptorId = appResponseErrorInterceptor(instance);
const refreshTokenInterceptorId = refreshTokenInterceptor(instance);
const checkTechnicalWorksInterceptorId = checkTechnicalWorksInterceptor(instance);

if (module.hot) {
  module.hot.dispose((): void => {
    instance.interceptors.request.eject(checkTechnicalWorksInterceptorId);
    instance.interceptors.request.eject(keycloakResponseModelInterceptorId);
    instance.interceptors.request.eject(appResponseErrorInterceptorId);
    instance.interceptors.request.eject(refreshTokenInterceptorId);
  });
}

export const get = (url: string, data?: RequestData, options?: RequestOptions): Promise<any> =>
  request(instance, "get", url, data, options);

export const post = (url: string, data?: RequestData, options?: RequestOptions): Promise<any> =>
  request(instance, "post", url, data, options);

export const put = (url: string, data?: RequestData, options?: RequestOptions): Promise<any> =>
  request(instance, "put", url, data, options);

export const del = (url: string, data?: RequestData, options?: RequestOptions): Promise<any> =>
  request(instance, "delete", url, data, options);
