import { FeedbackRequestDto } from "~/entities/feedback/send-message/types";
import { getUserTokenInfo } from "~/modules/userToken/api";
import { ContentType, post } from "~/lib/request";

export const sendFeedbackMessage = async (requestData: FeedbackRequestDto) => {
  const uploadData = {
    ...requestData,
    clientId: getUserTokenInfo().id,
    clientLogin: getUserTokenInfo().login,
  };
  const res = await post(
    "@auth/notifier/notifications/ops/feedback",
    {
      data: uploadData,
    },
    { contentType: ContentType.FormData }
  );
  return res.data;
};
