import { makeStyles } from "tss-react/mui";

export const useSourceOfincomeSectionStyles = makeStyles()(() => ({
  warning: {
    marginBottom: "40px",
  },
  docWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "8px",
    marginTop: "10px",
  },
}));
