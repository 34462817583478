import { combineReducers } from "redux";

import account, { AccountState, STATE_NAME as ACCOUNT_STATE_NAME } from "~/modules/account/reducer";
import anketa, { AnketaState, STATE_NAME as ANKETA_STATE_NAME } from "~/modules/anketa/reducer";
import app, { AppState, STATE_NAME as APP_STATE_NAME } from "~/modules/app/reducer";
import auth, { AuthState, STATE_NAME as AUTH_STATE_NAME } from "~/modules/auth/reducer";
import banks, { BanksState, STATE_NAME as BANKS_STATE_NAME } from "~/modules/banks/reducer";
import clientCard, { ClientCardState, STATE_NAME as CLIENT_CARD_STATE_NAME } from "~/modules/client-card/reducer";
import keycloak, { KeycloakState, STATE_NAME as KEYCLOAK_STATE_NAME } from "~/modules/keycloak/reducer";
import offer, { OfferState, STATE_NAME as OFFER_STATE_NAME } from "~/modules/offer/reducer";
import product, { ProductState, STATE_NAME as PRODUCT_STATE_NAME } from "~/modules/product/reducer";
import requests, { RequestsState, STATE_NAME as REQUESTS_STATE_NAME } from "~/modules/requests/reducer";

export interface RootState {
  [APP_STATE_NAME]: AppState;
  [AUTH_STATE_NAME]: AuthState;
  [KEYCLOAK_STATE_NAME]: KeycloakState;
  [CLIENT_CARD_STATE_NAME]: ClientCardState;
  [ANKETA_STATE_NAME]: AnketaState;
  [ACCOUNT_STATE_NAME]: AccountState;
  [REQUESTS_STATE_NAME]: RequestsState;
  [OFFER_STATE_NAME]: OfferState;
  [PRODUCT_STATE_NAME]: ProductState;
  [BANKS_STATE_NAME]: BanksState;
}

export default combineReducers<RootState>({
  [APP_STATE_NAME]: app,
  [AUTH_STATE_NAME]: auth,
  [KEYCLOAK_STATE_NAME]: keycloak,
  [CLIENT_CARD_STATE_NAME]: clientCard,
  [ANKETA_STATE_NAME]: anketa,
  [ACCOUNT_STATE_NAME]: account,
  [REQUESTS_STATE_NAME]: requests,
  [OFFER_STATE_NAME]: offer,
  [PRODUCT_STATE_NAME]: product,
  [BANKS_STATE_NAME]: banks,
});
