import React, { FunctionComponent, memo, ReactElement } from "react";

import { ChangePasswordForm } from "~/features/UnauthAndBlocked";

export interface ChangeOrResetPasswordProps {
  variant?: "login" | "profile";
  isRequiredToSetSecurityQuestion: boolean;
  children?: never;
}

const ChangeOrResetPassword: FunctionComponent<ChangeOrResetPasswordProps> = (): ReactElement | null => {
  return <ChangePasswordForm />;
};

ChangeOrResetPassword.defaultProps = {
  variant: "login",
};

export default memo(ChangeOrResetPassword);
