import { AxiosInstance, AxiosRequestConfig } from "axios";

export { AxiosInstance, AxiosRequestConfig };

export type RequestMethod = "get" | "post" | "put" | "delete";

export enum ContentType {
  FormUrlencoded = "application/x-www-form-urlencoded",
  FormData = "multipart/form-data",
  JSON = "application/json;charset=UTF-8",
  PDF = "application/pdf",
}

export interface DataObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface RequestData {
  data?: DataObject;
  params?: DataObject;
}

export interface RequestOptions {
  isBlob?: boolean;
  headers?: DataObject;
  opts?: Partial<AxiosRequestConfig>;
  contentType?: ContentType;
  authorizationBearerToken?: string;
  isPrivate?: boolean;
  delay?: number;
}
