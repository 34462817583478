import React, { useMemo } from "react";
import { Box, InputLabel, Typography } from "@mui/material";
import cn from "classnames";
import { useForm } from "effector-forms";
import { useStore } from "effector-react";
import { makeStyles } from "tss-react/mui";

import { PasswordRules } from "~/features/UnauthAndBlocked/ResetPasswordForm/types";
import { useTranslation } from "~/i18n";
import { maxLengthPassword } from "~/lib/validation/rules";
import { validateNonLeadingSpaces } from "~/lib/validation/utils";
import { UnblockingUserSteps } from "~/modules/keycloak/unblocking-user-model/steps-enum";
import { unblockingModel } from "~/modules/keycloak/unblocking-user-model/unblocking-user-model";
import { authStyles } from "~/pages/UnauthAndBlocked/Login/screens/LoginScreen/AuthStyles";
import PasswordIsValid from "~/shared/Icon/icons/password_done_icon.svg";
import PasswordIsEmptyRed from "~/shared/Icon/icons/password_empty_icon_red.svg";
import PasswordField from "~/shared/ui/PasswordField";
import TextField from "~/shared/ui/TextField";
import { BaseButton } from "~/shared/ui-kit/BaseComponents";
import colorTable from "~/shared/ui-kit/constants/colors";

const useStyles = makeStyles()({
  label: {
    fontSize: 16,
    color: colorTable.black,
    opacity: 0.86,
    letterSpacing: "0.2px",
  },
  question: {
    fontSize: 16,
    letterSpacing: "0.3px",
    textAlign: "center",
    color: colorTable.black,
    height: "auto",
    background: colorTable.white_10,
    borderRadius: 8,
    lineHeight: "24px",
  },
  questionMobile: {
    fontSize: 32,
    letterSpacing: "0.3px",
    textAlign: "center",
    color: colorTable.black,
    height: "auto",
    background: colorTable.white_10,
    borderRadius: 8,
    lineHeight: "48px",
    paddingTop: "21px",
  },
  mobileInput: {
    "& label": {
      fontSize: "30px",
    },
    "& div": {
      height: "12vw",
      fontSize: "40px",
    },
    "& div:hover": {
      background: colorTable.white,
    },
    "& input": {
      height: "100%",
    },
    "& div > button > span > svg": {
      width: "50px",
      height: "50px",
    },
    "& p": {
      fontSize: "32px",
    },
  },
  mobileButton: {
    height: "12vw",
    fontSize: "40px",
  },
  labelMobile: {
    fontSize: "30px",
  },
  inputLabel: {
    color: colorTable.misteryBlue,
    textAlign: "left",
    marginBottom: "8px",
    fontSize: 14,
  },
  helperLabel: {
    display: "flex",
    color: colorTable.lightGrey_90,
    textAlign: "left",
    marginBottom: "8px",
    fontSize: 12,
  },
  helperLabelMobile: {
    display: "flex",
    color: colorTable.lightGrey_90,
    textAlign: "left",
    marginBottom: "16px",
    fontSize: 24,
  },
  imageMargin: {
    marginRight: "8px",
  },
  imageMarginMobile: {
    marginRight: "8px",
    width: "32px",
    height: "32px",
  },
  errorHelper: {
    color: colorTable.red_10,
  },
  passwordIsValid: {
    color: colorTable.green_30,
  },
  PasswordIsEmptyRed: {
    color: colorTable.lightGrey_90,
  },
  newPasswordText: {
    color: colorTable.blackDefault,
    fontSize: "16px",
    textAlign: "left",
  },
  newPasswordTextMobile: {
    color: colorTable.blackDefault,
    fontSize: "32px",
    textAlign: "left",
    marginTop: "42px",
    marginBottom: "32px",
  },
  errorText: {
    color: colorTable.red,
    fontSize: "12px",
    textAlign: "left",
  },
  errorTextMobile: {
    color: colorTable.red,
    fontSize: "24px",
  },
});

const EnterAnswerForm = () => {
  const { t, i18n } = useTranslation();
  const { classes: authClasses } = authStyles();
  const { classes } = useStyles();

  const {
    formUserAnswer,
    $question,
    $invalidAnswer,
    $enterNewPasswordAndSecurityAnswerFxPending,
    setStep,
    setAnswerAndPassword,
    $checkStatusProcessAfterEnterNewPasswordFxPending,
  } = unblockingModel;
  const { submit, values, fields } = useForm(formUserAnswer);

  const question = useStore($question);
  const invalidAnswer = useStore($invalidAnswer);
  const enterNewPasswordAndSecurityAnswerFxPending = useStore($enterNewPasswordAndSecurityAnswerFxPending);
  const checkStatusProcessAfterEnterNewPasswordFxPending = useStore($checkStatusProcessAfterEnterNewPasswordFxPending);

  const onBack = () => setStep(UnblockingUserSteps.EnterOtpCode);

  const submitCallBack = () => {
    submit();
    setAnswerAndPassword();
  };

  const nextBtnDisabled = useMemo(() => {
    return (
      enterNewPasswordAndSecurityAnswerFxPending ||
      checkStatusProcessAfterEnterNewPasswordFxPending ||
      fields.answer.hasError() ||
      fields.newPassword.hasError() ||
      fields.confirmPassword.value !== fields.newPassword.value ||
      !fields.confirmPassword.value ||
      !fields.newPassword.value ||
      !fields.answer.value
    );
  }, [enterNewPasswordAndSecurityAnswerFxPending, checkStatusProcessAfterEnterNewPasswordFxPending, fields]);

  return (
    <form className={authClasses.form} noValidate>
      <Box className={authClasses.header}>
        <h2>{t("FORGOT_PASSWORD.question-descriptions")}</h2>
      </Box>
      <Box>
        <Typography className={cn(classes.label, classes.question)}>{question || "Контрольный вопрос"}</Typography>
      </Box>
      <Box mt={2}>
        <InputLabel className={classes.inputLabel}>{t("CHANGE_PASSWORD.labels.answer")}</InputLabel>
        <TextField
          securityAnswerInput
          className={authClasses.textField}
          name="answer"
          value={values.answer}
          error={fields.answer.hasError()}
          onChange={(e: { target: { value: string } }) =>
            fields.answer.onChange(validateNonLeadingSpaces(e.target.value))
          }
          fullWidth
          helperText={t(fields.answer.errorText())}
        />
        {invalidAnswer && (
          <Typography className={classes.errorText}>{t("CHANGE_PASSWORD.validation.invalid-answer")}</Typography>
        )}
      </Box>
      <Box mt={2} mb="16px">
        <InputLabel className={classes.inputLabel}>{t("CHANGE_PASSWORD.labels.new-password")}</InputLabel>
        <PasswordField
          className={authClasses.textField}
          name="password"
          value={values.newPassword}
          error={fields.newPassword.hasError()}
          onChange={(e) => fields.newPassword.onChange(e.target.value)}
          fullWidth
        />
      </Box>
      <Box mt={1}>
        <span className={classes.helperLabel}>
          <img
            src={
              fields.newPassword.errors.some((item) => item.rule.includes(PasswordRules.PASSWORD_SHORT)) ||
              !values.newPassword
                ? PasswordIsEmptyRed
                : PasswordIsValid
            }
            alt="AlertRound"
            className={classes.imageMargin}
          />
          <span
            className={cn({
              [classes.passwordIsValid]: !fields.newPassword.errors.some((item) =>
                item.rule.includes(PasswordRules.PASSWORD_SHORT)
              ),
              [classes.errorHelper]: fields.newPassword.errors.some((item) =>
                item.rule.includes(PasswordRules.PASSWORD_SHORT)
              ),
              [classes.PasswordIsEmptyRed]:
                !fields.newPassword.errors.some((item) => item.rule.includes(PasswordRules.PASSWORD_SHORT)) &&
                !values.newPassword,
            })}
          >
            {t("VALIDATION.short-password")}
          </span>
        </span>
      </Box>
      <Box mt={1} mb="16px">
        <span className={classes.helperLabel}>
          <img
            src={
              fields.newPassword.errors.some(
                (item) =>
                  item.rule.includes(PasswordRules.PASSWORD_LONG) && values.newPassword.length > maxLengthPassword
              )
                ? PasswordIsEmptyRed
                : PasswordIsValid
            }
            alt="AlertRound"
            className={classes.imageMargin}
          />
          <span
            className={cn({
              [classes.passwordIsValid]: !fields.newPassword.errors.some((item) =>
                item.rule.includes(PasswordRules.PASSWORD_LONG)
              ),
              [classes.errorHelper]: fields.newPassword.errors.some(
                (item) =>
                  item.rule.includes(PasswordRules.PASSWORD_LONG) && values.newPassword.length > maxLengthPassword
              ),
              [classes.PasswordIsEmptyRed]:
                !fields.newPassword.errors.some((item) => item.rule.includes(PasswordRules.PASSWORD_LONG)) &&
                !values.newPassword,
            })}
          >
            {t("VALIDATION.long-password")}
          </span>
        </span>
      </Box>
      <Box mt={1} mb="16px">
        <span className={classes.helperLabel}>
          <img
            src={
              fields.newPassword.errors.some((item) => item.rule.includes(PasswordRules.PASSWORD_IS_VALID)) ||
              !values.newPassword
                ? PasswordIsEmptyRed
                : PasswordIsValid
            }
            style={i18n.language === "ru" ? { marginTop: "-15px" } : {}}
            alt="AlertRound"
            className={classes.imageMargin}
          />
          <span
            className={cn({
              [classes.passwordIsValid]: !fields.newPassword.errors.some((item) =>
                item.rule.includes(PasswordRules.PASSWORD_IS_VALID)
              ),
              [classes.errorHelper]: fields.newPassword.errors.some((item) =>
                item.rule.includes(PasswordRules.PASSWORD_IS_VALID)
              ),
              [classes.PasswordIsEmptyRed]:
                !fields.newPassword.errors.some((item) => item.rule.includes(PasswordRules.PASSWORD_IS_VALID)) &&
                !values.newPassword,
            })}
          >
            {t("VALIDATION.weak-password")}
          </span>
        </span>
      </Box>
      <Box mt={2}>
        <InputLabel className={classes.inputLabel}>{t("CHANGE_PASSWORD.labels.confirm-password")}</InputLabel>
        <PasswordField
          className={authClasses.textField}
          name="confirmPassword"
          value={values.confirmPassword}
          error={fields.newPassword.hasError() || fields.confirmPassword.value !== fields.newPassword.value}
          helperText={
            fields.confirmPassword.value !== fields.newPassword.value &&
            t("CHANGE_PASSWORD.validation.passwords-not-match")
          }
          onChange={(e) => fields.confirmPassword.onChange(e.target.value)}
          fullWidth
        />
      </Box>
      <Box className={authClasses.buttonsWrapper} style={{ marginTop: "32px" }}>
        <Box>
          <BaseButton type="button" typeStyle="white" onClick={onBack} className={authClasses.formBtn}>
            {t("GENERAL.back")}
          </BaseButton>
        </Box>
        <Box mb={4}>
          <BaseButton
            className={cn(authClasses.buttonDesktop, authClasses.formBtn)}
            typeStyle="black"
            onClick={submitCallBack}
            disabled={nextBtnDisabled}
            isLoader={enterNewPasswordAndSecurityAnswerFxPending || checkStatusProcessAfterEnterNewPasswordFxPending}
          >
            <span>{t("GENERAL.enter")}</span>
          </BaseButton>
        </Box>
      </Box>
    </form>
  );
};

export default EnterAnswerForm;
