export const MONEY_FIELD_SIZE = 18;

export const AMOUNT_FIELD_SIZE = 6;

export const LOCALE_STORAGE_KEY = "d_locale";

export const TOKENS_STORAGE_KEY = "tokens";

export const ACCOUNT_REQUEST_STATUS = "account_status";

export const ACCOUNT_ASSET_REQUEST_STATUS = "account_asset_status";

export const DMA_US_MARKET_LOCATION_ID = "0a301d56-c951-11ed-814f-0050568f3c85";

export const CASH_USD_LOCATION_ID = "2a895c9b-55fe-11ed-814d-0050568f3c85";

export const CASH_EUR_LOCATION_ID = "86f6ae10-746d-11ed-814e-0050568f3c85";

export const US_MARKET_LOCATION_ID = "9770158d-d7c8-11ee-8155-0050568f3c85";

export const CLIENT_MONEY_TRADING_ACCOUNT_EUR = "86f6ae12-746d-11ed-814e-0050568f3c85";

export const CLIENT_MONEY_TRADING_ACCOUNT_USD = "0eeff59c-59e7-11ed-814d-0050568f3c85";
