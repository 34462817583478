import { BankResponse } from "~/entities/banks";

import { createAction, createAsyncActions } from "~/lib/redux";

export const [banksRequestAction, banksStartAction, banksSuccessAction, banksErrorAction] = createAsyncActions<
  BankResponse,
  { scopeId: string; searchText: string },
  Error,
  { scopeId: string }
>("BANKS", ({ scopeId }) => ({ scopeId }));

export const banksClearAction = createAction<{ scopeId: string }>("BANKS_CLEAR");
