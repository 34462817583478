import React, { ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  hidden: {
    display: "none",
  },
});

interface IVisibilityControlProps {
  hidden: boolean;
  children: ReactNode;
}

const VisibilityControl = ({ hidden, children }: IVisibilityControlProps) => {
  const { classes } = useStyles();
  return <div className={hidden ? classes.hidden : undefined}>{children}</div>;
};

export default VisibilityControl;
