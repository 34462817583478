import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import { links } from "~/constants/enums";
import { useTranslation } from "~/i18n";
import { useRouterHooks } from "~/lib/router";
import { authStyles } from "~/pages/UnauthAndBlocked/Login/screens/LoginScreen/AuthStyles";
import Icon from "~/shared/Icon";
import { BaseButton } from "~/shared/ui-kit/BaseComponents";
import colorTable from "~/shared/ui-kit/constants/colors";

interface IRegistrationSuccess {
  header: any;
  subTitle?: any;
}

const useStyles = makeStyles()((theme: Theme) => ({
  textMobile: {
    fontSize: "32px",
    textAlign: "center",
    lineHeight: "48px",
    color: colorTable.lightGrey_90,
  },
  text: {
    color: colorTable.lightGrey_90,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down(768)]: {
      marginTop: "64px",
      marginBottom: "64px",
      width: "100%",
    },
  },
  buttonMobile: {
    fontSize: "14px",
    height: "48px",
    background: colorTable.darkBlue,
    width: "100%",
    "&:hover": {
      background: colorTable.blue_10,
    },
    "&.MuiButton-contained": {
      boxShadow: "none",
    },
  },
}));

const RegistrationSuccess = ({ header, subTitle }: IRegistrationSuccess) => {
  const { classes } = authStyles();
  const { classes: localClasses } = useStyles();
  const { t } = useTranslation();
  const { navigate } = useRouterHooks();

  const goToLoginPage = () => {
    navigate(links.edm);
    window.location.reload();
  };

  const isTabletOrMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Box className={localClasses.wrapper}>
      <Icon name="SuccessRegister" size={70} />
      <Box mb="16px">
        <Typography
          style={isTabletOrMobile ? { marginTop: "16px", fontWeight: 600 } : { marginTop: "16px" }}
          className={classes.header}
        >
          {header}
        </Typography>
      </Box>
      <Box mb="32px">
        <Typography className={localClasses.text}>{subTitle}</Typography>
      </Box>
      <Box style={isTabletOrMobile ? { width: "100%" } : {}}>
        <BaseButton
          className={isTabletOrMobile ? localClasses.buttonMobile : classes.buttonDesktop}
          typeStyle="black"
          onClick={goToLoginPage}
          style={{ width: "100%" }}
        >
          {t("REGISTRATION.SUCCESSFUL_REGISTRATION.button")}
        </BaseButton>
      </Box>
    </Box>
  );
};

export default RegistrationSuccess;
