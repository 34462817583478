import React, { ReactElement } from "react";
import { CircularProgress } from "@mui/material";
import cn from "classnames";
import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

const useStyles = makeStyles()({
  withRightMargin: {
    marginRight: "10px",
  },
  withLeftMargin: {
    marginLeft: "20px",
  },
  colorPrimary: {
    display: "flex",

    "& .MuiCircularProgress-circleIndeterminate ": {
      color: colorTable.charcoalBlack,
    },
    "& .MuiCircularProgress-circle": {
      stroke: colorTable.charcoalBlack,
    },
  },
});

interface Props {
  withMargin?: boolean;
  withMarginLeft?: boolean;
  children?: never;
}

const ButtonLoader = ({ withMargin, withMarginLeft }: Props): ReactElement => {
  const { classes } = useStyles();
  return (
    <CircularProgress
      size={20}
      thickness={6}
      className={cn(
        { [classes.withLeftMargin]: withMarginLeft },
        { [classes.withRightMargin]: withMargin },
        classes.colorPrimary
      )}
    />
  );
};

export default ButtonLoader;
