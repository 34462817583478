import { createEvent, createStore } from "effector";

export interface headerThemeType {
  background: string;
  isDarkColorTheme: boolean;
  isHiddenTabMenu?: boolean;
  isStaticMobileHeader?: boolean;
}

const $headerTheme = createStore<headerThemeType>({
  background: "",
  isDarkColorTheme: false,
  isStaticMobileHeader: false,
  isHiddenTabMenu: false,
});

const changeHeaderTheme = createEvent<Partial<headerThemeType>>();

$headerTheme.on(changeHeaderTheme, (state, newState) => ({ ...state, ...newState }));

export const headerModel = {
  $headerTheme,
  changeHeaderTheme,
};
