import { createAsyncReducer } from '~/lib/redux';

import {
  keycloakResetPasswordClearAction,
  keycloakResetPasswordErrorAction,
  keycloakResetPasswordStartAction,
  keycloakResetPasswordSuccessAction,
} from '../actions';

export const STATE_NAME = 'resetPassword';

const [reducer, defaultState] = createAsyncReducer(
  keycloakResetPasswordStartAction,
  keycloakResetPasswordSuccessAction,
  keycloakResetPasswordErrorAction,
  keycloakResetPasswordClearAction,
);

export type KeycloakResetPasswordState = typeof defaultState;

export default reducer;
