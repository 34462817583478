import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    textAlign: "center",
  },
  progressContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1rem",
    maxWidth: "64rem",
    margin: "0 auto",
    position: "relative",
  },
  progressContainerMobile: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1rem",
    maxWidth: "100vw",
    position: "relative",
    width: "57rem",
  },
  progress: {
    backgroundColor: colorTable.lightGrey_100,
    height: "2px",
    left: "0",
    position: "absolute",
    top: "43%",
    width: "100%",
    zIndex: -1,
    [theme.breakpoints.down(768)]: {
      top: "43%",
    },
  },

  progressActiveFirst: {
    backgroundColor: colorTable.darkBlue,
    height: "2px",
    left: "0",
    position: "absolute",
    top: "43%",
    width: "30%",
    zIndex: -1,
  },
  progressActiveSecond: {
    backgroundColor: colorTable.darkBlue,
    height: "2px",
    left: "0",
    position: "absolute",
    top: "43%",
    width: "50%",
    zIndex: -1,
  },
  progressActiveThird: {
    backgroundColor: colorTable.darkBlue,
    height: "2px",
    left: "0",
    position: "absolute",
    top: "43%",
    width: "100%",
    zIndex: -1,
  },
  box: {
    alignItems: "center",
    backgroundColor: colorTable.white,
    border: `2px solid ${borderColors.lightGrey_100}`,
    borderRadius: "3px",
    color: colorTable.lightGrey_100,
    display: "flex",
    height: "20px",
    lineHeight: "12px",
    justifyContent: "center",
    width: "20px",
    fontSize: "12px",
  },
  boxMobile: {
    alignItems: "center",
    backgroundColor: colorTable.white,
    border: `2px solid ${borderColors.lightGrey_100}`,
    borderRadius: "8px",
    color: colorTable.darkBlue,
    display: "flex",
    height: "3rem",
    fontSize: "24px",
    justifyContent: "center",
    width: "3rem",
  },
  active: {
    backgroundColor: colorTable.darkBlue,
    border: "none",
    color: colorTable.white,
  },
  text: {
    fontSize: "12px",
    color: colorTable.blackDefault,
    marginTop: 9,
  },
  textWrapper: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "64rem",
    margin: "0 auto",
    [theme.breakpoints.down(768)]: {
      display: "none",
    },
  },
  progressBarWrapper: {
    marginBottom: "26px",
    padding: "0 16px",
  },
}));
