import { createReducer, Reducer } from '~/lib/redux';
import { AsyncData, AsyncDataPhase, initial, error, success } from '~/lib/redux/async';

import { BankSectionDto } from '~/entities/anketa';

import {
  anketaSaveBankSectionStartAction,
  anketaSaveBankSectionSuccessAction,
  anketaSaveBankSectionErrorAction,
  anketaUpdateBankSectionStartAction,
  anketaUpdateBankSectionSuccessAction,
  anketaUpdateBankSectionErrorAction,
} from '../actions';

export const STATE_NAME = 'saveOrUpdateBankSection';

export type AnketaSaveOrUpdateBankSectionState = AsyncData<BankSectionDto>;

export const defaultState: AnketaSaveOrUpdateBankSectionState = initial();

const reducer: Reducer<AnketaSaveOrUpdateBankSectionState> = createReducer<AnketaSaveOrUpdateBankSectionState>(
  {},
  defaultState,
);

const pendingReducer = (state: AnketaSaveOrUpdateBankSectionState): AnketaSaveOrUpdateBankSectionState => ({
  ...state,
  phase: AsyncDataPhase.Pending,
});

const successReducer = (_: unknown, payload: BankSectionDto): AnketaSaveOrUpdateBankSectionState =>
  success<BankSectionDto>(payload);

const errorReducer = (state: AnketaSaveOrUpdateBankSectionState, err: Error): AnketaSaveOrUpdateBankSectionState =>
  error<BankSectionDto>(state.data || null, err);

reducer.on(anketaSaveBankSectionStartAction, pendingReducer);
reducer.on(anketaUpdateBankSectionStartAction, pendingReducer);

reducer.on(anketaSaveBankSectionSuccessAction, successReducer);
reducer.on(anketaUpdateBankSectionSuccessAction, successReducer);

reducer.on(anketaSaveBankSectionErrorAction, errorReducer);
reducer.on(anketaUpdateBankSectionErrorAction, errorReducer);

export default reducer;
