import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()(() => ({
  formBlock: {
    paddingBottom: "64px",
    borderBottom: `1px solid ${borderColors.greyContour}`,
    [themeV5.breakpoints.down(768)]: {
      paddingBottom: "40px",
    },
  },
  notBorder: {
    borderBottom: "none !important",
  },
  notPadding: {
    paddingBottom: "0px !important",
  },
  title: {
    color: colorTable.charcoalBlack,
  },
  subtitle: {
    paddingTop: "8px",
    color: colorTable.textSecondary,
  },
  content: {
    paddingTop: "24px",
  },
  wrapIconTooltip: {
    marginLeft: "8px",
    transform: "translateY(-2px)",
  },
}));
