export enum CustomerAgreementStatus {
  DRAFT = "draft",
  SENT = "sent",
  PENDING = "pending",
  ACTIVATED = "activated",
  REJECTED = "rejected",
  INITIALIZING = "initializing",
  SMS_SIGNING = "sms_signing",
  CLOSED = "closed",
}

export enum ProcessesAvailable {
  ASSET_MANAGEMENT = "ASSET_MANAGEMENT",
  BROKER_SERVICE = "BROKER_SERVICE",
}

export enum BlockArguments {
  REPLEENISHMENT_BLOCK = "Incoming transfer is not allowed",
  ORDER_REPORT_BLOCK = "Sending an account statement report is not allowed",
  FUNDS_WITHDRAWAL_BLOCK = "Funds withdrawal is not allowed",
  TRADING_ACTIVITY_BLOCK = "Trading activity is not allowed",
}
