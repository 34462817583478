/* eslint-disable @typescript-eslint/camelcase */
import { endsWith, get } from "lodash";

import { AppResponseError } from "~/entities/app";
import { cast, Yup } from "~/lib/validation";

export interface KeycloakTokenModel {
  access_token: string;
  refresh_token: string;
}

export interface RegistrationDataModel {
  username: {
    login: string;
    region: string;
  };
  firstName: string;
  email: string;
  cpnAgreement: boolean;
  password: string;
  recaptcha?: string;
  otpSecret: string | null;
}

const schema = Yup.object<KeycloakTokenModel>({
  access_token: Yup.string().required(),
  refresh_token: Yup.string().required(),
}).label("KeycloakTokenModel");

export const castKeycloakTokenModel = cast<KeycloakTokenModel>(schema);

export const isNeedOtpResponse = (response: unknown): boolean => get(response, "data.content") === "Need OTP";

export const isTokenUrl = (url: string): boolean => endsWith(url, "protocol/openid-connect/token");

export const isTokenErrorResponse = (error: unknown): boolean => {
  return !!(
    AppResponseError.is(error) &&
    get(error, "error.config") &&
    isTokenUrl(get(error, "error.config.url")) &&
    get(error, "error.isAxiosError")
  );
};

export const isInvalidGrantErrorResponse = (error: unknown): boolean => {
  return !!(
    AppResponseError.is(error) &&
    get(error, "error.isAxiosError") &&
    get(error, "error.response.status") === 400 &&
    get(error, "error.response.data.error") === "invalid_grant"
  );
};

export const isErrorResponseShouldToUseRefreshToken = (error: unknown): boolean => {
  return !!(
    AppResponseError.is(error) &&
    get(error, "error.config") &&
    !isTokenUrl(get(error, "error.config.url")) &&
    get(error, "error.isAxiosError") &&
    get(error, "error.response.status") === 401
  );
};
