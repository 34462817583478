import { createAsyncReducer } from '~/lib/redux';

import {
  requestsPrintFormsClearAction,
  requestsPrintFormsErrorAction,
  requestsPrintFormsStartAction,
  requestsPrintFormsSuccessAction,
} from '../actions';

export const STATE_NAME = 'printForms';

const [reducer, defaultState] = createAsyncReducer(
  requestsPrintFormsStartAction,
  requestsPrintFormsSuccessAction,
  requestsPrintFormsErrorAction,
  requestsPrintFormsClearAction,
);

export type RequestsPrintFormsState = typeof defaultState;

export default reducer;
