import { createDomain, forward, sample } from "effector";
import { createForm } from "effector-forms";
import { createGate } from "effector-react";
import moment from "moment";

import { activityTypes, OperatorCondEnum, webEnum } from "~/constants/enums";
import { conditionalValue } from "~/lib/utils";
import { cityRules, rules, websiteRules } from "~/lib/validation/rules";
import { newDateRules } from "~/pages/OpenAccount/services/rules";
import { brokerService } from "~/pages/OpenAccount/services/services";

const individualEntrepreneurInfoDomain = createDomain("Individual Entrepreneur Info");
const gate = createGate({ domain: individualEntrepreneurInfoDomain });

const setIndividualEntrepreneur = individualEntrepreneurInfoDomain.createEvent<boolean>();

const $isIndividualEntrepreneur = individualEntrepreneurInfoDomain.createStore(false);
$isIndividualEntrepreneur.reset(gate.close);
$isIndividualEntrepreneur.on(setIndividualEntrepreneur, (_, isEntrepreneur) => isEntrepreneur);
$isIndividualEntrepreneur.on(
  brokerService.$processInfo,
  (_, data) =>
    data?.inputData?.professionalActivity?.activityType === activityTypes?.INDIVIDUAL_ENTREPRENEUR ||
    data?.inputData?.professionalActivity?.activityType === activityTypes?.SELF_EMPLOYED
);

const form = createForm({
  domain: individualEntrepreneurInfoDomain,
  fields: {
    registrationDate: {
      init: null as Date | null | string,
      rules: [
        newDateRules.requiredDate($isIndividualEntrepreneur),
        newDateRules.validDate($isIndividualEntrepreneur),
        newDateRules.minDate($isIndividualEntrepreneur),
        newDateRules.lessToday($isIndividualEntrepreneur, "OPEN_ACCOUNT.errors.date_registration_less_today"),
      ],
    },
    registrationNumber: {
      init: "",
      rules: [rules.required($isIndividualEntrepreneur), rules.less(50, $isIndividualEntrepreneur)],
    },
    businessCountry: {
      init: "",
    },
    country: {
      init: "",
      rules: [rules.less(50, $isIndividualEntrepreneur)],
    },
    city: {
      init: "",
      rules: cityRules($isIndividualEntrepreneur),
    },
    registrationCountry: {
      init: "",
      rules: [],
    },
    type: {
      init: "",
      rules: [rules.less(250, $isIndividualEntrepreneur)],
    },
    number: {
      init: "",
      rules: [rules.less(50, $isIndividualEntrepreneur)],
    },
    issuedBy: {
      init: "",
      rules: [rules.less(250, $isIndividualEntrepreneur)],
    },
    licenseValidFrom: {
      init: null as Date | null | string,
      rules: [
        newDateRules.validDate($isIndividualEntrepreneur),
        newDateRules.minDate($isIndividualEntrepreneur),
        {
          name: "period start less period end",
          source: $isIndividualEntrepreneur,
          validator: (date, form, needToValidate) => {
            if (date && form.expirationDate) {
              if (needToValidate !== null) {
                return needToValidate ? moment(date).isBefore(moment(form.expirationDate), "day") : true;
              }
              return moment(date).isBefore(moment(form.expirationDate));
            }
            return true;
          },
          errorText: "OPEN_ACCOUNT.errors.licence_from",
        },
      ],
    },
    expirationDate: {
      init: null as Date | null | string,
      rules: [newDateRules.validDate($isIndividualEntrepreneur), newDateRules.minDate($isIndividualEntrepreneur)],
    },
    issuedDate: {
      init: null as Date | null | string,
      rules: [newDateRules.validDate($isIndividualEntrepreneur), newDateRules.minDate($isIndividualEntrepreneur)],
    },
    website: {
      init: webEnum.HTTPS,
      rules: websiteRules($isIndividualEntrepreneur),
    },
    typesOfLicensedActivities: {
      init: "",
      rules: [rules.less(1024, $isIndividualEntrepreneur)],
    },
  },
  validateOn: ["submit"],
});

forward({ from: gate.close, to: form.reset });

sample({
  source: $isIndividualEntrepreneur,
  target: [form.reset],
  filter: (isIndividualEntrepreneur) => !isIndividualEntrepreneur,
});

sample({
  // @ts-ignore
  source: brokerService.$processInfo,
  target: form.setForm,
  fn: (response) => {
    const data = response?.inputData?.professionalActivity;
    if (data?.activityType === activityTypes.INDIVIDUAL_ENTREPRENEUR) {
      return {
        registrationDate: data?.registrationDate ? moment(data?.registrationDate).utcOffset(0, true).toDate() : "",
        registrationNumber: conditionalValue(data?.registrationNumber?.trim(), "", OperatorCondEnum.NULLISH),
        businessCountry: conditionalValue(data?.businessCountry?.trim(), "", OperatorCondEnum.NULLISH),
        country: conditionalValue(data?.registrationCountry?.trim(), "", OperatorCondEnum.NULLISH),
        city: conditionalValue(data?.city?.trim(), "", OperatorCondEnum.NULLISH),
        registrationCountry: conditionalValue(data?.registrationCountry?.trim(), "", OperatorCondEnum.NULLISH),
        type: conditionalValue(data?.licenseInfo?.type?.trim(), "", OperatorCondEnum.NULLISH),
        number: conditionalValue(data?.licenseInfo?.number?.trim(), "", OperatorCondEnum.NULLISH),
        issuedBy: conditionalValue(data?.licenseInfo?.issuedBy?.trim(), "", OperatorCondEnum.NULLISH),
        licenseValidFrom: data?.licenseInfo?.licenseValidFrom
          ? moment(data?.licenseInfo?.licenseValidFrom).utcOffset(0, true).toDate()
          : "",
        expirationDate: data?.licenseInfo?.expirationDate
          ? moment(data?.licenseInfo?.expirationDate).utcOffset(0, true).toDate()
          : "",
        issuedDate: data?.licenseInfo?.issuedDate
          ? moment(data?.licenseInfo?.issuedDate).utcOffset(0, true).toDate()
          : "",
        website: conditionalValue(data?.website?.trim(), "", OperatorCondEnum.NULLISH),
        typesOfLicensedActivities: conditionalValue(
          data?.licenseInfo?.typesOfLicensedActivities?.trim(),
          "",
          OperatorCondEnum.NULLISH
        ),
      };
    }
    return {};
  },
});

export const individualEntrepreneurInfo = {
  $isIndividualEntrepreneur,
  form,
  gate,
  setIndividualEntrepreneur,
};
