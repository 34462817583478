import { createDomain, forward, sample } from "effector";
import { createForm } from "effector-forms";
import { createGate } from "effector-react";
import { pending } from "patronum";

import { changeSecurityQuestionApi, SecurityQuestion } from "~/entities/change-security-question";
import { getCurrentClient } from "~/entities/client";
import { ChangeSecurityQuestionCode } from "~/entities/keycloak";
import { autofillOtpCode } from "~/lib/autofillOtpCode";
import { notificationModel } from "~/modules/notification-model";
import { CurrentClientResponse, HandlerStateProcessParams, ParamsActiveTask } from "./types";

const domain = createDomain("change security question domain");
const domainOtp = createDomain("change security question otp");
const gate = createGate({ domain });
const gateOtp = createGate({ domain: domainOtp });
const gateCurrentSecurityQuestion = createGate({ domain });

const form = createForm({
  domain,
  fields: {
    question: {
      init: "",
      rules: [
        {
          name: "question",
          validator: (val: string) => val.trim() !== "",
          errorText: "VALIDATION.required-field",
        },
      ],
      validateOn: ["submit"],
    },
    answer: {
      init: "",
      rules: [
        {
          name: "answer",
          validator: (value: string) => {
            if (!value.trim()) {
              return {
                isValid: false,
                errorText: "VALIDATION.required-field",
              };
            }

            if (value.length > 255) {
              return {
                isValid: false,
                errorText: "VALIDATION.securityQuestionAnswer.max-length",
              };
            }

            return true;
          },
        },
      ],
      validateOn: ["submit", "change"],
    },
  },
});

forward({ from: [gateCurrentSecurityQuestion.close, gate.close], to: form.reset });

const formOtp = createForm({
  domain: domainOtp,
  fields: {
    otp: {
      init: "",
      rules: [
        {
          name: "otp",
          validator: (val: string) => val.trim() !== "",
          errorText: "VALIDATION.required-field",
        },
      ],
    },
  },
});

forward({ from: gateOtp.close, to: formOtp.reset });

const setListSecurityQuestion = domain.createEvent<SecurityQuestion[]>();
const setActiveTaskId = domain.createEvent<string>();
const setShowOtpField = domain.createEvent<boolean>();
const setNewSecurityQuestionForm = domain.createEvent<boolean>();
const resendOtpCode = domain.createEvent();
const setLoading = domain.createEvent<boolean>();
const setSuccessfully = domain.createEvent<boolean>();

const setBusinessKeyProcces = domain.createEvent<string>();
setBusinessKeyProcces.watch((data) => getStateProccessFx(data));

const handlerDataCurrentClient = domain.createEvent<CurrentClientResponse>();
handlerDataCurrentClient.watch((data) => {
  if (!data.questionAnswer) {
    return setListSecurityQuestion([]);
  }
  form.fields.question.onChange(data.questionAnswer.question.id);

  setListSecurityQuestion([
    {
      id: data.questionAnswer.question.id,
      questionText: data.questionAnswer.question.questionText,
      language: data.questionAnswer.question.language,
    },
  ]);
});

const checkingActiveTask = domain.createEvent<ParamsActiveTask>();
checkingActiveTask.watch((data) => {
  if (data.taskId) {
    return sendTaskCompleteFx(data);
  }

  startProcessFx();
});

const handlerStateProcces = domain.createEvent<HandlerStateProcessParams>();
handlerStateProcces.watch(({ stateProcces, variablesTask, businessKeyProcces }: HandlerStateProcessParams) => {
  if (stateProcces.activeTasks.length) {
    setActiveTaskId(stateProcces.activeTasks[0].id);

    if (stateProcces.processResult === ChangeSecurityQuestionCode.INCORRECT_ANSWER) {
      form.fields.answer.addError({ rule: "answer", errorText: "VALIDATION.securityQuestionAnswer.incorrect-answer" });
      setLoading(false);
      return;
    }

    if (!stateProcces.processResult) {
      if (stateProcces.activeTasks[0].taskDefinitionKey === ChangeSecurityQuestionCode.ENTER_NEW_SECURITY_QUESTION) {
        setNewSecurityQuestionForm(true);
        setLoading(false);
        return;
      }

      if (stateProcces.activeTasks[0].taskDefinitionKey === ChangeSecurityQuestionCode.OTP_CONFIRMATION) {
        setShowOtpField(true);
        setLoading(false);
        return;
      }
    }

    return sendTaskCompleteFx({
      taskId: stateProcces.activeTasks[0].id,
      variables: variablesTask,
    });
  }

  if (stateProcces.processResult === ChangeSecurityQuestionCode.SUCCESSFUL_UPDATE_SECURITY_QUESTION) {
    setLoading(false);
    setSuccessfully(true);
    return;
  }

  getStateProccessFx(businessKeyProcces);
});

const getCurrentClientFx = domain.createEffect(getCurrentClient);
getCurrentClientFx.fail.watch((failData: any) => {
  notificationModel.errorDefaultServer(failData);
});

const getQuestionsListFx = domain.createEffect(changeSecurityQuestionApi.getSecurityQuestionsList);
getQuestionsListFx.fail.watch((failData: any) => {
  notificationModel.errorDefaultServer(failData);
});

const startProcessFx = domain.createEffect(changeSecurityQuestionApi.changeSecurityQuestionStartProcess);
startProcessFx.fail.watch((failData: any) => {
  notificationModel.errorDefaultServer(failData);
});

const getStateProccessFx = domain.createEffect(changeSecurityQuestionApi.getProcess);
getStateProccessFx.fail.watch((failData: any) => {
  notificationModel.errorDefaultServer(failData);
});

const sendTaskCompleteFx = domain.createEffect(changeSecurityQuestionApi.changeSecurityQuestionComplete);
sendTaskCompleteFx.fail.watch((failData: any) => {
  notificationModel.errorDefaultServer(failData);
});

const $listSecurityQuestion = domain.createStore<SecurityQuestion[]>([]);
$listSecurityQuestion.on(setListSecurityQuestion, (_, payload: SecurityQuestion[]) => {
  return payload;
});
$listSecurityQuestion.reset([gate.close, gateCurrentSecurityQuestion.close]);

const $activeTaskId = domain.createStore<string>("");
$activeTaskId.on(setActiveTaskId, (_, payload) => payload);
$activeTaskId.reset([gate.close]);

const $businessKeyProcces = domain.createStore<string>("");
$businessKeyProcces.on(setBusinessKeyProcces, (_, payload) => payload);
$businessKeyProcces.reset([gate.close]);

const $isShowOtpField = domain.createStore<boolean>(false);
$isShowOtpField.on(setShowOtpField, (_, payload) => payload);
$isShowOtpField.reset([gate.close]);

const $isNewSecurityQuestionForm = domain.createStore<boolean>(false);
$isNewSecurityQuestionForm.on(setNewSecurityQuestionForm, (_, payload) => payload);
$isNewSecurityQuestionForm.reset([gate.close]);

const $isLoading = domain.createStore<boolean>(false);
$isLoading.on(setLoading, (_, payload) => payload);
$isLoading.reset([gate.close]);

const $isSuccessfully = domain.createStore<boolean>(false);
$isSuccessfully.on(setSuccessfully, (_, payload) => payload);
$isSuccessfully.reset([gate.close]);

sample({
  source: getCurrentClientFx.doneData,
  target: handlerDataCurrentClient,
});

sample({
  source: getQuestionsListFx.doneData,
  fn: (data) => data.content,
  target: setListSecurityQuestion,
});

sample({
  clock: form.formValidated,
  source: [form.$values, $activeTaskId] as const,
  fn([form, taskId]): ParamsActiveTask {
    return {
      taskId,
      variables: {
        question_id: form.question,
        answer: form.answer.trim(),
      },
    };
  },
  target: checkingActiveTask,
});

sample({
  clock: form.formValidated,
  fn: () => true,
  target: setLoading,
});

sample({
  source: startProcessFx.doneData,
  fn: (data) => data.businessKey,
  target: setBusinessKeyProcces,
});

sample({
  clock: getStateProccessFx.doneData,
  source: [form.$values, $businessKeyProcces] as const,
  fn: ([form, businessKeyProcces], stateProcces): HandlerStateProcessParams => ({
    stateProcces,
    variablesTask: {
      question_id: form.question,
      answer: form.answer,
    },
    businessKeyProcces,
  }),
  target: handlerStateProcces,
});

sample({
  clock: sendTaskCompleteFx.doneData,
  source: $businessKeyProcces,
  target: getStateProccessFx,
});

sample({
  clock: formOtp.formValidated,
  source: [$activeTaskId, formOtp.$values] as const,
  fn: ([taskId, form]): ParamsActiveTask => ({
    taskId,
    variables: {
      enteredCode: form.otp,
    },
  }),
  target: checkingActiveTask,
});

sample({
  clock: formOtp.formValidated,
  fn: () => true,
  target: setLoading,
});

sample({
  clock: resendOtpCode,
  source: $activeTaskId,
  fn: (taskId): ParamsActiveTask => ({
    taskId,
    variables: {
      enteredCode: null,
    },
  }),
  target: sendTaskCompleteFx,
});
const $isInitPending = pending({ effects: [getCurrentClientFx] });

getStateProccessFx.doneData.watch((data) => {
  autofillOtpCode(data?.resultData?.debugData, () => formOtp.setForm({ otp: data.resultData.debugData }));
});

export const changeSecurityQuestionModel = {
  $isInitPending,
  $isLoading,
  $isNewSecurityQuestionForm,
  $isShowOtpField,
  $isSuccessfully,
  $listSecurityQuestion,
  form,
  formOtp,
  gate,
  gateCurrentSecurityQuestion,
  gateOtp,
  getCurrentClientFx,
  getQuestionsListFx,
  resendOtpCode,
};
