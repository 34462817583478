import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export default makeStyles()(() => ({
  radioItem: {
    display: "flex",
    padding: "24px",
    gap: "0 16px",
    alignItems: "start",
    cursor: "pointer",
  },

  radio: {
    minWidth: "24px",
    minHeight: "24px",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    background: colorTable.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${borderColors.lightGrey_50}`,

    "&.checked": {
      background: colorTable.charcoalBlack,
    },
  },
  circle: {
    minWidth: "8px",
    minHeight: "8px",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    background: colorTable.white,
  },
  input: {
    position: "absolute",
    zIndex: "-1000",
    opacity: 0,
  },
  wrapIcon: {
    width: "32px",
    height: "32px",
    maxHeight: "32px",
    maxWidth: "32px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "16px",
    width: "100%",

    [themeV5.breakpoints.down(768)]: {
      alignItems: "start",
    },
  },
  wrapContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  content: {
    width: "100%",
  },
  btnMore: {
    color: colorTable.charcoalBlack,
  },
  labelText: {
    color: colorTable.moonGrey,
  },
  titleText: {
    color: colorTable.charcoalBlack,
  },
}));
