export const getBrowserVersion = (objectReturn?: boolean) => {
  const userAgent = navigator.userAgent;
  let browser, version;

  if (/Edg\//.test(userAgent)) {
    browser = "Edge";
    version = userAgent.match(/Edg\/(\d+\.\d+(\.\d+)?)/)?.[1];
  } else if (/YaBrowser\//.test(userAgent)) {
    browser = "YaBrowser";
    version = userAgent.match(/YaBrowser\/(\d+\.\d+(\.\d+)?)/)?.[1];
  } else if (/Firefox\//.test(userAgent)) {
    browser = "Firefox";
    version = userAgent.match(/Firefox\/(\d+\.\d+(\.\d+)?)/)?.[1];
  } else if (/OPR\//.test(userAgent)) {
    browser = "Opera";
    version = userAgent.match(/OPR\/(\d+\.\d+(\.\d+)?)/)?.[1];
  } else if (/Safari\//.test(userAgent) && !/Chrome\//.test(userAgent)) {
    // Safari идет после проверки на Chrome, потому что строка User Agent для Safari может содержать "Safari" и "Chrome" одновременно
    browser = "Safari";
    version = userAgent.match(/Version\/(\d+\.\d+(\.\d+)?)/)?.[1];
  } else if (/Chrome\//.test(userAgent)) {
    browser = "Chrome";
    version = userAgent.match(/Chrome\/(\d+\.\d+(\.\d+)?)/)?.[1];
  } else if (userAgent.indexOf("Android") > -1 && userAgent.indexOf("Mobile") > -1) {
    browser = "Android Mobile Browser";
    version = userAgent.slice(userAgent.indexOf(browser) + browser.length).split(/[ .]/)[0];
  } else if (userAgent.indexOf("iPhone") > -1 || userAgent.indexOf("iPad") > -1) {
    browser = "iOS Mobile Safari";
    version = userAgent.slice(userAgent.indexOf(browser) + browser.length).split(/[ .]/)[0];
  } else {
    return "Browser version could not be determined";
  }

  if (objectReturn) {
    return { browser, version };
  }

  return `${browser} - ${version}`;
};

export const getTimezone = () => -new Date().getTimezoneOffset() / 60;

export const getUserAgentOSVersion = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  let os = null;
  let version = null;

  if (/Mac/.test(platform)) {
    os = "macOS";
    version = (userAgent.match(/Mac OS X ([\._\d]+)/) || [])[1] || null;
    if (version) {
      version = version.replace(/_/g, ".");
    }
  } else if (/Win/.test(platform)) {
    os = "Windows";
    version = (userAgent.match(new RegExp("Windows NT ([\\.\\d]+)")) || [])[1] || null;
  } else if (/Linux/.test(platform)) {
    os = "Linux";
    version = (userAgent.match(new RegExp("Linux ([\\.\\d]+)")) || [])[1] || null;
  }

  if (os && version) {
    return os + " " + version;
  } else if (os) {
    return os;
  } else {
    return "Unknown OS";
  }
};
