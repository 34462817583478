import React, { FormEvent } from "react";
import { Box, InputLabel, useMediaQuery } from "@mui/material";
import { useForm } from "effector-forms";
import { useGate, useStore } from "effector-react";

import { links, modalTypes } from "~/constants/enums";
import { passwordPattern } from "~/constants/regexp";
import { BaseOperationResult } from "~/features/BaseComponents";
import { ControlButtons } from "~/features/ControlButtons";
import { ModalNotification } from "~/features/ModalNotification";
import { ChangeOrResetPasswordFormErrors } from "~/features/UnauthAndBlocked";
import { t } from "~/i18n";
import { useRouterHooks } from "~/lib/router";
import { maxLengthPassword, minLengthPassword } from "~/lib/validation/rules";
import {
  $isLoading,
  $isShowOtpField,
  $isSuccessfully,
  $isTemporaryBlocking,
  $otpError,
  form,
  gate,
  otpForm,
  updateOtpCode,
} from "~/modules/keycloak/change-password-model";
import { logoutModel } from "~/modules/keycloak/logout-model";
import successIcon from "~/shared/Icon/icons/sumsubCheckSuccess.svg";
import PasswordField from "~/shared/ui/PasswordField";
import { SmsInput } from "../../SmsInput";
import { useStyles } from "./styles";

export const ChangePasswordForm = () => {
  const { setLogin } = logoutModel;
  const { navigate } = useRouterHooks();
  const { fields, submit } = useForm(form);
  const { fields: fieldsOtp, submit: submitOtp } = useForm(otpForm);

  const isLoading = useStore($isLoading);
  const isShowOtpField = useStore($isShowOtpField);
  const otpError = useStore($otpError);
  const isSuccessfully = useStore($isSuccessfully);
  const isTemporaryBlocking = useStore($isTemporaryBlocking);

  useGate(gate);

  const { classes } = useStyles();
  const isTabletOrMobile = useMediaQuery("(max-width: 768px)");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isShowOtpField) return submitOtp();

    return submit();
  };

  if (isSuccessfully) {
    return (
      <BaseOperationResult
        titleText={t("CHANGE_PASSWORD.messages.success")}
        buttonText={t("OK")}
        handlerClickBtn={() => navigate(links.profile)}
        srcIconStatus={successIcon}
        alignLeft={!isTabletOrMobile}
      />
    );
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)} style={{ margin: 0 }}>
      <ModalNotification
        type={modalTypes.any}
        title={t("BLOCKED.temporarily-blocked.title")}
        arrDescription={[t("BLOCKED.temporarily-blocked.description")]}
        buttons={[
          {
            typeStyle: "black",
            buttonHandler: () => {
              setLogin(false);
              navigate(links.unblocking);
            },
            buttonText: t("OK"),
          },
        ]}
        isOpen={isTemporaryBlocking}
        onClose={() => {
          setLogin(false);
          navigate(links.unblocking);
        }}
      />
      <Box className={classes.flexWrapper} mt="24px">
        <Box className={classes.inputWrapper}>
          <InputLabel className={classes.inputLabel}>{t("CHANGE_PASSWORD.labels.current-password")}</InputLabel>
          <PasswordField
            className={classes.textField}
            name="currentPassword"
            value={fields.currentPassword.value}
            error={fields.currentPassword.hasError()}
            onChange={(e) => fields.currentPassword.onChange(e.target.value)}
            fullWidth
            helperText={t(fields.currentPassword.errorText())}
            disabled={isShowOtpField}
          />
        </Box>
      </Box>
      <Box className={classes.flexWrapper} mt="16px">
        <Box className={classes.inputWrapper}>
          <InputLabel className={classes.inputLabel}>{t("CHANGE_PASSWORD.labels.new-password")}</InputLabel>
          <PasswordField
            className={classes.textField}
            name="newPassword"
            value={fields.newPassword.value}
            error={fields.newPassword.hasError()}
            onChange={(e) => fields.newPassword.onChange(e.target.value)}
            fullWidth
            helperText={t(fields.newPassword.errorText())}
            disabled={isShowOtpField}
          />
        </Box>
      </Box>
      {!!fields.newPassword.value.length && !isShowOtpField && (
        <Box>
          <ChangeOrResetPasswordFormErrors
            variant="profile"
            short={fields.newPassword.value.length < minLengthPassword}
            weak={!passwordPattern.test(fields.newPassword.value)}
            long={fields.newPassword.value.length > maxLengthPassword}
            submitted={true}
            on={fields.newPassword.isTouched}
          />
        </Box>
      )}
      <Box className={classes.flexWrapper} mt="16px">
        <Box className={classes.inputWrapper}>
          <InputLabel className={classes.inputLabel}>{t("CHANGE_PASSWORD.labels.confirm-password")}</InputLabel>
          <PasswordField
            className={classes.textField}
            name="confirmPassword"
            value={fields.confirmPassword.value}
            error={fields.confirmPassword.hasError()}
            onChange={(e) => fields.confirmPassword.onChange(e.target.value)}
            fullWidth
            helperText={
              fields.confirmPassword.value !== fields.newPassword.value
                ? t("CHANGE_PASSWORD.validation.not_equal")
                : t(fields.confirmPassword.errorText())
            }
            disabled={isShowOtpField}
          />
        </Box>
      </Box>
      {isShowOtpField && (
        <Box className={classes.widthWrapper}>
          <SmsInput
            name="otp"
            resend={updateOtpCode}
            otpValue={fieldsOtp.otp.value}
            changeOtpCode={fieldsOtp.otp.onChange}
            hasError={!!otpError}
            errorText={t(otpError)}
          />
        </Box>
      )}
      <Box className={classes.widthWrapper} mt={5}>
        <ControlButtons
          onNextStep={(e) => handleSubmit(e)}
          onPrevStep={() => navigate(links.profile)}
          nextButtonText={t("GENERAL.continue")}
          prevButtonText={t("GENERAL.back")}
          disableNextButton={
            isLoading ||
            fields.newPassword.hasError() ||
            !fields.currentPassword.value.length ||
            !fields.newPassword.value.length ||
            !fields.confirmPassword.value.length ||
            fields.confirmPassword.value !== fields.newPassword.value
          }
          disableBackButton={false}
          loading={isLoading}
          nextButtonType="button"
          prevButtonType="button"
        />
      </Box>
    </form>
  );
};
