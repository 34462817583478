import React from "react";
import { Box } from "@mui/material";
import cn from "classnames";

import { useTranslation } from "~/i18n";
import DocumentPic1 from "~/shared/Icon/icons/documentsSectionPic1.svg";
import DocumentPic2 from "~/shared/Icon/icons/documentsSectionPic2.svg";
import DocumentPic3 from "~/shared/Icon/icons/documentsSectionPic3.svg";
import DocumentPic4 from "~/shared/Icon/icons/documentsSectionPic4.svg";
import { BaseTypography } from "~/shared/ui-kit/BaseComponents";
import { SubstractIcon } from "~/shared/ui-kit/constants/icons";
import useStyles from "./styles";

interface ISendingDocumentsWarningProps {
  className?: string;
}

export default ({ className }: ISendingDocumentsWarningProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Box className={cn(classes.mainWrap, className)}>
      <Box className={classes.wrapTextWarning}>
        <SubstractIcon className={classes.icon} />
        <Box>
          <BaseTypography className={classes.warningText} tag="p" fontType="text">
            {t("OPEN_ACCOUNT.documentsSection.warningList.p1")}
          </BaseTypography>
          <BaseTypography className={classes.warningText} tag="p" fontType="text">
            {t("OPEN_ACCOUNT.documentsSection.warningList.p2")}
          </BaseTypography>
          <BaseTypography className={classes.warningText} tag="p" fontType="text">
            {t("OPEN_ACCOUNT.documentsSection.warningList.p3")}
          </BaseTypography>
        </Box>
      </Box>
      <Box className={classes.boxImg}>
        <img className={classes.img} src={DocumentPic1} alt="document1" />
        <img className={classes.img} src={DocumentPic2} alt="document2" />
        <img className={classes.img} src={DocumentPic3} alt="document3" />
        <img className={classes.img} src={DocumentPic4} alt="document4" />
      </Box>
    </Box>
  );
};
