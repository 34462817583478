import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import _ from "lodash";

import { useTranslation } from "~/i18n";
import { useStyles } from "./styles";
import { TabPanel } from "./TabPanel";

export interface BaseTabsProps {
  tabs: string[];
  tabsContent: React.ReactNode[];
  handleTabChange?: () => void;
  activeTabIndex?: number;
  stylesWrapper?: React.CSSProperties;
  stylesTabs?: React.CSSProperties;
  stylesTab?: React.CSSProperties;
}

export const BaseTabs = ({
  handleTabChange,
  activeTabIndex = 0,
  tabs,
  tabsContent,
  stylesWrapper,
  stylesTabs,
  stylesTab,
}: BaseTabsProps) => {
  const { classes } = useStyles();
  const [value, setValue] = React.useState(activeTabIndex);

  function handleChange(_: any, newValue: number) {
    setValue(newValue);
  }

  const { t } = useTranslation();
  return (
    <Box className={classes.wrapper} style={stylesWrapper}>
      <Tabs
        value={value}
        onChange={handleTabChange ? handleTabChange : handleChange}
        className={classes.tabsWrapper}
        style={stylesTabs}
        variant="scrollable"
      >
        {tabs.map((tab, index) => {
          return <Tab label={t(tab)} key={tab} className={classes.tab} value={index} style={stylesTab} />;
        })}
      </Tabs>
      {_.map(tabsContent, (tab: React.ReactNode, index: number) => {
        return (
          <TabPanel key={`tab-content-${index}`} value={value} index={index}>
            {tab}
          </TabPanel>
        );
      })}
    </Box>
  );
};
