import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Box, InputLabel, Typography, useMediaQuery } from "@mui/material";
import cn from "classnames";
import { Form, useForm } from "effector-forms";

import { useTranslation } from "~/i18n";
import { useOtpSmsSubstitution } from "~/lib/hooks/useOtpSmsSubstitution/useOtpSmsSubstitution";
import { Timer } from "~/lib/timer";
import { loginModel } from "~/modules/keycloak/login-model";
import { authStyles } from "~/pages/UnauthAndBlocked/Login/screens/LoginScreen/AuthStyles";
import TextField from "~/shared/ui/TextField";
import { BaseButton } from "~/shared/ui-kit/BaseComponents";
import colorTable from "~/shared/ui-kit/constants/colors";

interface OtpEnterForm {
  enterCallback: () => void;
  getNewOtpCallback: () => void;
  onBackCallback: () => void;
  form: Form<any>;
  setOtpPending?: (value: boolean) => void;
  isPending?: boolean;
  otpError?: boolean;
  isLoginOtp?: boolean;
}

export const OtpEnterForm = ({
  enterCallback,
  getNewOtpCallback,
  form,
  onBackCallback,
  isPending,
  otpError,
  isLoginOtp,
  setOtpPending,
}: OtpEnterForm) => {
  const { classes } = authStyles();
  const { t } = useTranslation();

  const [time, setTime] = useState(60);
  const { values, fields } = useForm(form);
  const { setRecaptchaValue } = loginModel;
  const otpInputRef = useRef(null);

  useOtpSmsSubstitution(otpInputRef);

  const onChange = (func: any, value: string) => {
    if (!/^[0-9]*$/.test(value) || (!!value && !(value.length < 7))) {
      return;
    }

    func(value.replace(/\s/g, ""));
  };

  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const onChangeRecaptcha = (token: string | null) => {
    if (token && isLoginOtp) setRecaptchaValue(token);
  };

  const otpOnEnterHandler = async () => {
    if (setOtpPending) {
      setOtpPending(true);
    }
    try {
      if (isLoginOtp) {
        await recaptchaRef.current?.execute();
      }
      enterCallback();
    } catch (error) {
      setOtpPending && setOtpPending(false);
    }
  };

  const handleSubmit = (event: any) => {
    //@ts-ignore
    if (event.key === "Enter" && !isPending && !!values.otp?.length && values.otp.length >= 6) {
      otpOnEnterHandler();
    }
  };

  const isTabletOrMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Box className={classes.otpForm}>
      <Typography
        style={
          isTabletOrMobile
            ? { marginBottom: "16px", textAlign: "center" }
            : { marginBottom: "32px", textAlign: "center" }
        }
        className={classes.header}
      >
        {t("LOGIN.otp")}
      </Typography>
      <Box>
        <InputLabel style={{ marginBottom: "16px" }} className={classes.inputLabelOtp}>
          {t("SMS_CONFIRM.message")}
        </InputLabel>
        <TextField
          autoComplete="one-time-code"
          ref={otpInputRef}
          fullWidth
          style={{ marginBottom: "0" }}
          value={values.otp}
          error={fields.otp.hasError() || otpError}
          onChange={(e: any) => onChange(fields.otp.onChange, e.target.value)}
          onKeyDown={(e) => handleSubmit(e)}
          name="sms"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]", autoComplete: "one-time-code" }}
        />
        {(fields.otp.hasError() || otpError) && (
          <p
            style={
              isTabletOrMobile
                ? { color: colorTable.red_10, fontSize: "12px", padding: "5px 0 0" }
                : { color: colorTable.red_10, fontSize: "14px" }
            }
          >
            {t("KEYCLOAK_ERRORS.INCORRECT_OTP")}
          </p>
        )}
      </Box>
      <Box className={classes.otpSendCodeWrapper}>
        <Timer time={time} setTime={setTime} startTime={60}>
          {({ expired }) => (
            <BaseButton
              className={cn(classes.forgotLink, { [classes.sendOtpCode]: time < 0 })}
              type="button"
              typeStyle="inline"
              style={{ border: "none", justifyContent: "center" }}
              onClick={() => {
                getNewOtpCallback();
                setTime(60);
              }}
              disabled={expired > 0}
            >
              {expired > 0 ? (
                <span style={{ color: colorTable.lightBlue_20 }}>{t("LOGIN.new-code-timer", { secs: expired })}</span>
              ) : (
                <span className={classes.forgotLink}>{t("LOGIN.new-code")}</span>
              )}
            </BaseButton>
          )}
        </Timer>
      </Box>
      <Box className={classes.buttonsWrapper}>
        <Box>
          <BaseButton
            type="button"
            disabled={isPending}
            typeStyle="white"
            onClick={onBackCallback}
            className={classes.backBtn}
          >
            {t("GENERAL.back")}
          </BaseButton>
        </Box>
        <ReCAPTCHA
          size="invisible"
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY as string}
          onChange={onChangeRecaptcha}
        />
        <Box>
          <BaseButton
            className={cn(classes.buttonDesktop, classes.formBtn)}
            typeStyle="black"
            disabled={isPending || values.otp.length < 6}
            onClick={otpOnEnterHandler}
            isLoader={isPending}
          >
            <span>{t("SMS_CONFIRM.actions.confirm")}</span>
          </BaseButton>
        </Box>
      </Box>
    </Box>
  );
};
