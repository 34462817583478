export enum AXIS_TYPE_ENUM {
  PERCENT = "PERCENT",
  VALUE = "VALUE",
}

export enum GRAPH_TYPE_ENUM {
  LINE = "LINE",
  AREA = "AREA",
}

export enum PERIODS_ENUM {
  ALL = "all",
  THREE_MONTHS = "3 mo.",
  SIX_MONTH = "6 mo.",
  ONE_YEAR = "1 yr.",
  TWO_YEARS = "2 yr.",
  THREE_YEARS = "3 yr.",
  FIVE_YEARS = "5 yr.",
}

export enum LABEL_POSITION {
  MIDDLE = "middle",
  END = "end",
  START = "start",
}
