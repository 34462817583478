export enum ResetPasswordTypes {
  RESET_BY_DOCUMENTS,
  RESET_BY_SECURITY_QUESTION,
}

export enum Steps {
  EnterUserCredentials = "UserCredentials",
  EnterAdditionalCredentials = "EnterUserCredentials",
  EnterOtpCode = "EnterOtpCode",
  EnterSecurityQuestion = "EnterSecurityQuestion ",
  EnterOtpCodeForQuestion = "EnterOtpCodeForQuestion",
  UserAlreadyExist = "UserAlreadyExist",
  SUCCESSFUL_REGISTRATION = "SUCCESSFUL_REGISTRATION",
  INVALID_OTP_FIRST_BLOCK = "INVALID_OTP_FIRST_BLOCK",
  INVALID_OTP_SECOND_BLOCK = "INVALID_OTP_SECOND_BLOCK",
  USERNAME_PERMANENTLY_BLOCKED = "USERNAME_PERMANENTLY_BLOCKED",
  TIME_EXPIRED = "TIME_EXPIRED",
}

export interface customerPrivacyNoticeType {
  docName: string;
  content: string;
}
