import React, { FormEvent } from "react";
import { Box, MenuItem } from "@mui/material";

import { links } from "~/constants/enums";
import { ControlButtons } from "~/features/ControlButtons";
import { SmsInput } from "~/features/SmsInput";
import { useTranslation } from "~/i18n";
import { useRouterHooks } from "~/lib/router";
import TextFieldCustom from "~/shared/ui/TextField";
import TextField from "~/shared/ui/TextField";
import { BaseTypography } from "~/shared/ui-kit/BaseComponents";
import useStyles from "./styles";
import { PropsBaseChangeSecurityQuestionForm } from "./types";

export const BaseChangeSecurityQuestionForm = ({
  controlButtons,
  questionField,
  otpField,
  answerField,
}: PropsBaseChangeSecurityQuestionForm) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { navigate } = useRouterHooks();

  const handlerClickPrevStep = () => {
    navigate(links.profile);
  };

  const handlerSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (controlButtons.disableNextButton) return;

    controlButtons.onNextStep("");
  };

  return (
    <ControlButtons
      onNextStep={controlButtons.onNextStep}
      onPrevStep={handlerClickPrevStep}
      nextButtonType="button"
      prevButtonType="button"
      loading={controlButtons.loading}
      disableNextButton={controlButtons.disableNextButton}
      disableBackButton={controlButtons.disableBackButton}
      nextButtonText={t("GENERAL.continue")}
      prevButtonText={t("GENERAL.back")}
      className={classes.controlButtons}
    >
      <form className={classes.form} onSubmit={handlerSubmit}>
        <Box>
          <BaseTypography tag="p" fontType="subhead" className={classes.formLabel}>
            {questionField.isNewQuestion
              ? t("CHANGE_SECURITY_QUESTION.labels.new-question")
              : t("CHANGE_SECURITY_QUESTION.titles.first-security-question")}
          </BaseTypography>
          <TextField
            name="question"
            fullWidth
            disabled={questionField.disabled}
            select
            value={questionField.value}
            onChange={questionField.handlerChangeValue}
            error={questionField.error}
            helperText={questionField.helperText}
            autoComplete="off"
          >
            {questionField.listMenu.map((question) => (
              <MenuItem key={question.id} value={question.id}>
                {question.questionText}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box>
          <BaseTypography tag="p" fontType="subhead" className={classes.formLabel}>
            {t("CHANGE_SECURITY_QUESTION.labels.answer")}
          </BaseTypography>
          <TextFieldCustom
            name="answer"
            securityAnswerInput
            fullWidth
            value={answerField.value}
            onChange={answerField.handlerChangeValue}
            error={answerField.error}
            helperText={answerField.helperText}
            disabled={answerField.disabled}
            autoComplete="off"
          />
        </Box>
        {otpField?.isShow && (
          <Box>
            <SmsInput
              effectorGate={otpField?.effectorGate}
              otpValue={otpField?.otpValue}
              changeOtpCode={otpField?.changeOtpCode}
              hasError={otpField?.hasError}
              errorText={otpField?.errorText}
              resend={otpField?.resend}
              pending={otpField?.pending}
            />
          </Box>
        )}
      </form>
    </ControlButtons>
  );
};
