import fileSaver from "file-saver";
import { forEach, isArray } from "lodash";
import sanitize from "sanitize-filename";

import { fileNamePattern } from "~/constants/regexp";
import { GetInvalidFileMessageParams } from "~/shared/types";

export function downloadBlob(filename: string, blob: Blob): void {
  const sanitizedFilename = sanitize(filename);
  //@ts-ignore
  if (window.navigator.msSaveBlob) {
    //@ts-ignore
    window.navigator.msSaveBlob(blob, sanitizedFilename);
  } else if (window.saveAs) {
    window.saveAs(blob, sanitizedFilename);
  } else {
    fileSaver.saveAs(blob, sanitizedFilename);
  }
}

export function downloadPDF(title: string, content: Blob): void {
  const blob = new Blob(isArray(content) ? content : [content], { type: "application/pdf" });
  const filename = sanitize(`${title}.pdf`);

  downloadBlob(filename, blob);
}

export function dataURIToBlob(dataURI: string): Blob {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const buffer = new Uint8Array(byteString.length);

  forEach(byteString, (_, index): void => {
    buffer[index] = byteString.charCodeAt(index);
  });

  return new Blob([buffer], { type: mimeString });
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject): void => {
    try {
      const reader = new FileReader();

      reader.onload = function (): void {
        const dataUrl = reader.result as string;
        resolve(dataUrl);
      };
      reader.readAsDataURL(blob);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
}

export function textToBlob(text: string, type = "text/plain"): Blob {
  return new Blob([text], {
    type,
  });
}

export const fileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const getInvalidFileMessage = ({ file, fileTypes, fileSize }: GetInvalidFileMessageParams): Promise<null> => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject("VALIDATION.required-field");
    } else if (!fileTypes.includes(file.type)) {
      reject("FILE_ERRORS.invalid-format");
    } else if (file.size > fileSize.bytes) {
      reject("VALIDATION.file_size_error");
    } else if (!fileNamePattern.test(file.name)) {
      reject("FILE_ERRORS.file-name-error");
    } else if (!file.size) {
      reject("VALIDATION.temp_storage_error");
    }

    resolve(null);
  });
};
