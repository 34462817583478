import { createElement, ReactHTML, ReactNode } from "react";
import cn from "classnames";
import "./reset.css";

import { useStyleTypography } from "~/shared/ui-kit/constants/typography";

export type TTypographyFont = keyof ReturnType<typeof useStyleTypography>["classes"];

export type BaseTypographyProps<T extends keyof ReactHTML> = React.ComponentProps<T> & {
  tag: keyof ReactHTML;
  children: ReactNode | ReactNode[];
  fontType?: TTypographyFont;
  className?: string;
};

export default function <T extends keyof ReactHTML>(props: BaseTypographyProps<T>) {
  const { classes } = useStyleTypography();

  return createElement(
    props.tag,
    { className: cn(classes[props.fontType ?? "default"], props.className), style: props.style },
    props.children
  );
}
