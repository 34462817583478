export enum UnblockingUserSteps {
  EnterUserPhone = "UserPhone",
  EnterOtpCode = "OtpCode",
  EnterSecurityAnswer = "SecurityAnswer",
  UnblockingUserSuccess = "UnblockingUserSuccess",
  OtpEnterError = "OtpEnterError",
  UserNotQuestion = "UserNotQuestion",
  UserAccountDisabled = "UserAccountDisabled",
  UserAccountTemporaryBlocked = "UserAccountTemporaryBlocked",
  SuccessfulUnlockAccount = "SuccessfulUnlockAccount",
}
