import { makeStyles } from "tss-react/mui";

import theme from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  switchWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiFormControlLabel-root": {
      marginRight: "0",
    },
  },
  switch: {
    "&.MuiFormControlLabel-root.Mui-disabled": {
      "& .MuiSwitch-root .MuiSwitch-track": {
        opacity: "unset",
        background: colorTable.lightGrey_30,
      },
      "& .MuiSwitch-thumb": {
        color: `${colorTable.white} !important`,
      },
      [theme.breakpoints.down(768)]: {
        "& .MuiSwitch-root": {
          width: "40px",
          height: "24px",
        },
        "& .MuiSwitch-thumb": {
          width: "20px",
          height: "20px",
        },
      },
    },
  },
  title: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: colorTable.blackDefault,
  },
});
