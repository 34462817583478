import { createReducer, Reducer } from "~/lib/redux";
import { AsyncData, AsyncDataPhase, error, initial, success } from "~/lib/redux/async";

import { AuthLoginModel } from "~/entities/auth";

import {
  authLoginStartAction,
  authLogoutErrorAction,
  authLogoutStartAction,
  authLogoutSuccessAction,
  authSetTokenStateAction,
  authLoginClearAction,
} from "./actions";

export const STATE_NAME = "auth";
export const defaultState: AsyncData<AuthLoginModel> = initial();

export type AuthState = AsyncData<AuthLoginModel>;

const reducer: Reducer<AuthState> = createReducer<AuthState>({}, defaultState);

const pendingReducer = (state: AuthState): AuthState => ({
  ...state,
  phase: AsyncDataPhase.Pending,
});

reducer.on(authLoginStartAction, pendingReducer);

reducer.on(authLoginClearAction, () => success<AuthLoginModel>({ result: false }));

reducer.on(authLogoutStartAction, pendingReducer);
reducer.on(
  authLogoutSuccessAction,
  (): AuthState => success<AuthLoginModel>({ result: false })
);
reducer.on(
  authLogoutErrorAction,
  (state: AuthState, err: Error): AuthState => error<AuthLoginModel>(state.data || null, err)
);

reducer.on(
  authSetTokenStateAction,
  (_, payload): AuthState => {
    if (payload.result) {
      const { result, userName, isTemporaryPassword, securityQuestion } = payload;
      return success<AuthLoginModel>({ result, userName, isTemporaryPassword, securityQuestion });
    }

    return success<AuthLoginModel>({ result: false });
  }
);

export default reducer;
