import { AccountsRequest } from "~/entities/accounts/models";
import { get } from "~/lib/request";

export const accountsApi = {
  accountRequest: async (): Promise<AccountsRequest> => {
    const res = await get("@auth/service-activation/requests/client/current");
    return res.data;
  },

  processesAvailableV2: async (data: any) => {
    const params = {
      serviceType: data?.serviceType,
    };
    const res = await get("@auth/service-activation/available/accounts", {
      params,
    });
    return res.data;
  },
};
