import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";
import borderColors from "~/shared/ui-kit/constants/borderColors";

export const useOpenAccountStyles = makeStyles()(() => ({
  formFieldsGridOneColumn: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: "16px",
  },
  formFieldsGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "24px",
    alignItems: "start",
    [themeV5.breakpoints.down(768)]: {
      gridTemplateColumns: "1fr",
    },
  },
  formFieldsAlignItemEnd: {
    [themeV5.breakpoints.up(768)]: {
      alignItems: "end",
    },
  },
  formFieldsAlignItemBaseLine: {
    [themeV5.breakpoints.up(768)]: {
      alignItems: "baseline",
    },
  },
  mobileCheckboxField: {
    [themeV5.breakpoints.down(1024)]: {
      alignItems: "flex-start !important",
    },
  },
  borderBlock: {
    borderBottom: `1px solid ${borderColors.greyContour}`,
    marginTop: "30px",
  },
  mbCheckboxField: {
    marginBottom: "40px",

    "&:last-child": {
      marginBottom: "0",
    },
  },
  fullWidthField: {
    gridColumn: "1/3",
    [themeV5.breakpoints.down(768)]: {
      gridColumn: "1/2",
    },
  },
  titleSection: {
    marginBottom: "24px",
  },
}));
