import { createReducer, onReset } from '~/lib/redux';
import { AsyncData, error, initial, pending, success } from '~/lib/redux/async';

import { SmsSign, SmsSignStateType } from '~/entities/common';

import {
  appSmsSignCheckClearAction,
  appSmsSignCheckErrorAction,
  appSmsSignCheckStartAction,
  appSmsSignCheckSuccessAction,
  appSmsSignClearAction,
  appSmsSignResendErrorAction,
  appSmsSignResendStartAction,
  appSmsSignResendSuccessAction,
  appSmsSignSignErrorAction,
  appSmsSignSignStartAction,
  appSmsSignSignSuccessAction,
} from '../actions';

export const STATE_NAME = 'smsSign';

export type AppSmsSignDataState = SmsSign & { state: SmsSignStateType };
export type AppSmsSignState = AsyncData<AppSmsSignDataState>;

const defaultState: AppSmsSignState = initial();

const reducer = createReducer<AppSmsSignState>({}, defaultState);

const pendingReducer = (state: SmsSignStateType) => ({ data }: AppSmsSignState): AppSmsSignState =>
  pending(data ? { ...data, state } : { timestamp: 0, state }, null);

const successReducer = ({ data }: AppSmsSignState, payload: SmsSign): AppSmsSignState =>
  success(data ? { ...data, ...payload, state: SmsSignStateType.None } : { ...payload, state: SmsSignStateType.None });

const errorReducer = ({ data }: AppSmsSignState, e: Error): AppSmsSignState =>
  error(data ? { ...data, state: SmsSignStateType.None } : null, e);

reducer.on(appSmsSignSignStartAction, pendingReducer(SmsSignStateType.Signing));
reducer.on(appSmsSignResendStartAction, pendingReducer(SmsSignStateType.Resending));
reducer.on(appSmsSignCheckStartAction, pendingReducer(SmsSignStateType.Checking));

reducer.on(appSmsSignSignSuccessAction, successReducer);
reducer.on(appSmsSignResendSuccessAction, successReducer);
reducer.on(
  appSmsSignCheckSuccessAction,
  ({ data }: AppSmsSignState, payload): AppSmsSignState => {
    return success({
      ...payload,
      state: SmsSignStateType.None,
      timestamp: data ? data.timestamp : 0,
    });
  },
);

reducer.on(appSmsSignSignErrorAction, errorReducer);
reducer.on(appSmsSignResendErrorAction, errorReducer);
reducer.on(appSmsSignCheckErrorAction, errorReducer);

reducer.on(appSmsSignCheckClearAction, () => initial());
reducer.on(appSmsSignClearAction, () => initial());

onReset(reducer, defaultState);

export { defaultState };
export default reducer;
