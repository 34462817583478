import { AxiosError } from "axios";
import { createDomain, forward } from "effector";
import { createGate } from "effector-react";
import { createReEffect } from "effector-reeffect";

import { edmAddressTypes } from "~/constants/enums";
import { currentClient } from "~/entities/current-client";
import { ContactTypeEnum, TClientCurrent } from "~/entities/current-client/types";

const currentClientDomain = createDomain("clientCardDomain");

const getCurrentClientFx = createReEffect<void, TClientCurrent, AxiosError>({ handler: currentClient });

const gate = createGate({ domain: currentClientDomain });

const getCurrentClient = currentClientDomain.createEvent();

forward({ from: [gate.open, getCurrentClient], to: getCurrentClientFx });

const $currentClient = currentClientDomain.createStore<TClientCurrent | null>(null);
$currentClient.on(getCurrentClientFx.doneData, (_, data) => data);
$currentClient.reset(gate.close);
const $registrationAddress = currentClientDomain.createStore<string>("");
const $mailingAddress = currentClientDomain.createStore<string>("");
const $actualAddress = currentClientDomain.createStore<string>("");

const $currentClientEmail = currentClientDomain.createStore<string | null | undefined>(null);
$currentClientEmail.on(
  getCurrentClientFx.doneData,
  (_, data) => data?.contacts?.find((contact) => contact?.type === ContactTypeEnum.EMAIL && contact.primary)?.value
);
$currentClientEmail.reset(gate.close);

$registrationAddress.on(getCurrentClientFx.doneData, (_, data) => {
  const permanentResidentialAddress = data?.addresses?.find(
    (address) => address?.type === edmAddressTypes.REGISTRATION_ADDRESS
  );
  return (
    permanentResidentialAddress?.address ??
    `${permanentResidentialAddress?.country}, ${
      permanentResidentialAddress?.state || ""
    }, ${permanentResidentialAddress?.street}, ${permanentResidentialAddress?.city}, ${permanentResidentialAddress?.house}${
      permanentResidentialAddress?.building || ""
    }, ${permanentResidentialAddress?.apartment || ""}`
  );
});

$mailingAddress.on(getCurrentClientFx.doneData, (_, data) => {
  const mailingAddress = data?.addresses?.find((address) => address?.type === edmAddressTypes.MAILING_ADDRESS);
  return (
    mailingAddress?.address ??
    `${mailingAddress?.country}, ${
      mailingAddress?.state || ""
    }, ${mailingAddress?.street}, ${mailingAddress?.city}, ${mailingAddress?.house}${
      mailingAddress?.building || ""
    }, ${mailingAddress?.apartment || ""}`
  );
});

$actualAddress.on(getCurrentClientFx.doneData, (_, data) => {
  const actualAddress = data?.addresses?.find((address) => address?.type === edmAddressTypes.ACTUAL_ADDRESS);
  return (
    actualAddress?.address ??
    `${actualAddress?.country}, ${
      actualAddress?.state || ""
    }, ${actualAddress?.street}, ${actualAddress?.city}, ${actualAddress?.house}${
      actualAddress?.building || ""
    }, ${actualAddress?.apartment || ""}`
  );
});

export const currentClientModel = {
  gate,
  $currentClient,
  getCurrentClientFx,
  $getCurrentClientPending: getCurrentClientFx.pending,
  getCurrentClient,
  $currentClientEmail,
  $registrationAddress,
  $mailingAddress,
  $actualAddress,
};
