// eslint-disable-next-line no-restricted-imports
import { initReactI18next } from "react-i18next";
import i18n, { TFunction } from "i18next";

import { i18nextPostProcessor } from "~/lib/date";
import { formatMoney, formatPercent } from "~/lib/numeral";
import en from "./locales/en.yaml";
import format from "./locales/format.yaml";
import ru from "./locales/ru.yaml";

i18n
  .use(initReactI18next)
  .use(i18nextPostProcessor)
  .init({
    resources: {
      en: {
        translation: en,
        format,
      },
      ru: {
        translation: ru,
        format,
      },
    },
    lng: "en",
    fallbackLng: "en",
    ns: ["translation", "format"],
    defaultNS: "translation",
    debug: process.env.NODE_ENV === "development",
    react: {
      useSuspense: false,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "ul", "li", "ol"],
    },
    interpolation: {
      escapeValue: false,
      format: (value, formatValue, lng): string => {
        switch (formatValue) {
          case "money-with-decimal":
          case "money-without-decimal":
            return formatMoney(lng || "", value, "{{ currency }}", formatValue === "money-with-decimal");
          case "percent-with-decimal":
          case "percent-without-decimal":
            return formatPercent(lng || "", value, formatValue === "percent-with-decimal");
        }
        return value;
      },
    },
  });

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const t: TFunction = (...props) => i18n.t(...props);

// eslint-disable-next-line no-restricted-imports
export { Trans, Translation, useTranslation, WithTranslation, withTranslation } from "react-i18next";
export default i18n;
