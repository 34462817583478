import { combineReducers } from 'redux';

import root, { BanksRootState, STATE_NAME as ROOT_STATE_NAME } from './reducers/root';

export const STATE_NAME = 'banks';

export interface BanksState {
  [ROOT_STATE_NAME]: BanksRootState;
}

export default combineReducers<BanksState>({
  [ROOT_STATE_NAME]: root,
});
