import { cast, Yup } from "~/lib/validation";

export interface EconomySectionDtoType {
  // step 1
  accountActivity: boolean[];
  // step 2
  expectedAssets: boolean[];
  // step 3
  employee: boolean;
  empAddrCity: string;
  empAddrCountryCode: string;
  empAddrHouse: string;
  empAddrHousing: string;
  empAddrIndex: number;
  empAddrRegion: string;
  empAddrStreet: string;
  empCountryCode: string;
  empName: string;
  empPhoneNumber: string;
  empSite: string;
  position: string;
  selfEmployed: boolean;
  unemployed: boolean;
  rentier: boolean;
  student: boolean;
  pensioner: boolean;
  other: boolean;
  otherDesc: string;
  // step 4
  annualSalary: string;
  fromSelf: string;
  commissions: string;
  dividends: string;
  rents: string;
  royalties: string;
  transFromParents: string;
  pensions: string;
  benefitsFinSupport: string;
  otherIncome: string;
  otherIncomeDesc: string;
  totalIncome: string;

  // step 5
  totalBank: string;
  immProp: string;
  movProp: string;
  finInstr: string;
  otherFin: string;
  otherFinDescription: string;
  totalWealth: string;
  loans: string;
  overdrafts: string;
  totalNet: string;
}

export type EconomySectionDto = Nullable<EconomySectionDtoType>;
export type EconomySectionSaveRequest = { step: 1 | 2 | 3 | 4 | 5; data: EconomySectionDto };

export const schema = Yup.object<EconomySectionDto>({
  // step 1
  accountActivity: Yup.array().of(Yup.boolean()).nullable(),
  // step 2
  expectedAssets: Yup.array().of(Yup.boolean()).nullable(),
  // step 3
  employee: Yup.boolean().nullable(),
  empName: Yup.string().nullable(),
  empCountryCode: Yup.string().nullable(),
  empAddrIndex: Yup.number().nullable(),
  empAddrCountryCode: Yup.string().nullable(),
  empAddrRegion: Yup.string().nullable(),
  empAddrCity: Yup.string().nullable(),
  empAddrStreet: Yup.string().nullable(),
  empAddrHouse: Yup.string().nullable(),
  empAddrHousing: Yup.string().nullable(),
  empPhoneNumber: Yup.string().nullable(),
  empSite: Yup.string().nullable(),
  position: Yup.string().nullable(),
  selfEmployed: Yup.boolean().nullable(),
  unemployed: Yup.boolean().nullable(),
  rentier: Yup.boolean().nullable(),
  student: Yup.boolean().nullable(),
  pensioner: Yup.boolean().nullable(),
  other: Yup.boolean().nullable(),
  otherDesc: Yup.string().nullable(),
  // step 4
  annualSalary: Yup.string().nullable(),
  fromSelf: Yup.string().nullable(),
  commissions: Yup.string().nullable(),
  dividends: Yup.string().nullable(),
  rents: Yup.string().nullable(),
  royalties: Yup.string().nullable(),
  transFromParents: Yup.string().nullable(),
  pensions: Yup.string().nullable(),
  benefitsFinSupport: Yup.string().nullable(),
  otherIncome: Yup.string().nullable(),
  otherIncomeDesc: Yup.string().nullable(),
  totalIncome: Yup.string().nullable(),
  // step 5
  totalBank: Yup.string().nullable(),
  immProp: Yup.string().nullable(),
  movProp: Yup.string().nullable(),
  finInstr: Yup.string().nullable(),
  otherFin: Yup.string().nullable(),
  otherFinDescription: Yup.string().nullable(),
  totalWealth: Yup.string().nullable(),
  loans: Yup.string().nullable(),
  overdrafts: Yup.string().nullable(),
  totalNet: Yup.string().nullable(),
}).label("EconomySectionDto");

export const castEconomySectionDto = cast<EconomySectionDto>(schema);

export const economySectionNullable: EconomySectionDto = {
  // step 1
  accountActivity: null,
  // step 2
  expectedAssets: null,
  // step 3
  employee: null,
  empAddrCity: null,
  empAddrCountryCode: null,
  empAddrHouse: null,
  empAddrHousing: null,
  empAddrIndex: null,
  empAddrRegion: null,
  empAddrStreet: null,
  empCountryCode: null,
  empName: null,
  empPhoneNumber: null,
  empSite: null,
  position: null,
  selfEmployed: null,
  unemployed: null,
  rentier: null,
  student: null,
  pensioner: null,
  other: null,
  otherDesc: null,
  // step 4
  annualSalary: null,
  fromSelf: null,
  commissions: null,
  dividends: null,
  rents: null,
  royalties: null,
  transFromParents: null,
  pensions: null,
  benefitsFinSupport: null,
  otherIncome: null,
  otherIncomeDesc: null,
  totalIncome: null,
  // step 5
  totalBank: null,
  immProp: null,
  movProp: null,
  finInstr: null,
  otherFin: null,
  otherFinDescription: null,
  totalWealth: null,
  loans: null,
  overdrafts: null,
  totalNet: null,
};
