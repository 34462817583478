import {
  ChangeSecurityQuestionCompleteRequest,
  ChangeSecurityQuestionRequest,
  ChangeSecurityQuestionResponse,
  GetSecurityQuestionsResponse,
  SecurityQuestionsResponse,
} from "~/entities/change-security-question";
import { ContentType, get, post, put } from "~/lib/request";
import { getUserTokenInfo } from "~/modules/userToken/api";

const bcsAzpHeader = process.env.REACT_APP_CLIENT_ID || "";
const bcsAzpHeaders = bcsAzpHeader ? { "BCS-AZP": bcsAzpHeader } : {};

export const changeSecurityQuestionApi = {
  questionsList: async (): Promise<SecurityQuestionsResponse> => {
    const res = await get("@auth/client/questions?size=100", undefined, {
      headers: bcsAzpHeaders,
      isPrivate: true,
    });
    return res.data;
  },
  questionsListRu: async (): Promise<SecurityQuestionsResponse> => {
    const res = await get("@auth/client/questions?size=100", undefined, {
      headers: undefined,
      isPrivate: false,
    });
    return res.data;
  },
  getSecurityQuestionsList: async (): Promise<GetSecurityQuestionsResponse> => {
    const res = await get("@auth/client/questions?size=100", undefined, {
      headers: bcsAzpHeaders,
      isPrivate: true,
    });
    return res.data;
  },
  changeSecurityQuestion: async (data: ChangeSecurityQuestionRequest): Promise<ChangeSecurityQuestionResponse> => {
    const res = await post(
      "@auth/auth/realms/Broker/bcs-api/otp/security-questions",
      //@ts-ignore
      { data },
      { headers: bcsAzpHeaders, contentType: ContentType.FormUrlencoded }
    );
    return res.data;
  },
  changeSecurityQuestionStartProcess: async (): Promise<ChangeSecurityQuestionResponse> => {
    const params = { clientId: getUserTokenInfo().id, clientLogin: getUserTokenInfo().login };
    const res = await post(
      "@auth/executor/v1/users/security-question",
      //@ts-ignore
      { data: { username: getUserTokenInfo().login }, params },
      { headers: bcsAzpHeaders, contentType: ContentType.FormUrlencoded }
    );
    return res.data;
  },
  getProcess: async (businessKey: string): Promise<any> => {
    const params = {
      clientId: getUserTokenInfo().id,
      clientLogin: getUserTokenInfo().login,
      processBusinessKey: businessKey,
    };
    const res = await get(
      "@auth/executor/v1/process/state",
      //@ts-ignore
      { params },
      { headers: bcsAzpHeaders, contentType: ContentType.FormUrlencoded }
    );
    return res.data;
  },
  changeSecurityQuestionComplete: async (
    data: ChangeSecurityQuestionCompleteRequest
  ): Promise<ChangeSecurityQuestionResponse> => {
    const res = await put(
      "@auth/executor/v1/task/complete",
      //@ts-ignore
      { data }
    );
    return res.data;
  },
};
