import React, {
  forwardRef,
  ReactNode,
  RefForwardingComponent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useScroll, useThrottle } from "react-use";
import DialogContent from "@mui/material/DialogContent";
import { noop } from "lodash";
import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export interface ContentProps {
  trigger: number;
  onScroll: (value: number) => void;
  children?: ReactNode;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    background: colorTable.white,
    padding: 0,
    minWidth: 970,
    overflowX: "hidden",
    [theme.breakpoints.down(1200)]: {
      minWidth: "auto",
      width: "100%",
    },
  },
}));

const Content = ({ onScroll, trigger, children }: ContentProps) => {
  const { classes } = useStyles();
  const [mounted, setMounted] = useState(false);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { y } = useScroll(scrollRef);
  const yDeb = useThrottle(y, 300);

  const z = useMemo((): number => {
    noop(trigger);
    if (scrollRef && scrollRef.current && mounted) {
      const scrollHeight = scrollRef.current.scrollHeight || 0;
      const clientHeight = scrollRef.current.clientHeight || 0;

      const result = Math.round(scrollHeight > 0 ? ((yDeb + clientHeight) / scrollHeight) * 100 : 0);
      return result > 100 ? 100 : result;
    }

    return 0;
  }, [scrollRef, yDeb, mounted, trigger]);

  useEffect(() => {
    onScroll(z);
    noop(trigger);
  }, [z, onScroll, trigger]);

  useEffect(() => {
    setTimeout(() => setMounted(true), 100);
  }, [setMounted]);

  return (
    <DialogContent classes={classes} ref={scrollRef} id="dialog">
      {children}
    </DialogContent>
  );
};

const ContentForwarded: RefForwardingComponent<{ scroll: () => void }, Omit<ContentProps, "trigger">> = (
  props,
  ref
) => {
  const [trigger, setTrigger] = useState(0);
  const handler = useCallback(
    () => ({
      scroll: (): void => {
        setTrigger((value) => value + 1);
      },
    }),
    [setTrigger]
  );

  useImperativeHandle(ref, handler);

  return <Content {...props} trigger={trigger} />;
};

export default forwardRef(ContentForwarded);
