import React from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ withMargin?: boolean }>()((_, { withMargin }) => ({
  root: {
    marginRight: withMargin ? 10 : undefined,
  },
}));

interface IInputLoaderProps {
  withMargin?: boolean;
  size?: number;
  children?: never;
}

const InputLoader = ({ withMargin = true, size = 16 }: IInputLoaderProps) => {
  const { classes } = useStyles({ withMargin });
  return <CircularProgress size={size} thickness={6} classes={classes} />;
};

export default InputLoader;
