import { CommonSectionDto } from "~/entities/anketa";

import { createAsyncReducer } from "~/lib/redux";
import { success } from "~/lib/redux/async";
import {
  anketaGetCommonSectionErrorAction,
  anketaGetCommonSectionStartAction,
  anketaGetCommonSectionSuccessAction,
  anketaSaveCommonSectionSuccessAction,
  anketaUpdateCommonSectionSuccessAction,
} from "../actions";

export const STATE_NAME = "commonSection";

const [reducer, defaultState] = createAsyncReducer(
  anketaGetCommonSectionStartAction,
  anketaGetCommonSectionSuccessAction,
  anketaGetCommonSectionErrorAction
);

const successReducer = (_: unknown, payload: CommonSectionDto): AnketaCommonSectionState =>
  success<CommonSectionDto>(payload);

reducer.on(anketaSaveCommonSectionSuccessAction, successReducer);
reducer.on(anketaUpdateCommonSectionSuccessAction, successReducer);

export type AnketaCommonSectionState = typeof defaultState;

export default reducer;
