import { Offer, PoolOffer, UnifiedPullOffer } from "./Offer";

export type OfferListSort = {
  direction: "ASC" | "DESC";
  field: string;
};

export type OfferListRequest = {
  riskLevel?: number;
  pageLimit?: number;
  numberAgreement?: string[];
  sort?: OfferListSort;
};

export type OfferListResponse = {
  countItems: number;
  items: Offer[];
};

export type FilteredOfferList = {
  countItems: number;
  items: PoolOffer[];
};

export type UnifiedOfferList = {
  countItems: number;
  items: UnifiedPullOffer[];
};
