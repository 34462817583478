import { makeStyles } from "tss-react/mui";

import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  textField: {
    marginBottom: "8px",
    "& .MuiFilledInput-root": {
      background: colorTable.white,
      border: `1px solid ${borderColors.lightGrey_50}`,
      borderRadius: "4px",
      height: "48px",

      "&.Mui-focused": {
        border: `1px solid ${borderColors.nobleOchre}`,
      },

      "&.Mui-error": {
        border: `1px solid ${borderColors.red}`,
      },

      "&.Mui-disabled": {
        background: colorTable.white_20,
      },
    },
    "& .MuiFilledInput-root .MuiInputBase-input": {
      fontSize: "14px",
      lineHeight: "20px",
      padding: "0 0 0 16px",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: colorTable.lightGrey_90,
    },
  },
  flexWrapper: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "640px",
  },
  widthWrapper: {
    maxWidth: "640px",
  },
  inputWrapper: {
    width: "100%",
  },
  inputLabel: {
    color: colorTable.darkGrey_10,
    textAlign: "left",
    marginBottom: "8px",
    fontSize: "14px",
    lineHeight: "20px",
  },
});
