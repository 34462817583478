import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { useStore } from "effector-react";
import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import { colorsLogo, links } from "~/constants/enums";
import { edmService } from "~/pages/EDM/services/edm-services";
import { LogoSvgColorType } from "./components/LogoSvg";
import whiteBcsCapitalLogo from "../AppIcon/logo_white_tablet.svg";
import bcsCapitalLogo from "../AppIcon/newLogo.svg";

const useStyles = makeStyles<{ color: LogoSvgColorType }>()((theme: Theme) => ({
  link: {
    textDecoration: "none",
  },
  root: {},
  icon: {
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(768)]: {
      height: "auto",
    },
  },
  logo: {
    height: "100%",
    [theme.breakpoints.down(992)]: {
      maxWidth: "235px",
    },
    [theme.breakpoints.up(768)]: {
      display: "block",
    },
    [theme.breakpoints.down(576)]: {
      maxWidth: "177px",
      height: "auto",
    },
  },
  svgLogo: {
    display: "block",

    "& > svg": {
      height: "55px",
      width: "55px",
    },
  },
}));

interface AppIconViewportProps {
  color: LogoSvgColorType;
  wrapWithRootLink?: boolean;
  children?: never;
}

const AppIconViewport = ({ color, wrapWithRootLink = false }: AppIconViewportProps) => {
  const { classes } = useStyles({ color });

  const { $isEdm } = edmService;
  const isEdm = useStore($isEdm);

  const wrp = (children: JSX.Element): JSX.Element =>
    wrapWithRootLink ? (
      <Link to={isEdm ? links.edm : links.operationsAssets} className={classes.link}>
        {children}
      </Link>
    ) : (
      children
    );

  return wrp(
    <Grid container className={classes.root} wrap="nowrap" alignItems="center" alignContent="center">
      <Grid item className={classes.icon}>
        <img
          src={color === colorsLogo.white ? whiteBcsCapitalLogo : bcsCapitalLogo}
          alt="BCS Capital (DIFC)"
          className={classes.logo}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(AppIconViewport);
