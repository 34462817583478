import { combineReducers } from 'redux';

import boot, { AppBootState, STATE_NAME as BOOT_STATE_NAME } from './reducers/boot';
import error, { AppErrorState, STATE_NAME as ERROR_STATE_NAME } from './reducers/error';
import locale, { AppLocaleState, STATE_NAME as LOCALE_STATE_NAME } from './reducers/locale';
import messages, { AppMessageState, STATE_NAME as MESSAGES_STATE_NAME } from './reducers/messages';
import smsSign, { AppSmsSignState, STATE_NAME as SMS_SIGN_STATE_NAME } from './reducers/smsSign';

export const STATE_NAME = 'app';

export { BOOT_STATE_NAME, AppBootState };
export { MESSAGES_STATE_NAME, AppMessageState };
export { ERROR_STATE_NAME, AppErrorState };
export { SMS_SIGN_STATE_NAME, AppSmsSignState };
export { LOCALE_STATE_NAME, AppLocaleState };

export interface AppState {
  [BOOT_STATE_NAME]: AppBootState;
  [MESSAGES_STATE_NAME]: AppMessageState;
  [ERROR_STATE_NAME]: AppErrorState;
  [SMS_SIGN_STATE_NAME]: AppSmsSignState;
  [LOCALE_STATE_NAME]: AppLocaleState;
}

export default combineReducers<AppState>({
  [BOOT_STATE_NAME]: boot,
  [MESSAGES_STATE_NAME]: messages,
  [ERROR_STATE_NAME]: error,
  [SMS_SIGN_STATE_NAME]: smsSign,
  [LOCALE_STATE_NAME]: locale,
});
