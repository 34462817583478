import { makeStyles } from "tss-react/mui";

import theme from "~/configs/theme";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  wrapperInput: {
    maxWidth: "463px",
    width: "100%",
    [theme.breakpoints.down(768)]: {
      maxWidth: "100%",
    },
  },
  inputLabel: {
    color: colorTable.lightGrey_40,
    textAlign: "left",
    marginBottom: "8px",
    fontSize: "14px",
    lineHeight: "20px",
    whiteSpace: "normal",
  },
  textField: {
    marginBottom: "8px",

    "& input": {
      textOverflow: "ellipsis",
      padding: 0,
    },

    "& .MuiFilledInput-root": {
      background: colorTable.white,
      border: `1px solid ${borderColors.lightGrey_50}`,
      borderRadius: "4px",
      height: "48px",
      "&.Mui-focused": {
        border: `1px solid ${borderColors.misteryBlue}`,
      },

      "&.Mui-error": {
        border: `1px solid ${borderColors.red}`,
      },

      "&.Mui-disabled": {
        background: colorTable.white_10,
      },

      "& .MuiIconButton-root": {
        padding: 0,
      },
    },

    "& .MuiFilledInput-root .MuiInputBase-input": {
      padding: "0 0 0 16px",
      color: colorTable.charcoalBlack,
      fontSize: "14px",
      lineHeight: "20px",

      "&.Mui-disabled": {
        color: colorTable.lightGrey_90,
      },
    },
  },
});
