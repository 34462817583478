import { AuthLoginModel, AuthLoginRequestModel } from "~/entities/auth";

import { createAction, createAsyncActions } from "~/lib/redux";

export const [authLoginStartAction, authLoginSuccessAction] = createAsyncActions<null, AuthLoginRequestModel>(
  "AUTH_LOGIN"
);

export const authLoginClearAction = createAction("AUTH_LOGIN_CLEAR");

export const [authLogoutRequestAction, authLogoutStartAction, authLogoutSuccessAction, authLogoutErrorAction] =
  createAsyncActions("AUTH_LOGOUT");

export const authSyncTokenStateAction = createAction("AUTH_SYNC_TOKEN_STATE");
export const authSetTokenStateAction = createAction<AuthLoginModel>("AUTH_SET_TOKEN_STATE");
