import { AxiosError } from "axios";
import { createDomain, createEffect, forward, sample } from "effector";
import { createGate } from "effector-react";

import { serviceTypesEnum } from "~/constants/enums";
import { DocumentListResponse, getDocumentsListUKF, getDocumentUKF } from "~/entities/documents/api";
import { downloadPdf } from "~/lib/utils";
import { DocsType } from "./types";

const documentsDomain = createDomain("documents list");

const gate = createGate({ domain: documentsDomain });

const getDocumentsListUKFFx = createEffect(getDocumentsListUKF);

const getDocumentUKFFx = createEffect<any, any, AxiosError>({
  handler: getDocumentUKF,
});

const setIdDocUKF = documentsDomain.createEvent<string>();
const deleteDocId = documentsDomain.createEvent();
const setBrokerDocsUKF = documentsDomain.createEvent<DocumentListResponse["content"]>();
const setArchiveBrokerUKF = documentsDomain.createEvent<DocumentListResponse["content"]>();
const setAssetDocsUKF = documentsDomain.createEvent<DocumentListResponse["content"]>();
const setArchiveAssetUKF = documentsDomain.createEvent<DocumentListResponse["content"]>();
const addDoc = documentsDomain.createEvent<any>();
const sortingReceivedDocuments = documentsDomain.createEvent<DocumentListResponse["content"]>();
sortingReceivedDocuments.watch((docs) => {
  const archiveBroker: DocumentListResponse["content"] = [];
  const archiveAsset: DocumentListResponse["content"] = [];
  const broker: DocumentListResponse["content"] = [];
  const asset: DocumentListResponse["content"] = [];

  docs.forEach((item) => {
    if (!item.isArchive && (item.processType === serviceTypesEnum["BROKER_SERVICE"] || !item.processType))
      broker.push(item);
    if (item.isArchive && (item.processType === serviceTypesEnum["BROKER_SERVICE"] || !item.processType))
      archiveBroker.push(item);
    if (!item.isArchive && (item.processType === serviceTypesEnum["ASSET_MANAGEMENT"] || !item.processType))
      asset.push(item);
    if (item.isArchive && (item.processType === serviceTypesEnum["ASSET_MANAGEMENT"] || !item.processType))
      archiveAsset.push(item);
  });

  setBrokerDocsUKF(broker);
  setArchiveBrokerUKF(archiveBroker);
  setAssetDocsUKF(asset);
  setArchiveAssetUKF(archiveAsset);
});

const $docUKF = documentsDomain.createStore<any>(null);
$docUKF.on(getDocumentUKFFx.doneData, (_, data) => data);
$docUKF.on(deleteDocId, () => null);
$docUKF.on(addDoc, (_, data) => data);
$docUKF.reset(gate.close);

const $documentsListUKF = documentsDomain.createStore<DocumentListResponse["content"]>([]);
$documentsListUKF.on(getDocumentsListUKFFx.doneData, (_, data: DocumentListResponse) => data.content);
$documentsListUKF.reset(gate.close);

const $brokerDocsUKF = documentsDomain.createStore<DocumentListResponse["content"]>([]);
$brokerDocsUKF.on(setBrokerDocsUKF, (_, data) => data);
$brokerDocsUKF.reset(gate.close);

const $archiveBrokerUKF = documentsDomain.createStore<DocumentListResponse["content"]>([]);
$archiveBrokerUKF.on(setArchiveBrokerUKF, (_, data) => data);
$archiveBrokerUKF.reset(gate.close);

const $assetDocsUKF = documentsDomain.createStore<DocumentListResponse["content"]>([]);
$assetDocsUKF.on(setAssetDocsUKF, (_, data) => data);
$assetDocsUKF.reset(gate.close);

const $archiveAssetUKF = documentsDomain.createStore<DocumentListResponse["content"]>([]);
$archiveAssetUKF.on(setArchiveAssetUKF, (_, data) => data);
$archiveAssetUKF.reset(gate.close);

const $docW8DownloadLoading = documentsDomain.createStore<boolean>(false);
const downloadW8DocBtnEvt = documentsDomain.createEvent();
const $docW8Id = documentsDomain.createStore<string>("");

forward({ from: [gate.open, downloadW8DocBtnEvt], to: getDocumentsListUKFFx });
forward({ from: setIdDocUKF, to: getDocumentUKFFx });

sample({
  source: getDocumentsListUKFFx.doneData,
  fn: (data: DocumentListResponse) => data.content,
  target: sortingReceivedDocuments,
});

$docW8DownloadLoading.on(downloadW8DocBtnEvt, () => true);

sample({
  source: getDocumentsListUKFFx.doneData,
  filter: $docW8DownloadLoading,
  fn: (data: DocumentListResponse) => data.content.find((doc) => doc.type === DocsType.w8ben)?.id ?? "",
  target: $docW8Id,
});

sample({
  source: $docW8Id,
  filter: () => $docW8DownloadLoading.getState() && Boolean($docW8Id.getState()),
  target: getDocumentUKFFx,
});

sample({
  source: getDocumentUKFFx.doneData,
  filter: $docW8DownloadLoading,
  fn: (doc) => {
    downloadPdf([
      {
        base64: doc?.base64Content,
        filename: doc?.bcsfsName,
      },
    ]);
    return false;
  },
  target: $docW8DownloadLoading,
});

export const documentsListModel = {
  gate,
  deleteDocId,
  addDoc,
  $documentsListUKF,
  $brokerDocsUKF,
  $archiveBrokerUKF,
  $assetDocsUKF,
  $archiveAssetUKF,
  $documentsListPendingUKF: getDocumentsListUKFFx.pending,
  setIdDocUKF,
  $docUKF,
  $getDocumentsPendingUKF: getDocumentUKFFx.pending,
  getDocumentsListUKFFx,
  getDocumentUKFFx,
  $docW8DownloadLoading,
  downloadW8DocBtnEvt,
};
