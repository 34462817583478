import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export default makeStyles()(() => ({
  checkboxField: {
    display: "flex",
    alignItems: "center",
    gap: "0 16px",
    cursor: "pointer",
    width: "fit-content",

    "&.disabled": {
      cursor: "not-allowed",
    },

    "&.reverse": {
      flexDirection: "row-reverse",
    },
  },
  switch: {
    position: "relative",
    background: colorTable.greyContour,
    minHeight: "16px",
    minWidth: "26px",
    borderRadius: "10px",

    "& .circle": {
      position: "absolute",
      left: "2px",
      top: "50%",
      transform: "translateY(-50%)",
      borderRadius: "100%",
      height: "12px",
      width: "12px",
      background: colorTable.white,
      transition: "left 0.3s",
    },

    "&.large": {
      minHeight: "24px",
      minWidth: "40px",
      borderRadius: "16px",

      "& .circle": {
        left: "4px",
        height: "20px",
        width: "20px",
      },

      "&.checked": {
        "& .circle": {
          left: "calc(100% - 4px)",
        },
      },
    },

    "&.disabled": {},

    "&.checked": {
      background: colorTable.charcoalBlack,

      "&.disabled": {
        background: colorTable.greyContour,
      },

      "& .circle": {
        left: "calc(100% - 2px)",
        transform: "translate(-100%, -50%)",
        transition: "left 0.3s",
      },
    },
  },
  inputCheckbox: {
    position: "absolute",
    zIndex: "-1000",
    opacity: 0,
  },
  checkIcon: {
    color: colorTable.white,
    width: "10px",
    height: "8px",
    display: "none",

    "&.checked": {
      display: "block",
    },
  },
  label: {
    color: colorTable.charcoalBlack,
    cursor: "inherit",

    "&.disabled": {
      color: colorTable.moonGrey,
    },
  },
}));
