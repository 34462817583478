export { ReactComponent as AddIcon } from "./AddIcon.svg";
export { ReactComponent as BankIcon } from "./BankIcon.svg";
export { ReactComponent as CalendarIcon } from "./CalendarIcon.svg";
export { ReactComponent as CallIcon } from "./CallIcon.svg";
export { ReactComponent as ChatIcon } from "./ChatIcon.svg";
export { ReactComponent as CheckIcon } from "./CheckIcon.svg";
export { ReactComponent as ChevronIcon } from "./ChevronIcon.svg";
export { ReactComponent as CircleBlueIcon } from "./CircleBlueIcon.svg";
export { ReactComponent as CircleGreenIcon } from "./CircleGreenIcon.svg";
export { ReactComponent as ClockIcon } from "./ClockIcon.svg";
export { ReactComponent as CloseIcon } from "./CloseIcon.svg";
export { ReactComponent as CrossIcon } from "./CrossIcon.svg";
export { ReactComponent as DocumentIcon } from "./DocumentIcon.svg";
export { ReactComponent as DownloadDocument } from "./DownloadDocument.svg";
export { ReactComponent as LockIcon } from "./LockIcon.svg";
export { ReactComponent as LoupeIcon } from "./LoupeIcon.svg";
export { ReactComponent as MessageIcon } from "./MessageIcon.svg";
export { ReactComponent as PortfolioIcon } from "./PortfolioIcon.svg";
export { ReactComponent as QuestionMarkIcon } from "./QuestionMarkIcon.svg";
export { ReactComponent as SubstractIcon } from "./SubstractIcon.svg";
export { ReactComponent as TrashIcon } from "./TrashIcon.svg";
export { ReactComponent as TrustIcon } from "./TrustIcon.svg";
export { ReactComponent as WarningIcon } from "./WarningIcon.svg";
