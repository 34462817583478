import { createDomain, forward, sample } from "effector";
import { createForm } from "effector-forms";
import { createGate } from "effector-react";
import moment from "moment";

import history from "~/configs/history";
import store from "~/configs/store";
import {
  addressesTypes,
  contactsTypes,
  genders,
  identityDocuments,
  links,
  LocalStorageKeysEnum,
  OpenAccountProcesses,
  OpenAssetManagementProcesses,
  OperatorCondEnum,
  processResults,
  processStatus,
  taskDefinitionKeys,
} from "~/constants/enums";
import { AppResponseError } from "~/entities/app";
import { authApi } from "~/entities/keycloak/api";
import { loadArrayPdf } from "~/entities/media/api";
import { t } from "~/i18n";
import { conditionalValue } from "~/lib/utils";
import { appMessageAction } from "~/modules/app/actions";
import { countriesModel } from "~/modules/countries";
import { notificationModel } from "~/modules/notification-model";
import { ProfessionalStatusStateResponse } from "~/modules/professional-status-model";
import { brokerServiceApi } from "~/pages/OpenAccount/services/api";
import { openAssetManagementServiceApi } from "~/pages/OpenAssetManagement/services/api";
import { createAddressForm, documentForm, formatCountrySearch } from "~/pages/OpenAssetManagement/services/helpers";
import { Country, openAssetManagementType } from "~/pages/OpenAssetManagement/services/types";
import { pdfBase64DocInfo } from "~/shared/types";

const assetManagementOnboardingDomain = createDomain("broker onboarding domain");
const gate = createGate({ domain: assetManagementOnboardingDomain });

const startAssetManagementOnboarding = assetManagementOnboardingDomain.createEffect({
  handler: openAssetManagementServiceApi.startAssetManagementOnboarding,
});
const getProcessFx = assetManagementOnboardingDomain.createEffect({ handler: authApi.getProcessState });
const putTaskCompleteFx = assetManagementOnboardingDomain.createEffect({ handler: brokerServiceApi.putTaskComplete });
const openingUsPdfModalFx = assetManagementOnboardingDomain.createEffect({
  handler: loadArrayPdf,
});

const setProcessInfo = assetManagementOnboardingDomain.createEvent<any>();
const setBusinessKey = assetManagementOnboardingDomain.createEvent<any>();
const sendDocsAccept = assetManagementOnboardingDomain.createEvent();
const setHasPatronymicName = assetManagementOnboardingDomain.createEvent<boolean>();
const setGarant = assetManagementOnboardingDomain.createEvent<boolean>();
const setNoTIN = assetManagementOnboardingDomain.createEvent<boolean>();
const sendCommonForm = assetManagementOnboardingDomain.createEvent();
const startConfirmation = assetManagementOnboardingDomain.createEvent();
const closePrintFormsModal = assetManagementOnboardingDomain.createEvent();
const searchCountry = assetManagementOnboardingDomain.createEvent<string>();
const clearCountries = assetManagementOnboardingDomain.createEvent();
const setStrategyInfo = assetManagementOnboardingDomain.createEvent<openAssetManagementType>();
const startAssetManagement = assetManagementOnboardingDomain.createEvent();
const goBackTo = assetManagementOnboardingDomain.createEvent<number>();
const stopProcess = assetManagementOnboardingDomain.createEvent();
const setShowNotificationModal = assetManagementOnboardingDomain.createEvent<boolean>();

const $businessKey = assetManagementOnboardingDomain.createStore<string>("");
$businessKey.reset(gate.close);
$businessKey.on(setBusinessKey, (_, data) => {
  return data;
});

const $processInfo = assetManagementOnboardingDomain.createStore<any>({});
$processInfo.reset([gate.close, stopProcess]);
$processInfo.on(setProcessInfo, (_, data) => data);

const $hasPatronymicName = assetManagementOnboardingDomain.createStore<boolean>(true);
$hasPatronymicName.reset([gate.close, stopProcess]);
$hasPatronymicName.on(setHasPatronymicName, (_, hasPatronymicName: boolean) => hasPatronymicName);

const $isActualAddressMatchesRegisteredAddress = assetManagementOnboardingDomain.createStore<boolean>(false);
$isActualAddressMatchesRegisteredAddress.reset([gate.close, stopProcess]);
$isActualAddressMatchesRegisteredAddress.on(
  $processInfo,
  (_, data) => data?.inputData?.userProperties?.isActualAddressMatchesRegisteredAddress
);

const $isMailingAddressMatchesRegisteredAddress = assetManagementOnboardingDomain.createStore<boolean>(false);
$isMailingAddressMatchesRegisteredAddress.reset([gate.close, stopProcess]);
$isMailingAddressMatchesRegisteredAddress.on(
  $processInfo,
  (_, data) => data?.inputData?.userProperties?.isMailingAddressMatchesRegisteredAddress
);

const $noTIN = assetManagementOnboardingDomain.createStore(false);
$noTIN.reset(gate.close);
$noTIN.on(setNoTIN, (_, noTIN) => noTIN);

const $printForms = assetManagementOnboardingDomain.createStore<pdfBase64DocInfo[]>([]);
$printForms.reset([closePrintFormsModal, gate.close, stopProcess]);
$printForms.on(openingUsPdfModalFx.doneData, (_, printForm) => printForm);

const $isGarant = assetManagementOnboardingDomain.createStore(false);
$isGarant.reset([gate.close, stopProcess]);
$isGarant.on(setGarant, (_, isGarant) => isGarant);

const $isOnline = assetManagementOnboardingDomain.createStore(false);
$isOnline.reset([gate.close, stopProcess]);
$isOnline.on($processInfo, (_, data) => data.resultData?.isOnline);

const $onlineFlag = assetManagementOnboardingDomain.createStore(false); // чтобы не переписывать валидаторы проще реверснуть значение чекбокса
$onlineFlag.reset([gate.close, stopProcess]);
$onlineFlag.on($processInfo, (_, data: any) => !data.resultData?.isOnline);

const $commonForm = assetManagementOnboardingDomain.createStore<any>({});
$commonForm.reset([gate.close, stopProcess]);

const $searchedCountries = assetManagementOnboardingDomain.createStore<null | Country[]>(null);
$searchedCountries.reset([gate.close, clearCountries, stopProcess]);

const $taskDefinitionKey = assetManagementOnboardingDomain.createStore<any>({});
$taskDefinitionKey.reset([gate.close, stopProcess]);
$taskDefinitionKey.on(getProcessFx.doneData, (_, data) => data?.activeTasks[0]?.taskDefinitionKey);

const $isFirst = assetManagementOnboardingDomain.createStore<boolean>(false);

const $strategyInfo = assetManagementOnboardingDomain.createStore<openAssetManagementType>({
  strategyType: null,
  strategyName: null,
});
$strategyInfo.reset([gate.close, stopProcess]);
$strategyInfo.on(setStrategyInfo, (_, data) => data);

const $goBackStep = assetManagementOnboardingDomain.createStore<number>(1);
$goBackStep.reset([gate.close, stopProcess]);
$goBackStep.on(goBackTo, (_, data) => data);

const $showNotificationModal = assetManagementOnboardingDomain.createStore<boolean>(false);
$showNotificationModal.on(setShowNotificationModal, (_, payload) => payload);

export const needCrcDiscription = (reason: string): boolean => reason === "ACCOUNT_OWNER_CANNOT_GET_TIN";
const commonForm = createForm({
  domain: assetManagementOnboardingDomain,
  fields: {
    firstName: {
      init: "",
    },
    lastName: {
      init: "",
    },
    middleName: {
      init: "",
    },
    hasNoMiddleName: {
      init: false,
    },
    clientStatus: {
      init: "",
    },
    gender: {
      init: null,
    },
    birthDay: {
      init: null as Date | null | string,
    },
    nationality: {
      init: "",
    },
    cityOfBirth: {
      init: "",
    },
    countryOfBirth: {
      init: "",
    },
    email: {
      init: "",
    },
    phoneNumber: {
      init: "",
    },
    actualAddressMatchesRegisteredAddress: {
      init: false,
    },
    mailingAddressMatchesRegisteredAddress: {
      init: false,
    },
    isCitizenshipByInvestmentInAnyCountry: {
      init: false,
    },
    isResidentInOtherCountries: {
      init: false,
    },
    politicallyExposedPerson: {
      init: false,
    },
    isRelatedToPoliticallyExposedPerson: {
      init: false,
    },
    isUSACitizen: {
      init: false,
    },
    isOnline: {
      init: false,
    },
  },
});

const permanentResidentialAddress = createAddressForm(
  addressesTypes.REGISTRATION_ADDRESS,
  assetManagementOnboardingDomain,
  $onlineFlag
);

const $actualFlag = assetManagementOnboardingDomain.createStore(false); // чтобы не переписывать валидаторы проще реверснуть значение чекбокса
$actualFlag.reset(gate.close);
$actualFlag.on(commonForm.$values, (_, data: any) => {
  return !data?.actualAddressMatchesRegisteredAddress;
});

const actualResidentialAddress = createAddressForm(
  addressesTypes.ACTUAL_ADDRESS,
  assetManagementOnboardingDomain,
  $actualFlag
);

forward({
  from: commonForm.fields.actualAddressMatchesRegisteredAddress.onChange,
  to: [actualResidentialAddress.resetErrors, actualResidentialAddress.reset],
});

forward({
  from: $strategyInfo,
  to: startAssetManagement,
});

sample({
  source: $strategyInfo,
  clock: startAssetManagement,
  filter: (data) => !!data?.strategyType,
  fn: (data: openAssetManagementType) => {
    return {
      strategyType: data?.strategyType,
      strategyName: data?.strategyName,
    };
  },
  target: startAssetManagementOnboarding,
});

const $mailingFlag = assetManagementOnboardingDomain.createStore(false); // чтобы не переписывать валидаторы проще реверснуть значение чекбокса
$mailingFlag.reset(gate.close);
$mailingFlag.on(commonForm.$values, (_, data: any) => !data?.mailingAddressMatchesRegisteredAddress);

const mailingAddress = createAddressForm(addressesTypes.MAILING_ADDRESS, assetManagementOnboardingDomain, $mailingFlag);

forward({
  from: commonForm.fields.mailingAddressMatchesRegisteredAddress.onChange,
  to: [mailingAddress.resetErrors, mailingAddress.reset],
});

const internationalPassport = documentForm(
  identityDocuments.INTERNATIONAL_PASSPORT,
  assetManagementOnboardingDomain,
  $onlineFlag
);

forward({
  from: internationalPassport.fields.issuedBy.onChange,
  to: internationalPassport.resetErrors,
});

const internalPassport = documentForm(
  identityDocuments.INTERNAL_PASSPORT,
  assetManagementOnboardingDomain,
  $onlineFlag
);

forward({
  from: internalPassport.fields.issuedBy.onChange,
  to: internalPassport.resetErrors,
});

const foreignPassport = documentForm(identityDocuments.FOREIGN_PASSPORT, assetManagementOnboardingDomain, $onlineFlag);

forward({
  from: foreignPassport.fields.issuedBy.onChange,
  to: foreignPassport.resetErrors,
});

sample({
  source: startAssetManagementOnboarding.doneData,
  fn: (data) => {
    localStorage.setItem(LocalStorageKeysEnum.onboardingBusinessKey, data?.businessKey);
    setBusinessKey(data?.businessKey);
    return {
      processBusinessKey: data?.businessKey,
    };
  },
  target: getProcessFx,
});

sample({
  source: [$processInfo],
  clock: sendDocsAccept,
  fn: ([processInfo]) => {
    return {
      taskId: processInfo?.activeTasks[0].id ?? "",
      variables: {
        reviewed: true,
      },
    };
  },
  target: putTaskCompleteFx,
});

sample({
  source: [$processInfo],
  clock: stopProcess,
  fn: ([processInfo]) => ({
    taskId: processInfo?.activeTasks ? processInfo.activeTasks[0].id : "",
    variables: {
      stopProcess: true,
    },
  }),
  target: putTaskCompleteFx,
});

sample({
  source: [$processInfo, commonForm.$values] as const,
  clock: sendCommonForm,
  fn: ([processInfo, form]) => {
    const addresses = [
      {
        country: permanentResidentialAddress.$values.getState().country,
        city: permanentResidentialAddress.$values.getState().city,
        type: permanentResidentialAddress.$values.getState().type,
        state: permanentResidentialAddress.$values.getState().state,
        street: permanentResidentialAddress.$values.getState().street,
        house: permanentResidentialAddress.$values.getState().house,
        building: permanentResidentialAddress.$values.getState().building,
        apartment: permanentResidentialAddress.$values.getState().apartment,
        zipcode: permanentResidentialAddress.$values.getState().zipcode,
      },
      {
        type: addressesTypes?.PLACE_BIRTH_ADDRESS,
        city: form?.cityOfBirth ?? "",
        country: form?.countryOfBirth ?? "",
      },
    ];

    const identityDocuments: any = [];

    if (internalPassport.$values.getState().number) {
      identityDocuments.push({
        type: internalPassport.$values.getState().type,
        number: internalPassport.$values.getState().number,
        serial: internalPassport.$values.getState().serial,
        issueDate: moment(internalPassport.$values.getState().issueDate)?.utcOffset(0, true),
        expireDate: moment(internalPassport.$values.getState().expireDate)?.utcOffset(0, true),
        issuedBy: internalPassport.$values.getState().issuedBy,
      });
    }

    if (internationalPassport.$values.getState().number) {
      identityDocuments.push({
        type: internationalPassport.$values.getState().type,
        number: internationalPassport.$values.getState().number,
        serial: internationalPassport.$values.getState().serial,
        issueDate: moment(internationalPassport.$values.getState().issueDate)?.utcOffset(0, true),
        expireDate: moment(internationalPassport.$values.getState().expireDate)?.utcOffset(0, true),
        issuedBy: internationalPassport.$values.getState().issuedBy,
      });
    }

    if (foreignPassport.$values.getState().number) {
      identityDocuments.push({
        type: foreignPassport.$values.getState().type,
        number: foreignPassport.$values.getState().number,
        serial: foreignPassport.$values.getState().serial,
        issueDate: moment(foreignPassport.$values.getState().issueDate)?.utcOffset(0, true),
        expireDate: moment(foreignPassport.$values.getState().expireDate)?.utcOffset(0, true),
        issuedBy: foreignPassport.$values.getState().issuedBy,
      });
    }

    if (!form.actualAddressMatchesRegisteredAddress) {
      addresses.push({
        country: actualResidentialAddress.$values.getState().country,
        city: actualResidentialAddress.$values.getState().city,
        type: actualResidentialAddress.$values.getState().type,
        state: actualResidentialAddress.$values.getState().state,
        street: actualResidentialAddress.$values.getState().street,
        house: actualResidentialAddress.$values.getState().house,
        building: actualResidentialAddress.$values.getState().building,
        apartment: actualResidentialAddress.$values.getState().apartment,
        zipcode: actualResidentialAddress.$values.getState().zipcode,
      });
    }

    if (!form.mailingAddressMatchesRegisteredAddress) {
      addresses.push({
        country: mailingAddress.$values.getState().country,
        city: mailingAddress.$values.getState().city,
        type: mailingAddress.$values.getState().type,
        state: mailingAddress.$values.getState().state,
        street: mailingAddress.$values.getState().street,
        house: mailingAddress.$values.getState().house,
        building: mailingAddress.$values.getState().building,
        apartment: mailingAddress.$values.getState().apartment,
        zipcode: mailingAddress.$values.getState().zipcode,
      });
    }
    commonForm.validate();
    permanentResidentialAddress.validate();
    actualResidentialAddress.validate();
    mailingAddress.validate();
    return {
      taskId: processInfo?.activeTasks[0].id ?? "",
      variables: {
        skipSteps: processInfo?.resultData?.isFirst === false,
        firstName: form?.firstName,
        lastName: form?.lastName,
        middleName: form?.middleName,
        hasNoMiddleName: form?.hasNoMiddleName,
        clientStatus: form?.clientStatus,
        gender: form?.gender,
        // @ts-ignore
        birthDay: moment(form?.birthDay)?.utcOffset(0, true),
        nationality: form?.nationality,
        identityDocuments: [...identityDocuments] ?? "",
        addresses: [...addresses] ?? "",
        contacts: [
          {
            type: contactsTypes.EMAIL,
            value: form?.email,
          },
          {
            type: contactsTypes.PHONE,
            value: form?.phoneNumber,
          },
        ],
        userPropertiesInfo:
          {
            mailingAddressMatchesRegisteredAddress: form.mailingAddressMatchesRegisteredAddress,
            actualAddressMatchesRegisteredAddress: form.actualAddressMatchesRegisteredAddress,
            isCitizenshipByInvestmentInAnyCountry: form.isCitizenshipByInvestmentInAnyCountry,
            isResidentInOtherCountries: form.isResidentInOtherCountries,
            politicallyExposedPerson: form.politicallyExposedPerson,
            isRelatedToPoliticallyExposedPerson: form.isRelatedToPoliticallyExposedPerson,
            isUSACitizen: form.isUSACitizen,
            peps: [],
          } ?? "",
      },
    };
  },
  target: $commonForm,
});

forward({
  from: $commonForm,
  to: [actualResidentialAddress.resetErrors, mailingAddress.resetErrors, permanentResidentialAddress.resetErrors],
});

sample({
  source: [$businessKey],
  clock: putTaskCompleteFx.done,
  fn: ([businessKey]) => {
    return {
      processBusinessKey: businessKey,
    };
  },
  target: getProcessFx,
});

sample({
  //@ts-ignore
  source: $processInfo,
  clock: setProcessInfo,
  target: commonForm.setForm,
  fn: (response) => {
    const data = response?.inputData;
    const placeBirth = data?.addresses?.find((place: any) => place?.type === addressesTypes?.PLACE_BIRTH_ADDRESS);
    const contactEmail = data?.contacts?.find((contact: any) => contact?.type === contactsTypes.EMAIL);
    const contactPhone = data?.contacts?.find((contact: any) => contact?.type === contactsTypes.PHONE);
    const hasNoMiddleName = data?.hasNoMiddleName || !data?.middleName;
    setHasPatronymicName(!hasNoMiddleName);
    return !!data?.firstName
      ? {
          firstName: conditionalValue(data?.firstName, null, OperatorCondEnum.NULLISH),
          lastName: conditionalValue(data?.lastName, null, OperatorCondEnum.NULLISH),
          middleName: conditionalValue(data?.middleName, null, OperatorCondEnum.NULLISH),
          hasNoMiddleName: !!data?.hasNoMiddleName,
          clientStatus: conditionalValue(data?.clientStatus, null, OperatorCondEnum.NULLISH),
          gender: conditionalValue(data?.gender, null, OperatorCondEnum.NULLISH),
          birthDay: data?.birthDay ? moment(data?.birthDay)?.utcOffset(0, true) : null,
          nationality: conditionalValue(data?.nationalityCharCode3, null, OperatorCondEnum.NULLISH),
          cityOfBirth: conditionalValue(placeBirth?.city, null, OperatorCondEnum.NULLISH),
          countryOfBirth: conditionalValue(placeBirth?.country, null, OperatorCondEnum.NULLISH),
          email: conditionalValue(contactEmail?.value, null, OperatorCondEnum.NULLISH),
          phoneNumber: conditionalValue(contactPhone?.value, null, OperatorCondEnum.NULLISH),
          actualAddressMatchesRegisteredAddress: !!data?.userProperties?.isActualAddressMatchesRegisteredAddress,
          mailingAddressMatchesRegisteredAddress: !!data?.userProperties?.isMailingAddressMatchesRegisteredAddress,
          isCitizenshipByInvestmentInAnyCountry: data?.userProperties?.isCitizenshipByInvestmentInAnyCountry,
          isResidentInOtherCountries: data?.userProperties?.isResidentInOtherCountries,
          politicallyExposedPerson: data?.userProperties?.isPoliticallyExposedPerson,
          isRelatedToPoliticallyExposedPerson: data?.userProperties?.isRelatedToPoliticallyExposedPerson,
          // сравнение - страховка от багов ответа УКФ.
          isUSACitizen: data?.resultData?.isFirst ? false : !(data?.userProperties?.isUSACitizen === false),
          isOnline: response?.resultData?.isOnline,
        }
      : {};
  },
});

sample({
  source: $processInfo,
  clock: setProcessInfo,
  target: permanentResidentialAddress.setForm,
  fn: (data) => {
    const permanentResidentialAddress = data?.inputData?.addresses?.find(
      (address: any) => address?.type === addressesTypes.REGISTRATION_ADDRESS
    );
    return permanentResidentialAddress
      ? {
          country: permanentResidentialAddress?.country,
          city: permanentResidentialAddress?.city,
          type: permanentResidentialAddress?.type,
          state: permanentResidentialAddress?.state,
          street: permanentResidentialAddress?.street,
          house: permanentResidentialAddress?.house,
          building: permanentResidentialAddress?.building,
          apartment: permanentResidentialAddress?.apartment,
          zipcode: permanentResidentialAddress?.zipcode,
        }
      : {};
  },
});

sample({
  //@ts-ignore
  source: $processInfo,
  clock: setProcessInfo,
  target: actualResidentialAddress.setForm,
  fn: (data) => {
    const actualResidentialAddress = data?.inputData?.addresses?.find(
      (address: any) => address?.type === addressesTypes.ACTUAL_ADDRESS
    );
    return actualResidentialAddress
      ? {
          country: actualResidentialAddress?.country ?? "",
          city: actualResidentialAddress?.city ?? "",
          type: actualResidentialAddress?.type ?? "",
          state: actualResidentialAddress?.state ?? "",
          street: actualResidentialAddress?.street ?? "",
          house: actualResidentialAddress?.house ?? "",
          building: actualResidentialAddress?.building ?? "",
          apartment: actualResidentialAddress?.apartment ?? "",
          zipcode: actualResidentialAddress?.zipcode ?? "",
        }
      : {};
  },
});

sample({
  //@ts-ignore
  source: $processInfo,
  clock: setProcessInfo,
  target: mailingAddress.setForm,
  fn: (data) => {
    const mailingAddress = data?.inputData?.addresses?.find(
      (address: any) => address?.type === addressesTypes.MAILING_ADDRESS
    );
    return mailingAddress
      ? {
          country: mailingAddress?.country ?? "",
          city: mailingAddress?.city ?? "",
          type: mailingAddress?.type ?? "",
          state: mailingAddress?.state ?? "",
          street: mailingAddress?.street ?? "",
          house: mailingAddress?.house ?? "",
          building: mailingAddress?.building ?? "",
          apartment: mailingAddress?.apartment ?? "",
          zipcode: mailingAddress?.zipcode ?? "",
        }
      : {};
  },
});

sample({
  //@ts-ignore
  source: $processInfo,
  clock: setProcessInfo,
  //@ts-ignore
  target: internationalPassport.setForm,
  fn: (data) => {
    const document = data?.inputData?.identityDocuments?.find(
      (doc: any) => doc?.type === identityDocuments.INTERNATIONAL_PASSPORT
    );
    return document
      ? {
          type: document?.type ?? "",
          number: document?.number ?? "",
          serial: document?.serial ?? "",
          issueDate: document?.issueDate ? moment(document?.issueDate)?.utcOffset(0, true) : "",
          expireDate: document?.expireDate ? moment(document?.expireDate)?.utcOffset(0, true) : "",
          issuedBy: document?.issuedBy ?? "",
        }
      : {};
  },
});

sample({
  //@ts-ignore
  source: $processInfo,
  clock: setProcessInfo,
  //@ts-ignore
  target: internalPassport.setForm,
  fn: (data) => {
    const document = data?.inputData?.identityDocuments?.find(
      (doc: any) => doc?.type === identityDocuments.INTERNAL_PASSPORT
    );
    return document
      ? {
          type: document?.type ?? "",
          number: document?.number ?? "",
          serial: document?.serial ?? "",
          issueDate: document?.issueDate ? moment(document?.issueDate)?.utcOffset(0, true) : "",
          expireDate: document?.expireDate ? moment(document?.expireDate)?.utcOffset(0, true) : "",
          issuedBy: document?.issuedBy ?? "",
        }
      : {};
  },
});

sample({
  //@ts-ignore
  source: $processInfo,
  clock: setProcessInfo,
  //@ts-ignore
  target: foreignPassport.setForm,
  fn: (data) => {
    const document = data?.inputData?.identityDocuments?.find(
      (doc: any) => doc?.type === identityDocuments.FOREIGN_PASSPORT
    );
    return document
      ? {
          type: document?.type ?? "",
          number: document?.number ?? "",
          serial: document?.serial ?? "",
          issueDate: document?.issueDate ? moment(document?.issueDate)?.utcOffset(0, true) : "",
          expireDate: document?.expireDate ? moment(document?.expireDate)?.utcOffset(0, true) : "",
          issuedBy: document?.issuedBy ?? "",
        }
      : {};
  },
});

sample({
  source: getProcessFx.doneData,
  clock: getProcessFx.doneData,
  filter: (data) => data?.activeTasks?.length,
  target: setProcessInfo,
});

sample({
  source: getProcessFx.doneData,
  filter: (data) => data?.resultData?.isFirst,
  // страховка от багов УКФ.
  // 2 генсог должен открываться только если isFirst прислали как false
  fn: (data) => !(data?.resultData?.isFirst === false),
  target: $isFirst,
});

sample({
  source: $processInfo,
  clock: setProcessInfo,
  fn: (data) => data.inputData?.userProperties?.isUSACitizen === false, // чтобы не переписывать валидаторы проще реверснуть значение чекбокса
  target: $isGarant,
});

sample({
  source: countriesModel.$allCountries,
  clock: searchCountry,
  fn: (countryList, searchQuery): null | Country[] => {
    if (!countryList) return [];

    if (!searchQuery) return formatCountrySearch(countryList).slice(0, 7);

    return formatCountrySearch(
      countryList.filter(
        (country: any) => country.englishName.toLocaleLowerCase().indexOf(searchQuery?.toLocaleLowerCase()) >= 0
      )
    );
  },
  target: $searchedCountries,
});

getProcessFx.failData.watch((data: any) => {
  const localeData = data as AppResponseError;

  if (localeData?.error?.response?.status === 404 && !!$businessKey.getState()) {
    store.dispatch(
      appMessageAction({
        message: t("ERRORS.process_error_404_DU_BO"),
        options: { variant: "error" },
      })
    );

    return history.push(links.operations);
  }
});

getProcessFx.doneData.watch((data: ProfessionalStatusStateResponse) => {
  switch (data?.processResult) {
    case processResults.GO_TO_SUPPORT:
    case processResults.FORBIDDEN_DUE_TO_AGE:
    case processResults.W8_NOT_ACCEPT:
    case processResults.PRINT_FORM_ERROR:
    case processResults.CANNOT_SEND_OTP_CODE:
    case processResults.FORBIDDEN:
    case processResults.INVALID_OTP_FIRST_BLOCK:
    case processResults.INVALID_OTP_SECOND_BLOCK:
    case processResults.USER_PERMANENTLY_BLOCKED:
    case processResults.ERROR_SEND_TO_BODR:
    case processResults.VALIDATION_ERROR:
    case processResults.SAVE_ERROR:
      setShowNotificationModal(true);
      break;
    case processResults.UNSUPPORTED_ERROR:
      store.dispatch(
        appMessageAction({
          message: t("ERRORS.process_error"),
          options: { variant: "error" },
        })
      );

      if (data?.state === processStatus.DONE) return history.push(links.operations);
      break;
    case processResults.WAITING_BODR_DESICION:
      store.dispatch(
        appMessageAction({
          message: t("MODAL_SUCCESS.du_title"),
          options: { variant: "success" },
        })
      );
      history.push(links.operations);
      break;
    default:
      return;
  }
});

getProcessFx.doneData.watch((data: any) => {
  if ($isOnline.getState()) {
    switch (data?.activeTasks[0]?.taskDefinitionKey) {
      case taskDefinitionKeys.REVIEWED_DOCS_ACCEPT:
        notificationModel.pushTo(OpenAssetManagementProcesses.DOCS_SECTION);
        break;
      case taskDefinitionKeys.POLLITICALY_EXPOSED_PERSON_INPUT:
        notificationModel.pushTo(OpenAssetManagementProcesses.COMMON_SECTION);
        break;
      case taskDefinitionKeys.ECONOMIC_PROFILE_INPUT:
        notificationModel.pushTo(OpenAssetManagementProcesses.ECONOMY_SECTION);
        break;
      case taskDefinitionKeys.SOURCE_FUNDS_UPLOAD:
        notificationModel.pushTo(OpenAssetManagementProcesses.SOURCE_OF_INCOME_SECTION);
        break;
      case taskDefinitionKeys.INVEST_PROFILE_INPUT:
        notificationModel.pushTo(OpenAssetManagementProcesses.INVEST_SECTION);
        break;
      case taskDefinitionKeys.BANK_ACCOUNT_INPUT:
        notificationModel.pushTo(OpenAssetManagementProcesses.BANK_SECTION);
        break;
      case taskDefinitionKeys.UPLOAD_EXTRA_DOCUMENT:
        notificationModel.pushTo(OpenAssetManagementProcesses.EXTRA_DOCUMENT_SECTION);
        break;
      case taskDefinitionKeys.ASSET_MANAGEMENT_STRATEGY_TYPE_CHOICE:
        notificationModel.pushTo(OpenAssetManagementProcesses.STRATEGY_TYPE_CHOICE_SECTION);
        break;
      case taskDefinitionKeys.ASSET_MANAGEMENT_STRATEGY_CHOICE:
        notificationModel.pushTo(OpenAssetManagementProcesses.STRATEGY_SECTION);
        break;
      case taskDefinitionKeys.ASSET_MANAGEMENT_PROFILE_INPUT:
        notificationModel.pushTo(OpenAssetManagementProcesses.STRATEGY_SECTION);
        break;
      case taskDefinitionKeys.ACCEPTED_PRINT_FORMS:
      case taskDefinitionKeys.OTP_CONFIRMATION:
        notificationModel.pushTo(OpenAccountProcesses.DOCUMENTS_SIGN_SECTION);
        startConfirmation();
        break;
      default:
        return;
    }
  } else {
    switch (data?.activeTasks[0]?.taskDefinitionKey) {
      case taskDefinitionKeys.REVIEWED_DOCS_ACCEPT:
        notificationModel.pushTo(OpenAssetManagementProcesses.DOCS_SECTION);
        break;
      case taskDefinitionKeys.OFFLINE_CLIENT_INPUT:
        notificationModel.pushTo(OpenAssetManagementProcesses.COMMON_SECTION);
        break;
      case taskDefinitionKeys.ECONOMIC_PROFILE_INPUT:
        notificationModel.pushTo(OpenAssetManagementProcesses.ECONOMY_SECTION);
        break;
      case taskDefinitionKeys.SOURCE_FUNDS_UPLOAD:
        notificationModel.pushTo(OpenAssetManagementProcesses.SOURCE_OF_INCOME_SECTION);
        break;
      case taskDefinitionKeys.INVEST_PROFILE_INPUT:
        notificationModel.pushTo(OpenAssetManagementProcesses.INVEST_SECTION);
        break;
      case taskDefinitionKeys.UPLOAD_EXTRA_DOCUMENT:
        notificationModel.pushTo(OpenAssetManagementProcesses.EXTRA_DOCUMENT_SECTION);
        break;
      case taskDefinitionKeys.BANK_ACCOUNT_INPUT:
        notificationModel.pushTo(OpenAssetManagementProcesses.BANK_SECTION);
        break;
      case taskDefinitionKeys.ASSET_MANAGEMENT_STRATEGY_TYPE_CHOICE:
        notificationModel.pushTo(OpenAssetManagementProcesses.STRATEGY_TYPE_CHOICE_SECTION);
        break;
      case taskDefinitionKeys.ASSET_MANAGEMENT_STRATEGY_CHOICE:
        notificationModel.pushTo(OpenAssetManagementProcesses.STRATEGY_SECTION);
        break;
      case taskDefinitionKeys.ASSET_MANAGEMENT_PROFILE_INPUT:
        notificationModel.pushTo(OpenAssetManagementProcesses.STRATEGY_SECTION);
        break;
      case taskDefinitionKeys.ACCEPTED_PRINT_FORMS:
      case taskDefinitionKeys.OTP_CONFIRMATION:
        notificationModel.pushTo(OpenAssetManagementProcesses.DOCUMENTS_SIGN_SECTION);
        startConfirmation();
        break;
      default:
        return;
    }
  }
});

sample({
  source: [$processInfo, $goBackStep],
  clock: goBackTo,
  fn: ([processInfo, goBackStep]) => {
    return {
      taskId: processInfo?.activeTasks ? processInfo.activeTasks[0].id : "",
      variables: {
        goBackTo: goBackStep,
      },
    };
  },
  target: putTaskCompleteFx,
});

sample({
  source: $processInfo,
  clock: closePrintFormsModal,
  fn: (processInfo) => {
    const taskDefinitionKey = processInfo?.activeTasks[0]?.taskDefinitionKey;
    switch (taskDefinitionKey) {
      case taskDefinitionKeys.ACCEPTED_PRINT_FORMS:
        return {
          taskId: processInfo?.activeTasks[0].id ?? "",
          variables: {
            accept: false,
          },
        };
      case taskDefinitionKeys.OTP_CONFIRMATION:
        return {
          taskId: processInfo?.activeTasks[0].id ?? "",
          variables: {
            cancelled: true,
            enteredCode: "",
          },
        };
      default:
        return;
    }
  },
  target: putTaskCompleteFx,
});

forward({ from: openingUsPdfModalFx.doneData, to: $printForms });

export const assetManagementService = {
  gate,
  commonForm,
  $processInfo,
  $processPending: getProcessFx.pending,
  $completePending: putTaskCompleteFx.pending,
  $startAssetManagementOnboardingPending: startAssetManagementOnboarding.pending,
  genders,
  setHasPatronymicName,
  $hasPatronymicName,
  sendDocsAccept,
  getProcessFx,
  internationalPassport,
  internalPassport,
  foreignPassport,
  permanentResidentialAddress,
  actualResidentialAddress,
  mailingAddress,
  $isGarant,
  setGarant,
  $noTIN,
  setNoTIN,
  setProcessInfo,
  sendCommonForm,
  $commonForm,
  putTaskCompleteFx,
  $businessKey,
  goBackTo,
  startConfirmation,
  closePrintFormsModal,
  $printForms,
  $isOnline,
  $isActualAddressMatchesRegisteredAddress,
  $isMailingAddressMatchesRegisteredAddress,
  searchCountry,
  clearCountries,
  $searchedCountries,
  $taskDefinitionKey,
  $isFirst,
  setStrategyInfo,
  $strategyInfo,
  $showNotificationModal,
  setShowNotificationModal,
  stopProcess,
};
