import { cast, Yup } from "~/lib/validation";

export interface ProcessResponse {
  status: number;
  message?: string | null;
}

export const schema = Yup.object<ProcessResponse>({
  status: Yup.number().required(),
  message: Yup.string().nullable(),
}).label("ProcessResponse");

export const castProcessResponse = cast(schema);
