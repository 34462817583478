import { SagaIterator } from 'redux-saga';

import { createAction, createAsyncActions } from '~/lib/redux';

import { AppErrorModel, AppLocale, AppMessageModel, AppMessageOptionsKey } from '~/entities/app';
import { SmsSign } from '~/entities/common';

export const [appBootRequestAction, appBootStartAction, appBootSuccessAction, appBootErrorAction] = createAsyncActions(
  'APP_BOOT',
);

export const appSetLocaleAction = createAction<AppLocale>('APP_SET_LOCALE');

export const appMessageAction = createAction<AppMessageModel>('APP_MESSAGE_OPEN');
export const appMessageCloseAction = createAction<AppMessageOptionsKey>('APP_MESSAGE_CLOSE');
export const appDefaultErrorMessageAction = createAction('APP_DEFAULT_ERROR');

export const appErrorAction = createAction<AppErrorModel>('APP_ERROR');
export const appErrorClearAction = createAction('APP_ERROR_CLEAR');

export const [
  appSmsSignSignRequestAction,
  appSmsSignSignStartAction,
  appSmsSignSignSuccessAction,
  appSmsSignSignErrorAction,
] = createAsyncActions<SmsSign, { requestId: string }>('SMS_SIGN_SIGN');

export const [
  appSmsSignResendRequestAction,
  appSmsSignResendStartAction,
  appSmsSignResendSuccessAction,
  appSmsSignResendErrorAction,
] = createAsyncActions<SmsSign, { requestId: string }>('SMS_SIGN_RESEND');

export const [
  appSmsSignCheckRequestAction,
  appSmsSignCheckStartAction,
  appSmsSignCheckSuccessAction,
  appSmsSignCheckErrorAction,
  appSmsSignCheckClearAction,
] = createAsyncActions<SmsSign, { requestId: string; otp: string }>('SMS_SIGN_CHECK');

export const appSmsSignClearAction = createAction('SMS_SIGN_CLEAR');

interface Files {
  data: { filename?: string; data: string; isBase64?: boolean }[];
  success?: () => SagaIterator;
  error?: () => SagaIterator;
}

export const [
  appSaveFilesRequestAction,
  appSaveFilesStartAction,
  appSaveFilesSuccessAction,
  appSaveFilesErrorAction,
] = createAsyncActions<null, Files>('SAVE_FILE');
