import { createDomain, forward, sample } from "effector";

import { links } from "~/constants/enums";
import { replenishmentBrokerageAccountApi } from "~/entities/replenishment-brokerage-account";
import { TServiceActivationStatus } from "~/entities/replenishment-brokerage-account/types";
import { t } from "~/i18n";
import { dateFormatter } from "~/lib/utils";
import { notificationModalModel } from "~/modules/notification-modal-model/model";
import { notificationModel } from "~/modules/notification-model";
import { operationIndexModel } from "~/modules/operationIndex";
import colorTable from "~/shared/ui-kit/constants/colors";
import { paymentProofDocumentModel } from "../payment-proof-document/model";

const domain = createDomain("replenishmentBrokerageAccount");

const getServiceActivationStatusFx = domain.createEffect(replenishmentBrokerageAccountApi.getServiceActivationStatus);
const checkServiceActivationStatusFx = domain.createEffect(replenishmentBrokerageAccountApi.getServiceActivationStatus);
const checkServiceActivationStatusWithoutModalFx = domain.createEffect(
  replenishmentBrokerageAccountApi.getServiceActivationStatus
);

const checkServiceActivationStatus = domain.createEvent();
const checkServiceActivationStatusWithoutModal = domain.createEvent();

const $serviceActivationFundsStatus = domain.createStore<TServiceActivationStatus[]>([]);
$serviceActivationFundsStatus.on(
  [
    getServiceActivationStatusFx.doneData,
    checkServiceActivationStatusFx.doneData,
    checkServiceActivationStatusWithoutModalFx.doneData,
  ],
  (_, data) => {
    const sortedDataByExpire = data.sort((a, b) => {
      const aExpireDate = Math.abs(new Date(a.expireDate).getTime() - new Date().getTime());
      const bExpireDate = Math.abs(new Date(b.expireDate).getTime() - new Date().getTime());

      return aExpireDate - bExpireDate;
    });

    return sortedDataByExpire;
  }
);

sample({
  clock: operationIndexModel.gate.open,
  target: getServiceActivationStatusFx,
});

forward({
  from: checkServiceActivationStatus,
  to: checkServiceActivationStatusFx,
});

forward({
  from: checkServiceActivationStatusWithoutModal,
  to: checkServiceActivationStatusWithoutModalFx,
});

sample({
  clock: getServiceActivationStatusFx.doneData,
  source: notificationModalModel.$notificationParams,
  filter: (_, data) => !!data.length,
  fn: (notificationParams, data) => ({
    ...notificationParams,
    title: t("REPLENISHMENT_BA.modals.notification.title", { date: dateFormatter(data[0]?.expireDate) }),
  }),
  target: notificationModalModel.setNotificationParams,
});

sample({
  clock: checkServiceActivationStatusFx.doneData,
  filter: (data) => !!data.length,
  target: [
    notificationModalModel.setNotificationParams.prepend(() => ({
      icon: "timeIcon",
      iconBackground: { backgroundColor: colorTable.yellowLight },
      title: t("WITHDRAW_BA.modals.sign_document_success.title"),
      descriptions: [t("REPLENISHMENT_BA.modals.notification.description")],
      buttons: [
        {
          typeStyle: "white",
          buttonHandler: () => {
            notificationModel.pushTo(links.operationsHistory);
            notificationModalModel.setShowNotificationModal(false);
          },
          buttonText: t("REPLENISHMENT_BA.modals.notification.button.0"),
        },
        {
          typeStyle: "black",
          buttonHandler: () => {
            notificationModel.pushTo(links.operationsPaymentProofDocument);
            notificationModalModel.setShowNotificationModal(false);
            paymentProofDocumentModel.startProcess($serviceActivationFundsStatus.getState()[0].id);
            paymentProofDocumentModel.setReplenishmentId($serviceActivationFundsStatus.getState()[0].id);
          },
          buttonText: t("REPLENISHMENT_BA.modals.notification.button.1"),
        },
      ],
      maxWidthContent: "667px",
    })),
    notificationModalModel.setShowNotificationModal.prepend(() => true),
  ],
});

export const replenishmentNotificationModalModel = {
  checkServiceActivationStatusWithoutModal,
  $serviceActivationFundsStatus,
  checkServiceActivationStatus,
};
