import { makeStyles } from "tss-react/mui";

import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()(() => ({
  wrapper: {
    marginTop: "10px",
  },
  tabsWrapper: {
    minHeight: "32px",
    marginBottom: "24px",
  },
  tab: {
    backgroundColor: colorTable.white,
    color: `${colorTable.charcoalBlack} !important`,
    padding: "6px 12px",
    borderRadius: "4px",
    opacity: 1,
    border: `2px solid ${borderColors.greyContour}`,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    height: "32px",
    minHeight: "32px",
    marginRight: "8px",
    maxWidth: "fit-content",
    textTransform: "none",
    "&.MuiTab-root.Mui-selected": {
      border: `2px solid ${borderColors.charcoalBlack}`,
      backgroundColor: colorTable.charcoalBlack,
      color: `${colorTable.white} !important`,
    },
  },
}));
