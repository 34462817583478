import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import { useGate, useStore } from "effector-react";

import { DocumentsForReviewFlags, links, taskDefinitionKeys } from "~/constants/enums";
import Loader from "~/features/Loader/Loader";
import PdfConfirmationsModal from "~/features/PdfConfirmationsModal";
import { useTranslation } from "~/i18n";
import { useRouterHooks } from "~/lib/router";
import { downloadPdf, getValueByCondition } from "~/lib/utils";
import { documentsModel } from "~/pages/BeforeApplication/services/documentsService";
import { brokerService } from "~/pages/OpenAccount/services/services";
import { assetManagementService } from "~/pages/OpenAssetManagement/services/services";
import { DocumentCard, OpenAccountLabel } from "~/shared/ui/OpenAccount";
import { BaseCheckbox } from "~/shared/ui-kit/BaseComponents";
import { useOpenAccountStyles } from "../../styles";
import { useStyles } from "./styles";

export interface DocumentsForReviewProps {
  forWhom?: string;
}

export default (props: DocumentsForReviewProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { classes: openAccountStyles } = useOpenAccountStyles();
  const { navigate } = useRouterHooks();

  const [isAcquainted, setIsAcquainted] = useState<boolean>(false);
  const [docName, setDocName] = useState<string>("");

  const BO = props.forWhom === DocumentsForReviewFlags.BO || props.forWhom === DocumentsForReviewFlags.BO2GS;
  const DU = props.forWhom === DocumentsForReviewFlags.DU || props.forWhom === DocumentsForReviewFlags.DU2GS;

  const { $processInfo, $processPending, sendDocsAccept } = BO ? brokerService : assetManagementService;
  const serviceOnboardingPending = getValueByCondition(
    [
      {
        condition: BO,
        value: brokerService.$startBrokerServiceOnboardingPending,
      },
    ],
    assetManagementService.$startAssetManagementOnboardingPending
  );
  const { $doc, $getDocumentsPending, deleteDocId, setIdDoc } = documentsModel;

  useGate(documentsModel.gate);

  const processInfo = useStore($processInfo);
  const processPending = useStore($processPending);
  const startBrokerServiceOnboardingPending = useStore(serviceOnboardingPending);
  const getDocumentsPending = useStore($getDocumentsPending);
  const doc = useStore($doc);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  function goToSteps() {
    switch (props.forWhom) {
      case DocumentsForReviewFlags.BO:
        sendDocsAccept();
        break;
      case DocumentsForReviewFlags.DU:
        sendDocsAccept();
        break;
    }
  }

  function onBack() {
    navigate(links.operations);
  }

  if (
    processPending ||
    startBrokerServiceOnboardingPending ||
    (processInfo?.activeTasks &&
      processInfo?.activeTasks[0]?.taskDefinitionKey !== taskDefinitionKeys.REVIEWED_DOCS_ACCEPT)
  ) {
    return <Loader isPage />;
  }

  return (
    <OpenAccountLabel
      isDocuments
      onPrevStep={() => onBack()}
      onNextStep={() => goToSteps()}
      disableNextButton={!isAcquainted}
      stepPaths={[]}
    >
      {(doc || getDocumentsPending) && (
        <PdfConfirmationsModal
          isOne
          pending={getDocumentsPending}
          files={
            getDocumentsPending || !doc
              ? []
              : [
                  {
                    base64: `data:application/pdf;base64,${doc?.base64Content}`,
                    filename: docName,
                  },
                ]
          }
          onClose={() => deleteDocId()}
          onSave={() =>
            getDocumentsPending || !doc
              ? null
              : downloadPdf([
                  {
                    base64: doc?.base64Content,
                    filename: doc?.bcsfsName,
                  },
                ])
          }
          disableActions
        />
      )}
      <Box className={classes.documentList}>
        {BO &&
          processInfo?.inputData?.map((doc: any) => {
            return (
              <DocumentCard
                fileName={doc.enName}
                docId={doc.id}
                onClickDocument={(docId, fileName) => {
                  setIdDoc(docId as number);
                  setDocName(fileName);
                }}
                key={doc.id}
              />
            );
          })}
        {DU &&
          processInfo?.inputData?.map((doc: any) => {
            return (
              <DocumentCard
                fileName={doc.enName}
                docId={doc.id}
                onClickDocument={(docId, fileName) => {
                  setIdDoc(docId as number);
                  setDocName(fileName);
                }}
                key={doc.id}
              />
            );
          })}
      </Box>
      <BaseCheckbox
        checked={isAcquainted}
        onChange={(e) => setIsAcquainted(e.target.checked)}
        className={cn(openAccountStyles.mobileCheckboxField, classes.checkbox)}
      >
        {t("OPERATIONS.document_for_review.check_box")}
      </BaseCheckbox>
    </OpenAccountLabel>
  );
};
