import { Store } from "effector";
import { Rule } from "effector-forms";
import moment, { Moment } from "moment";

import { dateTypes, webEnum } from "~/constants/enums";
import { moreAndMinLength } from "~/constants/errorMessages";
import {
  apartmentPattern,
  buildingPattern,
  cityPattern,
  companyNamePattern,
  defaultInputPattern,
  emailPattern,
  housePattern,
  namePattern,
  noDoubleSpacesPattern,
  notDigitPattern,
  passwordPattern,
  phonePattern,
  positionPattern,
  relationsPattern,
  statePattern,
  streetPattern,
  swiftPattern,
  zipCodePattern,
} from "~/constants/regexp";
import { CountryCode } from "~/entities/keycloak";
import { t } from "~/i18n";
import parser from "~/lib/inputMaskParser";
import { TSelectOptions } from "~/shared/types";
import { isDateLessThanToday, isMinDateValid } from "../date";

// подключение библиотеки через import невозможно
const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

// TODO Перенести остальные правила (даты, файлы и тд)
export const dateRules = {
  isBeforeEndDate: (
    formField: string,
    customError: string = "OPEN_ACCOUNT.errors.valid_period",
    booleanSource?: Store<boolean>
  ): Rule<Moment | null | Date> => ({
    name: "isBeforeEndDate",
    source: booleanSource,
    validator: (value, form) => {
      if (form[formField] && value) {
        const dateField: Moment = moment(form[formField], dateTypes.RUS_DATE);
        const currentValue = moment(value, dateTypes.RUS_DATE);

        if (currentValue?.isSame(dateField)) return true;

        const valid = !!currentValue?.isBefore(dateField);
        return valid;
      }
      return true;
    },
    errorText: customError,
  }),
  isAfterStartDate: (
    formField: string,
    customError: string = "OPEN_ACCOUNT.errors.valid_period",
    booleanSource?: Store<boolean>
  ): Rule<Moment | null> => ({
    name: "isAfterStartDate",
    source: booleanSource,
    validator: (value: Moment | null, form) => {
      if (form[formField] && value) {
        const dateField: Moment = moment(form[formField], dateTypes.RUS_DATE);
        const currentValue = moment(value, dateTypes.RUS_DATE);

        if (currentValue?.isSame(dateField)) return true;

        const valid = !!currentValue?.isAfter(dateField);
        return valid;
      }
      return true;
    },
    errorText: customError,
  }),
  minDate: (booleanSource?: Store<boolean>, errorText?: string): Rule<string | Moment | Date> => ({
    name: "minDate",
    source: booleanSource,
    validator: (date, _, needToValidate) => {
      if (date) {
        const isValid = isMinDateValid(date);
        if (needToValidate !== null) return needToValidate ? isValid : true;
        return isValid;
      }
      return true;
    },
    errorText: errorText ?? "OPEN_ACCOUNT.errors.licence_min_date",
  }),
  lessToday: (
    booleanSource?: Store<boolean>,
    errorText = "OPEN_ACCOUNT.errors.date_less_today",
    isTodayIncluded?: boolean
  ): Rule<string | Moment | Date> => ({
    name: "lessToday",
    source: booleanSource,
    validator: (date, _, needToValidate) => {
      if (date) {
        if (needToValidate !== null) {
          return needToValidate ? isDateLessThanToday(date, isTodayIncluded) : true;
        }
        return isDateLessThanToday(date, isTodayIncluded);
      }
      return true;
    },
    errorText,
  }),
};

export const rules = {
  required: (booleanSource?: Store<boolean>, customError = "VALIDATION.required-field"): Rule<string> => ({
    name: "required",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate ? Boolean(val?.trim()) : true;
      else return Boolean(val?.trim());
    },
    errorText: customError,
  }),
  requiredArray: (booleanSource?: Store<boolean>, customError = "VALIDATION.required-field"): Rule<string[]> => ({
    name: "required",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate ? !!val.length : true;
      else return !!val.length;
    },
    errorText: customError,
  }),
  requiredMoneyNew: (booleanSource?: Store<boolean>): Rule<string | null> => ({
    name: "required money",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (!val) return false;
      if (needToValidate !== null) return needToValidate ? val?.trim() !== "" : true;
      else return val?.trim() !== "" && val?.trim() !== "0";
    },
    errorText: "VALIDATION.required-field",
  }),
  requiredMoney: (booleanSource?: Store<boolean>): Rule<string | null> => ({
    name: "required money",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (!val) return false;
      if (needToValidate !== null) return needToValidate ? val?.trim() !== "" : true;
      else return val?.trim() !== "";
    },
    errorText: "VALIDATION.required-field",
  }),

  requiredDate: (booleanSource?: Store<boolean>): Rule<string | null | Moment | Date> => ({
    name: "requiredDate",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate ? Boolean(val) : true;
      else return Boolean(val);
    },
    errorText: "VALIDATION.required-field",
  }),

  requiredOptions: (
    booleanSource?: Store<boolean>,
    customError = "VALIDATION.required-field"
  ): Rule<TSelectOptions | null> => ({
    name: "required",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null) return needToValidate ? !!val?.label && !!val?.value : true;
      else return !!val?.label && !!val?.value;
    },
    errorText: customError,
  }),

  validDate: (
    booleanSource?: Store<boolean>,
    customError = "VALIDATION.invalid_date"
  ): Rule<string | Moment | Date | null> => ({
    name: "validDate",
    source: booleanSource,
    validator: (val: Moment | string | null | Date, _, needToValidate) => {
      if (val && needToValidate) {
        return moment(val, "DD.MM.YYYY").isValid();
      } else return true;
    },
    errorText: customError,
  }),

  isBeforeDate: (
    formField: string,
    booleanSource?: Store<boolean>,
    customError: string = "VALIDATION.date_agreement_value"
  ): Rule<Moment | null> => ({
    name: "isBeforeDate",
    source: booleanSource,
    validator: (value: Moment | string | null, form) => {
      const dateField: string = form[formField];
      if (value && dateField) {
        return !moment(value).isBefore(moment(dateField, "DD.MM.YYYY"));
      }
      return true;
    },
    errorText: customError,
  }),

  less: (max: number, booleanSource?: Store<boolean>, errorText?: string): Rule<string> => ({
    name: "less",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      const value = val === null ? "" : val;
      if (needToValidate !== null) return needToValidate ? value?.trim().length <= max : true;
      else return value?.trim().length <= max;
    },
    errorText: errorText ? errorText : `OPEN_ACCOUNT.errors.less_${max}`,
  }),
  lessAndMore: (max: number, min: number, booleanSource?: Store<boolean>): Rule<string> => ({
    name: "less and more",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null)
        return needToValidate ? val?.trim().length <= max && val?.trim().length >= min : true;
      else return val?.trim().length <= max && val?.trim().length >= min;
    },
    errorText: t("OPEN_ACCOUNT.errors.more_min_less_max", { min: min.toString(), max: max.toString() }),
  }),
  lessAndMoreWithoutT: (max: number, min: number, booleanSource?: Store<boolean>): Rule<string> => ({
    name: "less and more without translate",
    source: booleanSource,
    validator: (val, _, needToValidate) => {
      if (needToValidate !== null)
        return needToValidate ? val?.trim().length <= max && val?.trim().length >= min : true;
      else return val?.trim().length <= max && val?.trim().length >= min;
    },
    errorText: "OPEN_ACCOUNT.errors.more_min_less_max",
  }),
  latinOnly: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "latinOnly",
    source: booleanSource,
    validator: (val, _, needToValidate) => validate(val, needToValidate, relationsPattern),
    errorText: "OPEN_ACCOUNT.errors.latinOnly",
  }),
  latinNumbersAllSymbolsOnly: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "latinNumbersAllSymbolsOnly",
    source: booleanSource,
    validator: (val, _, needToValidate) => validate(val, needToValidate, defaultInputPattern),
    errorText: "OPEN_ACCOUNT.errors.latinNumbersAllSymbolsOnly",
  }),
  trueName: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "true name",
    source: booleanSource,
    validator: (val, _, needToValidate) => validate(val, needToValidate, namePattern),
    errorText: "OPEN_ACCOUNT.errors.realName",
  }),
  companyName: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "true name",
    source: booleanSource,
    validator: (val, _, needToValidate) => validate(val, needToValidate, companyNamePattern),
    errorText: "OPEN_ACCOUNT.errors.company_name",
  }),
  position: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "position",
    source: booleanSource,
    validator: (val, _, needToValidate) => validate(val, needToValidate, positionPattern),
    errorText: "OPEN_ACCOUNT.errors.position",
  }),
  city: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "city",
    source: booleanSource,
    validator: (val, _, needToValidate) => validate(val, needToValidate, cityPattern),
    errorText: "OPEN_ACCOUNT.errors.city",
  }),
  street: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "street",
    source: booleanSource,
    validator: (val, _, needToValidate) => validate(val, needToValidate, streetPattern),
    errorText: "OPEN_ACCOUNT.errors.street",
  }),
  house: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "house",
    source: booleanSource,
    validator: (val, _, needToValidate) => validate(val, needToValidate, housePattern),
    errorText: "OPEN_ACCOUNT.errors.house",
  }),
  building: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "house",
    source: booleanSource,
    validator: (val, _, needToValidate) => validate(val, needToValidate, buildingPattern),
    errorText: "OPEN_ACCOUNT.errors.building",
  }),
  apartment: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "apartment",
    source: booleanSource,
    validator: (val, _, needToValidate) => validate(val, needToValidate, apartmentPattern),
    errorText: "OPEN_ACCOUNT.errors.apartment",
  }),
  state: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "state",
    source: booleanSource,
    validator: (val, _, needToValidate) => validate(val, needToValidate, statePattern),
    errorText: "OPEN_ACCOUNT.errors.state",
  }),
  zipCode: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "zipCode",
    source: booleanSource,
    validator: (val, _, needToValidate) => validate(val, needToValidate, zipCodePattern),
    errorText: "OPEN_ACCOUNT.errors.zipcode",
  }),
  someRule: (): Rule<boolean[]> => ({
    name: "one checked",
    validator: (val) => val?.some((k) => k === true),
  }),
  notNull: (): Rule<number | null | string | boolean> => ({
    name: "not null",
    validator: (val) => val !== null,
    errorText: "OPEN_ACCOUNT.errors.select_answer",
  }),
  swift: (): Rule<string> => ({
    name: "swift",
    validator: (val) => swiftPattern.test(val),
  }),
  requiredAfterBankSelect: (): Rule<string> => ({
    name: "required after bank selected",
    validator: (val) => Boolean(val?.trim()),
    errorText: "OPEN_ACCOUNT.errors.fill_after_select_bank",
  }),
  phone: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "valid phone",
    source: booleanSource,
    validator: (val, _, needToValidate) => validate(val, needToValidate, phonePattern),
  }),
  website: (booleanSource?: Store<boolean>, required?: boolean): Rule<string> => ({
    name: "valid website",
    source: booleanSource,
    validator: (val, _) => {
      if (required && val === webEnum.HTTPS) {
        return false;
      }
      return true;
    },
    errorText: "VALIDATION.required-field",
  }),
  password: (booleanSource?: Store<boolean>, isNeedError?: boolean): Rule<string> => ({
    name: "password is valid",
    source: booleanSource,
    validator: (val) => passwordPattern.test(val),
    errorText: isNeedError ? "VALIDATION.weak-password" : "",
  }),
  listCheckbox: () => ({
    name: "mandatory checkbox",
    validator: (val: unknown[]) => {
      return Boolean(val.length);
    },
    errorText: "VALIDATION.required-field",
  }),
  noDoubleSpaces: () => ({
    name: "noDouble spaces",
    validator: (val?: string) => {
      if (val) {
        return noDoubleSpacesPattern.test(val);
      } else {
        return true;
      }
    },
    errorText: "VALIDATION.no_double_spaces",
  }),
  phoneNumberIsValid: (currentCountyCode: Store<string | undefined>): Rule<string> => ({
    name: "phone number is valid",
    validator: (phoneNumber: string) => {
      const replaceLogin = phoneNumber.replace(notDigitPattern, "");

      const numberIsValid = phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(replaceLogin, currentCountyCode.getState()),
        currentCountyCode.getState()
      );
      return numberIsValid;
    },
    errorText: "REGISTRATION.error.invalid-number",
  }),
  phoneNumberIsField: (countryCodes: Store<CountryCode[]>, currentCodeIso: Store<string>): Rule<string> => ({
    name: "phone number is field",
    validator: (phoneNumber: string) => {
      const phoneNumberOnlyDigits = "+" + phoneNumber.replace(notDigitPattern, "");
      const currentCountry = countryCodes.getState().find((country) => country.codeIso === currentCodeIso.getState());
      const phoneCodeLength = currentCountry?.phoneCode?.length ? currentCountry?.phoneCode?.length : 0;

      const phoneLength = parser
        .parse(currentCountry?.phoneMask ? `1[${phoneCodeLength}] ` + currentCountry?.phoneMask : "")
        .replace(notDigitPattern, "").length;

      return phoneNumberOnlyDigits.length === phoneLength;
    },
    errorText: "VALIDATION.required-field",
  }),
  email: (booleanSource?: Store<boolean>): Rule<string> => ({
    name: "email",
    source: booleanSource,
    validator: (val, _, needToValidate) => validate(val, needToValidate, emailPattern),
    errorText: "VALIDATION.email",
  }),
};

export const validate = (value: string, needToValidate: boolean, pattern: RegExp) => {
  if (needToValidate !== null) return needToValidate && value ? pattern.test(value) : true;
  return value ? pattern.test(value) : true;
};

export const cityRules = (validation: any, required = false) => {
  const localeRules = [rules.less(100, validation), rules.city(validation), rules.noDoubleSpaces()];
  return isRequired(required, localeRules, validation);
};

export const stateRules = (validation: any, required = false) => {
  const localeRules = [rules.less(50, validation), rules.state(validation), rules.noDoubleSpaces()];
  return isRequired(required, localeRules, validation);
};

export const streetRules = (validation: any, required = false) => {
  const localeRules = [rules.less(100, validation), rules.street(validation), rules.noDoubleSpaces()];
  return isRequired(required, localeRules, validation);
};

export const houseRules = (validation: any, required = false) => {
  const localeRules = [rules.less(30, validation), rules.house(validation), rules.noDoubleSpaces()];
  return isRequired(required, localeRules, validation);
};

export const buildingRules = (validation: any, required = false) => {
  const localeRules = [rules.less(20, validation), rules.building(validation), rules.noDoubleSpaces()];
  return isRequired(required, localeRules, validation);
};

export const apartmentRules = (validation: any, required = false) => {
  const localeRules = [rules.less(10, validation), rules.apartment(validation), rules.noDoubleSpaces()];
  return isRequired(required, localeRules, validation);
};

export const zipCodeRules = (validation: any, required = false) => {
  const localeRules = [
    rules.lessAndMoreWithoutT(moreAndMinLength.zipCode.max, moreAndMinLength.zipCode.min, validation),
    rules.zipCode(validation),
    rules.noDoubleSpaces(),
  ];
  return isRequired(required, localeRules, validation);
};

export const websiteRules = (validation: any, required = false) => {
  return [rules.less(250, validation), rules.website(validation, required)];
};

export const positionRules = (validation: any, required = false) => {
  const localeRules = [rules.less(50, validation), rules.position(validation)];
  return isRequired(required, localeRules, validation);
};

export const phoneRules = (validation: any, required = false) => {
  const localeRules = [rules.less(30, validation)];
  return isRequired(required, localeRules, validation);
};

export const employerRules = (validation: any, required = false) => {
  const localeRules = [rules.less(50, validation), rules.companyName(validation)];
  return isRequired(required, localeRules, validation);
};

export const entityRules = (validation: any, required = false) => {
  const localeRules = [rules.less(50, validation), rules.companyName(validation)];
  return isRequired(required, localeRules, validation);
};
export const otherActivityRules = (validation: any, required = false) => {
  const localeRules = [rules.less(100)];
  return isRequired(required, localeRules, validation);
};

const isRequired = (required: boolean, localeRules: any, validation: any) => {
  if (required) {
    return [rules.required(validation), ...localeRules];
  }
  return localeRules;
};
export const required = true;
export const maxLengthPassword = 50;
export const minLengthPassword = 8;
