import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "~/i18n";
import colorTable from "~/shared/ui-kit/constants/colors";

const useStyles = makeStyles()({
  countDown: {
    fontSize: "14px",
    color: colorTable.charcoalBlack,
    lineHeight: "20px",
    cursor: "pointer",
  },
});

type CountDownProps = {
  counter: number;
  setCounter: (counter: number) => void;
};

export const CountDown = ({ counter, setCounter }: CountDownProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return <Box className={classes.countDown}>{t("SMS_CONFIRM.actions.timer", { secs: counter })}</Box>;
};
