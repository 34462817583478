import { makeStyles } from "tss-react/mui";

import theme from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  warningText: {
    margin: 0,
    marginLeft: "11px",
    color: "black",
  },
  warningTextMobile: {
    fontSize: "12px",
    lineHeight: "16px",
    padding: "5px",
    width: "90%",
  },
  warning: {
    display: "flex",
    marginBottom: "64px",
    background: colorTable.lightBlue,
    padding: "24px",
    borderRadius: "8px",
    [theme.breakpoints.down(576)]: {
      marginTop: "20px",
      marginBottom: "20px",
      padding: "15px",
    },
  },
  warningInner: {
    display: "flex",
    gap: "16px",
  },
  documentBannerText: {
    color: colorTable.charcoalBlack,
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    margin: 0,
    width: "96.5%",
  },
  documentsIcon: {
    display: "flex",
    marginTop: "40px",
    width: "100%",
    "& div": {
      width: "50%",
      display: "flex",
      justifyContent: "space-around",
      "& img": {
        maxHeight: "163px",
      },
    },
    [theme.breakpoints.down(576)]: {
      marginTop: "20px",
      flexWrap: "wrap",
      "& div": {
        width: "100%",
        height: "50%",
        display: "flex",
        justifyContent: "space-around",
        paddingBottom: "22px",
        "& img": {
          minHeight: "110px",
          maxHeight: "270px",
          height: "auto",
        },
      },
    },
  },
});
