import { AxiosError } from "axios";
import { createDomain, createEffect, forward, sample } from "effector";
import { createGate } from "effector-react";
import { createReEffect } from "effector-reeffect";
import moment from "moment";

import { dateTypes, links, serviceTypesEnum } from "~/constants/enums";
import { accountsApi } from "~/entities/accounts/api";
import { banksMeta, currenciesCodes } from "~/entities/banks/api";
import { hasActiveAgreementStatus } from "~/entities/client-card";
import { productBuyApi, productsApi } from "~/entities/productBuy";
import {
  AgreementStatusEnum,
  AgreementTypeEnum,
  TAccountAgreement,
  TAccountAgreementOverrided,
  THoldBlockedOperation,
} from "~/entities/productBuy/models/ClientCard";
import { t } from "~/i18n";
import { brokerServiceApi } from "~/pages/OpenAccount/services/api";
import { TCurrency } from "~/pages/OpenAccount/services/types";
import { notificationModel } from "../notification-model";

const getAccountAgreementsFx = createEffect<void, TAccountAgreement[], AxiosError>({
  handler: productBuyApi.getAccountAgreements,
});
const getCurrenciesFx = createEffect<void, TCurrency[], AxiosError>({
  handler: brokerServiceApi.searchCurrencyAll,
});
const banksMetaFx = createReEffect({ handler: banksMeta });
const getProcessBSFx = createReEffect({ handler: accountsApi.processesAvailableV2 });
const getProcessAMFx = createReEffect({ handler: accountsApi.processesAvailableV2 });
const currenciesCodesFx = createReEffect<any, any, Error>({
  handler: currenciesCodes,
});
const getAccountsFx = createReEffect<any, any, Error>({
  handler: productsApi.getAccounts,
});

const clientCardDomain = createDomain("clientCardDomain");

const gate = createGate({ domain: clientCardDomain });

const professionalStatusGate = createGate({ domain: clientCardDomain });

const setArchiveOpen = clientCardDomain.createEvent<boolean>();
const setIsArchiveAssetOpen = clientCardDomain.createEvent<boolean>();
const setIsAssetManagment = clientCardDomain.createEvent<boolean>();
const getProcessBS = clientCardDomain.createEvent();
const getProcessAM = clientCardDomain.createEvent();
const getClientCard = clientCardDomain.createEvent();
const setStartAgreement = clientCardDomain.createEvent<boolean>();
const restartGetProcces = clientCardDomain.createEvent();

forward({ from: [gate.open, getClientCard], to: [getCurrenciesFx, currenciesCodesFx] });
forward({ from: getCurrenciesFx.done, to: getAccountAgreementsFx });

const $currenciesAll = clientCardDomain.createStore<TCurrency[]>([]);
$currenciesAll.on(getCurrenciesFx.doneData, (_, data) => data);

const $isArchiveOpen = clientCardDomain.createStore<boolean>(false);
$isArchiveOpen.on(setArchiveOpen, (_, data) => data);

const $isArchiveAssetOpen = clientCardDomain.createStore<boolean>(false);
$isArchiveAssetOpen.on(setIsArchiveAssetOpen, (_, data) => data);

const $isAssetManagment = clientCardDomain.createStore<boolean>(false);
$isAssetManagment.on(setIsAssetManagment, (_, data) => data);

const $blockValue = clientCardDomain.createStore<THoldBlockedOperation[] | null>(null);

const $clientCard = clientCardDomain.createStore<TAccountAgreementOverrided[]>([]);

const $agreements = clientCardDomain.createStore<TAccountAgreementOverrided[]>([]);
$agreements.reset(gate.close);

const $newAgreements = clientCardDomain.createStore<TAccountAgreementOverrided[]>([]);
$newAgreements.reset(gate.close);

const $bankLocations = clientCardDomain.createStore<any>([]);
$bankLocations.on(banksMetaFx.doneData, (state, data) => [...state, data]);
$bankLocations.reset(gate.close);

const $processBS = clientCardDomain.createStore<any>(null);
$processBS.on(getProcessBSFx.doneData, (_, data) => data?.reason);
$processBS.reset(gate.close);

const $processAM = clientCardDomain.createStore<any>(null);
$processAM.on(getProcessAMFx.doneData, (_, data) => data.reason);
$processAM.reset(gate.close);

const $currencies = clientCardDomain.createStore<any>([]);
$currencies.reset(gate.close);
$currencies.on(currenciesCodesFx.doneData, (_, data) => data);

const $isFirstStartAgreements = clientCardDomain.createStore(false);
$isFirstStartAgreements.on(setStartAgreement, (_, payload) => payload);

const $hasBoOrDu = clientCardDomain.createStore(false);
sample({
  clock: getAccountAgreementsFx.doneData,
  fn: (data) => {
    const isHasBoOrDu =
      data.filter(
        (item) =>
          [AgreementTypeEnum.GENERAL_BO, AgreementTypeEnum.GENERAL_DU].includes(item.type) &&
          item.status === AgreementStatusEnum.ACTIVE
      ).length > 0;

    if (!isHasBoOrDu && professionalStatusGate.status.getState()) {
      notificationModel.pushTo(links.profile);
    }

    return isHasBoOrDu;
  },
  target: $hasBoOrDu,
});
$hasBoOrDu.reset(gate.close);

const $hasActiveBo = clientCardDomain.createStore(false);
$hasActiveBo.on(
  getAccountAgreementsFx.doneData,
  (_, accountAgreements) =>
    !!accountAgreements.find(
      (item) => AgreementTypeEnum.GENERAL_BO === item.type && item.status === AgreementStatusEnum.ACTIVE
    )
);
$hasActiveBo.reset(gate.close);

const setIsNotBA = clientCardDomain.createEvent<boolean>();

sample({
  source: getAccountAgreementsFx.doneData,
  target: [getProcessAM, getProcessBS],
});

sample({
  clock: restartGetProcces,
  target: [getProcessAM, getProcessBS],
});

sample({
  clock: getProcessBS,
  fn: () => ({
    serviceType: serviceTypesEnum.BROKER_SERVICE,
  }),
  target: getProcessBSFx,
});

sample({
  clock: getProcessAM,
  fn: () => ({
    serviceType: serviceTypesEnum.ASSET_MANAGEMENT,
  }),
  target: getProcessAMFx,
});

forward({ from: getAccountAgreementsFx.doneData, to: getAccountsFx });

sample({
  clock: getAccountAgreementsFx.doneData,
  source: getCurrenciesFx.doneData,
  fn: (currencies, agreements) => {
    return agreements.map((agreement) => ({
      ...agreement,
      openDate: moment(agreement.openDate).isValid() ? moment(agreement.openDate).format(dateTypes.RUS_DATE) : "",
      brokerAccounts: agreement.brokerAccounts.map((account) => ({
        ...account,
        brokerAccountDetails: account.brokerAccountDetails.map((detail) => {
          const currency = currencies.find((currency) => currency.id === detail.currencyId);

          return {
            ...detail,
            currencyCode: currency?.numericCode,
            currencySymbol: currency?.symbol,
            currencyName: currency?.alphabeticCode,
          };
        }),
      })),
    }));
  },
  target: $clientCard,
});

sample({
  source: $clientCard,
  fn: () => true,
  target: setStartAgreement,
});

sample({
  source: $clientCard,
  clock: [getAccountsFx.doneData, $clientCard],
  fn: (agreements, accounts) => {
    if (agreements.length) {
      const activeAgreement = agreements.filter((i) => hasActiveAgreementStatus(i.status));
      if (activeAgreement.length) {
        activeAgreement.sort(
          (a, b) =>
            Date.parse(moment(a.openDate, ["MM-DD-YYYY", "DD-MM-YYYY"]).format("MM-DD-YYYY")) -
            Date.parse(moment(b.openDate, ["MM-DD-YYYY", "DD-MM-YYYY"]).format("MM-DD-YYYY"))
        );
      } else {
        agreements.sort((a, b) => Date.parse(b.openDate) - Date.parse(a.openDate));
      }

      const agreementsWithGroup = activeAgreement.map((agreement) => {
        agreement.brokerAccounts.map((account) => {
          const currentAccount = accounts.content?.find(
            (account: any) => account.accountNumber === account.brokerLocation
          );
          account.group = currentAccount?.locationGroup;

          return account;
        });
        return agreement;
      });

      return agreementsWithGroup;
    } else {
      return [];
    }
  },
  target: $newAgreements,
});

sample({
  source: $newAgreements,
  fn: (agreements) => {
    const newAgreements: TAccountAgreementOverrided[] = [...agreements];
    newAgreements.forEach((agreement) => {
      agreement.brokerAccounts.forEach(async (account) => {
        account.brokerBank = null;
        try {
          const meta = await banksMetaFx(account.number);
          account.brokerBank = meta?.shortName;
        } catch (e) {
          //@ts-ignore
          console.error(e.error);
        }
      });
    });
    return newAgreements;
  },
  target: $agreements,
});

sample({
  source: $agreements,
  fn: (agreements) => {
    const newBlockValues: THoldBlockedOperation[] = [];
    agreements.forEach((agreement) => {
      if (!!agreement?.holdBlockedOperations?.length) {
        newBlockValues.push(...agreement.holdBlockedOperations);
      }
    });
    return newBlockValues;
  },
  target: $blockValue,
});

forward({ from: setIsNotBA, to: notificationModel.error.prepend(() => t("VALIDATION.is_not_BA")) });

getAccountAgreementsFx.fail.watch((failData: any) => {
  if (failData?.error?.error?.response?.status !== 401) {
    notificationModel.errorDefaultServer(failData);
  }
});

currenciesCodesFx.fail.watch((failData: any) => {
  notificationModel.errorDefaultServer(failData);
});

export const clientCardModel = {
  $clientCard,
  $agreements,
  getAccountAgreementsFx,
  $getClientCardPending: getAccountAgreementsFx.pending,
  $getProcessAMFxPending: getProcessAMFx.pending,
  $getProcessBSFxPending: getProcessBSFx.pending,
  $banksMetaPending: banksMetaFx.pending,
  $getAccountsPending: getAccountsFx.pending,
  getAccountsFx,
  clientCardGate: gate,
  setIsNotBA,
  $isArchiveOpen,
  $isArchiveAssetOpen,
  setArchiveOpen,
  setIsArchiveAssetOpen,
  $isAssetManagment,
  setIsAssetManagment,
  $blockValue,
  $processBS,
  $processAM,
  getProcessBS,
  getProcessAM,
  $currencies,
  $currenciesCodesPending: currenciesCodesFx.pending,
  getClientCard,
  $isFirstStartAgreements,
  restartGetProcces,
  setStartAgreement,
  $hasBoOrDu,
  $hasActiveBo,
  $currenciesAll,
  professionalStatusGate,
};
