export const OTP_SECRET_ERRORS = {
  otpSecretIsIncorrect: "Field otpSecret is incorrect",
};

export const moreAndMinLength = {
  zipCode: {
    min: 4,
    max: 14,
  },
  password: {
    min: 8,
    max: 100,
  },
};
