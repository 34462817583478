import React from "react";
import { useEvent, useStore } from "effector-react";

import { useTranslation } from "~/i18n";
import { documentsListModel } from "~/modules/documents";
import { BaseButton } from "~/shared/ui-kit/BaseComponents";
import { useStyles } from "./styles";

export const DownloadDocW8Btn = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { $docW8DownloadLoading, downloadW8DocBtnEvt } = documentsListModel;
  const docW8DownloadLoading = useStore($docW8DownloadLoading);
  const downloadW8Doc = useEvent(downloadW8DocBtnEvt);

  return (
    <BaseButton
      disabled={docW8DownloadLoading}
      className={classes.blackButton}
      onClick={downloadW8Doc}
      typeStyle="white"
    >
      {t("MODAL_SUCCESS.download_form")}
    </BaseButton>
  );
};
