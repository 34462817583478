import React from "react";
import { Box, Button } from "@mui/material";
import cn from "classnames";

import { useStyles } from "./styles";
import { BaseOperationResultProps } from "./types";

export const BaseOperationResult = ({
  titleText,
  children,
  buttonText,
  className,
  isError,
  srcIconStatus,
  alignLeft,
  handlerClickBtn,
}: BaseOperationResultProps) => {
  const { classes } = useStyles();

  return (
    <Box className={cn(classes.blockOperationResult, className, alignLeft && classes.alignLeft)}>
      <div className={cn(classes.wrapperIconStatus, isError && classes.statusError)}>
        <img src={srcIconStatus} alt="status" />
      </div>
      <h2 className={classes.titleOperationResult}>{titleText}</h2>
      {children && <p className={classes.additionalInformation}>{children}</p>}
      <Button className={classes.buttonNext} onClick={handlerClickBtn}>
        {buttonText}
      </Button>
    </Box>
  );
};
