import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import cn from "classnames";

import { BaseTypography, type BaseTypographyProps } from "~/shared/ui-kit/BaseComponents";
import Tooltip from "../Tooltip";
import { useStyles } from "./styles";

export interface FormBlockProps {
  children: ReactNode | ReactNode[];
  title?: string;
  subtitle?: string;
  className?: string;
  tooltipText?: React.ReactNode;
  isNotBorder?: boolean;
  isNotPadding?: boolean;
  fontType?: BaseTypographyProps<"p">["fontType"];
  contentStyle?: string;
}

export default (props: FormBlockProps) => {
  const { classes } = useStyles();

  return (
    <Box
      className={cn(
        classes.formBlock,
        props.isNotBorder && classes.notBorder,
        props.isNotPadding && classes.notPadding,
        props.className
      )}
    >
      {props.title && (
        <BaseTypography tag="p" fontType={props.fontType || "title_2_semiBold"} className={classes.title}>
          {props.title}
          {props.tooltipText && <Tooltip tooltipText={props.tooltipText} classNameWrapIcon={classes.wrapIconTooltip} />}
        </BaseTypography>
      )}
      {props.subtitle && (
        <BaseTypography tag="p" fontType="text" className={classes.subtitle}>
          {props.subtitle}
        </BaseTypography>
      )}
      <Box className={cn((props.subtitle || props.title) && classes.content, props.contentStyle)}>{props.children}</Box>
    </Box>
  );
};
