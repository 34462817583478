import { createDomain } from "effector";

import { TNotificationParams } from "./types";

const domain = createDomain("notification modal");

const setShowNotificationModal = domain.createEvent<boolean>();
const setNotificationParams = domain.createEvent<TNotificationParams>();

const $showNotificationModal = domain.createStore<boolean>(false);
$showNotificationModal.on(setShowNotificationModal, (_, data) => data);

const $notificationParams = domain.createStore<TNotificationParams>({
  title: "",
  descriptions: [],
  buttons: [],
  icon: "timeIcon",
  iconBackground: null,
  maxWidthContent: null,
});
$notificationParams.on(setNotificationParams, (_, data) => data);

export const notificationModalModel = {
  $notificationParams,
  $showNotificationModal,
  setShowNotificationModal,
  setNotificationParams,
};
