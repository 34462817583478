import { makeStyles } from "tss-react/mui";

import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export default makeStyles()(() => ({
  checkboxField: {
    display: "flex",
    alignItems: "start",
    gap: "0 16px",
    cursor: "pointer",
    width: "fit-content",

    "&.disabled": {
      cursor: "not-allowed",
    },
  },
  checkbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    maxWidth: "24px",
    maxHeight: "24px",
    minWidth: "24px",
    minHeight: "24px",
    border: `1px solid ${borderColors.greyContour}`,
    background: colorTable.white,
    boxSizing: "border-box",
    "&:hover": {
      border: `1px solid ${borderColors.charcoalBlack}`,
    },

    "&.disabled": {
      background: colorTable.lightGrey,
      border: `1px solid ${borderColors.greyContour}`,
    },

    "&.checked": {
      background: colorTable.charcoalBlack,
      border: "none",
    },

    "&.checked.disabled": {
      background: colorTable.greyContour,
    },
  },
  inputCheckbox: {
    position: "absolute",
    zIndex: "-1000",
    opacity: 0,
  },
  checkIcon: {
    color: colorTable.white,
    width: "10px",
    height: "8px",
    display: "none",

    "&.checked": {
      display: "block",
    },
  },
  label: {
    color: colorTable.charcoalBlack,
    cursor: "inherit",

    "&.disabled": {
      color: colorTable.moonGrey,
    },
  },
}));
