import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyle = makeStyles()(() => ({
  phoneSelect: {
    minWidth: "80px",
    height: "48px",
    [themeV5.breakpoints.down(768)]: {
      minWidth: "115px",
    },
    color: `${colorTable.charcoalBlack} !important`,
    "& > div > div > span": {
      display: "none",
    },
    "& > div > span": {
      display: "none",
    },
    "& > div > .MuiInputBase-input.MuiOutlinedInput-input": {
      padding: "0 !important",
    },
    "&:hover fieldset": {
      border: `1px solid ${borderColors.nobleOchre} !important`,
    },
  },
  phoneOption: {
    width: "100%",
    display: "flex !important",
    justifyContent: "space-between !important",
    padding: "16px!important",
    "&::before": {
      display: "none !important",
    },
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  imageCountry: {
    width: "24px",
    height: "24px",
    marginRight: "12px",
    objectFit: "cover",
    borderRadius: "50%",
    border: `1px solid ${borderColors.lightGrey_50}`,
  },
}));
