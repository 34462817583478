import React from "react";
import { ClickAwayListener, useMediaQuery } from "@mui/material";

import Content, { ITooltipProps } from "./Content";

export default (props: ITooltipProps) => {
  const isTabletOrMobile = useMediaQuery("(max-width: 992px)");

  const [open, setOpen] = React.useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  if (!isTabletOrMobile) {
    return (
      <Content tooltipText={props.tooltipText} classNameWrapIcon={props.classNameWrapIcon}>
        {props.children}
      </Content>
    );
  }

  return (
    <ClickAwayListener onClickAway={onClose}>
      <span>
        <Content
          tooltipText={props.tooltipText}
          classNameWrapIcon={props.classNameWrapIcon}
          onOpen={onOpen}
          open={open}
        >
          {props.children}
        </Content>
      </span>
    </ClickAwayListener>
  );
};
