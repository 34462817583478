import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export default makeStyles()(() => ({
  backButton: {
    fontSize: "16px !important",
    marginBottom: "36px !important",
  },
  title: {
    color: colorTable.charcoalBlack,
  },
  titleBox: {
    paddingBottom: "24px",
    [themeV5.breakpoints.down(768)]: {
      paddingBottom: "16px",
    },
  },
  titleMargin: {
    marginBottom: "8px",
  },
  subtitle: {
    color: colorTable.textSecondary,
    marginBottom: "24px",
    [themeV5.breakpoints.down(1024)]: {
      marginBottom: "0px",
    },
  },
  linkWarning: {
    textDecoration: "none",
    color: colorTable.charcoalBlack,
  },
  spacer: {
    width: "100vw",
    position: "relative",
    left: "calc(-50vw + 50%)",
    height: "40px",
    background: "linear-gradient(180deg, #F4F7FC 0%, rgba(255, 255, 255, 0) 100%)",

    [themeV5.breakpoints.down(1024)]: {
      height: "24px",
    },
  },
  linearProgress: {
    padding: "40px 0px 24px 0px",
    [themeV5.breakpoints.down(1024)]: {
      padding: "24px 0px",
    },
  },
  wrapperBtn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "64px",

    [themeV5.breakpoints.down(768)]: {
      paddingTop: "40px",
      flexDirection: "column-reverse",
      gap: "16px 0",
    },
  },
  button: {
    width: "135px",

    [themeV5.breakpoints.down(768)]: {
      width: "100%",
    },
  },
}));
