import { CheckProductTypeRequest, CheckProductTypeResponse } from "~/entities/productBuy/models/ProductTypes";
import { get, post } from "~/lib/request";
import { offersListSort } from "~/lib/sortTransform";
import { getUserTokenInfo } from "~/modules/userToken/api";
import { TAccount } from "~/shared/types";
import {
  ApplicationDocumentRequest,
  ApplicationDocumentResponse,
  ApplicationOtpCheckRequest,
  ApplicationOtpCheckResponse,
  ApplicationRequest,
} from "./models/Application";
import { TAccountAgreement } from "./models/ClientCard";
import { CommissionRequest, ConvertCurrencyRequest } from "./models/Commission";
import { Offer, OfferRequest } from "./models/Offer";
import { OfferListRequest, OfferListResponse } from "./models/OfferList";
import { ProductCountSentRequest } from "./models/ProductCountSent";

export const productBuyApi = {
  getOfferList: async (_requestData: OfferListRequest): Promise<OfferListResponse> => {
    const res = await get(
      "@msspproduct/v3/front/offer",
      {
        params: {
          masterAgreement:
            _requestData?.numberAgreement && _requestData?.numberAgreement.length
              ? "IN," + _requestData.numberAgreement.join(",")
              : null,
          jurisdictionName: "EQ,Dubai",
          partnerId: process.env.REACT_APP_PARTNER_ID,
          sort: "createDate-",
          pageNum: 1,
          pageLimit: _requestData.pageLimit,
        },
      },
      {
        isPrivate: false,
      }
    );
    return { items: res.data.items, countItems: res.data.countItems };
  },

  getOfferPoolList: async (_requestData: OfferListRequest): Promise<OfferListResponse> => {
    const res = await get(
      "@msspproduct/v3/front/offer",
      {
        params: {
          jurisdictionName: "EQ,Dubai",
          partnerId: process.env.REACT_APP_PARTNER_ID,
          sort: offersListSort(_requestData.sort),
        },
      },
      {
        isPrivate: false,
      }
    );
    return { items: res.data.items, countItems: res.data.countItems };
  },

  getAccountAgreements: async (): Promise<TAccountAgreement[]> => {
    const params = {
      clientId: getUserTokenInfo().id,
    };
    const res = await get("@auth/account/agreements", { params });

    return res.data.content;
  },

  getOffer: async (data: OfferRequest): Promise<Offer> => {
    const res = await get(
      `@msspproduct/v3/front/offer/${data.id}`,
      {
        params: {
          partnerId: process.env.REACT_APP_PARTNER_ID,
        },
      },
      {
        isPrivate: false,
      }
    );

    return { ...res.data };
  },

  getCommission: async (requestData: CommissionRequest): Promise<number> => {
    const clientId = getUserTokenInfo().id;
    const res = await get("@msbuyingproduct/v1/admin/application/commission", {
      params: {
        offerId: requestData.offerId,
        account: requestData.account,
        agreementNumber: requestData.agreementNumber,
        investmentSum: requestData.investmentSum,
        clientId,
      },
    });
    return res.data;
  },
  getConvertedCurrency: async (requestData: ConvertCurrencyRequest): Promise<any> => {
    const clientId = getUserTokenInfo().id;
    const res = await get("@msbuyingproduct/v1/admin/application/totalAmount", {
      params: {
        price: requestData.price,
        investmentSum: requestData.investmentSum,
        currency: requestData.currency,
        currencySettlement: requestData.currencySettlement,
        clientId,
      },
    });
    return res.data;
  },
  postApplication: async (requestData: ApplicationRequest): Promise<number> => {
    const params = {
      clientId: getUserTokenInfo().id,
      clientLogin: requestData.edoAgreementNumber,
    };
    const res = await post(
      "@msbuyingproduct/v1/admin/application/start",
      requestData.priceFactor
        ? {
          data: {
            account: requestData.account,
            agreementNumber: requestData.agreementNumber,
            investmentSum: requestData.investmentSum,
            offerId: requestData.offerId,
            partnerId: process.env.REACT_APP_PARTNER_ID,
            confirmationFlag: requestData.confirmationFlag,
            viewedFiles: requestData.viewedFiles,
            priceFactor: requestData.priceFactor,
            accountCurrency: requestData.accountCurrency,
            locationUuid: requestData.locationUuid,
          },
          params,
        }
        : {
          data: {
            account: requestData.account,
            agreementNumber: requestData.agreementNumber,
            investmentSum: requestData.investmentSum,
            offerId: requestData.offerId,
            partnerId: process.env.REACT_APP_PARTNER_ID,
            confirmationFlag: requestData.confirmationFlag,
            viewedFiles: requestData.viewedFiles,
            accountCurrency: requestData.accountCurrency,
            locationUuid: requestData.locationUuid,
          },
          params,
        }
    );
    return res.data;
  },

  getApplicationDocument: async (requestData: ApplicationDocumentRequest): Promise<ApplicationDocumentResponse> => {
    const clientId = getUserTokenInfo().id;
    const res = await get(`@msbuyingproduct/v1/admin/application/${requestData.applicationId}/document`, {
      params: { view: requestData.view, clientId },
    });
    return { ...res.data };
  },

  applicationSign: async (applicationId: number) => {
    const params = {
      clientId: getUserTokenInfo().id,
    };
    return await post(`@msbuyingproduct/v1/admin/application/${applicationId}/sign`, { params });
  },

  applicationResend: async (applicationId: number) => {
    const params = {
      clientId: getUserTokenInfo().id,
    };
    return await post(`@msbuyingproduct/v1/admin/application/${applicationId}/resend`, { params });
  },

  applicationOtpCheck: async (requestData: ApplicationOtpCheckRequest): Promise<ApplicationOtpCheckResponse> => {
    const params = {
      clientId: getUserTokenInfo().id,
    };
    const res = await post(
      `@msbuyingproduct/v1/admin/application/${requestData.applicationId}/check/${requestData.otp}`,
      { params }
    );
    return { ...res.data };
  },
  checkProductType: async (data: CheckProductTypeRequest): Promise<CheckProductTypeResponse> => {
    const params = {
      clientId: getUserTokenInfo().id,
    };
    const checkedGuids = [];
    for (let guid of data.guids) {
      const res = await get(`@msbuyingproduct/v1/admin/dictionary/product-types/${guid}`, { params });
      checkedGuids.push(res.data);
    }
    return checkedGuids;
  },
  applicationStatusCount: async (requestData: ProductCountSentRequest) => {
    const clientId = getUserTokenInfo().id;
    const res = await get(`@msbuyingproduct/v1/admin/application/count/${requestData.status}`, {
      params: {
        offerIds: requestData.offerIds.join(","),
        clientId,
      },
    });
    return { ...res.data };
  },
  getTwa: async (data: any) => {
    const clientId = getUserTokenInfo().id;
    const res = await get("@msbuyingproduct/v1/admin/application/document/tmd", {
      params: {
        offerId: data.id,
        clientId,
      },
    });
    return { ...res.data };
  },
  generateTwa: async (data: any) => {
    const clientId = getUserTokenInfo().id;
    const res = await post("@msbuyingproduct/v1/admin/application/document/tmd", {
      params: {
        offerId: data.id,
        clientId,
      },
    });
    return { ...res.data };
  },
};

export const productsApi = {
  //в дальнейшем изменить на @profile
  getForwards: async () => {
    const params = {
      clientId: getUserTokenInfo().id,
      page: 0,
      size: 50,
    };
    const res = await get("@auth/profile/api/v1/assets/forwards", {
      params,
    });
    return res.data;
  },
  getPreIpos: async () => {
    const params = {
      clientId: getUserTokenInfo().id,
      page: 0,
      size: 50,
    };
    const res = await get("@auth/profile/api/v1/assets/pre-ipos", {
      params,
    });
    return res.data;
  },
  getOthers: async () => {
    const params = {
      clientId: getUserTokenInfo().id,
      page: 0,
      size: 50,
    };
    const res = await get("@auth/profile/api/v1/assets/others", {
      params,
    });
    return res.data;
  },
  getNotes: async () => {
    const params = {
      clientId: getUserTokenInfo().id,
      page: 0,
      size: 50,
    };
    const res = await get("@auth/profile/api/v1/assets/notes", {
      params,
    });
    return res.data;
  },
  getAccounts: async (): Promise<{ content: TAccount[] }> => {
    const params = {
      clientId: getUserTokenInfo().id,
      page: 0,
      size: 50,
    };
    const res = await get("@auth/profile/api/v1/accounts", {
      params,
    });
    return res.data;
  },
  getDate: async () => {
    const params = {
      clientId: getUserTokenInfo().id,
      page: 0,
      size: 50,
    };
    const res = await get("@auth/profile/api/v1/profile/dates", {
      params,
    });
    return res.data;
  },
  getUnderlyingAssets: async (requestData: any) => {
    const res = await get("@msrequestsmsspdictionary/asset/by-isin-or-ticker", {
      params: { isin: `${requestData}` },
    });
    return res.data;
  },
};
