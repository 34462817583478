import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()(() => ({
  investHead: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    gap: "16px",
    [themeV5.breakpoints.down(768)]: {
      flexWrap: "wrap",
    },
  },
  filterButton: {
    [themeV5.breakpoints.up(991)]: {
      "&:hover": {
        "& svg": {
          color: colorTable.nobleOchre,
        },
      },
    },
  },
  filterCounter: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    background: colorTable.red,
    color: colorTable.white,
    whiteSpace: "nowrap",
  },
  filtersBox: {
    zIndex: 5,
    top: "38px",
    right: 0,
    position: "absolute",
    width: "832px",
    padding: "24px 26px",
    borderRadius: "8px",
    background: colorTable.white,
    boxShadow: "0 40px 80px 0 rgba(0, 0, 0, 0.16), 0 20px 40px 0 rgba(0, 0, 0, 0.04)",
    [themeV5.breakpoints.down(910)]: {
      width: "100%",
    },
  },
  filterHead: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "24px",
  },
  btnWrap: {
    display: "flex",
    gap: "12px",
  },
}));
