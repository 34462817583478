import React from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import "./style.css";

import { t } from "~/i18n";
import Icon from "~/shared/Icon";

interface OAStepProgressBarProps {
  page: number;
}

type accomplishedStep = {
  accomplished: boolean;
};

const OAStepProgressBarSow = ({ page }: OAStepProgressBarProps) => {
  let stepPercentage;
  switch (page) {
    case 1:
      stepPercentage = 0;
      break;
    case 2:
      stepPercentage = 18;
      break;
    case 3:
      stepPercentage = 40;
      break;
    case 4:
      stepPercentage = 60;
      break;
    case 5:
      stepPercentage = 80;
      break;
    case 6:
      stepPercentage = 105;
      break;
    default:
      stepPercentage = 0;
  }

  return (
    <div className="progressBarWrapper">
      <ProgressBar percent={stepPercentage}>
        <Step>
          {({ accomplished }: accomplishedStep) => (
            <div className="stepWrapper" style={page === 1 ? { display: "block" } : undefined}>
              <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>
                {accomplished && page !== 1 ? (
                  <Icon size={24} name="accomplished" />
                ) : (
                  <Icon size={20} name="personalDetails" />
                )}
              </div>
              <div className="stepText">{t("EDM.personal_details.title")}</div>
            </div>
          )}
        </Step>
        <Step>
          {({ accomplished }: accomplishedStep) => (
            <div className="stepWrapper" style={page === 2 ? { display: "block" } : undefined}>
              <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>
                {accomplished && page !== 2 ? (
                  <Icon size={24} name="accomplished" />
                ) : (
                  <Icon size={20} name="suitcase" />
                )}
              </div>
              <div className="stepText">{t("OPEN_ACCOUNT.stepper.financialProfile")}</div>
            </div>
          )}
        </Step>
        <Step>
          {({ accomplished }: accomplishedStep) => (
            <div className="stepWrapper" style={page === 3 ? { display: "block" } : undefined}>
              <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>
                {accomplished && page !== 3 ? (
                  <Icon size={24} name="accomplished" />
                ) : (
                  <Icon size={20} name="testIcon" />
                )}
              </div>
              <div className="stepText">{t("OPEN_ACCOUNT.stepper.assessmentTest")}</div>
            </div>
          )}
        </Step>
        <Step>
          {({ accomplished }: accomplishedStep) => (
            <div className="stepWrapper" style={page === 4 ? { display: "block" } : undefined}>
              <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>
                {accomplished && page !== 4 ? <Icon size={24} name="accomplished" /> : <Icon size={20} name="W8" />}
              </div>
              <div className="stepText">{t("OPEN_ACCOUNT.stepper.w8")}</div>
            </div>
          )}
        </Step>
        <Step>
          {({ accomplished }: accomplishedStep) => (
            <div className="stepWrapper" style={page === 5 ? { display: "block" } : undefined}>
              <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>
                {accomplished && page !== 5 ? (
                  <Icon size={24} name="accomplished" />
                ) : (
                  <Icon size={20} name="confirmationDocs" />
                )}
              </div>
              <div className="stepText">{t("OPEN_ACCOUNT.stepper.confirmationDocuments")}</div>
            </div>
          )}
        </Step>
        <Step>
          {({ accomplished }: accomplishedStep) => (
            <div className="stepWrapper" style={page === 6 ? { display: "block" } : undefined}>
              <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>
                {accomplished && page !== 6 ? (
                  <Icon size={24} name="accomplished" />
                ) : (
                  <Icon size={20} name="documentsIcon" />
                )}
              </div>
              <div className="stepText">{t("OPEN_ACCOUNT.stepper.documentsSign")}</div>
            </div>
          )}
        </Step>
      </ProgressBar>
    </div>
  );
};

export default OAStepProgressBarSow;
