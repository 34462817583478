import { makeStyles } from "tss-react/mui";

import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useInvestSectionStyles = makeStyles()(() => ({
  titleSectionBO: {
    marginBottom: "32px",
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 400,
    color: colorTable.charcoalBlack,
  },
  contentStyle: {
    paddingTop: "0px",
  },
  titleSection: {
    marginBottom: "40px",
  },
  tooltipSection: {
    transform: "translateY(-2px)",
  },
  checkboxBlock: {
    padding: "24px",
    background: colorTable.lightGrey,
    "flex-direction": "row-reverse !important",
    justifyContent: "space-between !important",
    maxWidth: "463px",
    width: "100% !important",
  },
  checkboxBlockBO: {
    padding: "24px 0",
    width: "100% !important",
    borderBottom: `1px solid ${borderColors.greyContour}`,
  },
  checkboxBlockEducation: {
    height: "100%",
  },
  formBlockEducation: {
    display: "flex",
    flexDirection: "column",
  },
  checkboxBlockInstruments: {
    maxWidth: "unset",
  },
  errorText: {
    color: colorTable.red,
    marginTop: "-12px",
  },
  errorTextBO: {
    color: colorTable.red,
    marginTop: "4px",
  },
  ptInstrumentsWrapSelect: {
    paddingTop: "24px",
  },
}));
