import { ProfileExistenceResponse } from "~/entities/anketa";

import { createAsyncReducer } from "~/lib/redux";
import { success } from "~/lib/redux/async";
import {
  anketaCheckProfileExistenceErrorAction,
  anketaCheckProfileExistenceStartAction,
  anketaCheckProfileExistenceSuccessAction,
  anketaSaveBankSectionSuccessAction,
  anketaSaveCommonSectionSuccessAction,
  anketaSaveEconomySectionSuccessAction,
  anketaSaveInvestSectionSuccessAction,
} from "../actions";

export const STATE_NAME = "profileExistence";

const [reducer, defaultState] = createAsyncReducer(
  anketaCheckProfileExistenceStartAction,
  anketaCheckProfileExistenceSuccessAction,
  anketaCheckProfileExistenceErrorAction
);

export type AnketaProfileExistenceState = typeof defaultState;

reducer.on(
  anketaSaveCommonSectionSuccessAction,
  (state: AnketaProfileExistenceState): AnketaProfileExistenceState =>
    state.data
      ? success<ProfileExistenceResponse>({ ...state.data, commonSectionCreated: true, profileExists: true })
      : state
);

reducer.on(
  anketaSaveEconomySectionSuccessAction,
  (state: AnketaProfileExistenceState): AnketaProfileExistenceState =>
    state.data
      ? success<ProfileExistenceResponse>({ ...state.data, economSectionCreated: true, profileExists: true })
      : state
);

reducer.on(
  anketaSaveBankSectionSuccessAction,
  (state: AnketaProfileExistenceState): AnketaProfileExistenceState =>
    state.data
      ? success<ProfileExistenceResponse>({ ...state.data, bankSectionCreated: true, profileExists: true })
      : state
);

reducer.on(
  anketaSaveInvestSectionSuccessAction,
  (state: AnketaProfileExistenceState): AnketaProfileExistenceState =>
    state.data
      ? success<ProfileExistenceResponse>({ ...state.data, investSectionCreated: true, profileExists: true })
      : state
);

export default reducer;
