import { combineReducers } from 'redux';

import postBrokerReport, {
  RequestsPostBrokerReportState,
  STATE_NAME as POST_BROKER_REPORT_STATE_NAME,
} from './reducers/postBrokerReport';
import postFundsWithdrawal, {
  RequestsPostFundsWithdrawalState,
  STATE_NAME as POST_FUNDS_WITHDRAWAL_STATE_NAME,
} from './reducers/postFundsWithdrawal';
import printForms, { RequestsPrintFormsState, STATE_NAME as PRINT_FORMS_STATE_NAME } from './reducers/printForms';

export const STATE_NAME = 'requests';

export { POST_BROKER_REPORT_STATE_NAME, RequestsPostBrokerReportState };
export { POST_FUNDS_WITHDRAWAL_STATE_NAME, RequestsPostFundsWithdrawalState };
export { PRINT_FORMS_STATE_NAME, RequestsPrintFormsState };

export interface RequestsState {
  [POST_BROKER_REPORT_STATE_NAME]: RequestsPostBrokerReportState;
  [POST_FUNDS_WITHDRAWAL_STATE_NAME]: RequestsPostFundsWithdrawalState;
  [PRINT_FORMS_STATE_NAME]: RequestsPrintFormsState;
}

export default combineReducers<RequestsState>({
  [POST_BROKER_REPORT_STATE_NAME]: postBrokerReport,
  [POST_FUNDS_WITHDRAWAL_STATE_NAME]: postFundsWithdrawal,
  [PRINT_FORMS_STATE_NAME]: printForms,
});
