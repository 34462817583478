import React, { ReactNode } from "react";
import { Box, FormControlLabel, Radio, Typography, useMediaQuery } from "@mui/material";
import cn from "classnames";
import { Event } from "effector";

import { accountActivity } from "~/pages/OpenAccount/services/EconomySectionServices/acсountActivity/accountActivity";
import Icon from "~/shared/Icon";
import { useStyles } from "./styles";

interface BaseActivityItemProps {
  label: string;
  isChecked?: boolean;
  children?: ReactNode;
  radioSetter?: Event<boolean>;
  isFirstElem?: boolean;
  accountActivityService?: {
    setRadio: Event<Event<boolean>>;
  };
}

export const BaseActivityItem = (props: BaseActivityItemProps) => {
  const { label, radioSetter, isChecked, children, accountActivityService } = props;
  const { setRadio } = accountActivityService ? accountActivityService : accountActivity;
  const { classes } = useStyles();

  const isTabletOrMobile = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <Box className={classes.activityItem}>
        <FormControlLabel
          control={
            <Radio
              className={isTabletOrMobile ? classes.checkboxMobile : classes.checkbox}
              checked={isChecked}
              onChange={radioSetter ? () => setRadio(radioSetter) : () => {}}
              checkedIcon={<Icon name="blackCircle" size={24} />}
              icon={<Icon name="grayCircle" size={24} />}
            />
          }
          label={
            <Typography className={cn(classes.label, isTabletOrMobile && classes.labelMobile)}>{label}</Typography>
          }
          labelPlacement="start"
          className={classes.activityItemInner}
        />
      </Box>
      {isChecked && <div style={children ? { marginTop: "16px", marginBottom: "30px" } : {}}>{children}</div>}
    </>
  );
};
