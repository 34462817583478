import { makeStyles } from "tss-react/mui";

import theme from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";
import { IBaseBackButtonProps } from "./ui";

export const useStyles = makeStyles<IBaseBackButtonProps>()(
  (_, { background, mobileTextColor, tabledOrMobileSize }) => ({
    backButton: {
      paddingBottom: "40px",
      [theme.breakpoints.down(992)]: {
        paddingTop: "24px",
        paddingBottom: "6px",
        paddingRight: "10px",
        marginTop: "-40px",
      },
      [theme.breakpoints.down(768)]: {
        paddingBottom: "24px",
      },
    },
    link: {
      color: colorTable.charcoalBlack,
      fontSize: "16px",
      lineHeight: "24px",
      display: "flex",
      gap: "9px",
      alignItems: "center",
      cursor: "pointer",
      minWidth: "150px",
      width: "fit-content",
      [theme.breakpoints.up(992)]: {
        "&:hover": {
          color: colorTable.nobleOchre,
          "& svg": {
            color: colorTable.nobleOchre,
          },
        },
      },
    },
    arrowBack: {
      transform: "rotate(90deg)",
      "& path": {
        strokeWidth: "2px",
      },
    },
    backgroundColor: {
      background: background ?? "transparent",
    },
    mobileTextColor: {
      [theme.breakpoints.down(tabledOrMobileSize ?? 768)]: {
        color: mobileTextColor,
        boxSizing: "border-box",
        height: "55px",
      },
    },
  })
);
