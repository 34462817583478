import React, { ReactHTML, useImperativeHandle, useRef } from "react";
import cn from "classnames";
import omit from "lodash/omit";

import { BaseTypography, BaseTypographyProps } from "~/shared/ui-kit/BaseComponents";
import useStyles from "./styles";

export interface BaseSwitchProps<T extends keyof ReactHTML> extends React.ComponentProps<"input"> {
  typographyProps?: Partial<Omit<BaseTypographyProps<T>, "children">>;
  children?: BaseTypographyProps<T>["children"];
  large?: boolean;
  checkboxRef?: React.MutableRefObject<HTMLInputElement | null>;
  checkboxFieldRef?: React.MutableRefObject<HTMLDivElement | null>;
  isReverseContent?: boolean;
}

export default <P extends keyof ReactHTML>(props: BaseSwitchProps<P>) => {
  const switchProps = omit(props, [
    "className",
    "typographyProps",
    "checkboxFieldRef",
    "checkboxRef",
    "children",
    "large",
    "typographyProps",
    "isReverseContent",
  ]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  useImperativeHandle(props.checkboxRef, () => inputRef.current as HTMLInputElement);

  const handlerClickField = () => {
    inputRef.current && inputRef.current.click();
  };

  const tableStateClasses = {
    disabled: props.disabled,
    checked: props.checked,
    large: props.large,
    reverse: props.isReverseContent,
  };

  const { classes } = useStyles();
  return (
    <div
      onClick={handlerClickField}
      className={cn(classes.checkboxField, tableStateClasses, props.className)}
      ref={props.checkboxFieldRef}
    >
      <input {...switchProps} ref={inputRef} type="checkbox" className={classes.inputCheckbox} />
      <div className={cn(classes.switch, tableStateClasses)}>
        <span className="circle" />
      </div>
      {props.children && (
        <BaseTypography
          {...props.typographyProps}
          tag={props.typographyProps?.tag || "label"}
          fontType={props.typographyProps?.fontType || "subhead"}
          className={cn(classes.label, tableStateClasses, props.typographyProps?.className)}
        >
          {props.children}
        </BaseTypography>
      )}
    </div>
  );
};
