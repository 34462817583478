import React, { ReactNode } from "react";
import { Box, useMediaQuery } from "@mui/material";
import cn from "classnames";
import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import ButtonLoader from "~/features/ButtonLoader";
import Icon from "~/shared/Icon";
import { BaseButton } from "~/shared/ui-kit/BaseComponents";
import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useControlButtonsStyles = makeStyles()((theme: Theme) => ({
  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down(768)]: {
      flexDirection: "column-reverse",
      gap: "16px",
    },
  },
  icon: {
    marginRight: 30,
  },
  linedButton: {
    border: `1px solid ${borderColors.blue_50}`,
  },
  mobileText: {
    fontSize: "14px",
  },
  mobileButton: {
    height: "70px",
    width: "100%",
    marginTop: "10px",
  },
  button: {
    height: "48px",
    width: "135px",
    fontWeight: 400,
    fontSize: "14px",
    color: colorTable.darkBlue,
    border: `1px solid ${colorTable.greyContour}`,
    "&.MuiButtonBase-root.Mui-disabled": {
      color: colorTable.lightGrey_70,
    },
    "&:hover": {
      background: colorTable.white,
      borderColor: colorTable.darkBlue,
    },
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
  },
  blackButton: {
    height: "48px",
    width: "135px",
    padding: "14px 24px",
    fontWeight: 400,
    fontSize: "14px",
    background: colorTable.darkBlue,
    color: colorTable.white,
    "&.MuiButtonBase-root.Mui-disabled": {
      background: colorTable.lightGrey_30,
      color: colorTable.lightGrey_70,
    },
    "&:hover": {
      background: colorTable.blue_10,
    },
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
  },
}));

export interface ControlWrapperProps {
  onNextStep?: (e: any) => void;
  nextButtonText?: string;
  prevButtonText?: string;
  nextButtonHaveIcon?: boolean;
  onPrevStep?: () => void;
  children?: ReactNode;
  pending?: boolean;
  loading?: boolean;
  disableNextButton?: boolean;
  disableBackButton?: boolean;
  buttonsBoxClassName?: string;
  nextButtonType?: "button" | "reset" | "submit";
  prevButtonType?: "button" | "reset" | "submit";
  className?: string;
}

export const ControlButtons = (props: ControlWrapperProps) => {
  const {
    onNextStep,
    onPrevStep,
    children,
    nextButtonHaveIcon,
    nextButtonText,
    prevButtonText,
    loading,
    disableNextButton,
    disableBackButton,
    buttonsBoxClassName,
    nextButtonType,
    prevButtonType,
    className,
  } = props;
  const { classes } = useControlButtonsStyles();

  const isTabletOrMobile = useMediaQuery("(max-width: 1024px)");

  return (
    <Box className={className}>
      {children && <Box mt="55px">{children}</Box>}
      <div className={cn(classes.buttons, buttonsBoxClassName)}>
        {onPrevStep && (
          <BaseButton
            typeStyle="white"
            type={nextButtonType}
            onClick={onPrevStep}
            className={cn(classes.button)}
            disabled={disableBackButton}
          >
            {loading && <ButtonLoader />}
            <span className={classes.mobileText}>{prevButtonText}</span>
          </BaseButton>
        )}
        {onNextStep && (
          <BaseButton
            typeStyle="black"
            type={prevButtonType}
            className={cn(classes.blackButton)}
            onClick={onNextStep}
            disabled={disableNextButton}
          >
            {loading && <ButtonLoader />}
            {nextButtonHaveIcon && <Icon name="goForward" size={isTabletOrMobile ? 56 : 32} className={classes.icon} />}
            <span className={classes.mobileText}>{nextButtonText}</span>
          </BaseButton>
        )}
      </div>
    </Box>
  );
};
