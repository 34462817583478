export type SecurityQuestionsResponse = {
  content: string;
};

export type QuestionsParsed = {
  questions: { question: string }[];
};

export type ChangeSecurityQuestionRequest = {
  question: string;
  answer: string;
};

export type ChangeSecurityQuestionCompleteRequest = {
  taskId: string;
  variables: {
    question_id?: string;
    answer?: string;
    enteredCode?: string | null;
  },
}

export type ChangeSecurityQuestionResponse = {
  content: string;
  businessKey: string;
};

export type SecurityQuestion = {
  id: string;
  language: "RU" | "EN";
  questionText: string;
}

export type GetSecurityQuestionsResponse = {
  content: SecurityQuestion[];
};
