import { combine, createDomain, Event, forward, sample } from "effector";
import { createForm } from "effector-forms";
import { createGate } from "effector-react";

import { activityTypes, activityTypesArray, OperatorCondEnum, webEnum } from "~/constants/enums";
import { conditionalValue } from "~/lib/utils";
import {
  buildingRules,
  cityRules,
  employerRules,
  houseRules,
  otherActivityRules,
  phoneRules,
  positionRules,
  required,
  rules,
  stateRules,
  streetRules,
  websiteRules,
  zipCodeRules,
} from "~/lib/validation/rules";
import { individualEntrepreneurInfo } from "~/pages/OpenAccount/services/EconomySectionServices/acсountActivity/individualEntrepreneurInfo";
import { booleanArrayToSelect } from "~/pages/OpenAccount/services/helpers";
import { brokerService } from "~/pages/OpenAccount/services/services";
import { entrepreneurInfo } from "./entrepreneurInfo";

const accountActivityDomain = createDomain("account activity");
const gate = createGate({ domain: accountActivityDomain });

const setEmployee = accountActivityDomain.createEvent<boolean>();
const setSelfEmployed = accountActivityDomain.createEvent<boolean>();
const setUnemployed = accountActivityDomain.createEvent<boolean>();
const setRentier = accountActivityDomain.createEvent<boolean>();
const setStudent = accountActivityDomain.createEvent<boolean>();
const setPensioner = accountActivityDomain.createEvent<boolean>();
const setOther = accountActivityDomain.createEvent<boolean>();
const submit = accountActivityDomain.createEvent();
const setIsSumbit = accountActivityDomain.createEvent<boolean>();

const resetRadio = accountActivityDomain.createEvent();
const setRadio = accountActivityDomain.createEvent<Event<boolean>>();

const $isEmployee = accountActivityDomain.createStore(false);
$isEmployee.reset([gate.close, resetRadio]);
$isEmployee.on(setEmployee, (_, isEmplpyee) => isEmplpyee);
$isEmployee.on(
  brokerService.$processInfo,
  (_, data) => data?.inputData?.professionalActivity?.activityType === activityTypes?.EMPLOYEE
);

const $isSelfEmployed = accountActivityDomain.createStore(false);
$isSelfEmployed.reset([gate.close, resetRadio]);
$isSelfEmployed.on(setSelfEmployed, (_, isSelfEmployed) => isSelfEmployed);
$isSelfEmployed.on(
  brokerService.$processInfo,
  (_, data) => data?.inputData?.professionalActivity?.activityType === activityTypes?.SELF_EMPLOYED
);

const $isUnemployed = accountActivityDomain.createStore(false);
$isUnemployed.reset([gate.close, resetRadio]);
$isUnemployed.on(setUnemployed, (_, isUnemployed) => isUnemployed);
$isUnemployed.on(
  brokerService.$processInfo,
  (_, data) => data?.inputData?.professionalActivity?.activityType === activityTypes?.UNEMPLOYED
);

const $isRentier = accountActivityDomain.createStore(false);
$isRentier.reset([gate.close, resetRadio]);
$isRentier.on(setRentier, (_, isRentier) => isRentier);
$isRentier.on(
  brokerService.$processInfo,
  (_, data) => data?.inputData?.professionalActivity?.activityType === activityTypes?.RENTIER
);

const $isStudent = accountActivityDomain.createStore(false);
$isStudent.reset([gate.close, resetRadio]);
$isStudent.on(setStudent, (_, isStudent) => isStudent);
$isStudent.on(
  brokerService.$processInfo,
  (_, data) => data?.inputData?.professionalActivity?.activityType === activityTypes?.STUDENT
);

const $isPensioner = accountActivityDomain.createStore(false);
$isPensioner.reset([gate.close, resetRadio]);
$isPensioner.on(setPensioner, (_, isPensioner) => isPensioner);
$isPensioner.on(
  brokerService.$processInfo,
  (_, data) => data?.inputData?.professionalActivity?.activityType === activityTypes?.PENSIONER
);

const $isOther = accountActivityDomain.createStore(false);
$isOther.reset([gate.close, resetRadio]);
$isOther.on(setOther, (_, isOther) => isOther);
$isOther.on(
  brokerService.$processInfo,
  (_, data) => data?.inputData?.professionalActivity?.activityType === activityTypes?.OTHER
);

const $isActivityChecked = accountActivityDomain.createStore(true);
$isActivityChecked.reset(gate.close);

const $selectedActivityType = accountActivityDomain.createStore("");
$selectedActivityType.reset(gate.close);

const $isSubmit = accountActivityDomain.createStore(false);
$isSubmit.reset(gate.close);
$isSubmit.on(setIsSumbit, (_, data) => data);

entrepreneurInfo.$isEntrepreneur.reset(resetRadio);
individualEntrepreneurInfo.$isIndividualEntrepreneur.reset(resetRadio);

sample({
  source: setRadio,
  fn: (event) => {
    resetRadio();
    event(true);
  },
});

sample({
  source: [
    $isEmployee,
    $isSelfEmployed,
    entrepreneurInfo.$isEntrepreneur,
    individualEntrepreneurInfo.$isIndividualEntrepreneur,
    $isUnemployed,
    $isRentier,
    $isStudent,
    $isPensioner,
    $isOther,
  ],
  target: $isActivityChecked,
  clock: submit,
  fn: (activities) => activities.some((activity) => activity),
});

sample({
  source: [
    $isEmployee,
    $isSelfEmployed,
    entrepreneurInfo.$isEntrepreneur,
    individualEntrepreneurInfo.$isIndividualEntrepreneur,
    $isUnemployed,
    $isRentier,
    $isStudent,
    $isPensioner,
    $isOther,
  ],
  target: $isActivityChecked,
  fn: () => true,
});

sample({
  source: [
    $isEmployee,
    $isSelfEmployed,
    entrepreneurInfo.$isEntrepreneur,
    individualEntrepreneurInfo.$isIndividualEntrepreneur,
    $isUnemployed,
    $isRentier,
    $isStudent,
    $isPensioner,
    $isOther,
  ],
  target: $selectedActivityType,
  fn: ([
    isEmployee,
    isSelfEmployed,
    isEntrepreneur,
    isIndividualEntrepreneur,
    isUnemployed,
    isRentier,
    isStudent,
    isPensioner,
    isOther,
  ]) => {
    const activityArray = [
      isEmployee,
      isSelfEmployed,
      isEntrepreneur,
      isIndividualEntrepreneur,
      isUnemployed,
      isRentier,
      isStudent,
      isPensioner,
      isOther,
    ];
    return activityTypesArray[booleanArrayToSelect(activityArray) || 0];
  },
});

const accountActivityForm = createForm({
  domain: accountActivityDomain,
  fields: {
    activityType: {
      init: "",
    },
    employerName: {
      init: "",
      rules: employerRules($isEmployee, required),
    },
    countryCode: {
      init: "",
      rules: [],
    },
    country: {
      init: "",
      rules: [rules.required($isEmployee)],
    },
    zipcode: {
      init: "",
      rules: zipCodeRules($isEmployee, required),
    },
    registrationCountry: {
      init: "",
      rules: [rules.required($isEmployee)],
    },
    region: {
      init: "",
      rules: stateRules($isEmployee, required),
    },
    city: {
      init: "",
      rules: cityRules($isEmployee, required),
    },
    street: {
      init: "",
      rules: streetRules($isEmployee, required),
    },
    house: {
      init: "",
      rules: houseRules($isEmployee, required),
    },
    building: {
      init: "",
      rules: buildingRules($isEmployee),
    },
    phoneNumber: {
      init: "",
      rules: phoneRules($isEmployee, required),
    },
    website: {
      init: webEnum.HTTPS,
      rules: websiteRules($isEmployee),
    },
    position: {
      init: "",
      rules: positionRules($isEmployee, required),
    },
    otherActivityDesc: {
      init: "",
      rules: otherActivityRules($isOther, required),
    },
    isSelfEmployed: {
      init: "",
    },
    selfEmployed: {
      init: "",
    },
  },
  validateOn: ["submit"],
});

forward({ from: gate.close, to: accountActivityForm.reset });
forward({ from: resetRadio, to: accountActivityForm.fields.otherActivityDesc.reset });

sample({
  source: $isEmployee,
  target: accountActivityForm.reset,
  filter: (isEmployee) => !isEmployee,
});

forward({
  from: submit,
  to: [accountActivityForm.validate, entrepreneurInfo.form.validate, individualEntrepreneurInfo.form.validate],
});

sample({
  clock: submit,
  source: $isSubmit,
  fn: (source) => !source,
  target: setIsSumbit,
});

sample({
  source: brokerService.$processInfo,
  target: accountActivityForm.setForm,
  fn: (response) => {
    const data = response?.inputData?.professionalActivity;
    if (data?.activityType !== activityTypes.EMPLOYEE) {
      return {};
    }

    return {
      activityType: conditionalValue(data?.activityType, "", OperatorCondEnum.NULLISH),
      employerName: conditionalValue(data?.employerName?.trim(), "", OperatorCondEnum.NULLISH),
      country: conditionalValue(data?.country?.trim(), "", OperatorCondEnum.NULLISH),
      zipcode: conditionalValue(data?.zipcode?.trim(), "", OperatorCondEnum.NULLISH),
      registrationCountry: conditionalValue(data?.registrationCountry?.trim(), "", OperatorCondEnum.NULLISH),
      region: conditionalValue(data?.region?.trim(), "", OperatorCondEnum.NULLISH),
      city: conditionalValue(data?.city?.trim(), "", OperatorCondEnum.NULLISH),
      street: conditionalValue(data?.street?.trim(), "", OperatorCondEnum.NULLISH),
      house: conditionalValue(data?.house?.trim(), "", OperatorCondEnum.NULLISH),
      building: conditionalValue(data?.building?.trim(), "", OperatorCondEnum.NULLISH),
      phoneNumber: conditionalValue(data?.phoneNumber?.trim(), "", OperatorCondEnum.NULLISH),
      website:
        data?.website?.trim() === "https://"
          ? null
          : conditionalValue(data?.website?.trim(), null, OperatorCondEnum.NULLISH),
      position: conditionalValue(data?.position?.trim(), "", OperatorCondEnum.NULLISH),
      otherActivityDesc: conditionalValue(data?.otherActivityDesc, "", OperatorCondEnum.NULLISH),
      isSelfEmployed: conditionalValue(data?.isSelfEmployed, "", OperatorCondEnum.NULLISH),
      selfEmployed: conditionalValue(data?.isSelfEmployed, "", OperatorCondEnum.NULLISH),
    };
  },
});

export const accountActivity = {
  $stores: combine({
    isEmployee: $isEmployee,
    isSelfEmployed: $isSelfEmployed,
    isUnemployed: $isUnemployed,
    isRentier: $isRentier,
    isStudent: $isStudent,
    isPensioner: $isPensioner,
    isOther: $isOther,
    isActivityChecked: $isActivityChecked,
  }),
  $isActivityChecked,
  setRadio,
  resetRadio,
  setEmployee,
  setSelfEmployed,
  setUnemployed,
  setRentier,
  setStudent,
  setPensioner,
  setOther,
  gate,
  submit,
  accountActivityForm,
  $selectedActivityType,
  $isSubmit,
  setIsSumbit,
};
