import { processActionsEnum } from "~/constants/enums";

export const UPLOAD_REQUEST_DOCUMENTS = "PAYMENT_PROOF";

export type TRequisit = {
  id: string;
  beneficiary: string;
  beneficiaryTin: string;
  beneficiaryAccount: string;
  beneficiaryBankAccount: string;
  beneficiaryAddress: string;
  beneficiaryBank: string;
  beneficiaryBankShortName: string;
  intermediaryBank: string;
  intermediarySwift: string;
  iban: string;
  swift: string;
  paymentDetails: string;
  currency: string;
  showAlert?: boolean;
};

export type TServiceActivationStatus = {
  id: string;
  businessKey: string;
  type: string;
  firstRequestNumber: string;
  date: string;
  expireDate: string;
  source: string;
  fileLink: {
    fileId: string;
    fileName: string;
    bucketName: string;
  }[];
};

export type TTaskCompleteParams = {
  taskId: string;
  variables: {
    extraDocuments?: null | processActionsEnum;
    removeBcsfsId?: string;
    removeDocumentType?: typeof UPLOAD_REQUEST_DOCUMENTS;
    stopProcess?: boolean;
  };
};

export type TPaymentProofState = {
  processKey: string;
  state: string;
  processResult: string;
  resultData: {
    extraDocuments: null | unknown[];
  };
  activeTasks: [
    {
      id: string;
      processInstanceId: string;
      taskDefinitionKey: typeof UPLOAD_REQUEST_DOCUMENTS;
      name: string;
      assignee: string;
      creationTime: string;
      taskFields: {
        variableName: string;
        type: string;
        label: string;
        properties: unknown;
      }[];
      taskPayload: null;
    },
  ];
  inputData: {
    documents: {
      bcsfsId: string;
      backetName: string;
      docType: string;
      comment: null;
    }[];
  };
};
