import { isFunction, isNil, isUndefined } from "lodash";

export interface NumeralOptions {
  delimiter?: string;
  decimal?: string;
  allowDecimal?: boolean;
  forceZeroOnBlur?: boolean;
  allowLeadingZero?: boolean;
  suffix?: string | ((value: string) => string);
  size?: number;
}

export const getLocaleOptions = (
  locale: string,
  delimiter?: string
): NumeralOptions & Required<Pick<NumeralOptions, "delimiter" | "decimal">> => {
  if (locale === "en") {
    return {
      delimiter: isUndefined(delimiter) ? " " : delimiter,
      decimal: ".",
    };
  }

  return {
    delimiter: isUndefined(delimiter) ? " " : delimiter,
    decimal: ".",
  };
};

export const parse = (
  value: string | number | undefined | null,
  options: Required<NumeralOptions>,
  strict = true,
  decimal?: string
): [string, string | undefined] => {
  let v = "";
  let [p1, p2]: (string | undefined)[] = [];
  if (typeof value === "number") {
    v = options.allowDecimal ? value.toString() : Math.round(value).toString();
    const parts = v.split(".");
    p1 = parts[0];
    p2 = parts[1];
  } else {
    v = isNil(value) ? "" : value;
    v = options.allowDecimal ? v.replace(/[^\d,.-]/g, "") : v.replace(/[^\d-]/g, "");
    const parts = v.trim().split(decimal || options.decimal);
    p1 = parts[0];
    p2 = parts[1];
  }

  p1 = p1 || "";
  p1 = p1.replace(/[^\d-]/g, "");
  p1 = options.size > 0 && p1.length > options.size ? p1.substring(0, options.size) : p1;
  p1 = options.allowLeadingZero ? p1 : p1.replace(/^[0]+/, "0").replace(/^[0]([1-9])/, "$1");
  p1 = p1.replace(/^[-]+/, "-");

  if (options.allowDecimal) {
    if (p1 !== "") {
      if (p2 !== undefined) {
        p2 = p2 || "";
        p2 = p2.replace(/[^\d]/g, "");
        if (strict) {
          p2 += "00";
        }
        p2 = p2.substr(0, 2);
      }
    } else {
      p2 = undefined;
    }
  } else {
    p2 = undefined;
  }

  return [p1, p2];
};

export const clear = (value: string | number | undefined | null, options: Required<NumeralOptions>): string => {
  const [p1, p2] = parse(value, options);

  if (p1 === "") {
    return options.forceZeroOnBlur ? "0" : "";
  }

  if (p1 === "0" && (!p2 || p2 === "0" || p2 === "00")) {
    return options.forceZeroOnBlur ? "0" : "";
  }

  let result = p1;
  if (p2 && p2 !== "0" && p2 !== "00") {
    result += "." + p2;
  }

  return result.trim();
};

export const format = (
  value: string | number | undefined | null,
  options: Required<NumeralOptions>,
  strict = true,
  decimal?: string
): string => {
  const [p1, p2] = parse(value, options, strict, decimal);

  if (p1 === "") {
    return "";
  }

  let result = p1;
  if (options.delimiter) {
    result = result.replace(/\B(?=(\d{3})+(?!\d))/g, options.delimiter);
  }

  if (options.allowDecimal) {
    if (p2 || (!strict && p2 === "")) {
      result += options.decimal + p2;
    }
  }

  if (result !== "" && options.suffix !== "") {
    result += isFunction(options.suffix) ? options.suffix(clear(value, options)) : options.suffix;
  }

  return result.trim();
};

export const formatMoney = (
  locale: string,
  value: string | number,
  currency: string,
  // currency: keyof typeof CurrencySymbol,
  allowDecimal = true,
  forceZeroOnBlur = false
): string => {
  return format(value, {
    ...getLocaleOptions(locale),
    suffix: ` ${currency}`,
    // suffix: ` ${CurrencySymbol[currency]}`,
    allowDecimal,
    forceZeroOnBlur,
    allowLeadingZero: false,
    size: 0,
  });
};

export const formatPercent = (
  locale: string,
  value: string | number,
  allowDecimal = false,
  forceZeroOnBlur = false
): string => {
  return format(value, {
    ...getLocaleOptions(locale),
    suffix: " %",
    allowDecimal,
    forceZeroOnBlur,
    allowLeadingZero: false,
    size: 0,
  });
};

export const req = (options?: NumeralOptions): Required<NumeralOptions> => {
  const {
    delimiter = "",
    decimal = ".",
    suffix = "",
    size = 0,
    allowDecimal = true,
    forceZeroOnBlur = false,
    allowLeadingZero = false,
  } = options || {};
  return { delimiter, decimal, suffix, size, allowDecimal, forceZeroOnBlur, allowLeadingZero };
};
