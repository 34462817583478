import { createAsyncReducer } from "~/lib/redux";
import { initial } from "~/lib/redux/async";
import {
  accountClearAction,
  accountRequestErrorAction,
  accountRequestStartAction,
  accountRequestSuccessAction,
} from "../actions";

export const STATE_NAME = "accountRequest";

const [reducer, defaultState] = createAsyncReducer(
  accountRequestStartAction,
  accountRequestSuccessAction,
  accountRequestErrorAction
);

reducer.on(accountClearAction, () => initial());

export type AccountRequestState = typeof defaultState;

export { defaultState };
export default reducer;
