import { createDomain, forward } from "effector";

import { countryEnum } from "~/constants/enums";
import { getAllCountries, getCountriesCitizenship } from "~/entities/countries/api";
import { TCountries } from "./types";

const countriesDomain = createDomain("countries domain");

const getAllCountriesFx = countriesDomain.createEffect({ handler: getAllCountries });
const getAllCountriesEv = countriesDomain.createEvent();

const getCountriesCitizenshipFx = countriesDomain.createEffect({ handler: getCountriesCitizenship });
const getCountriesCitizenshipEv = countriesDomain.createEvent();

//при TCountries[] валятся компоненты Autocomplete
const $countriesCitizenship = countriesDomain.createStore<any>([]);
const $filteredCountriesCitizenship = countriesDomain.createStore<TCountries[]>([]);

$countriesCitizenship.on(getCountriesCitizenshipFx.doneData, (_, data) => data.content);

//при TCountries[] валятся компоненты Autocomplete
const $allCountries = countriesDomain.createStore<any>([]);
const $filteredAllCountries = countriesDomain.createStore<TCountries[]>([]);

$allCountries.on(getAllCountriesFx.doneData, (_, data) => data.content);

$filteredAllCountries.on(getAllCountriesFx.doneData, (_, data) => {
  let rusElem: TCountries | null = null;
  let areElem: TCountries | null = null;

  const filterCountry = (data.content as TCountries[]).filter((item) => {
    if (item.codeIso === countryEnum.RF) {
      rusElem = item;
      return false;
    }

    if (item.codeIso === countryEnum.ARE) {
      areElem = item;
      return false;
    }

    return true;
  });

  const sortedCountry = [...filterCountry].sort((a, b) => a.englishName.localeCompare(b.englishName));

  rusElem && sortedCountry.unshift(rusElem);
  areElem && sortedCountry.unshift(areElem);

  return sortedCountry;
});

$filteredCountriesCitizenship.on(getCountriesCitizenshipFx.doneData, (_, data) => {
  let rusElem: TCountries | null = null;
  let areElem: TCountries | null = null;

  const filterCountry = (data.content as TCountries[]).filter((item) => {
    if (item.codeIso === countryEnum.RF) {
      rusElem = item;
      return false;
    }

    if (item.codeIso === countryEnum.ARE) {
      areElem = item;
      return false;
    }

    return true;
  });

  const sortedCountry = [...filterCountry].sort((a, b) => a.englishName.localeCompare(b.englishName));

  rusElem && sortedCountry.unshift(rusElem);
  areElem && sortedCountry.unshift(areElem);

  return sortedCountry;
});
forward({ from: getAllCountriesEv, to: getAllCountriesFx });
forward({ from: getCountriesCitizenshipEv, to: getCountriesCitizenshipFx });

export const countriesModel = {
  getAllCountries: getAllCountriesEv,
  $allCountries,
  $filteredAllCountries,
  $getCountriesPending: getAllCountriesFx.pending,
  getCountriesCitizenship: getCountriesCitizenshipEv,
  $countriesCitizenship,
  $filteredCountriesCitizenship,
  $getCountriesCitizenshipPending: getCountriesCitizenshipFx.pending,
};
