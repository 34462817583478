import React from "react";
import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/material";
import cn from "classnames";

import { floatToMoney } from "~/lib/money";
import { BaseTypography } from "~/shared/ui-kit/BaseComponents";
import useStyles from "./styles";

interface TotalCountProps {
  value: number | string;
  label: string;
  className?: string;
}

export default (props: TotalCountProps) => {
  const isTabletOrMobile = useMediaQuery("(max-width: 768px)");

  const { classes } = useStyles();

  const { value, label } = props;

  return (
    <Box className={cn(classes.wrapper, props.className)}>
      <BaseTypography tag="p" fontType={isTabletOrMobile ? "caption" : "text"}>
        {label}
      </BaseTypography>
      <BaseTypography tag="p" fontType={isTabletOrMobile ? "caption_semiBold" : "text_semiBold"}>
        {floatToMoney(value)} $
      </BaseTypography>
    </Box>
  );
};
