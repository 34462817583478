import React from "react";
import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import { CountryCode } from "~/entities/keycloak";
import i18n from "~/i18n";
import { useStyle } from "./styles";

interface ICountryPhoneNumberFieldProps {
  setCountry: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  countryCodes: CountryCode[];
  codeIso: string;
}

export const CountryPhoneNumberField = ({ setCountry, countryCodes, codeIso }: ICountryPhoneNumberFieldProps) => {
  const { classes } = useStyle();

  return (
    <Select
      sx={{
        background: "none",
        padding: "0",
      }}
      name="citizenship"
      value={codeIso}
      onChange={setCountry}
      className={classes.phoneSelect}
    >
      {countryCodes.map((item) => (
        <MenuItem key={item.codeIso} value={item.codeIso} className={classes.phoneOption}>
          <Box className={classes.menuItem}>
            <img alt="countryFlag" className={classes.imageCountry} src={item.pictogram} />
            <span>{i18n.language === "ru" ? item.shortName : item.englishName}</span>
          </Box>
          <span>{item.phoneCode}</span>
        </MenuItem>
      ))}
    </Select>
  );
};
