import { cast, Yup } from "~/lib/validation";

export type KeycloakResponseModelContext =
  | "security-question"
  | "initial-credentials"
  | "reset-password"
  | "change-password"
  | "change-security-question";

export enum KeycloakResponseModelState {
  Success = "SUCCESS",
  Fail = "FAIL",
}

export enum KeycloakResponseModelCode {
  Otp = "OTP",
}

export enum KeycloakResponseErrorCode {
  AnswerNotCorrect = "ANSWER_NOT_CORRECT",
  AnswerNotCorrectWithAttempts = "ANSWER_NOT_CORRECT_WITH_ATTEMPTS",
  CurrentPassNotValid = "CURRENT_PASS_NOT_VALID",
  PassNotValid = "PASS_NOT_VALID",
  BadRequest = "BAD_REQUEST",
  BadParams = "BAD_PARAMS",
  OtpFail = "OTP_FAIL",
  IncorrectOtp = "INCORRECT_OTP",
  ClientBlock = "CLIENT_BLOCK",
  UserNotFound = "USER_NOT_FOUND",
  QuestionNotSet = "QUESTION_NOT_SET",
}

export interface KeycloakResponseModel {
  state: KeycloakResponseModelState;
  code?: KeycloakResponseErrorCode | string;
  message?: string;
  content?: string;
  attributes?: {
    attemptsLeft?: number;
  };
}

export interface KeycloakRequestResetPasswordModel {
  userName: string,
  answer: string,
  password?: string
}

export interface KeycloakResponseModelCheckOtpContextMeta {
  context: KeycloakResponseModelContext;
}

export interface KeycloakResponseModelSecurityQuestionUserNameMeta {
  userName: string;
}

export interface KeycloakResponseSuccessModel extends KeycloakResponseModel {
  state: KeycloakResponseModelState.Success;
}

export interface KeycloakResponseFailModel extends KeycloakResponseModel {
  state: KeycloakResponseModelState.Fail;
}

const schema = Yup.object<KeycloakResponseModel>({
  state: Yup.mixed()
    .oneOf([KeycloakResponseModelState.Success, KeycloakResponseModelState.Fail])
    .required(),
  code: Yup.string(),
  content: Yup.string(),
  attributes: Yup.object({
    attemptsLeft: Yup.number(),
  }),
}).label("KeycloakResponseModel");

const schemaSuccess = Yup.object<KeycloakResponseSuccessModel>({
  state: Yup.mixed()
    .oneOf([KeycloakResponseModelState.Success])
    .required(),
  code: Yup.string(),
  content: Yup.string(),
  attributes: Yup.object({
    attemptsLeft: Yup.number(),
  }),
}).label("KeycloakResponseSuccessModel");

const schemaFail = Yup.object<KeycloakResponseFailModel>({
  state: Yup.mixed()
    .oneOf([KeycloakResponseModelState.Fail])
    .required(),
  code: Yup.string(),
  content: Yup.string(),
  attributes: Yup.object({
    attemptsLeft: Yup.number(),
  }),
}).label("KeycloakResponseFailModel");

export const castKeycloakResponseModel = cast<KeycloakResponseModel>(schema);
export const castKeycloakResponseSuccessModel = cast<KeycloakResponseSuccessModel>(schemaSuccess);
export const castKeycloakResponseFailModel = cast<KeycloakResponseFailModel>(schemaFail);
