export enum OperationsType {
  WITHDRAWAL = "WITHDRAWAL",
  DEPOSIT = "DEPOSIT",
}

export enum EnumStrategyType {
  ORDINARY = "ORDINARY",
  TRUNCHE = "TRUNCHE",
  IndividualAM = "INDIVIDUAL",
}

export enum EnumActivitiesType {
  BOND = "BOND",
  CURRENCY = "CURRENCY",
  OTHER = "OTHER",
  STOCK = "STOCK",
}

export enum EnumRequisitesType {
  PRODUCT_ID = "PRODUCT_ID",
  STRATEGY_ID = "STRATEGY_ID",
}
