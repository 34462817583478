export enum SecurityQuestionState {
  FAIL = "FAIL",
  SUCCESS = "SUCCESS",
  DONE = "DONE",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum ResetPasswordTypes {
  SECURITY_QUESTION = "SECURITY_QUESTION",
  BIRTHDAY_PASSPORT = "BIRTHDAY_PASSPORT",
  NO_DISABLED = "NO_DISABLED",
  DONE = "DONE",
  IN_PROGRESS = "IN_PROGRESS",
  SERVER_ERROR = "SERVER_ERROR",
}

export enum CHANGE_PASSWORD_ENUM {
  USER_NOT_FOUND = "USER_NOT_FOUND",
  PASSWORD_MATCHES_5_PREVIOUS = "PASSWORD_MATCHES_5_PREVIOUS",
  THE_CURRENT_PASSWORD_VALUE_MISMATCH = "THE_CURRENT_PASSWORD_VALUE_MISMATCH",
  INVALID_PASSWORD = "INVALID_PASSWORD",
}

export enum WithdrawalTypes {
  PARTIAL = "PARTIAL",
  FULL = "FULL",
}

export enum ChangeSecurityQuestionCode {
  INCORRECT_ANSWER = "INCORRECT_ANSWER",
  ENTER_NEW_SECURITY_QUESTION = "ENTER_NEW_SECURITY_QUESTION",
  OTP_CONFIRMATION = "OTP_CONFIRMATION",
  SUCCESSFUL_UPDATE_SECURITY_QUESTION = "SUCCESSFUL_UPDATE_SECURITY_QUESTION",
}

export enum SecurityQuestionCode {
  USER_NOT_FOUND = "USER_NOT_FOUND",
  QUESTION_NOT_SET = "QUESTION_NOT_SET",
  OTP = "OTP",
  CLIENT_BLOCK = "CLIENT_BLOCK",
}

export enum ResetByPassportCode {
  ANSWER_NOT_CORRECT_WITH_ATTEMPTS = "ANSWER_NOT_CORRECT_WITH_ATTEMPTS",
  EXCEEDED_MAXIMUM_ACTIVATION_RESET_ATTEMPTS_NUMBER = "EXCEEDED_MAXIMUM_ACTIVATION_RESET_ATTEMPTS_NUMBER",
  BIRTHDAY_PASSPORT_NOT_SET = "BIRTHDAY_PASSPORT_NOT_SET",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  CLIENT_HAVE_SECURITY_ANSWER = "CLIENT_HAVE_SECURITY_ANSWER",
  CLIENT_NOT_BLOCK = "CLIENT_NOT_BLOCK",
  BAD_REQUEST = "BAD_REQUEST",
  BAD_PARAMS = "BAD_PARAMS",
  OTP_FAIL = "OTP_FAIL",
}

export type SecurityQuestionRequest = {
  userName: string;
};

export type ProcessBusinessRequest = {
  username: {
    login: string;
    region: string;
  };
  recaptcha?: string;
  otpSecret: string | null;
};

export type ProcessBusinessResponse = {
  businessKey: string;
};

export type ProcessBusinessResetPasswordRequest = ProcessBusinessRequest & {
  session: string;
  otpSecret: string | null;
};

export type SecurityQuestionResponse = {
  state: SecurityQuestionState;
  code: SecurityQuestionCode;
  content: string;
};

export type ProcessStateRequest = {
  processBusinessKey: string;
  sumSubToken?: string;
  emptyProcessResultRefresh?: boolean;
  processResult?: string;
};

export type OtpCodeRequest = {
  sid?: string;
  otp?: string;
  taskId?: string;
  variables?: {
    enteredCode: string | null;
  };
};

export type OtpCodeResponse = {
  content: string;
};

export type ResetByPassportRequest = {
  userName: string;
  birthday: string;
  passportNumber: string;
};

export type ResetByPassportResponse = {
  content: string;
};

export type LoginRequest = {
  username: string;
  password: string;
  recaptcha: string;
  region: string | undefined;
};

export interface RegistrationRequest {
  username: string;
  firstName: string;
  lastName: string;
  middleName: string;
  noMiddleName: boolean;
  password: string;
}

export type LoginWithOtpCodeRequest = {
  username: string;
  password: string;
  session_code?: string;
  session_id: string;
  recaptcha?: string;
  region: string | undefined;
};

export type LoginNoOtpResponse = {
  content: string;
  state: string;
};

export type LoginOtpResponse = {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  refresh_expires_in: number;
  token_type: string;
  session_state: string;
};

export type LoginSessionsResponse = {
  message_code: string;
  message_description: string;
  debug_data: DebugData;
};

export type RegistrationResponse = {
  businessKey: string;
};

export type DebugData = {
  code: string;
};

export type ChangePasswordRequest = {
  password: string;
  oldPassword: string;
};

export type ChangePasswordResponse = {
  businessKey: any;
  state: "SUCCESS" | "FAIL";
  code?: string;
  content?: string;
};

export type ChangePasswordGetProcessResponse = {
  activeTasks: {
    id: string;
    taskDefinitionKey: string;
  }[];
  processResult: string;
  resultData: {
    debugData?: string;
    error_message?: string;
  };
  state: string;
};

export type ChangeTemporaryPasswordRequest = {
  password: string;
  username: UserName;
  recaptcha: string;
  otpSecret: string | null;
};

export type UserName = {
  login: string;
  region: string;
};

export type CountryCode = {
  id: string;
  isDeleted: boolean;
  createdDate: string;
  createdBy: any;
  lastModifiedDate: string;
  lastModifiedBy: any;
  codeIso: string;
  shortName: string;
  fullName: string;
  englishName: string;
  charCode2: string;
  charCode3: string;
  phoneCode: string;
  phoneMask: string;
  pictogram: string;
};
