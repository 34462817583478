import JSZip from "jszip";
import moment from "moment";

import { CheckStatus, clientStatusWithBoAndDu, dateTypes, dmaBrokerStatus, OperatorCondEnum } from "~/constants/enums";
import { t } from "~/i18n";

// backward capability
export { formikPropTypes, preventDefaultCallback } from "../react";

export const delay = <T>(ms: number, result?: T, logResult?: boolean): Promise<T> =>
  new Promise((resolve): void => {
    setTimeout((): void => {
      if (logResult && result) {
        // eslint-disable-next-line no-console
        console.log("delay result: ", result);
      }

      // @ts-ignore
      resolve(result);
    }, ms);
  });

export const timestamp = (): number => Math.round(new Date().getTime() / 1000);

export const supportTelephone = (): string => process.env.REACT_APP_SUPPORT_TELEPHONE || "";

export const getSupportEmail = (): string => process.env.REACT_APP_SUPPORT_EMAIL || "";

export const latinsNumbersAndSpecSymbolsFilter = (value: string): string => {
  return value.replace(/[^a-zA-Z0-9!@"'#$%:^,&.*;)(_+=\s\\/]+/g, "");
};

export const numberFilter = (value: string): string => {
  return value.replace(/\D+$/g, "");
};

export const maxSymbols = (value: string, length: number): string => {
  return value.substring(0, length);
};

type file = {
  base64?: string | null;
  blob?: Blob | null;
  filename?: string | null;
};

export const downloadPdf = async (files: file[]) => {
  for (const file of files) {
    const a = document.createElement("a");
    if (file.filename) {
      a.download = file.filename;
    }
    if (file.base64) {
      a.href = `data:application/pdf;base64,${file.base64}`;
      a.click();
    } else if (file.blob) {
      a.href = URL.createObjectURL(file.blob);
      a.click();
    }
    a.remove();
    // Задержка 300 мс между скачиваниями
    // чтобы обойти ограничение браузера на 10 штук
    await new Promise((resolve) => setTimeout(resolve, 300));
  }
};

export const matchMimeType = (mimeType?: string | null): string | undefined => {
  const matcher = [
    { mimeType: "application/pdf", name: "pdf" },
    { mimeType: "image/jpeg", name: "jpg" },
    { mimeType: "image/gif", name: "gif" },
    { mimeType: "image/png", name: "png" },
  ];
  return matcher.find((file) => file.mimeType === mimeType)?.name;
};

export const downloadBlob = (file: Blob, filename: string = "unknown.txt") => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(file);
  a.download = filename;
  a.href = url;

  a.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  document.body.removeChild(a);
};

export const statusTextError = (status: string) => {
  if (status === CheckStatus.ANTIVIRUS_CHECK_ERROR) {
    return t("VALIDATION.antivirus_check_error");
  }
  if (status === CheckStatus.FILE_TYPE_ERROR) {
    return t("VALIDATION.file_type_error");
  }
  if (status === CheckStatus.FILE_SIZE_ERROR) {
    return t("VALIDATION.file_size_error");
  }
  if (status === CheckStatus.FILE_NAME_ERROR) {
    return t("VALIDATION.file_name_error");
  }
  if (status === CheckStatus.FILE_TYPE_INVALID) {
    return t("VALIDATION.file_type_invalid");
  }
  if (status === CheckStatus.TEMP_STORAGE_ERROR) {
    return t("VALIDATION.temp_storage_error");
  }
  return "";
};

export const statusErrorAntivirus = [
  CheckStatus.ANTIVIRUS_CHECK_ERROR,
  CheckStatus.FILE_TYPE_ERROR,
  CheckStatus.FILE_SIZE_ERROR,
  CheckStatus.FILE_NAME_ERROR,
  CheckStatus.FILE_TYPE_INVALID,
  CheckStatus.TEMP_STORAGE_ERROR,
];

export const currencyForCode = (code: string, currencies: any) => {
  const currency = currencies?.find((currency: any) => currency.code === code);
  return currency?.name || "";
};

export const downloadFileZip = async (filename: string, content: string) => {
  const zip = new JSZip();
  await zip.loadAsync(content, { base64: true });
  const blob = await zip.generateAsync({ type: "blob" });

  const element = document.createElement("a");
  element.setAttribute("href", window.URL.createObjectURL(blob));
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const checkTypeOnDate = (type: string) => {
  return ["CLIENT_FORM", "ACCEPTANCE_GENERAL_TERMS", "AM_ACCEPTANCE", "AM_REQUEST"].includes(type);
};

export const textFormater = (num: number, textArr: string[], lang: string) => {
  let formatedText;
  const checkArray = ["11", "12", "13", "14"];
  const checkArrayForLastNum = ["5", "6", "7", "8", "9", "0"];
  const checkArrayForLastNum2 = ["2", "3", "4"];

  if (lang === "ru") {
    if (checkArray.includes(`${num}`.substring(-2, 2)) || checkArrayForLastNum.includes(`${num}`.slice(-1))) {
      formatedText = textArr[2];
    } else if (checkArrayForLastNum2.includes(`${num}`.slice(-1))) {
      formatedText = textArr[1];
    } else {
      formatedText = textArr[0];
    }
  }
  if (lang === "en") {
    if (num > 1) {
      formatedText = textArr[1];
    } else {
      formatedText = textArr[0];
    }
  }

  return formatedText;
};

export const dateFormatter = (date: Date | string | undefined, format = dateTypes.RUS_DATE) => {
  return date ? moment(date).format(format) : null;
};

export const formatCurrency = (input: string, customSeparator?: string) => {
  const regex = /^(\D*)(\d+)([\s,.]*)(\w*)/;
  const matches = input.match(regex);

  if (!matches) {
    return input;
  }

  const prefix = matches[1]; // Любые буквы в начале строки (если есть)
  const amount = matches[2]; // Сумма
  const currencyCode = matches[4]; // Код валюты (если указан)

  const specialChars = /[!@#$%^&*()+\-=[\]{};':"\|,.<>/?~]/;
  if (specialChars.test(input)) {
    return input;
  }
  const russianText = /[А-я]+/;
  if (russianText.test(input)) {
    return input;
  }

  let formattedString = `${prefix}${amount.replace(/\B(?=(\d{3})+(?!\d))/g, customSeparator || " ")}`;

  if (currencyCode) {
    formattedString += ` ${currencyCode}`;
  }

  return formattedString;
};

export const clientStatusHasBoOrDu = (clientStatus: string) => {
  return clientStatusWithBoAndDu.includes(clientStatus as dmaBrokerStatus);
};

export const validateNonLeadingSpaces = (value: string) => {
  const valueWithoutLeadingSpaces = value.replace(/^\s+/, "");

  return valueWithoutLeadingSpaces.replace(/\s+/g, " ");
};

export const capitalizeString = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const conditionalValue = <T, Y>(
  value: T,
  returnValueDefault: string | Y = "",
  operator: OperatorCondEnum = OperatorCondEnum.OR
): NonNullable<T> | Y | string => {
  switch (operator) {
    case OperatorCondEnum.OR:
      return value || returnValueDefault;
    case OperatorCondEnum.NULLISH:
      return value ?? returnValueDefault;
    default:
      return value || returnValueDefault;
  }
};

export const getValueByCondition = <T>(
  conditions: {
    condition: boolean;
    value: T;
  }[],
  returnDefaultValue: T = undefined as unknown as T
): T => {
  for (const { condition, value } of conditions) {
    if (condition) {
      return value;
    }
  }

  return returnDefaultValue;
};
