import { createTheme } from "@mui/material";

import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";
import fonts from "./fonts";

// по неясной мне пока причине, при оформлении брейкпоинта берёт предыдущее значение

// все значения увеличены на 1 для синхронности с useMediaQuery

const breakpointValues = {
  xs: 115, // 320
  sm: 321, // 576
  md: 577, // 768
  lg: 769, // 992
  xl: 993, // 1200
  xxl: 1201, // 1920
  xxxl: 1921,
};

const breakpointsTheme = createTheme({
  breakpoints: {
    values: breakpointValues,
  },
});

const theme = createTheme({
  // @ts-ignore
  props: {
    MuiWithWidth: {
      initialWidth: "md",
    },
    MuiTextField: {
      variant: "filled",
      InputProps: {
        disableUnderline: true,
      },
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiCheckbox: {
      focusRipple: false,
      color: "primary",
    },
    MuiRadio: {
      focusRipple: false,
      color: "primary",
    },
    MuiTab: {
      disableRipple: true,
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: "Suisse Intl, sans-serif",
  },
  breakpoints: {
    values: breakpointValues,
  },
  mixins: {
    toolbar: {
      minHeight: 90,
    },
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: "24px",
      },
      paragraph: {
        marginBottom: "24px",
      },
      body1: {
        fontSize: 16,
        lineHeight: "24px",
        color: colorTable.darkGrey_20,
        letterSpacing: "0.2",
      },
      body2: {
        fontSize: 14,
        lineHeight: "22px",
        opacity: 0.5,
        color: "rgba(0, 0, 0, 0.86)",
        letterSpacing: 0,
      },
      h1: {
        fontSize: "24px",
        lineHeight: "32px",
        color: colorTable.black_10,
        fontWeight: 500,
      },
      subtitle1: {
        fontSize: "12px",
        fontWeight: "bold",
        lineHeight: 1,
        letterSpacing: "0.56px",
        color: colorTable.darkGrey_20,
        textTransform: "uppercase",
      },
    },
    MuiSnackbarContent: {
      root: {
        [breakpointsTheme.breakpoints.up("md")]: {
          minWidth: 450,
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": fonts as any, // eslint-disable-line @typescript-eslint/no-explicit-any
      },
    },
    MuiTableCell: {
      root: {
        padding: "26px 0",
        fontSize: 16,
      },
      head: {
        textTransform: "uppercase",
        fontSize: 10,
        fontWeight: 600,
        lineHeight: 1,
        padding: "16px 0",
      },
    },
    MuiButton: {
      root: {
        height: 56,
        fontSize: 16,
        fontWeight: 500,
        textTransform: "none",
        padding: "14px 32px",
      },
      outlined: {
        color: colorTable.white,
        borderColor: colorTable.white,
      },
      text: {
        padding: "14px 32px",
      },
      sizeSmall: {
        fontSize: 12,
        padding: 0,
        height: 32,
        lineHeight: 0,
      },
    },
    MuiInputBase: {
      root: {
        height: 72,
        backgroundColor: colorTable.white_40,
        borderRadius: 4,
        border: "2px solid transparent",
      },
      input: {
        paddingLeft: 24,
        ".MuiFilledInput-root &": {
          paddingLeft: 24,
        },
        ".MuiFormLabel-root ~ .MuiFilledInput-root &": {
          paddingTop: 20,
        },
      },
    },
    MuiInputAdornment: {
      filled: {
        "&.MuiInputAdornment-positionStart": {
          marginTop: "inherit",
        },
      },
    },
    MuiFormControl: {
      root: {
        overflow: "hidden",
      },
    },
    MuiInputLabel: {
      root: {
        whiteSpace: "nowrap",
      },
      filled: {
        transform: "translate(24px, 28px) scale(1)",
        "&.MuiInputLabel-shrink": {
          transform: "translate(24px, 10px) scale(0.75)",
        },
      },
      shrink: {
        transform: "translate(24px, 10px) scale(0.75)",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: colorTable.white_40,
        border: "2px solid transparent",

        "&:focus": {
          backgroundColor: colorTable.white_40,
        },
        "&:hover": {
          backgroundColor: colorTable.white_40,
        },
        "&:disabled": {
          backgroundColor: colorTable.white_60,
        },
        "&.Mui-focused": {
          backgroundColor: colorTable.white_40,
        },
        "&.Mui-disabled": {
          backgroundColor: colorTable.white_60,
        },
        "&.Mui-error": {
          border: `2px solid ${borderColors.red}`,
        },
      },
      input: {
        padding: "2px 0 7px",
      },
    },
    MuiTabs: {
      indicator: {
        height: 0,
      },
      flexContainer: {
        height: "100%",
      },
    },
    MuiTab: {
      root: {
        padding: 0,
        textTransform: "none",
        fontSize: 16,
        minWidth: "auto",
        marginRight: 40,
        [breakpointsTheme.breakpoints.up("xs")]: {
          fontSize: 16,
          minWidth: "auto",
        },
        "&.Mui-selected, &.MuiTab-textColorPrimary.Mui-selected": {
          color: colorTable.blackDefault,
        },
        "&.MuiTab-textColorPrimary": {
          color: colorTable.lightGrey_90,
        },
        "&:last-child": {
          marginRight: 0,
        },
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: "none",
        minHeight: 80,
        height: 80,
        alignItems: "center",
        flexDirection: "row",
      },
      colorPrimary: {
        background: "none",
        backgroundColor: colorTable.white,
        color: colorTable.darkGrey_20,
      },
    },
    MuiToolbar: {
      root: {
        minHeight: 90,
      },
    },
    MuiCircularProgress: {
      circleIndeterminate: {
        color: colorTable.blue_50,
      },
    },
    MuiFormHelperText: {
      contained: {
        marginRight: 0,
        marginLeft: 0,
      },
    },
    MuiSelect: {
      root: {
        "&.Mui-disabled ~ .MuiSelect-icon": {
          opacity: 0,
        },
      },
    },
  },
});

export const themeV5 = createTheme({
  typography: {
    fontFamily: "Suisse Intl, sans-serif",
  },
  breakpoints: {
    values: breakpointValues,
  },
});

export type Theme = typeof theme;

export default theme;
