import React, { ReactNode } from "react";
import DialogActions from "@mui/material/DialogActions";
import { makeStyles } from "tss-react/mui";

export interface ActionsProps {
  children?: ReactNode;
}

const useStyles = makeStyles()({
  root: {
    padding: 0,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
});

const Actions = ({ children }: ActionsProps) => {
  const { classes } = useStyles();
  return (
    <DialogActions classes={classes} disableSpacing>
      {children}
    </DialogActions>
  );
};

export default Actions;
