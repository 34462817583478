import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  content: {
    height: "100%",
    maxWidth: "480px",
    width: "100%",
    padding: "40px 16px",
  },
  head: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  },
  btnApply: {
    marginTop: "24px !important",
    width: "100%",
  },
  btnReset: {
    marginTop: "16px !important",
    width: "100%",
  },
}));
