import { makeStyles } from "tss-react/mui";

import borderColors from "~/shared/ui-kit/constants/borderColors";
import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()({
  radioBox: {
    display: "flex",
    gap: "16px",
    padding: "24px 0",
  },
  wrapper: {
    width: "100%",
    borderBottom: `1px solid ${borderColors.greyContour}`,
  },
  radio: {
    width: "24px",
    height: "24px",
    padding: 0,
  },
  label: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color: colorTable.charcoalBlack,
  },
  content: {
    paddingBottom: "24px",
  },
});
