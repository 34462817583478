import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()(() => ({
  icon: {
    fill: colorTable.textSecondary,
    "&:hover": {
      fill: colorTable.charcoalBlack,
    },
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    "& .MuiIconButton-root": {
      padding: "0px",
      marginLeft: "8px",
    },
  },
  wrapIconTooltip: {
    marginLeft: "8px",
  },
}));
