import { createDomain, forward, sample } from "effector";
import { createForm } from "effector-forms";
import { createGate } from "effector-react";
import moment from "moment";

import { activityTypes, webEnum } from "~/constants/enums";
import {
  apartmentRules,
  buildingRules,
  cityRules,
  entityRules,
  houseRules,
  phoneRules,
  required,
  rules,
  stateRules,
  streetRules,
  websiteRules,
  zipCodeRules,
} from "~/lib/validation/rules";
import { Country } from "~/modules/openAccountShared";
import { newDateRules } from "~/pages/OpenAccount/services/rules";
import { brokerService } from "~/pages/OpenAccount/services/services";

const entrepreneurInfoDomain = createDomain("Entrepreneur Info");
const gate = createGate({ domain: entrepreneurInfoDomain });

const setAddrCountry = entrepreneurInfoDomain.createEvent<null | Country>();
const setCountry = entrepreneurInfoDomain.createEvent<null | Country>();
const setCountryBusiness = entrepreneurInfoDomain.createEvent<null | Country>();
const setEntrepreneur = entrepreneurInfoDomain.createEvent<boolean>();

const $isEntrepreneur = entrepreneurInfoDomain.createStore(false);
$isEntrepreneur.reset(gate.close);
$isEntrepreneur.on(setEntrepreneur, (_, isEntrepreneur) => isEntrepreneur);
$isEntrepreneur.on(
  brokerService.$processInfo,
  (_, data) => data?.inputData?.professionalActivity?.activityType === activityTypes?.LEGAL_ENTITY
);

const form = createForm({
  domain: entrepreneurInfoDomain,
  fields: {
    employerName: {
      init: "",
      rules: entityRules($isEntrepreneur, required),
    },
    businessCountry: {
      init: "",
      rules: [rules.required($isEntrepreneur)],
    },
    registrationDate: {
      init: null as null | Date | string,
      rules: [
        newDateRules.requiredDate($isEntrepreneur),
        newDateRules.validDate($isEntrepreneur),
        newDateRules.minDate($isEntrepreneur),
        newDateRules.lessToday($isEntrepreneur, "OPEN_ACCOUNT.errors.date_registration_less_today"),
      ],
    },
    registrationCountry: {
      init: "",
      rules: [rules.required($isEntrepreneur)],
    },
    country: {
      init: "",
      rules: [rules.required($isEntrepreneur)],
    },
    zipcode: {
      init: "",
      rules: zipCodeRules($isEntrepreneur, required),
    },
    region: {
      init: "",
      rules: stateRules($isEntrepreneur, required),
    },
    city: {
      init: "",
      rules: cityRules($isEntrepreneur, required),
    },
    street: {
      init: "",
      rules: streetRules($isEntrepreneur, required),
    },
    house: {
      init: "",
      rules: houseRules($isEntrepreneur, required),
    },
    building: {
      init: "",
      rules: buildingRules($isEntrepreneur),
    },
    apartment: {
      init: "",
      rules: apartmentRules($isEntrepreneur),
    },
    phoneNumber: {
      init: "",
      rules: phoneRules($isEntrepreneur, required),
    },
    website: {
      init: webEnum.HTTPS,
      rules: websiteRules($isEntrepreneur),
    },
  },
  validateOn: ["submit"],
});

forward({ from: gate.close, to: form.reset });
forward({ from: setAddrCountry, to: form.fields.registrationCountry.resetErrors });
forward({ from: setCountry, to: form.fields.country.resetErrors });
forward({ from: setCountryBusiness, to: form.fields.businessCountry.resetErrors });

sample({
  source: $isEntrepreneur,
  target: form.reset,
  filter: (isEntrepreneur) => !isEntrepreneur,
});

sample({
  source: setAddrCountry,
  target: form.setForm,
  fn: (country) => ({
    registrationCountry: country?.countryCode ?? "",
  }),
});

sample({
  source: setCountry,
  target: form.setForm,
  fn: (country) => ({
    businessCountry: country?.countryCode ?? "",
  }),
});

sample({
  // @ts-ignore
  source: brokerService.$processInfo,
  target: form.setForm,
  fn: (response) => {
    const data = response?.inputData?.professionalActivity;
    if (data?.activityType === activityTypes.LEGAL_ENTITY) {
      return {
        employerName: data?.employerName?.trim(),
        businessCountry: data?.businessCountry?.trim(),
        registrationDate: data?.registrationDate ? moment(data?.registrationDate).utcOffset(0, true).toDate() : null,
        registrationCountry: data?.registrationCountry?.trim(),
        country: data?.country?.trim(),
        zipcode: data?.zipcode?.trim(),
        region: data?.region?.trim(),
        city: data?.city?.trim(),
        street: data?.street?.trim(),
        house: data?.house?.trim(),
        building: data?.building?.trim(),
        apartment: data?.apartment?.trim(),
        phoneNumber: data?.phoneNumber?.trim(),
        website: data?.website?.trim(),
      };
    }
    return {};
  },
});

export const entrepreneurInfo = {
  $isEntrepreneur,
  form,
  gate,
  setAddrCountry,
  setCountryBusiness,
  setCountry,
  setEntrepreneur,
};
