import React from "react";
import { FixedSizeList } from "react-window";
import { GetItemPropsOptions } from "downshift";
import { toString } from "lodash";

import ItemRenderer from "~/features/FormikSuggestionsFieldComponent/ItemRenderer";
import { AutocompleteItemT } from "~/shared/ui/Autocomplete/components/AutocompleteItem/types";

const ITEM_HEIGHT = 48;

interface AutocompleteItemProps {
  list: AutocompleteItemT[];
  highlightedIndex: number | null;
  getItemProps: (options: GetItemPropsOptions<AutocompleteItemT>) => unknown;
}

export const AutocompleteItem = (props: AutocompleteItemProps) => {
  const { list, highlightedIndex, getItemProps } = props;

  return (
    <FixedSizeList
      itemData={{ list, highlightedIndex, getItemProps }}
      height={ITEM_HEIGHT * (list.length > 7 ? 7 : list.length)}
      itemCount={list.length}
      itemSize={ITEM_HEIGHT}
      itemKey={(index): string => (list[index] ? toString(list[index].value) : `index~${index}`)}
      width="100%"
    >
      {ItemRenderer}
    </FixedSizeList>
  );
};
