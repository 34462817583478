import { AccountRequest, AccountRequestStatus } from "../models/AccountRequest";
import { getAccountRequestStatus } from "./selectors";

export const isAccountRequestIsNotFoundForUser = (data: AccountRequest): boolean =>
  data.code === 404 && data.message === "Request is not found for user";

export const isAccountRequestStatusSigned = (status: AccountRequestStatus | string | undefined): boolean =>
  ([
    AccountRequestStatus.ALERT,
    AccountRequestStatus.SENT,
    AccountRequestStatus.ERROR,
    AccountRequestStatus.ACCEPTED,
    AccountRequestStatus.PENDING,
    AccountRequestStatus.ACTIVATED,
    AccountRequestStatus.REJECTED,
    AccountRequestStatus.TIMEOUT_REJECTED,
  ] as unknown[]).indexOf(status) > -1;

export const isAccountRequestStatusNotSigned = (status: AccountRequestStatus | string | undefined): boolean =>
  ([AccountRequestStatus.INITIALIZING, AccountRequestStatus.SMS_SIGNING] as unknown[]).indexOf(status) > -1;

export const isAccountRequestStatusInFinalState = (status: AccountRequestStatus | string | undefined): boolean =>
  ([
    AccountRequestStatus.ERROR,
    AccountRequestStatus.ACTIVATED,
    AccountRequestStatus.REJECTED,
    AccountRequestStatus.TIMEOUT_REJECTED,
  ] as unknown[]).indexOf(status) > -1;

export const isAccountRequestStatusInSuccessState = (status: AccountRequestStatus | string | undefined): boolean =>
  status === AccountRequestStatus.ACTIVATED;

export const isAccountRequestStatusInProcessing = (data: AccountRequest): boolean => {
  const status = getAccountRequestStatus(data);

  return (
    isAccountRequestStatusSigned(status) &&
    (!isAccountRequestStatusInFinalState(status) ||
      (isAccountRequestStatusInFinalState(status) && isAccountRequestStatusInSuccessState(status)))
  );
};

export const isAccountRequestStatusReadyToOpenAccount = (data: AccountRequest): boolean => {
  const status = getAccountRequestStatus(data);

  return (
    isAccountRequestStatusNotSigned(status) ||
    isAccountRequestIsNotFoundForUser(data) ||
    (isAccountRequestStatusInFinalState(status) && !isAccountRequestStatusInSuccessState(status))
  );
};

export const isAccountRequestStatusRejected = (data: AccountRequest | null): boolean => {
  const status = getAccountRequestStatus(data);

  return status === AccountRequestStatus.REJECTED;
};

export const isAccountRequestStatusSent = (data: AccountRequest | null): boolean => {
  const status = getAccountRequestStatus(data);

  return status === AccountRequestStatus.SENT;
};

export const isAccountRequestButtonActive = (data?: AccountRequest | null): boolean => {
  if (data) {
    const status = getAccountRequestStatus(data);
    return status === AccountRequestStatus.SENT;
  }
  return false;
};
