import React from "react";
import InputMask from "react-input-mask";
import { InputProps as InputPropsType } from "@mui/material/Input";

import TextField from "~/shared/ui/TextField";

interface MaskedInputProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  mask: string;
  value?: any;
  label?: string;
  error?: boolean;
  helperText?: React.ReactNode;
  fullWidth?: boolean;
  className?: string;
  disabled?: boolean;
  maskChar?: string;
  style?: any;
  InputProps?: InputPropsType;
  ref?: React.Ref<HTMLDivElement>;
  clearInput?: boolean;
  clearCallBack?: any | (() => any);
  placeholder?: string;
  alwaysShowMask?: boolean;
}

export const MaskedInput = (props: MaskedInputProps) => {
  const {
    mask,
    onChange,
    value,
    error,
    helperText,
    label,
    fullWidth,
    className = "",
    disabled,
    maskChar,
    InputProps,
    clearInput,
    clearCallBack,
    style,
    placeholder,
    alwaysShowMask,
    ref = null,
  } = props;

  return (
    <InputMask
      disabled={disabled}
      mask={mask}
      alwaysShowMask={alwaysShowMask}
      onChange={onChange}
      value={value}
      //@ts-ignore
      maskChar={maskChar}
    >
      {(inputProps: any) => (
        <TextField
          {...inputProps}
          ref={ref}
          placeholder={placeholder}
          clearInput={clearInput}
          clearCallBack={clearCallBack}
          className={className}
          error={error}
          style={style}
          helperText={helperText}
          label={<span>{label}</span>}
          fullWidth={fullWidth}
          disabled={disabled}
          InputProps={InputProps}
        />
      )}
    </InputMask>
  );
};
