import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";

export interface FlexWrapperProps {
  children?: ReactNode;
  padding?: string;
  styles?: object;
  className?: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    maxWidth: "1200px",
    padding: "0 15px",
    boxSizing: "border-box",
    [theme.breakpoints.down(992)]: {
      padding: "0 40px",
    },
    [theme.breakpoints.down(768)]: {
      fontSize: "14px",
      padding: "0 15px",
    },
  },
}));

const ContentWrapperViewport = ({ children, className }: FlexWrapperProps) => {
  const { classes } = useStyles();

  return <Box className={cn(classes.root, className)}>{children}</Box>;
};

export default ContentWrapperViewport;
