import React from "react";

import { useTranslation } from "~/i18n";
import Icon from "~/shared/Icon";
import DocumentPic1 from "~/shared/Icon/icons/documentsSectionPic1.svg";
import DocumentPic2 from "~/shared/Icon/icons/documentsSectionPic2.svg";
import DocumentPic3 from "~/shared/Icon/icons/documentsSectionPic3.svg";
import DocumentPic4 from "~/shared/Icon/icons/documentsSectionPic4.svg";
import { useStyles } from "./styles";

export const DocumentsWarning = () => {
  const { classes } = useStyles();

  const { t } = useTranslation();

  return (
    <div className={classes.warning} style={{ flexDirection: "column" }}>
      <div className={classes.warningInner}>
        <Icon name="substract" size={20} />
        <p
          dangerouslySetInnerHTML={{ __html: t("OPEN_ACCOUNT.documentsSection.warning") }}
          className={classes.documentBannerText}
        />
      </div>
      <div className={classes.documentsIcon}>
        <div>
          <img src={DocumentPic1} alt="document1" />
          <img src={DocumentPic2} alt="document2" />
        </div>
        <div>
          <img src={DocumentPic3} alt="document3" />
          <img src={DocumentPic4} alt="document4" />
        </div>
      </div>
    </div>
  );
};
