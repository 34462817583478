import { PostProcessorModule } from "i18next";
import { toNumber } from "lodash";
import moment, { Moment } from "moment";

export enum Period {
  one = "one",
  few = "few",
  many = "many",
  other = "other",
}

export const getFormatPeriodRu = (value: number): Period => {
  const v10 = value % 10;
  const v100 = value % 100;

  if (v10 === 1 && v100 !== 11) {
    return Period.one;
  } else if (v10 >= 2 && v10 <= 4 && v100 < 12 && v100 > 14) {
    return Period.few;
  } else if (v10 === 0 || (v10 >= 5 && v10 <= 9) || (v100 >= 11 && v100 <= 14)) {
    return Period.many;
  }

  return Period.other;
};

export const getFormatPeriodEn = (value: number): Period => {
  return value === 1 ? Period.one : Period.other;
};

export interface I18nextPostProcessor extends PostProcessorModule {
  options: I18nextPostProcessorOptions;
  setOptions: (options: I18nextPostProcessorOptions) => void;
}

export interface I18nextPostProcessorOptions {
  oneSuffix: string;
  fewSuffix: string;
  manySuffix: string;
  otherSuffix: string;
}

export const i18nextPostProcessor: I18nextPostProcessor = {
  name: "period",
  type: "postProcessor",

  options: {
    oneSuffix: "_one",
    fewSuffix: "_few",
    manySuffix: "_many",
    otherSuffix: "_other",
  },

  setOptions(options: I18nextPostProcessorOptions) {
    this.options = {
      ...this.options,
      ...options,
    };
  },

  process: function processI18NextValue(value, key, options, translator): string {
    const count = toNumber(options.count);
    const period = translator?.language === "ru" ? getFormatPeriodRu(count) : getFormatPeriodEn(count);

    const newOptions = { ...options };
    delete newOptions.postProcess;

    switch (period) {
      case Period.one:
        return translator.translate([`${key}${this.options.oneSuffix}`], newOptions);
      case Period.few:
        return translator.translate([`${key}${this.options.fewSuffix}`], newOptions);
      case Period.many:
        return translator.translate([`${key}${this.options.manySuffix}`], newOptions);
      case Period.other:
        return translator.translate([`${key}${this.options.otherSuffix}`], newOptions);
      default:
        return value;
    }
  },
};

export const formatDate = (dateString: string) => {
  const dateArr = dateString.split(",");
  const date = dateArr[0];
  const time = dateArr[1];
  const timeSplit = time.split(":");
  return { time: `${timeSplit[0].trim()}:${timeSplit[1].trim()}`, date: date };
};

/**
 * Check if an incoming date string is not less than January 1, 1900, and is in one of the accepted date formats.
 * Accepted date formats: 'YYYY-MM-DD', 'YYYY.MM.DD', 'DD.MM.YYYY'.
 *
 * @param {Moment | Date | string | number} dateValue - The date string to be validated.
 * @returns {boolean} True if the date is valid and not less than January 1, 1900, false otherwise.
 */
export const isMinDateValid = (dateValue: Moment | Date | string | number) => {
  const dateFormats = ["YYYY-MM-DD", "YYYY.MM.DD", "DD.MM.YYYY"];

  for (const format of dateFormats) {
    const date = moment(dateValue, format, true);

    if (date.isValid() && date.isSameOrAfter(moment("02.01.1900", "DD.MM.YYYY"))) {
      return true;
    }
  }

  return false;
};

/**
 * Check if an incoming date is less than today's date.
 * Accepted date formats: 'YYYY-MM-DD', 'YYYY.MM.DD', 'DD.MM.YYYY'.
 *
 * @param {Moment | Date | string | number} dateValue - The date string to be compared with today's date.
 * @returns {boolean} True if the date is less than today's date, false otherwise.
 */
export const isDateLessThanToday = (dateValue: Moment | Date | string | number, isTodayIncluded?: boolean) => {
  const dateFormats = ["YYYY-MM-DD", "YYYY.MM.DD", "DD.MM.YYYY"];

  for (const format of dateFormats) {
    const date = moment(dateValue, format, true);

    if (date.isValid() && isTodayIncluded) {
      if (date.isBefore(moment(), "day") || date.isSame(moment(), "day")) {
        return true;
      }
    }

    if (date.isValid() && date.isBefore(moment(), "day")) {
      return true;
    }
  }

  return false;
};

export const padDate = (date: string) => {
  return Number(date.padStart(1, ""));
};

export const formatDateToDTO = (date: null | undefined | string): string =>
  date ? moment(date).format("DD.MM.YYYY") : "";
