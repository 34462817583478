import React from "react";
import { Box, Typography } from "@mui/material";
import cn from "classnames";
import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import { links } from "~/constants/enums";
import { useTranslation } from "~/i18n";
import { useRouterHooks } from "~/lib/router";
import { UnblockingUserSteps } from "~/modules/keycloak/unblocking-user-model/steps-enum";
import { unblockingModel } from "~/modules/keycloak/unblocking-user-model/unblocking-user-model";
import Icon from "~/shared/Icon";
import { BaseButton } from "~/shared/ui-kit/BaseComponents";
import colorTable from "~/shared/ui-kit/constants/colors";

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    [theme.breakpoints.down(768)]: {
      marginTop: "64px",
      marginBottom: "64px",
    },
  },
  title: {
    fontSize: "24px",
    color: colorTable.blackDefault,
    lineHeight: "28px",
    fontWeight: 600,
  },
  titleMobile: {
    fontSize: "48px",
    color: colorTable.blackDefault,
    lineHeight: "28px",
    fontWeight: 600,
  },
  text: {
    fontSize: "16px",
    marginBottom: "32px",
    color: colorTable.lightGrey_90,
  },
  textMobile: {
    fontSize: "32px",
    marginBottom: "64px",
    color: colorTable.lightGrey_90,
  },
  icon: {
    marginBottom: "16px",
  },
  link: {
    color: colorTable.blue_20,
    textDecoration: "underline",
  },
  buttonMobile: {
    height: "12vw",
    borderRadius: "4px",
    color: colorTable.white,
    background: colorTable.darkBlue,
    marginBottom: "20px",
    "& > span": {
      fontSize: "28px",
      color: colorTable.white,
    },
    "&.MuiButton-contained": {
      boxShadow: "none",
    },
    "&:hover": {
      background: colorTable.blue_10,
    },
  },
  buttonDesktop: {
    marginBottom: "10px !important",
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  btn: {
    width: "280px",
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
  },
}));

export const TemporaryBlockedAccount = () => {
  const { t } = useTranslation();
  const { setStep } = unblockingModel;
  const { classes: classesComponent } = useStyles();
  const { navigate } = useRouterHooks();

  const backToLogin = () => {
    window.location.reload();
  };

  const unBlockUser = () => {
    navigate(links.unblocking);
    setStep(UnblockingUserSteps.EnterUserPhone);
  };

  return (
    <Box className={classesComponent.wrapper}>
      <Box className={classesComponent.icon}>
        <Icon name="blockAccount" size={70} />
      </Box>
      <Box mb="16px">
        <Typography className={classesComponent.title}>{t("BLOCKED.temporarily-blocked.title")}</Typography>
      </Box>
      <Box>
        <Typography className={classesComponent.text}>{t("BLOCKED.temporarily-blocked.subtitle")}</Typography>
      </Box>
      <Box className={classesComponent.buttonsWrapper}>
        <BaseButton
          typeStyle="black"
          onClick={unBlockUser}
          className={cn(classesComponent.buttonDesktop, classesComponent.btn)}
        >
          <span>{t("UNBLOCKING_USER.button")}</span>
        </BaseButton>
        <BaseButton typeStyle="white" className={classesComponent.btn} onClick={backToLogin}>
          <span>{t("BLOCKED.button")}</span>
        </BaseButton>
      </Box>
    </Box>
  );
};
