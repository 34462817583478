import { cast, Yup } from "~/lib/validation";

export interface InvestSectionDtoType {
  // step 1
  elementary: boolean;
  economEducation: boolean[];
  techEducation: boolean[];
  otherEducation: boolean[];
  // step 2
  expInFinSector: boolean[];
  // step 3
  complexFinInstr: boolean[];
  // step 4
  firstTestQuest: boolean;
  // step 5
  secondTestQuest: boolean;
  // step 6
  investType: boolean[];
  // step 7
  assetsDrops: boolean[];
  // step 8
  savingsPart: boolean[];
  // step 9
  investPeriod: boolean[];
  // step 10
  ratio: boolean[];
  // step 11
  specify: boolean[];
  // step 12
  affordToLose: boolean[];
}

export type InvestSectionDto = Nullable<InvestSectionDtoType>;
export type InvestSectionSaveRequest = {
  step: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  data: InvestSectionDto;
};

export const schema = Yup.object<InvestSectionDto>({
  // step 1
  elementary: Yup.boolean().nullable(),
  economEducation: Yup.array().of(Yup.boolean()).nullable(),
  techEducation: Yup.array().of(Yup.boolean()).nullable(),
  otherEducation: Yup.array().of(Yup.boolean()).nullable(),
  // step 2
  expInFinSector: Yup.array().of(Yup.boolean()).nullable(),
  // step 3
  complexFinInstr: Yup.array().of(Yup.boolean()).nullable(),
  // step 4
  firstTestQuest: Yup.boolean().nullable(),
  // step 5
  secondTestQuest: Yup.boolean().nullable(),
  // step 6
  investType: Yup.array().of(Yup.boolean()).nullable(),
  // step 7
  assetsDrops: Yup.array().of(Yup.boolean()).nullable(),
  // step 8
  savingsPart: Yup.array().of(Yup.boolean()).nullable(),
  // step 9
  investPeriod: Yup.array().of(Yup.boolean()).nullable(),
  // step 10
  ratio: Yup.array().of(Yup.boolean()).nullable(),
  // step 11
  specify: Yup.array().of(Yup.boolean()).nullable(),
  // step 12
  affordToLose: Yup.array().of(Yup.boolean()).nullable(),
}).label("InvestSectionDto");

export const castInvestSectionDto = cast<InvestSectionDto>(schema);

export const investSectionNullable: InvestSectionDto = {
  // step 1
  elementary: null,
  economEducation: null,
  otherEducation: null,
  techEducation: null,
  // step 2
  expInFinSector: null,
  // step 3
  complexFinInstr: null,
  // step 4
  firstTestQuest: null,
  // step 5
  secondTestQuest: null,
  // step 6
  investType: null,
  // step 7
  assetsDrops: null,
  // step 8
  savingsPart: null,
  // step 9
  investPeriod: null,
  // step 10
  ratio: null,
  // step 11
  specify: null,
  // step 12
  affordToLose: null,
};
