import { createSelector, Selector } from 'reselect';

import { isError, isInitial, isNotReady, isPending, isReady, isSuccess, needToLoad, toAsyncState } from './async';
import {
  AsyncData,
  AsyncDataError,
  AsyncDataInitial,
  AsyncDataPending,
  AsyncDataPhase,
  AsyncDataSuccess,
} from './models';

export class AsyncDataExt<T = null, E extends Error = Error> {
  private d: AsyncData<T, E>;

  public constructor(data: AsyncData<T, E>) {
    this.d = data;
  }

  public get phase(): AsyncDataPhase {
    return this.d.phase;
  }
  public get data(): T | null {
    return this.d.data;
  }

  public get error(): E | null {
    return this.d.error;
  }

  public needToLoad(includeErrorState = false): boolean {
    return needToLoad(this.d, includeErrorState);
  }

  public isInitial(): this is AsyncDataInitial {
    return isInitial(this.d);
  }

  public isPending(): this is AsyncDataPending<T, E> {
    return isPending(this.d);
  }

  public isError(): this is AsyncDataError<T, E> {
    return isError(this.d);
  }

  public isSuccess(): this is AsyncDataSuccess<T> {
    return isSuccess(this.d);
  }

  public isNotReady(): boolean {
    return isNotReady(this.d);
  }

  public isReady(): boolean {
    return isReady(this.d);
  }

  public toAsyncState(includeInitialState = false): ReturnType<typeof toAsyncState> {
    return toAsyncState(this.d, includeInitialState);
  }
}

export const extSelector = <T = null, E extends Error = Error>(state: AsyncData<T, E>): AsyncDataExt<T, E> =>
  new AsyncDataExt(state);

export const createAsyncSelector = <S extends unknown, T = null, E extends Error = Error>(
  selector: Selector<S, AsyncData<T, E>>,
): Selector<S, AsyncDataExt<T, E>> => createSelector(selector, extSelector);
