import React, { ReactNode } from "react";
import { Box, useMediaQuery } from "@mui/material";
import cn from "classnames";

import { links } from "~/constants/enums";
import { useTranslation } from "~/i18n";
import { urlBack, useRouterHooks } from "~/lib/router";
import colorTable from "~/shared/ui-kit/constants/colors";
import Icon, { IconNameType } from "~/shared/ui-kit/constants/icons/Icon";
import { useStyles } from "./styles";

export interface IBaseBackButtonProps {
  mobileTextColor?: colorTable;
  style?: Record<string, string>;
  link?: string | typeof urlBack;
  children?: ReactNode;
  text?: string;
  classNameLink?: string;
  className?: string;
  tabledOrMobileSize?: number;
  routeState?: any;
  onBack?: () => void;
  resetMargin?: boolean;
  iconName?: IconNameType;
  background?: string;
}

export const BaseBackButton = (props: IBaseBackButtonProps) => {
  const {
    mobileTextColor = colorTable.charcoalBlack,
    style,
    link = links.forYouCommon,
    className,
    tabledOrMobileSize = 768,
    text = "TAB_MENU.for_you",
    routeState,
    onBack,
    resetMargin,
    iconName = "chevronIcon",
    background,
  } = props;

  const { classes } = useStyles({ mobileTextColor, background, tabledOrMobileSize });
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery(`(max-width: ${tabledOrMobileSize}px)`);
  const { navigate } = useRouterHooks();

  const handleBack = () => {
    if (onBack) {
      return onBack();
    }

    if (typeof link === "number") {
      return navigate(link);
    }
    return navigate(link, { state: routeState });
  };

  return (
    <Box className={cn(classes.backgroundColor, { [classes.backButton]: !resetMargin }, className)} style={style}>
      <Box className={cn(classes.link, classes.mobileTextColor, props.classNameLink)} onClick={handleBack}>
        <Icon
          name={iconName}
          color={isTabletOrMobile ? mobileTextColor : colorTable.charcoalBlack}
          size={8}
          className={classes.arrowBack}
        />
        {props.children || t(text)}
      </Box>
    </Box>
  );
};
