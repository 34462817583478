import React, { useEffect } from "react";
import { useForm } from "effector-forms";
import { useStore } from "effector-react";

import { BaseChangeSecurityQuestionForm } from "~/features/BaseComponents";
import { useTranslation } from "~/i18n";
import { validateNonLeadingSpaces } from "~/lib/validation/utils";
import { changeSecurityQuestionModel } from "~/modules/keycloak/change-security-question-model";

export const ChangeSecurityQuestionForm = () => {
  const {
    form,
    formOtp,
    gateOtp,
    $listSecurityQuestion,
    getQuestionsListFx,
    $isShowOtpField,
    resendOtpCode,
    $isLoading,
  } = changeSecurityQuestionModel;
  const listSecurityQuestion = useStore($listSecurityQuestion);
  const isShowOtpField = useStore($isShowOtpField);
  const isLoading = useStore($isLoading);
  const { submit, fields } = useForm(form);
  const { submit: submitOtp, fields: fieldsOtp } = useForm(formOtp);
  const { t, i18n } = useTranslation();

  const handlerNextStep = () => {
    if (isShowOtpField) return submitOtp();

    submit();
  };

  useEffect(() => {
    getQuestionsListFx();
  }, []);

  useEffect(() => {
    if (isShowOtpField) return;

    fields.question.reset();
  }, [i18n.language]);

  return (
    <BaseChangeSecurityQuestionForm
      controlButtons={{
        onNextStep: handlerNextStep,
        disableBackButton: isLoading,
        disableNextButton: isLoading,
        loading: isLoading,
      }}
      questionField={{
        isNewQuestion: true,
        listMenu: listSecurityQuestion.filter((question) => question.language === i18n.language.toUpperCase()),
        disabled: isShowOtpField,
        value: fields.question.value,
        error: fields.question.hasError(),
        helperText: t(fields.question.errorText()),
        handlerChangeValue: (e) => fields.question.onChange(e.target.value),
      }}
      answerField={{
        value: fields.answer.value,
        error: fields.answer.hasError(),
        helperText: t(fields.answer.errorText()),
        handlerChangeValue: (e) => fields.answer.onChange(validateNonLeadingSpaces(e.target.value)),
        disabled: isShowOtpField,
      }}
      otpField={{
        isShow: isShowOtpField,
        effectorGate: gateOtp,
        otpValue: fieldsOtp.otp.value,
        changeOtpCode: fieldsOtp.otp.onChange,
        hasError: fieldsOtp.otp.hasError(),
        errorText: t(fieldsOtp.otp.errorText()),
        resend: resendOtpCode,
      }}
    />
  );
};
