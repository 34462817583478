export interface Remittance {
  sourceLocationGroupId: string;
  destLocationGroupId: string;
  amount: number;
  serviceId: string;
  orderDirection: string;
}

export type pdfBase64Info = {
  content: string;
  type: string;
};

export enum pdfBase64DmaType {
  dma = "dma",
  transfer_order = "transfer-order",
  fee_schedule_effective = "fee-schedule-effective",
  md_terms_deactivation = "md_terms_deactivation",
  fee_schedule = "fee-schedule",
  schedule_e = "schedule_e",
}
