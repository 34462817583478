import axios from "axios";
import { CANCEL } from "redux-saga";

import token from "~/lib/token";
import { delay as delayFromUtils } from "~/lib/utils";
import { AxiosInstance, AxiosRequestConfig, ContentType, RequestData, RequestMethod, RequestOptions } from "./types";
import { appApiUrl, convertDataToRequestFormat } from "./utils";

export const request = (
  instance: AxiosInstance,
  method: RequestMethod,
  url: string,
  { data, params }: RequestData = {},
  { headers, opts, isBlob, contentType, authorizationBearerToken, isPrivate = true, delay = 0 }: RequestOptions = {}
): Promise<unknown> => {
  const authToken = isPrivate ? authorizationBearerToken || token.getAuthorizationBearerToken() : "";

  const config: AxiosRequestConfig = {
    url: appApiUrl(url),
    method,
    params,
    data: data ? convertDataToRequestFormat(data, contentType) : undefined,
    ...(isBlob
      ? {
          responseType: "blob",
          timeout: 30000,
        }
      : {}),
    ...(opts || {}),
    headers: {
      "Content-Type": contentType || ContentType.JSON,
      ...(authToken
        ? {
            Authorization: `Bearer ${authToken}`,
          }
        : {}),
      ...(headers || {}),
    },
  };

  let cancel;
  const result = instance({
    ...config,
    cancelToken: new axios.CancelToken((c): void => {
      cancel = c;
    }),
  })
    .then((data) => (delay > 0 ? delayFromUtils(delay, data) : data))
    .catch((error: unknown): void => {
      if (!axios.isCancel(error)) {
        throw error;
      }
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (result as any)[CANCEL] = cancel; // requires for redux-saga
  return result;
};
