import { createAsyncReducer } from '~/lib/redux';

import {
  keycloakChangePasswordClearAction,
  keycloakChangePasswordErrorAction,
  keycloakChangePasswordStartAction,
  keycloakChangePasswordSuccessAction,
} from '../actions';

export const STATE_NAME = 'changePassword';

const [reducer, defaultState] = createAsyncReducer(
  keycloakChangePasswordStartAction,
  keycloakChangePasswordSuccessAction,
  keycloakChangePasswordErrorAction,
  keycloakChangePasswordClearAction,
);

export type KeycloakChangePasswordState = typeof defaultState;

export default reducer;
