import { cast, Yup } from "~/lib/validation";

interface IoPepInfoDtoType {
  function: string;
  periodEnd: string;
  periodStart: string;
  birthCity: string;
  birthCountryCode: string;
  birthDate: string;
  surname: string;
  name: string;
  middleName: string;
  passportDate: string;
  passSeries: string;
  passNum: string;
  relationship: string;
}

export type IoPepInfoDto = Nullable<IoPepInfoDtoType>;

export const schema = Yup.object<IoPepInfoDto>({
  function: Yup.string().nullable(),
  periodEnd: Yup.string().nullable(),
  periodStart: Yup.string().nullable(),
  birthCity: Yup.string().nullable(),
  birthCountryCode: Yup.string().nullable(),
  birthDate: Yup.string().nullable(),
  surname: Yup.string().nullable(),
  name: Yup.string().nullable(),
  middleName: Yup.string().nullable(),
  passportDate: Yup.string().nullable(),
  passSeries: Yup.string().nullable(),
  passNum: Yup.string().nullable(),
  relationship: Yup.string().nullable(),
}).label("IoPepInfoDto");

export const castIoPepInfoDto = cast<IoPepInfoDto>(schema);

export const emptyIoPepInfo: IoPepInfoDto = {
  function: "",
  periodStart: "",
  periodEnd: "",
  birthCity: "",
  birthCountryCode: "",
  birthDate: "",
  surname: "",
  name: "",
  middleName: "",
  passportDate: "",
  passSeries: "",
  passNum: "",
  relationship: "",
};
