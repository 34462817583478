import React, { FunctionComponent, ReactElement, SVGProps, useState } from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import { makeStyles } from "tss-react/mui";

import colorTable from "../../colors";
import { ReactComponent as AndroidAppQr } from "../Android_app_qr.svg";
import { ReactComponent as AndroidApp } from "../AndroidApp.svg";
import { ReactComponent as AppQR } from "../app_qr.svg";
import { ReactComponent as AppleAppQr } from "../Apple_app_qr.svg";
import { ReactComponent as AppleApp } from "../AppleApp.svg";
import { ReactComponent as AttentionIcon } from "../Attention_24.svg";
import { ReactComponent as Buy } from "../Buy.svg";
import { ReactComponent as CalendarIcon } from "../CalendarIcon.svg";
import { ReactComponent as ChartArrowRight } from "../chart_arrow_right.svg";
import { ReactComponent as ChartLines } from "../ChartLines.svg";
import { ReactComponent as ChartPie } from "../ChartPie.svg";
import { ReactComponent as ChatIcon } from "../ChatIcon.svg";
import { ReactComponent as CheckboxFilter } from "../checkbox_filter.svg";
import { ReactComponent as CheckboxEmptyFilter } from "../checkbox_filter_empty.svg";
import { ReactComponent as ChevronAccordion } from "../ChevronAccordion.svg";
import { ReactComponent as ChevronIcon } from "../ChevronIcon.svg";
import { ReactComponent as Close } from "../Close.svg";
import { ReactComponent as Alto } from "../coloredIcons/alto.svg";
import { ReactComponent as CircleIcon1 } from "../coloredIcons/circle_icon_1.svg";
import { ReactComponent as CircleIcon2 } from "../coloredIcons/circle_icon_2.svg";
import { ReactComponent as CircleIcon3 } from "../coloredIcons/circle_icon_3.svg";
import { ReactComponent as CircleIcon4 } from "../coloredIcons/circle_icon_4.svg";
import { ReactComponent as CircleIcon5 } from "../coloredIcons/circle_icon_5.svg";
import { ReactComponent as CircleIcon6 } from "../coloredIcons/circle_icon_6.svg";
import { ReactComponent as Forward } from "../coloredIcons/forward.svg";
import { ReactComponent as Contact } from "../Contact.svg";
import { ReactComponent as CouponDate } from "../couponDate.svg";
import { ReactComponent as Currency } from "../Currency.svg";
import { ReactComponent as DeligatedManagement } from "../Deligated-Management.svg";
import { ReactComponent as DocumentOrderStatus } from "../Document.svg";
import { ReactComponent as Document } from "../DocumentIcon.svg";
import { ReactComponent as DoneIcon } from "../Done.svg";
import { ReactComponent as DownloadArrow } from "../DownloadArrow.svg";
import { ReactComponent as DownloadDocument } from "../DownloadDocument.svg";
import { ReactComponent as EmptyFolder } from "../empty_folder.svg";
import { ReactComponent as ErrorOrderStatus } from "../ErrorOrderStatus.svg";
import { ReactComponent as EyeHide } from "../EyeHide.svg";
import { ReactComponent as EyeShow } from "../EyeShow.svg";
import { ReactComponent as Facebook } from "../Facebook.svg";
import { ReactComponent as Favorites } from "../Favorites.svg";
import { ReactComponent as Filter } from "../Filter.svg";
import { ReactComponent as Fire } from "../Fire.svg";
import { ReactComponent as Flash } from "../Flash.svg";
import { ReactComponent as NoticeAlert } from "../HotNewsAlert.svg";
import { ReactComponent as IncomeWithdraw } from "../IncomeWithdraw.svg";
import { ReactComponent as InfoNoticeIcon } from "../Info_filled.svg";
import { ReactComponent as InfoIcon } from "../InfoIcon.svg";
import { ReactComponent as Information_light } from "../Information_light.svg";
import { ReactComponent as Instagram } from "../Instagram.svg";
import { ReactComponent as Invalid_browser } from "../Invalid_browser.svg";
import { ReactComponent as LockFilled } from "../Lock.svg";
import { ReactComponent as Lock } from "../Lock_24.svg";
import { ReactComponent as MarginTrade } from "../Margin_trade.svg";
import { ReactComponent as Mobile } from "../Mobile.svg";
import { ReactComponent as Pending } from "../Pending.svg";
import { ReactComponent as Percent } from "../Percent.svg";
import { ReactComponent as PercentCircle } from "../PercentCircle.svg";
import { ReactComponent as ProfitabilityUp } from "../profitability_up.svg";
import { ReactComponent as Rectangle } from "../Rectangle_2252.svg";
import { ReactComponent as Replenishment } from "../Replenishment.svg";
import { ReactComponent as Reports } from "../Reports.svg";
import { ReactComponent as Requisites } from "../requisites.svg";
import { ReactComponent as Sell } from "../Sell.svg";
import { ReactComponent as Shield } from "../Shield.svg";
import { ReactComponent as SortingDefault } from "../SortingDefault.svg";
import { ReactComponent as SortingDesc } from "../SortingDesc.svg";
import { ReactComponent as SortingIcon } from "../SortingIcon.svg";
import { ReactComponent as AlphabetIcon } from "../stockIcons/AlphabetInc.svg";
import { ReactComponent as AnalogDevicesIcon } from "../stockIcons/AnalogDevicesIcon.svg";
import { ReactComponent as DynatraceIcon } from "../stockIcons/DynatraceIcon.svg";
import { ReactComponent as MarvelIcon } from "../stockIcons/MarvellIcon.svg";
import { ReactComponent as NewmontIcon } from "../stockIcons/NewmontCorporation.svg";
import { ReactComponent as VisaIcon } from "../stockIcons/VisaInc.svg";
import { ReactComponent as SuccessStatus } from "../SuccessStatus.svg";
import { ReactComponent as SwapRepo } from "../SwapRepo.svg";
import { ReactComponent as TableDoubleArrow } from "../TableDoubleArrow.svg";
import { ReactComponent as Terminal } from "../Terminal.svg";
import { ReactComponent as TimeIcon } from "../Time_24.svg";
import { ReactComponent as TimeCurrentIcon } from "../Time_current.svg";
import { ReactComponent as UsdIcon } from "../UsdIcon.svg";
import { ReactComponent as Warning } from "../Warning.svg";
import { ReactComponent as Withdraw } from "../Withdraw.svg";
import { ReactComponent as GreenCheck } from "../Сheckmark_24.svg";

export const IconMap = {
  document: Document,
  replenishment: Replenishment,
  withdraw: Withdraw,
  reports: Reports,
  downloadArrow: DownloadArrow,
  downloadDocument: DownloadDocument,
  tableDoubleArrow: TableDoubleArrow,
  timeIcon: TimeIcon,
  greenCheck: GreenCheck,
  circleLock: Lock,
  attentionIcon: AttentionIcon,
  chat: ChatIcon,
  contact: Contact,
  appleApp: AppleApp,
  eyeShow: EyeShow,
  eyeHide: EyeHide,
  androidApp: AndroidApp,
  facebook: Facebook,
  instagram: Instagram,
  infoIcon: InfoIcon,
  favorites: Favorites,
  deligatedManagement: DeligatedManagement,
  terminal: Terminal,
  chartPie: ChartPie,
  chartLines: ChartLines,
  marginTrade: MarginTrade,
  appQR: AppQR,
  marvelIcon: MarvelIcon,
  analogDevicesIcon: AnalogDevicesIcon,
  dynatraceIcon: DynatraceIcon,
  alphabetIcon: AlphabetIcon,
  newmontIcon: NewmontIcon,
  visaIcon: VisaIcon,
  chevronIcon: ChevronIcon,
  close: Close,
  usdIcon: UsdIcon,
  circleIcon1: CircleIcon1,
  circleIcon2: CircleIcon2,
  circleIcon3: CircleIcon3,
  circleIcon4: CircleIcon4,
  circleIcon5: CircleIcon5,
  circleIcon6: CircleIcon6,
  forward: Forward,
  alto: Alto,
  doneIcon: DoneIcon,
  infoNoticeIcon: InfoNoticeIcon,
  noticeAlert: NoticeAlert,
  couponDate: CouponDate,
  chevronAccordion: ChevronAccordion,
  pending: Pending,
  errorOrderStatus: ErrorOrderStatus,
  documentOrderStatus: DocumentOrderStatus,
  sortingDesc: SortingDesc,
  successStatus: SuccessStatus,
  sortingIcon: SortingIcon,
  sortingDefault: SortingDefault,
  incomeWithdraw: IncomeWithdraw,
  chartArrowRight: ChartArrowRight,
  profitabilityUp: ProfitabilityUp,
  mobile: Mobile,
  lock: LockFilled,
  appleAppQr: AppleAppQr,
  androidAppQr: AndroidAppQr,
  warning: Warning,
  checkboxFilter: CheckboxFilter,
  checkboxEmptyFilter: CheckboxEmptyFilter,
  percent: Percent,
  flash: Flash,
  buy: Buy,
  sell: Sell,
  emptyFolder: EmptyFolder,
  fire: Fire,
  calendarIcon: CalendarIcon,
  currency: Currency,
  shield: Shield,
  percentCircle: PercentCircle,
  filter: Filter,
  timeCurrentIcon: TimeCurrentIcon,
  rectangle: Rectangle,
  information_light: Information_light,
  requisites: Requisites,
  swapRepo: SwapRepo,
  invalid_browser: Invalid_browser,
};

export type IconNameType = keyof typeof IconMap;

export type IconSize =
  | 720
  | 370
  | 150
  | 116
  | 100
  | 81
  | 70
  | 68
  | 56
  | 54
  | 50
  | 40
  | 32
  | 30
  | 27
  | 24
  | 20
  | 19
  | 18
  | 16
  | 14
  | 12
  | 10
  | 8;

interface ImgIconProps extends IconProps {
  icon?: string;
}

const useStyles = makeStyles<Pick<IconProps, "name" | "size" | "color" | "withBackground">>()(
  (_, { name, size, color, withBackground }) => ({
    root: {
      width: 20,
      height: 20,
    },
    size: {
      width: size,
      height: size,
    },
    color: {
      color,
    },
    withBackground: {
      padding: withBackground?.padding,
      borderRadius: withBackground?.borderRadius,
      backgroundColor: withBackground?.backgroundColor,
      width: withBackground?.width || "fit-content",
      height: withBackground?.height,
      display: withBackground?.display || "block",
      alignItems: withBackground?.alignItems || "initial",
      justifyContent: withBackground?.justifyContent || "initial",
    },
    name: {
      backgroundImage: name && IconMap[name] ? `url("${IconMap[name]}")` : "none",
      backgroundRepeat: "no-repeat",
    },
  })
);

const ImgIcon = ({ className, name, size = 20, icon, fallbackSrc }: ImgIconProps): ReactElement => {
  const { classes } = useStyles({ name, size });
  const [imageSrc, setImageSrc] = useState<string | undefined>(icon);

  const handleImageError = () => {
    if (fallbackSrc) {
      setImageSrc(fallbackSrc);
    }
  };

  return <img src={imageSrc} className={cn(classes.size, className)} onError={handleImageError} />;
};

export interface IconProps {
  className?: string;
  size?: IconSize;
  name?: IconNameType;
  icon?: string | FunctionComponent<SVGProps<SVGSVGElement>>;
  fallbackSrc?: string;
  children?: any;
  color?: colorTable;
  withBackground?: {
    padding?: string;
    borderRadius?: string;
    backgroundColor?: colorTable;
    width?: number | string;
    height?: number | string;
    display?: string;
    alignItems?: string;
    justifyContent?: string;
  };
}

/**
 *
 * @param className
 * @param name - name of svg component from map
 * @param color - color of svg component
 * @param withBackground - styles for svg wrapper bacground
 * @param size - default is 20px
 * @param icon - SVGComponent(can be controlled by classname) or image source(for backend img urls)
 * @param fallbackSrc - if there is no image source(from backend) change it on fallbackSrc
 * @returns react svg component, img or svg element
 */

const Icon = ({
  className,
  name,
  size = 20,
  icon,
  fallbackSrc,
  color = colorTable.charcoalBlack,
  withBackground,
}: IconProps): ReactElement => {
  const { classes } = useStyles({ name, size, color, withBackground });

  if (icon) {
    if (typeof icon === "string") {
      return <ImgIcon className={className} name={name} size={size} icon={icon} fallbackSrc={fallbackSrc} />;
    }

    const SVGIcon = icon;
    return <SVGIcon className={cn(classes.size, className)} />;
  }

  const IconComponent = name && IconMap[name];

  return (
    <>
      {!!IconComponent && (
        <Box className={classes.withBackground}>
          <IconComponent className={cn(classes.size, classes.color, className)} />
        </Box>
      )}
    </>
  );
};

export default Icon;
