import React, { ReactHTML, useImperativeHandle, useRef } from "react";
import cn from "classnames";
import omit from "lodash/omit";

import { BaseTypography, BaseTypographyProps } from "~/shared/ui-kit/BaseComponents";
import { CheckIcon } from "~/shared/ui-kit/constants/icons";
import useStyles from "./styles";

export interface BaseCheckboxProps<T extends keyof ReactHTML> extends React.ComponentProps<"input"> {
  typographyProps?: Pick<BaseTypographyProps<T>, "className" | "fontType">;
  children?: BaseTypographyProps<T>["children"];
  checkboxRef?: React.MutableRefObject<HTMLInputElement | null>;
  checkboxFieldRef?: React.MutableRefObject<HTMLDivElement | null>;
}

export default function <P extends keyof ReactHTML>(props: BaseCheckboxProps<P>) {
  const checkboxProps = omit(props, ["typographyProps", "children", "checkboxFieldRef", "checkboxRef", "className"]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  useImperativeHandle(props.checkboxRef, () => inputRef.current as HTMLInputElement);

  const handlerClickField = () => {
    inputRef.current && inputRef.current.click();
  };

  const tableStateClasses = {
    disabled: props.disabled,
    checked: props.checked,
  };

  const { classes } = useStyles();
  return (
    <div
      onClick={handlerClickField}
      className={cn(classes.checkboxField, tableStateClasses, props.className)}
      ref={props.checkboxFieldRef}
    >
      <input {...checkboxProps} ref={inputRef} type="checkbox" className={classes.inputCheckbox} />
      <div className={cn(classes.checkbox, tableStateClasses)}>
        <CheckIcon className={cn(classes.checkIcon, tableStateClasses)} />
      </div>
      {props.children && (
        <BaseTypography
          {...props.typographyProps}
          tag="label"
          fontType={props.typographyProps?.fontType || "subhead"}
          className={cn(classes.label, tableStateClasses, props.typographyProps?.className)}
        >
          {props.children}
        </BaseTypography>
      )}
    </div>
  );
}
