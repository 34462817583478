import React, { useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import TextField, { TextFieldProps } from "~/shared/ui/TextField";
import colorTable from "~/shared/ui-kit/constants/colors";
import Icon from "~/shared/ui-kit/constants/icons/Icon";

const useStyles = makeStyles()((theme) => ({
  endBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
    color: colorTable.moonGrey,
    "&:hover path": {
      fill: colorTable.charcoalBlack,
    },
    [theme.breakpoints.down(768)]: {
      padding: "7px",
      "& svg": {
        width: "20px",
        height: "20px",
      },
    },
  },
}));

export type PasswordFieldProps = Omit<TextFieldProps, "type">;

const inputProps = { autoComplete: "off" };

const PasswordField = (props: PasswordFieldProps) => {
  const { defaultVisibleValue, ...textFieldProps } = props;

  const [showPassword, changeShowPassword] = useState<boolean>(!!defaultVisibleValue ? defaultVisibleValue : false);
  const { classes } = useStyles();

  return (
    <TextField
      {...textFieldProps}
      type={showPassword ? "text" : "password"}
      inputProps={inputProps}
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              className={classes.endBtn}
              aria-label="Toggle password visibility"
              onClick={(): void => changeShowPassword(!showPassword)}
            >
              {showPassword ? (
                <Icon name="eyeHide" color={colorTable.moonGrey} size={24} withBackground={{ height: 24 }} />
              ) : (
                <Icon name="eyeShow" color={colorTable.moonGrey} size={24} withBackground={{ height: 24 }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
