import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import cn from "classnames";

import Icon from "~/shared/Icon";
import { IconNameType, IconSize } from "~/shared/Icon/Icon";
import { ReactComponent as ExclamationIcon } from "~/shared/Icon/icons/tooltip/exclamation_icon.svg";
import { ReactComponent as QuestionIcon } from "~/shared/Icon/icons/tooltip/tooltip-icon.svg";
import { Tooltip } from "~/shared/ui/OpenAccount";
import { useStyles } from "./styles";

type TTooltipIconProps = {
  icon?: IconNameType | ReactNode;
  size?: IconSize;
  isExclamationIcon?: boolean;
};

export const TooltipIcon = ({ size = 16, isExclamationIcon = false }: TTooltipIconProps) => {
  const { classes } = useStyles();
  if (isExclamationIcon) {
    return <Icon icon={ExclamationIcon} size={size} className={classes.icon} />;
  }
  return <Icon icon={QuestionIcon} size={size} className={classes.icon} />;
};

interface BaseTooltipProps {
  text?: string | ReactNode;
  children?: ReactNode;
  hideIcon?: boolean;
  icon?: IconNameType | ReactNode;
  isExclamationIcon?: boolean;
  className?: string;
}

export const BaseTooltip = ({ text, children, icon, hideIcon, isExclamationIcon, className }: BaseTooltipProps) => {
  const { classes } = useStyles();

  return (
    <>
      <Box className={cn(classes.flexBox, className)}>
        {children}
        {text && !hideIcon && (
          <Tooltip tooltipText={text} classNameWrapIcon={classes.wrapIconTooltip}>
            <TooltipIcon icon={icon} isExclamationIcon={isExclamationIcon} />
          </Tooltip>
        )}
      </Box>
    </>
  );
};
