import { createSelector } from "reselect";

import { createAsyncSelector, extSelector } from "~/lib/redux/async";
import {
  AppBootState,
  AppErrorState,
  AppMessageState,
  AppSmsSignState,
  AppState,
  BOOT_STATE_NAME,
  ERROR_STATE_NAME,
  MESSAGES_STATE_NAME,
  SMS_SIGN_STATE_NAME,
  STATE_NAME,
} from "./reducer";

export const appStateSelector = (state: { [STATE_NAME]: AppState }): AppState => state[STATE_NAME];

export const appBootStateSelector = createSelector(appStateSelector, (state): AppBootState => state[BOOT_STATE_NAME]);

export const appBootSelector = createSelector(appBootStateSelector, extSelector);

export const appMessagesStateSelector = createSelector(
  appStateSelector,
  (state): AppMessageState => state[MESSAGES_STATE_NAME]
);

export const appErrorStateSelector = createSelector(
  appStateSelector,
  (state): AppErrorState => state[ERROR_STATE_NAME]
);

export const appSmsSignStateSelector = createSelector(
  appStateSelector,
  (state): AppSmsSignState => state[SMS_SIGN_STATE_NAME]
);

export const appSmsSignAsyncSelector = createAsyncSelector(appSmsSignStateSelector);
