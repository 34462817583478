import { createReducer, onReset } from "~/lib/redux";

import { KeycloakResponseModelState } from "~/entities/keycloak";

import { keycloakCheckOtpSuccessAction } from "../actions";

export const STATE_NAME = "resetPasswordQuestion";

const defaultState = "";

const reducer = createReducer({}, defaultState);

reducer.on(keycloakCheckOtpSuccessAction, (state, payload): string => {
  if (
    payload.state === KeycloakResponseModelState.Success &&
    payload.context === "security-question" &&
    payload.content
  ) {
    return payload.content;
  }

  return state;
});

onReset(reducer, defaultState);

export type KeycloakResetPasswordQuestionState = string;

export default reducer;
