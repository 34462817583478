export enum CurrencyCode {
  SUR = 'SUR',
  RUB = 'RUB',
  RUR = 'RUR',
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  CHF = 'CHF',
  JPY = 'JPY',
}

export enum CurrencySymbol {
  SUR = '₽',
  RUB = '₽',
  RUR = '₽',
  EUR = '€',
  USD = '$',
  GBP = '£',
  CHF = '₣',
  JPY = '¥',
}
