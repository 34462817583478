import React, { ReactElement } from "react";
import { Typography } from "@mui/material";
import { nanoid } from "nanoid";
import { makeStyles } from "tss-react/mui";

import { themeV5 } from "~/configs/theme";
import colorTable from "~/shared/ui-kit/constants/colors";

const useStyles = makeStyles()(() => ({
  alignedText: {
    textAlign: "center",
    fontSize: "16px",
    color: colorTable.textSecondary,
    "& a": {
      textDecoration: "none",
      color: colorTable.charcoalBlack,
      fontWeight: 600,
    },
  },
  subTitleDescription2: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: colorTable.textSecondary,
    "& a": {
      textDecoration: "none",
      color: colorTable.charcoalBlack,
      fontWeight: 600,
    },
    [themeV5.breakpoints.down(768)]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
}));

interface IProps {
  arrDescr: (string | ReactElement)[];
}

// внутри строки в языковых файлах можно оставлять ссылки на почту и телефон
// если нужно прокинуть элемент, то прокидываем его одним из элементов массива
//TODO заменить nanoid()

export const ModalDescriptionGroup = ({ arrDescr }: IProps) => {
  const { classes } = useStyles();

  return (
    <>
      {arrDescr.map((el: string | ReactElement, ind: number) => {
        return typeof el === "string" ? (
          <Typography
            className={classes.subTitleDescription2}
            dangerouslySetInnerHTML={{ __html: el }}
            key={nanoid()}
          />
        ) : (
          <Typography className={classes.subTitleDescription2} key={`key_${ind}`}>
            {el}
          </Typography>
        );
      })}
    </>
  );
};
