import React from "react";
import { Box, Grid } from "@mui/material";
import { useUnit } from "effector-react";
import { makeStyles } from "tss-react/mui";

import ViewportHeader from "~/app/layouts/BaseLayout/components/ViewportHeader";
import { useTranslation } from "~/i18n";
import { checkBrowserVersionModel } from "~/modules/check-browser-version";
import { BaseTypography } from "~/shared/ui-kit/BaseComponents";
import colorTable from "~/shared/ui-kit/constants/colors";
import Icon from "~/shared/ui-kit/constants/icons/Icon";

const useStyles = makeStyles()(() => ({
  wrapper: {
    fontFamily: "Formular, Arial, sans-serif",
    height: "100%",
  },
  notAvailable: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "black",
  },
  containerWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    marginTop: "-80px",
    padding: "0 16px",
    gap: "24px",
    "& p": {
      textAlign: "center",
    },
  },
  icon: {
    alignSelf: "center",
  },
  subtitle: {
    paddingTop: "8px",
    color: colorTable.textSecondary,
    maxWidth: "490px",
    textAlign: "center",
    whiteSpace: "pre-wrap",
  },
}));

export const NotAvailableBrowser = () => {
  const { t } = useTranslation();
  const { $message } = checkBrowserVersionModel;
  const message = useUnit($message);
  const { classes } = useStyles();

  return (
    <Grid container direction="column" alignItems="stretch" wrap="nowrap">
      <Box className={classes.containerWrapper}>
        <ViewportHeader unAuthorized={true} />
        <Box className={classes.notAvailable}>
          <Box className={classes.content}>
            <Box className={classes.icon}>
              <Icon name="invalid_browser" size={68} />
            </Box>
            <Box>
              <BaseTypography fontType="title_2_bold" tag="p">
                {t(message.title)}
              </BaseTypography>
              <BaseTypography tag="p" fontType="text" className={classes.subtitle}>
                {message?.browser && message?.version
                  ? t("ERRORS.invalid_browser", {
                      browser: message?.browser,
                      version: message?.version,
                    })
                  : t("ERRORS.invalid_browser_without_version")}
              </BaseTypography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
