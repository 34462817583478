import { SELECT_ALL_NAME } from "./const";

export const filterBy = (list: any, accountNumber: any, currencyCode: any) => {
  if (!list || !list?.length) {
    return [];
  }

  let result = list;

  if (accountNumber !== SELECT_ALL_NAME) {
    result = result.filter((item: any) => item.accountNumber === accountNumber);
  }

  if (currencyCode !== SELECT_ALL_NAME) {
    result = result.filter((item: any) => {
      return item.currencyInfo?.code === currencyCode
    });
  }

  return result;
};
