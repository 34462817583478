import { combineReducers } from 'redux';

import current, { ClientCardCurrentState, STATE_NAME as CURRENT_STATE_NAME } from './reducers/current';

export const STATE_NAME = 'client-card';

export interface ClientCardState {
  [CURRENT_STATE_NAME]: ClientCardCurrentState;
}

export default combineReducers<ClientCardState>({
  [CURRENT_STATE_NAME]: current,
});
