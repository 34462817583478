import { get } from "~/lib/request";
import { result } from "~/lib/validation";
import { getUserTokenInfo } from "~/modules/userToken/api";
import { BankResponse, caseBankResponse } from "./models/BankResponse";

export const banks = async (searchText: string): Promise<BankResponse> =>
  result(await get("@msbank/api/v2/front/banks", { params: { searchText } }), caseBankResponse);

export const banksMeta = async (uuid: string) => {
  const res = await get("@msbank/api/v2/banks/meta", { params: { uuid } });
  return res.data;
};

export const currenciesCode = async (code: string) => {
  const res = await get("@msbank/api/v2/currency", { params: { code } });
  return res.data;
};

export const currenciesCodes = async () => {
  const params = {
    clientId: getUserTokenInfo().id,
  };
  const res = await get("@msbank/api/v2/currency/all", { params });
  return res.data;
};
