import { makeStyles } from "tss-react/mui";

import colorTable from "~/shared/ui-kit/constants/colors";

export const useStyles = makeStyles()((theme) => ({
  button: {
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: "auto",
    "&:hover": {
      backgroundColor: colorTable.transparent,
    },
    [theme.breakpoints.down(768)]: {
      padding: 0,
      height: "auto",
    },
  },
  arrow: {
    color: colorTable.lightGrey_40,
    transition: "transform 0.3s ease-out",
    [theme.breakpoints.down(768)]: {
      fontSize: "19px !important",
    },
  },
  arrowRotate: {
    transform: "rotate(180deg)",
  },
  white: {
    color: `${colorTable.white} !important`,
  },
  black: {
    color: colorTable.blackBlue,
  },
  text: {
    width: "60px",
    position: "absolute",
    fontWeight: 500,
    fontSize: "14px",
    right: "26px",
    color: colorTable.blackDefault,
    "&:hover": {
      color: colorTable.blue_20,
    },
  },
  textWhite: {
    position: "absolute",
    color: colorTable.white,
    fontWeight: 500,
    fontSize: "11px",
  },
  changeLocaleMenuWrapper: {
    position: "absolute",
    zIndex: 1000,
    width: "208px",
    top: "100%",
    left: "-163px",
    background: colorTable.white,
    boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    [theme.breakpoints.down(576)]: {
      left: "-120px",
      width: "160px",
    },
  },
  switchLocaleWrapper: {
    position: "relative",
  },
  switchLocaleText: {
    fontSize: "12px",
    color: colorTable.charcoalBlack,
    cursor: "pointer",
    "&:hover": {
      color: colorTable.nobleOchre,
    },
  },
  switchLocaleTextWrapper: {
    padding: "12px 16px",
    "&:hover": {
      background: colorTable.lightGrey,
      cursor: "pointer",
    },
  },
  onlyText: {
    color: "black",
    fontWeight: 500,
    fontSize: "14px",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(768)]: {
      fontSize: "12px !important",
    },
    "&:hover": {
      color: colorTable.nobleOchre,
    },
  },
  onlyTextActive: {
    color: colorTable.nobleOchre,
  },
}));
