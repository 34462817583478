import React, { ReactElement, useMemo } from "react";
import { Link } from "react-router-dom";

import { checkSentStatusTypes, links } from "~/constants/enums";
import { useTranslation } from "~/i18n";
import { ModalButtonGroup, ModalDescriptionGroup, ModalTitleGroup } from "~/shared/ui/Modal";
import { DownloadDocW8Btn } from "../helper";

interface TOperationContentProps {
  checkSentStatus: string;
  btnHandler: () => void;
  withDocBtn?: boolean;
}

export const OperationContent = ({ checkSentStatus, btnHandler, withDocBtn }: TOperationContentProps) => {
  const { t } = useTranslation();

  const openAccountModalText = useMemo((): (string | ReactElement)[] => {
    switch (checkSentStatus) {
      case checkSentStatusTypes.bs:
        return [
          t("MODAL_OPEN_ACCOUNT.bs"),
          <>
            {t("MODAL_OPEN_ACCOUNT.bs_desc")} <Link to={links.services}>{t("MODAL_OPEN_ACCOUNT.services")}</Link>
          </>,
        ];
      case checkSentStatusTypes.bsCheck:
        return [t("MODAL_OPEN_ACCOUNT.bs_check")];
      case checkSentStatusTypes.duCheck:
        return [
          t("MODAL_OPEN_ACCOUNT.du_check"),
          <>
            {t("MODAL_OPEN_ACCOUNT.du_desc")} <Link to={links.services}>{t("MODAL_OPEN_ACCOUNT.services")}</Link>
          </>,
        ];
      case checkSentStatusTypes.du:
        return [
          t("MODAL_OPEN_ACCOUNT.du"),
          <>
            {t("MODAL_OPEN_ACCOUNT.du_desc")} <Link to={links.servicesDocuments}>{t("DOCUMENTS.label")}</Link>
          </>,
        ];
      default:
        return [];
    }
  }, []);

  const modalTitle = checkSentStatus.includes(checkSentStatusTypes.bs)
    ? t("MODAL_OPEN_ACCOUNT.bs_title")
    : t("MODAL_OPEN_ACCOUNT.du_title");

  return (
    <>
      <ModalTitleGroup title={modalTitle} />
      <ModalDescriptionGroup arrDescr={openAccountModalText} />
      <ModalButtonGroup
        buttons={[
          {
            typeStyle: "black",
            buttonText: t("OK"),
            buttonHandler: btnHandler,
          },
        ]}
        docBtn={withDocBtn ? <DownloadDocW8Btn /> : undefined}
      />
    </>
  );
};
