import { createReducer, Reducer } from '~/lib/redux';
import { AsyncData, AsyncDataPhase, initial, error, success } from '~/lib/redux/async';

import { InvestSectionDto } from '~/entities/anketa';

import {
  anketaSaveInvestSectionStartAction,
  anketaSaveInvestSectionSuccessAction,
  anketaSaveInvestSectionErrorAction,
  anketaUpdateInvestSectionStartAction,
  anketaUpdateInvestSectionSuccessAction,
  anketaUpdateInvestSectionErrorAction,
} from '../actions';

export const STATE_NAME = 'saveOrUpdateInvestSection';

export type AnketaSaveOrUpdateInvestSectionState = AsyncData<InvestSectionDto>;

export const defaultState: AnketaSaveOrUpdateInvestSectionState = initial();

const reducer: Reducer<AnketaSaveOrUpdateInvestSectionState> = createReducer<AnketaSaveOrUpdateInvestSectionState>(
  {},
  defaultState,
);

const pendingReducer = (state: AnketaSaveOrUpdateInvestSectionState): AnketaSaveOrUpdateInvestSectionState => ({
  ...state,
  phase: AsyncDataPhase.Pending,
});

const successReducer = (_: unknown, payload: InvestSectionDto): AnketaSaveOrUpdateInvestSectionState =>
  success<InvestSectionDto>(payload);

const errorReducer = (state: AnketaSaveOrUpdateInvestSectionState, err: Error): AnketaSaveOrUpdateInvestSectionState =>
  error<InvestSectionDto>(state.data || null, err);

reducer.on(anketaSaveInvestSectionStartAction, pendingReducer);
reducer.on(anketaUpdateInvestSectionStartAction, pendingReducer);

reducer.on(anketaSaveInvestSectionSuccessAction, successReducer);
reducer.on(anketaUpdateInvestSectionSuccessAction, successReducer);

reducer.on(anketaSaveInvestSectionErrorAction, errorReducer);
reducer.on(anketaUpdateInvestSectionErrorAction, errorReducer);

export default reducer;
