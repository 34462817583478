import { clientChannels, DocumentsForReviewFlags, orderStatusesEnum, orderTypesEnum } from "~/constants/enums";
import { get, post, put } from "~/lib/request";
import { TUploadFile } from "~/modules/replenishment-brokerage-account-model/payment-proof-document/types";
import { brokerServiceApi } from "~/pages/OpenAccount/services/api";
import { TRequisit, TServiceActivationStatus, TTaskCompleteParams } from "./types";

export const replenishmentBrokerageAccountApi = {
  getRequisitesBo: async (): Promise<TRequisit[]> => {
    const params = {
      serviceType: DocumentsForReviewFlags.BO,
    };
    const res = await get("@auth/portfolio/v2/requisites", { params });

    return res.data.content;
  },
  startProcess: async (id: string): Promise<string> => {
    const params = {
      requestId: id,
    };
    const res = await post(
      "@auth/executor/v1/requests/bodr/documents",
      { params },
      {
        headers: {
          "X-Channel": clientChannels.UCF_WEB,
        },
      }
    );

    return res.data.businessKey;
  },
  taskComplete: async (data: TTaskCompleteParams): Promise<[]> => {
    const res = await put("@auth/executor/v1/task/complete", { data });

    return res.data;
  },
  getServiceActivationStatus: async (): Promise<TServiceActivationStatus[]> => {
    const params = {
      serviceType: orderTypesEnum.FUNDS_DEPOSIT,
      status: orderStatusesEnum.PENDING_APPROVAL_DOCUMENT,
    };
    const res = await get("@auth/service-activation/status", { params });

    return res.data.content;
  },
  uploadDocuments: async (document: TUploadFile): Promise<[]> => {
    const res = await brokerServiceApi.uploadingFiles(document);

    return res;
  },
};
