import React, { memo } from "react";
import { ListChildComponentProps } from "react-window";
import { MenuItem, useMediaQuery } from "@mui/material";
import { GetItemPropsOptions } from "downshift";

interface Suggestion {
  label?: string;
  value: string | number;
}

type TItemRendererProps = Omit<ListChildComponentProps, "data"> & {
  data: {
    list: Suggestion[];
    highlightedIndex: number | null;
    getItemProps: (options: GetItemPropsOptions<Suggestion>) => Record<string, unknown>;
  };
};

const ItemRenderer = ({ data, index, style }: TItemRendererProps) => {
  const { list, highlightedIndex, getItemProps } = data;
  const suggestion = list[index] || {};

  const isTabletOrMobile = useMediaQuery("(max-width: 1024px)");

  return (
    <MenuItem
      key={index}
      {...getItemProps({ item: suggestion, index })}
      selected={highlightedIndex === index}
      style={style}
    >
      <div
        style={
          isTabletOrMobile
            ? { overflow: "hidden", textOverflow: "ellipsis", fontSize: "32px", lineHeight: "32px" }
            : { overflow: "hidden", textOverflow: "ellipsis" }
        }
      >
        {suggestion.label || suggestion.value}
      </div>
    </MenuItem>
  );
};

export default memo(ItemRenderer);
