import { OTP_SECRET_ERRORS } from "~/constants/errorMessages";
import { t } from "~/i18n";
import { firebaseModel } from "~/modules/firebase";
import { TGetBusinessKeyError, TProcessError } from "~/shared/types";

export const showErrorMessage = (
  data: TProcessError,
  callback: (message: string) => void,
  defaultErrorMessage = t("ERRORS.unknown")
) => {
  const errorMessage = data?.error?.response?.data?.body?.error;

  return errorMessage ? callback(errorMessage) : callback(defaultErrorMessage);
};

export const showOtpSecretError = (data: Record<"error", TGetBusinessKeyError>, elseCallback?: () => void) => {
  const message = data?.error?.error.response?.data.error;

  if (message === OTP_SECRET_ERRORS.otpSecretIsIncorrect) {
    return firebaseModel.otpSecretIsIncorrect();
  } else {
    elseCallback && elseCallback();
  }
};
