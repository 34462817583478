import { omit } from 'lodash';

import { AsyncData, AsyncDataPhase, createReducer, onReset } from '~/lib/redux';
import { initial } from '~/lib/redux/async';

import { BankResponse } from '~/entities/banks';

import { banksClearAction, banksErrorAction, banksStartAction, banksSuccessAction } from '../actions';

export const STATE_NAME = 'root';

const defaultState: {
  [scopeId: string]: AsyncData<BankResponse>;
} = {};

const reducer = createReducer({}, defaultState);

reducer.on(banksStartAction, (state, _, meta) => {
  const scopeId = meta?.scopeId;
  if (!scopeId) return state;

  const item = state[scopeId] ?? initial();
  return {
    ...state,
    [scopeId]: {
      ...item,
      phase: AsyncDataPhase.Pending,
    },
  };
});

reducer.on(banksSuccessAction, (state, payload, meta) => {
  const scopeId = meta?.scopeId;
  if (!scopeId) return state;

  return {
    ...state,
    [scopeId]: {
      phase: AsyncDataPhase.Success,
      data: payload,
      error: null,
    },
  };
});

reducer.on(banksErrorAction, (state, payload, meta) => {
  const scopeId = meta?.scopeId;
  if (!scopeId) return state;

  return {
    ...state,
    [scopeId]: {
      phase: AsyncDataPhase.Error,
      error: payload,
      data: null,
    },
  };
});

reducer.on(banksClearAction, (state, { scopeId }) => {
  if (!scopeId) return state;

  return omit(state, [scopeId]);
});

onReset(reducer, defaultState);

export type BanksRootState = typeof defaultState;

export default reducer;
