import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import DialogBase from "@mui/material/Dialog";
import cn from "classnames";
import { makeStyles } from "tss-react/mui";

import { Theme } from "~/configs/theme";
import { colors } from "~/constants/enums";
import Icon from "~/shared/Icon";
import colorTable from "~/shared/ui-kit/constants/colors";

interface IProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const useDialogStyles = makeStyles()((theme: Theme) => ({
  paper: {
    cursor: "pointer",
    overflow: "initial",
    borderTopRightRadius: "8px!important",
    borderTopLeftRadius: "8px!important",
    maxWidth: "970px!important",
    overflowY: "visible",
    [theme.breakpoints.down(1200)]: {
      width: "100%",
      maxWidth: "auto",
    },
    "&.MuiDialog-paper": {
      overflowY: "visible",
    },
  },
  root: {
    overflowY: "visible",
    "& .MuiBackdrop-root": {
      [theme.breakpoints.down(768)]: {
        backgroundColor: "rgba(0, 0, 0, 0)",
      },
    },
  },
  scrollPaper: {
    overflowY: "visible",
    [theme.breakpoints.down(768)]: {
      alignItems: "flex-end",
      width: "100vw",
    },
  },
  paperScrollPaper: {
    overflowY: "visible",
    margin: "70px",
    [theme.breakpoints.down(768)]: {
      margin: 0,
    },
    [theme.breakpoints.down(576)]: {
      maxHeight: "calc(100% - 62px)",
    },
  },
}));

const useIconButtonStyles = makeStyles()((theme: Theme) => ({
  root: {
    zIndex: 1,
    position: "absolute",
    right: -60,
    color: colorTable.white,
    [theme.breakpoints.down(1200)]: {
      right: -40,
    },
    [theme.breakpoints.down(768)]: {
      right: 16,
      top: 24,
    },
  },
}));

const Dialog = ({ open, onClose, children }: IProps) => {
  const { classes: dialogClasses } = useDialogStyles();
  const { classes: iconButtonClasses } = useIconButtonStyles();

  return (
    <DialogBase
      open={open}
      onClose={onClose}
      classes={dialogClasses}
      scroll="paper"
      BackdropProps={{ style: { backgroundColor: colors.dialogBackground } }}
    >
      <Box onClick={onClose} className={cn(iconButtonClasses.root)}>
        <Icon name="close" size={24} />
      </Box>
      {children}
    </DialogBase>
  );
};

export default Dialog;
